import { render, staticRenderFns } from "./TemplatesLayout.vue?vue&type=template&id=4a637bed&"
import script from "./TemplatesLayout.vue?vue&type=script&lang=ts&"
export * from "./TemplatesLayout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_jmni46ydenuhv6sytlbyqtthlq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports