













































































































import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import FileUpAndDownload from '@/components/FileUpAndDownload.vue';
import ReportType from '@/models/ReportType';

export default mixins(CreateAndUpdateForm, SharedDataMixin).extend({
  components: { FileUpAndDownload },
  name: 'report-template-details-form',
  props: {
    fileDe: Object,
    fileEn: Object,
    fileFr: Object,
    fileIt: Object
  },
  data() {
    return {
      hasApiError: false
    };
  },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.reportTemplateForm);
    (this.$refs.templatename as HTMLInputElement).focus();
  },
  computed: {
    reportTypes: function () {
      return this.enumToObjectArray(ReportType).map((rt) => ({
        id: rt.id,
        name: rt.name === 'GoodManufacturingPractice' ? 'GMP' : rt.name
      }));
    }
  },
  methods: {
    uploadFile: async function (id: string, file: File) {
      const language = id.substring(id.length - 2);
      this.$emit('file-uploaded', language, file);
    },
    downloadFile: async function (id: string) {
      const language = id.substring(id.length - 2);
      this.$emit('file-downloaded', language);
    }
  }
});
