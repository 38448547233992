/* eslint-disable max-classes-per-file */

type AnalysisGroupCategoryState = 'new' | 'existing' | 'none';

export interface IAnalysisGroupCategoryState {
  kind: AnalysisGroupCategoryState;
  id: number | undefined;
  parentId: number | undefined;
}

export class NewAnalysisGroupCategory implements IAnalysisGroupCategoryState {
  public kind: AnalysisGroupCategoryState = 'new';
  public id: number | undefined = undefined;
  public parentId: number | undefined;
  constructor(parentId?: number | undefined) {
    this.parentId = parentId;
  }
}

export class ExistingAnalysisGroupCategory implements IAnalysisGroupCategoryState {
  public kind: AnalysisGroupCategoryState = 'existing';
  public id: number;
  public parentId: number | undefined;
  constructor(id: number) {
    this.id = id;
  }
}

export class NoAnalysisGroupCategory implements IAnalysisGroupCategoryState {
  public kind: AnalysisGroupCategoryState = 'none';
  public id: number | undefined = undefined;
  public parentId: number | undefined = undefined;
}
