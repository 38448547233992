





































































































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import _ from 'lodash';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import ReportCommentDetailsForm from '@/views/MasterData/ReportComment/ReportCommentDetailsForm.vue';
import {
  IReportCommentState,
  NoReportComment,
  ExistingReportComment,
  NewReportComment
} from '@/models/IReportCommentState';
import IReportComment from '@/models/IReportComment';
import IDepartment from '@/models/IDepartment';

export default mixins(SettingsMixin, SharedDataMixin).extend({
  components: { ReportCommentDetailsForm },
  name: 'report-comment',
  props: {
    itemId: String
  },
  data: function () {
    return {
      reportCommentsRd: RemoteData.notAsked<Array<IReportComment>, Error>(),
      reportCommentRd: UpdatableRemoteData.notAsked<IReportComment, Error>(),
      reportCommentState: new NoReportComment()
    };
  },
  created: async function () {
    await this.loadReportComments();
    if (this.id) {
      this.loadReportComment(this.id);
      this.reportCommentState = new ExistingReportComment(this.id);
    }
  },
  computed: {
    id: function (): number {
      return +this.itemId;
    }
  },
  watch: {
    reportCommentState: async function (value: IReportCommentState) {
      switch (value.kind) {
        case 'none':
          this.reportCommentRd = UpdatableRemoteData.notAsked();
          this.$router.push(`/masterdata/reportcomment`);
          break;
        case 'new':
          this.reportCommentRd = UpdatableRemoteData.notCreated({
            departmentCode: this.$department,
            nameDe: '',
            nameFr: '',
            nameIt: '',
            nameEn: ''
          } as IReportComment);
          this.$router.push(`/masterdata/reportcomment`);
          break;
        case 'existing':
          if (value.id) {
            await this.loadReportComment(value.id);
            this.$router.push(`/masterdata/reportcomment/${value.id}`);
          }
          break;
      }
    }
  },
  methods: {
    reportCommentDepartments: function (): IDepartment[] {
      return this.$departments
        .filter(
          (d) =>
            this.reportCommentsRd
              .withDefault([])
              .map((rc) => rc.departmentCode)
              .indexOf(d.code) >= 0
        )
        .sort();
    },
    reportCommentsByDepartment: function (department: string) {
      return _.sortBy(
        this.reportCommentsRd.getData().filter((rc) => rc.departmentCode === department),
        (rc) => rc.name
      );
    },
    loadReportComments: async function () {
      try {
        this.reportCommentsRd = await this.$labordatApi.getPagedRd(`/api/reportcomment`);
      } catch (error) {
        this.reportCommentsRd = RemoteData.failure(error as Error);
      }
    },
    loadReportComment: async function (id: number) {
      try {
        this.reportCommentRd = UpdatableRemoteData.loading();
        this.reportCommentRd = await this.$labordatApi.getUrd(`/api/reportcomment/${id}`);
      } catch (error) {
        this.reportCommentRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    selectReportComment: function (event: Event) {
      const itemId = +(event.target as HTMLInputElement).value;
      if (itemId) {
        this.reportCommentState = new ExistingReportComment(itemId);
      }
    },
    newReportComment: function () {
      this.reportCommentState = new NewReportComment();
    },
    deleteReportComment: async function () {
      if (this.reportCommentState.kind !== 'existing' || !this.reportCommentRd.hasData()) {
        return;
      }

      if (confirm('Wollen Sie den Berichtskommentar wirklich löschen?') === false) {
        return;
      }

      try {
        this.reportCommentRd = UpdatableRemoteData.updating(this.reportCommentRd.getData());

        const reportComment = this.reportCommentRd.getData();
        await this.$labordatApi.delete(`/api/reportcomment/${reportComment.id}`, {
          data: reportComment
        });

        this.reportCommentState = new NoReportComment();
        await this.loadReportComments();
      } catch (error) {
        this.reportCommentRd = UpdatableRemoteData.updateFailure(
          this.reportCommentRd.getData(),
          error as Error
        );
      }
    },
    resetReportComment: function () {
      this.reportCommentState = new NoReportComment();
    },
    createReportComment: async function () {
      const reportComment = this.reportCommentRd.getData();
      this.reportCommentRd = UpdatableRemoteData.updating(reportComment);
      try {
        const result: IReportComment = await this.$labordatApi
          .post(`/api/reportcomment`, reportComment)
          .then((response) => response.data as IReportComment);

        this.reportCommentState = new ExistingReportComment(result.id);
      } catch (error) {
        this.reportCommentRd = UpdatableRemoteData.updateFailure<IReportComment, Error>(
          reportComment,
          error as Error
        );
      }
      await this.loadReportComments();
    },
    updateReportComment: async function () {
      const reportComment = this.reportCommentRd.getData();
      this.reportCommentRd = UpdatableRemoteData.updating(reportComment);
      this.reportCommentRd = await this.$labordatApi
        .put(`/api/reportcomment/${reportComment.id}`, reportComment)
        .then((response) => response.data as IReportComment)
        .then((data) => {
          return UpdatableRemoteData.success<IReportComment, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IReportComment, Error>(reportComment, error as Error)
        );
      await this.loadReportComments();
    }
  }
});
