import { render, staticRenderFns } from "./TreeViewNode.vue?vue&type=template&id=04800569&scoped=true&"
import script from "./TreeViewNode.vue?vue&type=script&lang=ts&"
export * from "./TreeViewNode.vue?vue&type=script&lang=ts&"
import style0 from "./TreeViewNode.vue?vue&type=style&index=0&id=04800569&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_jmni46ydenuhv6sytlbyqtthlq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04800569",
  null
  
)

export default component.exports