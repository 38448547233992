import Vue from 'vue';
import Router from 'vue-router';
import OrderLayout from './views/OrderLayout.vue';
import OrderDetails from './views/Order/OrderDetails.vue';
import OrderSamples from './views/Order/OrderSamples.vue';
import OrderPreparation from './views/Order/OrderPreparation.vue';
import OrderReport from './views/Order/OrderReport.vue';
import OrderReportDetails from './views/Order/OrderReportDetails.vue';
import AutomaticReportSendingWorkflow from './views/Order/AutomaticReportSendingWorkflow/AutomaticReportSendingWorkflow.vue';
import OrderInvoicing from './views/Order/OrderInvoicing.vue';
import SummaryLayout from './views/SummaryLayout.vue';
import MasterDataLayout from './views/MasterData/MasterDataLayout.vue';
import TemplatesLayout from './views/Templates/TemplatesLayout.vue';
import BusinessPartnerLayout from './views/BusinessPartner/BusinessPartnerLayout.vue';
import Billing from './views/Billing.vue';
import SystemInformationLayout from './views/SystemInformation/SystemInformationLayout.vue';
import AutomaticReportSendingProtocol from './views/AutomaticReportSending/AutomaticReportSendingProtocol.vue';
import AccessDenied from './views/AccessDenied.vue';
import AuthenticationStateService from './services/AuthenticationStateService';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/orders',
      meta: {
        requireAuth: true
      },
      component: OrderLayout,
      props: true,
      children: [
        {
          path: '',
          component: OrderDetails
        }
      ]
    },
    {
      path: '/orders/:orderId',
      meta: {
        requireAuth: true
      },
      component: OrderLayout,
      props: true,
      children: [
        {
          path: '',
          component: OrderDetails
        },
        {
          path: 'samples',
          component: OrderSamples
        },
        {
          path: 'samples/:itemNumber',
          component: OrderSamples,
          props: true
        },
        {
          path: 'samples/:itemNumber/:fast',
          component: OrderSamples,
          props: true
        },
        {
          path: 'preparation',
          component: OrderPreparation
        },
        {
          path: 'report',
          component: OrderReport
        },
        {
          name: 'ReportDetails',
          path: 'report/:reportId',
          component: OrderReportDetails,
          props: true
        },
        {
          name: 'AutomaticReportSendingWorkflow',
          path: 'report/:reportId/automaticReportSendingWorkflow',
          component: AutomaticReportSendingWorkflow,
          props: true
        },
        {
          path: 'invoicing',
          component: OrderInvoicing
        }
      ]
    },
    {
      path: '/summaries',
      alias: '/',
      meta: {
        requireAuth: true
      },
      component: SummaryLayout
    },
    {
      path: '/billing',
      meta: {
        requireAuth: true,
        areAdminPermissionsRequired: true
      },
      component: Billing
    },
    {
      path: '/automaticreportsending',
      meta: {
        requireAuth: true
      },
      component: AutomaticReportSendingProtocol
    },
    {
      path: '/masterdata',
      meta: {
        requireAuth: true,
        areAdminPermissionsRequired: true
      },
      redirect: '/masterdata/analysisgroup'
    },
    {
      path: '/masterdata/:entityId/:itemId?',
      meta: {
        requireAuth: true,
        areAdminPermissionsRequired: true
      },
      component: MasterDataLayout,
      props: true
    },
    {
      path: '/templates',
      meta: {
        requireAuth: true,
        areAdminPermissionsRequired: true
      },
      redirect: '/templates/reporttemplate'
    },
    {
      path: '/templates/:entityId/:itemId?',
      meta: {
        requireAuth: true,
        areAdminPermissionsRequired: true
      },
      component: TemplatesLayout,
      props: true
    },
    {
      path: '/businesspartner',
      meta: {
        requireAuth: true
      },
      component: BusinessPartnerLayout
    },
    {
      path: '/systeminformation',
      meta: {
        requireAuth: true,
        areAdminPermissionsRequired: true
      },
      component: SystemInformationLayout
    },
    {
      name: 'AccessDenied',
      path: '/accessdenied',
      component: AccessDenied
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const needsAdminPermissionsToEnter: boolean =
    to.meta !== undefined &&
    'areAdminPermissionsRequired' in to.meta &&
    to.meta.areAdminPermissionsRequired === true;

  if (!needsAdminPermissionsToEnter) {
    next();
    return;
  }

  await Vue.prototype.$msal.acquireToken();
  if (
    !AuthenticationStateService.authenticatedUserHasAdminPermissions(
      Vue.prototype.$msal.data?.graph?.groups?.value
    )
  ) {
    next({ name: 'AccessDenied' });
    return;
  }

  next();
});

export default router;
