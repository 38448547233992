import Vue from 'vue';
import $ from 'jquery';

export default Vue.extend({
  methods: {
    setTypeaheadInputInvalid: function (typeahead: HTMLInputElement, message: string) {
      typeahead.setCustomValidity(message);
      typeahead.reportValidity();
      typeahead.oninput = function () {
        // @ts-ignore
        this.setCustomValidity('');
      };
    },
    typeaheadInputById: function (id: string): HTMLInputElement {
      return $(`#${id} input[type="search"]`)[0] as HTMLInputElement;
    }
  }
});
