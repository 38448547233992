


















































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import {
  SmartTable,
  Config,
  Column,
  Sorting,
  PagingOptions,
  State,
  Filter
} from 'rey-vue-smarttable';
import IOrderRequest from '@/models/IOrderRequest';

export default mixins(SettingsMixin).extend({
  name: 'businesspartnerlayout',
  components: { SmartTable },
  data: function () {
    return {
      config: new Config(
        (x) => x.bppId,
        [
          new Column({ title: 'Kundennr.', fieldName: 'customerNumber', fieldType: 'numeric' }),
          new Column({ title: 'Firma', fieldName: 'customerCompanyName', fieldType: 'string' }),
          new Column({ title: 'Kontaktname', fieldName: 'customerContact', fieldType: 'string' }),
          new Column({
            title: 'Auftragsbezeichnung',
            fieldName: 'orderDescription',
            fieldType: 'string'
          }),
          new Column({
            title: 'Auftragseingang',
            fieldName: 'receiptDate',
            fieldType: 'date'
          })
        ],
        [new Sorting('receiptDate', 'ascending')],
        new PagingOptions(25),
        undefined,
        undefined,
        [new Filter('requestStatus', 'numeric', 'equals', ['0'])]
      ).withEfApiActions(
        // @ts-ignore
        this.$labordatApi,
        'orderrequest'
      ),
      state: new State([new Sorting('receiptDate', 'ascending')]),
      selectedItem: undefined as IOrderRequest | undefined,
      requestStatus: 'ok' as 'failure' | 'running' | 'ok'
    };
  },
  methods: {
    acceptOrder: async function () {
      try {
        this.requestStatus = 'running';

        if (this.selectedItem !== undefined) {
          await this.$labordatApi
            .post(`/api/businesspartner/accept/${this.selectedItem.bppId}/${this.$department}`)
            .then((response) => {
              this.selectedItem = undefined;
              this.requestStatus = 'ok';
              this.$router.push(`/orders/${response.data}`);
            });
        }
      } catch (error) {
        this.requestStatus = 'failure';
      }
    }
  }
});
