var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('h3',[_vm._v("Grundlagen für Bewertung")]),(_vm.sampleBasisOfValidationsRd.hasData())?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sampleBasisOfValidationState.id),expression:"sampleBasisOfValidationState.id"}],staticClass:"custom-select",attrs:{"size":"16"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.sampleBasisOfValidationState, "id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.selectSampleBasisOfValidation]}},_vm._l((_vm.sampleBasisOfValidationDepartments()),function(department){return _c('optgroup',{key:department.code,attrs:{"label":department.code + ': ' + department.name}},_vm._l((_vm.sampleBasisOfValidationsByDepartment(
              department.code
            )),function(sampleBasisOfValidation){return _c('option',{key:sampleBasisOfValidation.name,domProps:{"value":sampleBasisOfValidation.id}},[_vm._v(" "+_vm._s(("" + (sampleBasisOfValidation.name)))+" ")])}),0)}),0):_vm._e()]),_c('div',{staticClass:"col-sm-8"},[_c('h3',[_vm._v("Details")]),(_vm.sampleBasisOfValidationRd.isNotAsked())?_c('div',[_c('span',[_vm._v("Keine Grundlage für Bewertung ausgewählt.")])]):_vm._e(),(_vm.sampleBasisOfValidationRd.isLoading())?_c('div',[_c('loading')],1):_vm._e(),(_vm.sampleBasisOfValidationRd.hasError())?_c('div',{staticClass:"my-3"},[_c('alert',{attrs:{"mode":"danger"}},[_vm._v("Ein Fehler ist aufgetreten. Bitte laden Sie die Seite neu.")])],1):_vm._e(),(_vm.sampleBasisOfValidationRd.hasData())?_c('div',[(
            _vm.sampleBasisOfValidationState.kind === 'existing' ||
            _vm.sampleBasisOfValidationState.kind === 'new'
          )?_c('sample-basis-of-validation-details-form',{ref:"sampleBasisOfValidationDetailsForm",attrs:{"is-in-update-mode":_vm.sampleBasisOfValidationState.kind === 'existing'},on:{"update":_vm.updateSampleBasisOfValidation,"create":_vm.createSampleBasisOfValidation},model:{value:(_vm.sampleBasisOfValidationRd.getData()),callback:function ($$v) {_vm.$set(_vm.sampleBasisOfValidationRd, "getData()", $$v)},expression:"sampleBasisOfValidationRd.getData()"}}):_vm._e()],1):_vm._e()])]),_c('sticky-actionbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":_vm.newSampleBasisOfValidation}},[_c('em',{staticClass:"fas fa-plus"}),_vm._v(" Neue Grundlage für Bewertung ")])]),(_vm.sampleBasisOfValidationState.kind === 'existing')?_c('div',[_c('button',{staticClass:"btn btn-danger mx-1",attrs:{"disabled":_vm.sampleBasisOfValidationRd.isUpdating() === true},on:{"click":_vm.deleteSampleBasisOfValidation}},[_c('em',{staticClass:"fas fa-trash"}),_vm._v(" Grundlage für Bewertung löschen ")])]):_vm._e()]},proxy:true},{key:"right",fn:function(){return [(_vm.sampleBasisOfValidationState.kind === 'new')?_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":function($event){return _vm.$refs.sampleBasisOfValidationDetailsForm.validateAndCreate()}}},[_vm._v(" Erstellen ")])]):_vm._e(),(_vm.sampleBasisOfValidationState.kind === 'new')?_c('div',[_c('button',{staticClass:"btn btn-secondary mx-1",on:{"click":_vm.resetSampleBasisOfValidation}},[_vm._v(" Abbrechen ")])]):_vm._e(),(_vm.sampleBasisOfValidationRd.isUpdateFailure())?_c('div',{staticClass:"text-danger"},[_vm._v(" Es ist ein Fehler aufgetreten. Laden Sie die Seite neu. "),_c('em',{staticClass:"fas fa-exclamation-triangle mx-3"})]):(
          _vm.sampleBasisOfValidationState.kind === 'existing' &&
          _vm.sampleBasisOfValidationRd.isUpdating() === true
        )?_c('div',[_vm._v(" Änderungen werden gespeichert "),_c('em',{staticClass:"fas fa-sync-alt fa-spin mx-3"})]):(
          _vm.sampleBasisOfValidationState.kind === 'existing' && _vm.sampleBasisOfValidationRd.hasData()
        )?_c('div',[_vm._v(" Alle Daten gespeichert "),_c('em',{staticClass:"fas fa-check mx-3"})]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }