












































































import Vue, { PropType } from 'vue';
import SelectableTag from './SelectableTag';

type PossiblePopoversToDisplay = 'None' | 'TagProposals' | 'CreateNewTag' | 'NoMatchingTagsFound';
export default Vue.extend({
  name: 'TagSelection',
  props: {
    selectedTags: {
      type: Array as PropType<Array<SelectableTag>>,
      required: true
    },
    availableTags: {
      type: Array as PropType<Array<SelectableTag>>,
      required: true
    },
    removeTagButtonCssClasses: {
      type: String,
      default: ''
    },
    defaultTagFontColor: {
      type: String,
      default: 'white'
    },
    defaultTagBackgroundColor: {
      type: String,
      default: '#333'
    },
    inputPlaceholder: {
      type: String,
      default: 'Weitere Tags hinzufügen...'
    },
    labelTagSelection: {
      type: String,
      default: 'Tag auswählen:'
    },
    isTagCreationEnabled: Boolean,
    labelTagCreation: {
      type: String,
      default: 'Neuen Tag erstellen:'
    },
    labelNoMatchingTagsFound: {
      type: String,
      default: 'Es konnten keine passenden Tags gefunden werden'
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentRef: 'tagComponent',
      inputRef: 'input',
      editingStarted: false,
      inputFieldValue: '',
      hoveredTagProposal: null as null | number
    };
  },
  mounted() {
    document.body.addEventListener('click', this.handlePotentialClickOutside);
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handlePotentialClickOutside);
  },
  watch: {
    inputFieldValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.hoveredTagProposal = null;
      }
    }
  },
  computed: {
    textInputStarted(): boolean {
      return !!this.inputFieldValue;
    },
    tagProposalsToDisplay(): Array<SelectableTag> {
      if (!this.inputFieldValue) {
        return this.availableTags;
      }
      return this.availableTags.filter(
        (tag) => tag.name.toLowerCase().indexOf(this.inputFieldValue.toLowerCase()) >= 0
      );
    },
    informationalPopoverToDisplay(): PossiblePopoversToDisplay {
      if (!this.editingStarted) {
        return 'None';
      }

      let hasProposalsToDisplay = this.tagProposalsToDisplay.length > 0;
      if (hasProposalsToDisplay) {
        return 'TagProposals';
      }

      if (!this.isTagCreationEnabled) {
        return 'NoMatchingTagsFound';
      }

      if (this.isTagCreationEnabled) {
        return this.inputFieldValue ? 'CreateNewTag' : 'None';
      }

      return 'None';
    }
  },
  methods: {
    handlePotentialClickOutside(event: Event) {
      let wasClickInsideComponent = event
        .composedPath()
        .includes(this.$refs[this.componentRef] as HTMLElement);

      let wasClickInsideTagProposalPopover = event
        .composedPath()
        .includes(this.$refs[this.componentRef] as HTMLElement);

      let wasClickInsideCreateTagPopover = event
        .composedPath()
        .includes(this.$refs[this.componentRef] as HTMLElement);

      if (
        !wasClickInsideComponent &&
        !wasClickInsideTagProposalPopover &&
        !wasClickInsideCreateTagPopover
      ) {
        this.stopEditing();
      }
    },
    handleEscPressed() {
      this.stopEditing();
      (this.$refs[this.inputRef] as HTMLElement).blur();
    },
    startEditing() {
      if (this.isReadOnly) {
        return;
      }
      this.editingStarted = true;
      (this.$refs[this.inputRef] as HTMLElement).focus();
    },
    stopEditing() {
      this.inputFieldValue = '';
      this.editingStarted = false;
    },
    tryToHoverPreviousProposal() {
      let indexOfProposalToHover: number;
      if (this.hoveredTagProposal === null) {
        indexOfProposalToHover = 0;
      } else {
        let indexOfCurrentlyHoveredProposal = this.tagProposalsToDisplay.findIndex(
          (tag) => tag.id == this.hoveredTagProposal
        );
        if (indexOfCurrentlyHoveredProposal == -1) {
          return;
        }
        indexOfProposalToHover =
          indexOfCurrentlyHoveredProposal == 0
            ? indexOfCurrentlyHoveredProposal
            : indexOfCurrentlyHoveredProposal - 1;
      }

      let previousTagProposal = this.tagProposalsToDisplay.at(indexOfProposalToHover);
      if (previousTagProposal !== undefined) {
        this.hoveredTagProposal = previousTagProposal.id;
      }
    },
    tryToHoverNextProposal() {
      let indexOfProposalToHover: number;
      if (this.hoveredTagProposal === null) {
        indexOfProposalToHover = 0;
      } else {
        let indexOfCurrentlyHoveredProposal = this.tagProposalsToDisplay.findIndex(
          (tag) => tag.id == this.hoveredTagProposal
        );
        if (indexOfCurrentlyHoveredProposal == -1) {
          return;
        }
        indexOfProposalToHover = indexOfCurrentlyHoveredProposal + 1;
      }

      let nextTagProposal = this.tagProposalsToDisplay.at(indexOfProposalToHover);
      if (nextTagProposal !== undefined) {
        this.hoveredTagProposal = nextTagProposal.id;
      }
    },
    handleClickOnTagProposal(e: Event, clickedTagProposal: SelectableTag) {
      this.addExistingTag(clickedTagProposal);
      (this.$refs[this.inputRef] as HTMLElement).focus();
    },
    handleClickOnCreateNewTagPreview() {
      this.createNewTag();
      (this.$refs[this.inputRef] as HTMLElement).focus();
    },
    handleEnterPressed() {
      if (
        this.informationalPopoverToDisplay === 'TagProposals' &&
        this.hoveredTagProposal !== null
      ) {
        let tagProposalToAdd = this.availableTags.find((x) => x.id === this.hoveredTagProposal);
        if (tagProposalToAdd) {
          this.addExistingTag(tagProposalToAdd);
        }
      }
      if (this.informationalPopoverToDisplay === 'CreateNewTag') {
        this.createNewTag();
      }
    },
    addExistingTag(tagToAdd: SelectableTag) {
      let safeCopy: SelectableTag = Object.assign({}, tagToAdd);
      this.$emit('tag-added', safeCopy);
    },
    createNewTag() {
      if (!this.isTagCreationEnabled || !this.inputFieldValue) {
        return;
      }
      let newlyCreatedTag: SelectableTag = {
        id: 0,
        name: this.inputFieldValue,
        slug: this.inputFieldValue,
        fontColor: this.defaultTagFontColor,
        backgroundColor: this.defaultTagBackgroundColor
      };
      this.inputFieldValue = '';
      this.$emit('tag-created', newlyCreatedTag);
    },
    handleClickOnRemoveTagButton(clickedTagProposal: SelectableTag) {
      let safeCopy: SelectableTag = Object.assign({}, clickedTagProposal);
      this.$emit('tag-removed', safeCopy);
      (this.$refs[this.inputRef] as HTMLElement).focus();
    },
    getInlineStylingsForTag(tagToRender: SelectableTag | null = null): string {
      if (tagToRender) {
        return `background-color: ${this.determineTagBackgroundColor(tagToRender)};
                border-color: ${this.determineTagBackgroundColor(tagToRender)};
                color: ${this.determineTagFontColor(tagToRender)}`;
      }
      return `background-color: ${this.defaultTagBackgroundColor};
              border-color: ${this.defaultTagBackgroundColor};
              color: ${this.defaultTagFontColor}`;
    },
    determineTagFontColor(tag: SelectableTag) {
      return tag.fontColor ? tag.fontColor : this.defaultTagFontColor;
    },
    determineTagBackgroundColor(tag: SelectableTag) {
      return tag.backgroundColor ? tag.backgroundColor : this.defaultTagBackgroundColor;
    }
  }
});
