import Vue from 'vue';
import ISummaryDefinitionEntity from '@/models/ISummaryDefinitionEntity';
import { SummaryDefinitionCategoryType } from '@/models/ISummaryDefinition';

export default Vue.extend({
  data: function () {
    return {
      entities: <Array<ISummaryDefinitionEntity>>[
        {
          entityType: 'Order',
          name: 'Auftrag'
        },
        {
          entityType: 'Sample',
          name: 'Probe'
        },
        {
          entityType: 'AnalysisParameter',
          name: 'Analyseparameter'
        }
      ]
    };
  },
  methods: {
    getGermanLabelOfCategory: function (categoryType: SummaryDefinitionCategoryType) {
      switch (categoryType) {
        case 'Order':
          return 'Auftrag';
        case 'AnalysisParameter':
          return 'Analyseparameter';
        case 'Sample':
          return 'Probe';
        case 'Additional':
          return 'Weitere';
        case 'Budget':
          return 'Budget';
        default:
          return '';
      }
    }
  }
});
