import Vue from 'vue';

export default Vue.extend({
  props: ['value'],
  data: function () {
    return {
      model: this.value
    };
  },
  watch: {
    model: {
      deep: true,
      handler: function (value) {
        this.$emit('input', value);
      }
    },
    value: {
      deep: true,
      handler: function (value) {
        this.model = value;
      }
    }
  }
});
