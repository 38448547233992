/* eslint-disable max-classes-per-file */
import { Result } from 'rey-frontend-fp';
import _ from 'lodash';

type Selection = number[];

class SelectionParser {
  private SINGLE_DELIMITER = ',';
  private RANGE_DELIMITER = '-';

  public parse(value: string, items: number[]): Result<Selection, string> {
    const parts = this.splitRanges(value);

    try {
      const values = parts
        .map((part: string) => this.parsePart(part, items))
        .reduce((acc, curr) => acc.concat(curr))
        .sort((a, b) => a - b);
      const result = _.sortedUniq(values);

      return Result.ok(result);
    } catch (error) {
      return Result.err((error as Error).message || 'Fehler');
    }
  }

  private splitRanges(value: string): string[] {
    return value.split(this.SINGLE_DELIMITER);
  }

  private parsePart(part: string, items: number[]): number[] {
    const values = part.split(this.RANGE_DELIMITER);

    if (values.length === 1) {
      return [this.parseInteger(values[0], items)];
    } else if (values.length === 2) {
      const first = this.parseInteger(values[0], items);
      const second = this.parseInteger(values[1], items);
      return this.buildRange(first, second, items);
    } else {
      throw new Error(`${part} enthält zu viele Bindestriche`);
    }
  }

  private parseInteger(value: string, items: number[]): number {
    if (!value) {
      throw new Error('Es fehlt eine Zahl');
    }

    // this is more strict than parseInt(value, 10);
    const parsed = +value;
    if (isNaN(parsed) === true) {
      throw new Error(`${value} ist keine Zahl`);
    }
    if (items == null || items.indexOf(parsed) < 0) {
      throw new Error(`${value} ist keine gültige Probe`);
    }
    return parsed;
  }

  private buildRange(first: number, second: number, items: number[]): number[] {
    if (first > second) {
      throw new Error(`${first} ist grösser als ${second}`);
    }
    if (items == null || first < items[0]) {
      throw new Error(`${first} ist kleiner als ${items[0]}`);
    }
    if (items == null || second > items[items.length - 1]) {
      throw new Error(`${second} ist grösser als ${items[items.length - 1]}`);
    }
    return _.intersection(_.range(first, second + 1), items);
  }
}

export default class SampleSelection {
  public result: Result<Selection, string>;
  public input: string;

  constructor(value: string, items: number[]) {
    if (items) {
      items.sort(function (one, two) {
        return one - two;
      });
    }

    this.input = value;
    this.result = this.parse(value, items);
  }

  private parse(value: string, items: number[]): Result<Selection, string> {
    const parser = new SelectionParser();
    const result = parser.parse(value, items);
    if (result.isOk()) {
      this.input = this.format(result.getData());
    }
    return result;
  }

  private format(items: number[]): string {
    let text = '';
    let startNumber: number | undefined;
    let previousCompareValue: number | undefined;
    let compareValue = Math.min.apply(null, items);
    for (const item of items) {
      if (item !== compareValue) {
        if (startNumber !== previousCompareValue) {
          text += `${startNumber}-${previousCompareValue},`;
        } else {
          text += `${previousCompareValue},`;
        }
        startNumber = undefined;
      }

      if (startNumber === undefined) {
        compareValue = item;
        startNumber = compareValue;
      }

      previousCompareValue = compareValue;
      compareValue++;
    }

    if (previousCompareValue) {
      if (startNumber && startNumber < previousCompareValue) {
        text += `${startNumber}-${previousCompareValue},`;
      } else {
        text += `${previousCompareValue},`;
      }
    }

    if (text.endsWith(',') === true) {
      text = text.substring(0, text.length - 1);
    }

    return text;
  }
}
