import Vue from 'vue';
import { RemoteData } from 'rey-frontend-fp';
import ISignee from '@/models/ISignee';

export default Vue.extend({
  data: function () {
    return {
      signeesRd: RemoteData.notAsked<Array<ISignee>, Error>()
    };
  },
  methods: {
    loadSignees: async function () {
      this.signeesRd = await this.$labordatApi.getRd('/api/signees');
    }
  }
});
