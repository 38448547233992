






















































import Vue from 'vue';
import { UpdatableRemoteData } from 'rey-frontend-fp';

export default Vue.extend({
  data: function () {
    return {
      requestState: UpdatableRemoteData.notAsked<unknown, Error>(),
      generationState: 'None' as 'None' | 'Generating' | 'Success' | 'Error',
      generationError: ''
    };
  },
  methods: {
    billNow: async function () {
      try {
        this.generationError = '';
        this.generationState = 'Generating';
        this.requestState = UpdatableRemoteData.updating({});
        await this.$labordatApi.post('/api/faktura').then((response) => {
          this.requestState = UpdatableRemoteData.success({});
          this.generationState =
            response && response.status === 200 && response.data === '{}' ? 'Success' : 'Error';
          this.generationError = response.data as string;
        });
      } catch (error) {
        this.requestState = UpdatableRemoteData.updateFailure({}, error as Error);
        this.generationState = 'Error';
      }
    }
  }
});
