


















import Vue from 'vue';
import _ from 'lodash';
import $ from 'jquery';
import SampleSelection from '@/services/SampleSelection';

export default Vue.extend({
  name: 'sample-range',
  props: {
    value: Object,
    items: Array,
    disabled: Boolean
  },
  watch: {
    value: {
      // need this for late initialization from parent
      deep: true,
      handler: function (newValue: SampleSelection, oldValue: SampleSelection) {
        if (oldValue.input !== newValue.input) {
          this.validateSampleSelection(this.value.input);
          this.$emit('change', newValue);
        }
      }
    }
  },
  methods: {
    validateSampleSelection: _.debounce(function (value: string) {
      // @ts-ignore
      const selection = new SampleSelection(value, this.items);
      // @ts-ignore
      this.$emit('input', selection);
      if (selection.result.isErr() === true) {
        $('#sampleSelectionInput').removeClass('is-valid');
        $('#sampleSelectionInput').addClass('is-invalid');
      } else {
        $('#sampleSelectionInput').removeClass('is-invalid');
        $('#sampleSelectionInput').addClass('is-valid');
      }
      $('#sampleSelectionForm').addClass('was-validated');
      _.debounce(function () {
        $('#sampleSelectionForm').removeClass('was-validated');
      }, 100)();
    }, 500)
  }
});
