


















import mixins from 'vue-typed-mixins';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import LabelTemplate from '@/views/Templates/LabelTemplate.vue';
import ReportTemplate from '@/views/Templates/ReportTemplate.vue';
import WorksheetTemplate from '@/views/Templates/WorksheetTemplate.vue';
import InvoicingTemplate from '@/views/Templates/InvoicingTemplate.vue';
import ReportPicture from '@/views/Templates/ReportPicture.vue';
import ReportPictureCategory from '@/views/Templates/ReportPictureCategory.vue';

export default mixins(SharedDataMixin).extend({
  components: {
    LabelTemplate,
    ReportTemplate,
    WorksheetTemplate,
    InvoicingTemplate,
    ReportPicture,
    ReportPictureCategory
  },
  props: {
    entityId: String,
    itemId: String
  },
  data: function () {
    return {
      entities: [
        {
          id: 'reporttemplate',
          name: 'Berichtsvorlagen',
          apiUrl: '/api/reporttemplate'
        },
        // {
        //   id: 'reportpicturecategory',
        //   name: 'Berichtsbilderkategorien',
        //   apiUrl: '/api/reportpicturecategory'
        // },
        {
          id: 'reportpicture',
          name: 'Berichtsbilder',
          apiUrl: '/api/reportpicture'
        },
        {
          id: 'worksheettemplate',
          name: 'Arbeitsblättervorlagen',
          apiUrl: '/api/worksheettemplate'
        },
        {
          id: 'labeltemplate',
          name: 'Etikettenvorlagen',
          apiUrl: '/api/labeltemplate'
        },
        {
          id: 'invoicingtemplate',
          name: 'Verrechnungsvorlagen',
          apiUrl: '/api/invoicingtemplate'
        }
      ]
    };
  }
});
