import Vue from 'vue';

export default Vue.extend({
  computed: {
    $parameterEditColumn: {
      get: function (): number {
        return this.$store.state.tempData.parameterEditColumn;
      },
      set: function (value: number) {
        this.$store.commit('tempData/setParameterEditColumn', value);
      }
    }
  }
});
