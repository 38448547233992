enum OrderStatus {
  New = 0,
  AnalysisStarted = 1,
  AnalysisCompleted = 2,
  Reported = 3,
  Invoiced = 4,
  Completed = 5
}

export default OrderStatus;
