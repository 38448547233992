
































import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import FileUpAndDownload from '@/components/FileUpAndDownload.vue';

export default mixins(CreateAndUpdateForm, SharedDataMixin).extend({
  components: { FileUpAndDownload },
  name: 'label-template-details-form',
  props: {
    standard: Object,
    anonymized: Object
  },
  data() {
    return {
      hasApiError: false
    };
  },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.labelTemplateForm);
  },
  methods: {
    uploadFile: async function (id: string, file: File) {
      this.$emit('file-uploaded', id, file);
    },
    downloadFile: async function (id: string) {
      this.$emit('file-downloaded', id);
    }
  }
});
