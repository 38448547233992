var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('h3',[_vm._v("Analysegruppenkategorien")]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"analysisGroupCategoryTypeahead"}},[_vm._v("Kategoriefilter")]),_c('analysis-group-category-typeahead',{staticClass:"mb-3",attrs:{"title":"Kategoriefilter"},on:{"hit":_vm.onHitAnalysisGroupCategory}}),(_vm.analysisGroupCategoriesRd.isLoading())?_c('loading'):(_vm.analysisGroupCategoriesRd.hasData())?_c('tree-view',{ref:"categoryTreeView",attrs:{"idPrefix":_vm.treeIdPrefix},on:{"selection-changed":_vm.categoryChanged},model:{value:(_vm.analysisGroupCategoriesRd.getData()),callback:function ($$v) {_vm.$set(_vm.analysisGroupCategoriesRd, "getData()", $$v)},expression:"analysisGroupCategoriesRd.getData()"}}):(_vm.analysisGroupCategoriesRd.hasError())?_c('alert',{attrs:{"mode":"danger"}},[_vm._v("Ein Fehler ist aufgetreten. Bitte laden Sie die Seite neu.")]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-8"},[_c('h3',[_vm._v("Details")]),(_vm.analysisGroupCategoryRd.isNotAsked())?_c('div',[_c('span',[_vm._v("Keine Kategorie ausgewählt.")])]):_vm._e(),(_vm.analysisGroupCategoryRd.isLoading())?_c('div',[_c('loading')],1):_vm._e(),(_vm.analysisGroupCategoryRd.hasError())?_c('div',{staticClass:"my-3"},[_c('alert',{attrs:{"mode":"danger"}},[_vm._v("Ein Fehler ist aufgetreten. Bitte laden Sie die Seite neu.")])],1):_vm._e(),(_vm.analysisGroupCategoryRd.hasData())?_c('div',[(
            _vm.analysisGroupCategoryState.kind === 'existing' ||
            _vm.analysisGroupCategoryState.kind === 'new'
          )?_c('analysis-group-category-details-form',{ref:"analysisGroupDetailsForm",attrs:{"is-in-update-mode":_vm.analysisGroupCategoryState.kind === 'existing'},on:{"update":_vm.updateAnalysisGroupCategory,"create":_vm.createAnalysisGroupCategory},model:{value:(_vm.analysisGroupCategoryRd.getData()),callback:function ($$v) {_vm.$set(_vm.analysisGroupCategoryRd, "getData()", $$v)},expression:"analysisGroupCategoryRd.getData()"}}):_vm._e()],1):_vm._e()])]),_c('sticky-actionbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":_vm.newAnalysisGroupCategory}},[_c('em',{staticClass:"fas fa-plus"}),_vm._v(" Neue Kategorie ")])]),_c('div',[_c('button',{staticClass:"btn btn-danger mx-1",attrs:{"disabled":_vm.analysisGroupCategoryState.kind !== 'existing' || _vm.analysisGroupCategoryRd.isUpdating()},on:{"click":_vm.deleteAnalysisGroupCategory}},[_c('em',{staticClass:"fas fa-trash"}),_vm._v(" Kategorie löschen ")])])]},proxy:true},{key:"right",fn:function(){return [(_vm.analysisGroupCategoryState.kind === 'new')?_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":function($event){return _vm.$refs.analysisGroupDetailsForm.validateAndCreate()}}},[_vm._v(" Erstellen ")])]):_vm._e(),(_vm.analysisGroupCategoryState.kind === 'new')?_c('div',[_c('button',{staticClass:"btn btn-secondary mx-1",on:{"click":_vm.resetAnalysisGroupCategory}},[_vm._v(" Abbrechen ")])]):_vm._e(),(_vm.analysisGroupCategoryRd.isUpdateFailure())?_c('div',{staticClass:"text-danger"},[_vm._v(" Es ist ein Fehler aufgetreten. Laden Sie die Seite neu. "),_c('em',{staticClass:"fas fa-exclamation-triangle mx-3"})]):(
          _vm.analysisGroupCategoryRd.isUpdating() && _vm.analysisGroupCategoryState.kind === 'existing'
        )?_c('div',[_vm._v(" Änderungen werden gespeichert "),_c('em',{staticClass:"fas fa-sync-alt fa-spin mx-3"})]):(
          _vm.analysisGroupCategoryRd.hasData() && _vm.analysisGroupCategoryState.kind === 'existing'
        )?_c('div',[_vm._v(" Alle Daten gespeichert "),_c('em',{staticClass:"fas fa-check mx-3"})]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }