
















































































































































































































































import $ from 'jquery';
import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import OrderViewHelperMixin from '@/mixins/OrderViewHelperMixin';
import FormatMixin from '@/mixins/FormatMixin';
import SettingsMixin from '@/mixins/SettingsMixin';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
// @ts-ignore
import { Pagination } from 'rey-vue-common';
import { SmartTableTable, State, Sorting, Config, Column, hasValue } from 'rey-vue-smarttable';
import FastResultEntryModal from './FastResultEntry.vue';
import IonicBalanceModal from './IonicBalanceModal.vue';
import IAnalysisGroup from '@/models/IAnalysisGroup';
import IAnalysisParameter from '@/models/IAnalysisParameter';
import IAnalysisGroupParameter from '@/models/IAnalysisGroupParameter';
import IAnalysisParameterIdentifier from '@/models/IAnalysisParameterIdentifier';
import IUpdatedAnalysisParameters from '@/models/IUpdatedAnalysisParameters';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import TempDataMixin from '@/mixins/TempDataMixin';
import IResultType from '@/models/IResultType';
import IDimension from '@/models/IDimension';
import Language from '@/models/Language';
import IAnalysisProcedure from '@/models/IAnalysisProcedure';
import LayoutElementType from '@/models/LayoutElementType';
import ISampleLayoutElement from '@/models/ISampleLayoutElement';
import IAnalysisGroupLayoutElement from '@/models/IAnalysisGroupLayoutElement';
import IAnalysisGroupElement from '@/models/IAnalysisGroupElement';
import IAnalysisParameterTableItem from '@/models/IAnalysisParameterTableItem';
import IAnalysisParameterDefinition from '@/models/IAnalysisParameterDefinition';
import AnalysisParameterDefinitionTypeahead from '@/components/AnalysisParameterDefinitionTypeahead.vue';
import ValidationStatusType from '@/models/ValidationStatusType';
import SmartTableConfigManagementMixin from '@/mixins/SmartTableConfigManagementMixin';
import ValidationStatusOverrideType from '@/models/ValidationStatusOverrideType';
import UncertaintyOfMeasurement from '@/models/UncertaintyOfMeasurementType';

export default mixins(
  OrderViewHelperMixin,
  SettingsMixin,
  SharedDataMixin,
  TempDataMixin,
  SmartTableConfigManagementMixin,
  ValidatorMixin,
  FormatMixin
).extend({
  name: 'sample-analysis-parameters',
  components: {
    AnalysisParameterDefinitionTypeahead,
    SmartTableTable,
    Pagination,
    FastResultEntryModal,
    IonicBalanceModal
  },
  props: {
    orderId: String,
    sampleId: Number,
    isLocked: Boolean,
    language: Number,
    fast: Boolean
  },
  data: function () {
    return {
      tableId: 'AnalysisParameter',
      modalOpen: false,
      resultTypesRd: RemoteData.notAsked<Array<IResultType>, Error>(),
      dimensionsRd: RemoteData.notAsked<Array<IDimension>, Error>(),
      analysisProceduresRd: RemoteData.notAsked<Array<IAnalysisProcedure>, Error>(),
      analysisGroupsRd: RemoteData.notAsked<Array<IAnalysisGroup>, Error>(),
      analysisGroupParametersRd: RemoteData.notAsked<Array<IAnalysisGroupParameter>, Error>(),
      analysisGroupLayoutElementsRd: RemoteData.notAsked<
        Array<IAnalysisGroupLayoutElement>,
        Error
      >(),
      selectedAnalysisGroup: '',
      selectedAnalysisGroupParameter: undefined as IAnalysisGroupElement | undefined,
      analysisParametersRd: UpdatableRemoteData.notAsked<Array<IAnalysisParameter>, Error>(),
      sampleLayoutElementsRd: UpdatableRemoteData.notAsked<Array<ISampleLayoutElement>, Error>(),
      isInAnalysisParameterEditMode: false,
      smartTableConfig: undefined as Config | undefined,
      smartTableState: new State([new Sorting('rapportPosition', 'ascending')]),
      smartTableSelectedAnalysisParameter: undefined as IAnalysisParameterTableItem | undefined,
      department: '',
      currentTopIndex: 0 as number,
      pageSize: 100 as number,
      analysisGroupTypeahead: '',
      updateAnalysisGroupTypeahead: _.debounce(
        async function (value: string) {
          // @ts-ignore
          await this.loadAnalysisGroups(value);
        }.bind(this),
        500
      )
    };
  },
  created: async function () {
    this.department = this.$department;

    this.initializeSmartTable();

    await this.loadResultTypes();
    await this.loadDimensions();
    await this.loadAnalysisProcedures();
    this.updateResultTypeColumnDropdownOption();
    this.updateDimensionColumnDropdownOptions();
    this.updateDepartmentColumnDropdownOptions();
    this.updateAnalysisProcedureColumnDropdownOptions();

    await this.loadAnalysisParameters();
    await this.loadSampleLayoutElements();
    await this.loadAnalysisGroups();

    this.isInAnalysisParameterEditMode = this.$analysisParameterEditMode || this.overrideEditMode;

    if (this.fast === true && this.isLocked === false) {
      this.$nextTick(() => {
        this.modalOpen = true;
        // @ts-ignore
        $('#fastResultEntryModal').modal('show');
      });
    }
  },
  mounted() {
    this.loadSmartTableConfig(this.tableId).then((config) => {
      if (config) {
        for (const filter of config?.state?.filters) {
          if (filter.fieldName?.indexOf('name') === 0) {
            filter.fieldName = this.getLanguageColumnName();
          }
        }

        // @ts-ignore
        this.$refs.smartTableAnalysisParameters.setLayout(config);
      }
    });
  },
  watch: {
    isInAnalysisParameterEditMode: function (value: boolean) {
      if (!this.overrideEditMode) {
        this.$analysisParameterEditMode = value;
      }
    },
    $department: async function () {
      await this.loadAnalysisGroups();
    },
    selectedAnalysisGroup: async function () {
      await this.loadAnalysisGroupParameters();
      await this.loadAnalysisGroupLayoutElements();
    },
    sampleId: async function () {
      await this.loadAnalysisParameters();
      await this.loadSampleLayoutElements();

      this.currentTopIndex = 0;
      this.isInAnalysisParameterEditMode = this.$analysisParameterEditMode || this.overrideEditMode;

      if (this.$parameterEditColumn) {
        _.debounce(function () {
          // @ts-ignore
          const column = this.$parameterEditColumn;
          const rows = $(`#mySmartTable tbody tr:not(.bg-layout-element) td:nth-child(${column})`);
          if (rows && rows.length > 0) {
            rows[0].click();
          }

          // @ts-ignore
          this.$parameterEditColumn = undefined;
        }, 500).bind(this)();
      }
    },
    metaRemoteStatus: function (value: 'failure' | 'updating' | 'ok') {
      this.$emit('update:metaRemoteStatus', value);
    },
    analysisGroupTypeahead: function (value: string) {
      if (value) {
        this.updateAnalysisGroupTypeahead(value);
      }
    }
  },
  computed: {
    overrideEditMode: function (): boolean {
      return (
        this.analysisParametersRd.getData().length +
          this.sampleLayoutElementsRd.getData().length ===
        0
      );
    },
    basePath: function (): string {
      return `/api/orders/${this.orderId}/samples/${this.sampleId}`;
    },
    metaRemoteStatus: function (): 'failure' | 'updating' | 'ok' {
      if (this.analysisParametersRd.hasError() || this.sampleLayoutElementsRd.hasError()) {
        return 'failure';
      } else if (
        this.analysisParametersRd.isUpdating() ||
        this.sampleLayoutElementsRd.isUpdating()
      ) {
        return 'updating';
      } else {
        return 'ok';
      }
    },
    smartTableData: function (): IAnalysisParameterTableItem[] {
      const analysisParameters: IAnalysisParameterTableItem[] = this.analysisParametersRd
        .map((array) =>
          array.map((a: IAnalysisParameter) => {
            (a as IAnalysisParameterTableItem).discriminator = 'AnalysisParameter';
            (a as IAnalysisParameterTableItem).type = LayoutElementType.Parameter;
            return a as IAnalysisParameterTableItem;
          })
        )
        .withDefault([]);

      const layoutElements: IAnalysisParameterTableItem[] = this.sampleLayoutElementsRd
        .map((array) =>
          array.map((a: ISampleLayoutElement) => {
            (a as IAnalysisParameterTableItem).discriminator = 'LayoutElement';
            return a as IAnalysisParameterTableItem;
          })
        )
        .withDefault([]);

      let rows = analysisParameters.concat(layoutElements);
      if (this.smartTableConfig) {
        rows = this.smartTableState.sortAndFilterLocal(rows, this.smartTableConfig);
      }
      return rows;
    },
    windowedSmartTableData: function (): IAnalysisParameterTableItem[] {
      return this.smartTableData.slice(this.currentTopIndex, this.currentTopIndex + this.pageSize);
    },
    analysisGroupElements: function (): IAnalysisGroupElement[] {
      const analysisGroupParameters: IAnalysisGroupElement[] = this.analysisGroupParametersRd
        .map((array) =>
          array.map(
            (a) =>
              ({
                id: a.id,
                type: 'AnalysisParameter',
                value: a
              } as IAnalysisGroupElement)
          )
        )
        .withDefault([]);
      const analysisGroupLayoutElements: IAnalysisGroupElement[] =
        this.analysisGroupLayoutElementsRd
          .map((array) =>
            array.map(
              (a) =>
                ({
                  id: a.id,
                  type: 'LayoutElement',
                  value: a
                } as IAnalysisGroupElement)
            )
          )
          .withDefault([]);

      return _.sortBy(
        analysisGroupParameters.concat(analysisGroupLayoutElements),
        (x) => x.value.rapportPosition
      );
    }
  },
  methods: {
    async updateSmartTableConfig() {
      this.saveSmartTableConfigDebounced(
        this.tableId,
        // @ts-ignore
        this.$refs.smartTableAnalysisParameters.getLayout()
      );
    },
    analysisGroupElementTitle: function (groupElement: IAnalysisGroupElement) {
      return groupElement.type === 'AnalysisParameter'
        ? (groupElement.value as IAnalysisGroupParameter).analysisParameterDefinitionCode
        : `${LayoutElementType.toGerman(
            (groupElement.value as IAnalysisGroupLayoutElement).type
          )} - ${(groupElement.value as IAnalysisGroupLayoutElement).value || ''}`;
    },
    addNewAnalysisParameter: async function (analysisParameter: IAnalysisParameterDefinition) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisParameters = await this.$labordatApi
          .post(`${this.basePath}/analysisparameters`, {
            analysisParameterDefinitionCode: analysisParameter.code
          })
          .then((response) => response.data as IUpdatedAnalysisParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    addNewLayoutElement: async function (
      layoutElementType: 'PageBreak' | 'Title' | 'Text' | 'Sum' | 'Price'
    ) {
      const type = LayoutElementType.parse(layoutElementType);
      if (type === undefined) {
        return;
      }

      const position =
        this.smartTableSelectedAnalysisParameter !== undefined
          ? (this.smartTableSelectedAnalysisParameter.rapportPosition || 0) + 1
          : (_.max(this.analysisParametersRd.getData().map((ap) => ap.rapportPosition)) || 0) + 1;

      const layoutElement = {
        type: type,
        sampleId: this.sampleId,
        rapportPosition: position,
        departmentCode: this.$department
      } as ISampleLayoutElement;
      this.setLayoutElementDefaultValue(layoutElement, layoutElementType);

      await this.createSampleLayoutElements([layoutElement]);
      this.$emit('updated-parameters');
    },
    addAllAnalysisParameters: async function () {
      if (
        !this.analysisGroupParametersRd.hasData() ||
        !this.analysisGroupLayoutElementsRd.hasData()
      ) {
        return;
      }

      await this.createAnalysisParameters(
        this.analysisGroupParametersRd.getData().map((p) => this.mapIAnalysisParameterIdentifier(p))
      );

      await this.createSampleLayoutElements(
        this.analysisGroupLayoutElementsRd.getData().map((l) => this.mapISampleLayoutElement(l))
      );

      this.$emit('updated-parameters');
    },
    addAnalysisParameterTableItem: async function () {
      if (!this.analysisGroupParametersRd.isSuccess() || !this.selectedAnalysisGroupParameter) {
        return;
      }

      if (this.selectedAnalysisGroupParameter.type === 'AnalysisParameter') {
        await this.addAnalysisParameter(this.selectedAnalysisGroupParameter.id);
      } else {
        await this.addSampleLayoutElement(this.selectedAnalysisGroupParameter.id);
      }

      this.$emit('updated-parameters');
    },
    addAnalysisParameter: async function (analysisGroupParameterId: number) {
      await this.createAnalysisParameters(
        this.analysisGroupParametersRd
          .getData()
          .filter((p) => p.id === analysisGroupParameterId)
          .map((p) => this.mapIAnalysisParameterIdentifier(p))
      );
    },
    addSampleLayoutElement: async function (sampleLayoutElementId: number) {
      await this.createSampleLayoutElements(
        this.analysisGroupLayoutElementsRd
          .getData()
          .filter((l) => l.id === sampleLayoutElementId)
          .map((l) => this.mapISampleLayoutElement(l))
      );
    },
    setLayoutElementDefaultValue(
      layoutElement: ISampleLayoutElement,
      layoutElementType: 'PageBreak' | 'Title' | 'Text' | 'Sum' | 'Price'
    ) {
      switch (layoutElementType) {
        case 'PageBreak':
          layoutElement.value = '-';
          break;
        case 'Title':
          layoutElement.value = 'Title';
          break;
        case 'Text':
          layoutElement.value = 'Text';
          break;
        case 'Sum':
          layoutElement.value = '0.00';
          break;
        case 'Price':
          layoutElement.value = '0.00';
          break;
      }
    },
    removeAnalysisParameterTableItem: async function () {
      if (this.smartTableSelectedAnalysisParameter !== undefined) {
        if (
          this.smartTableSelectedAnalysisParameter.discriminator === 'AnalysisParameter' &&
          this.smartTableSelectedAnalysisParameter.measurement !== null &&
          this.smartTableSelectedAnalysisParameter.measurement !== undefined &&
          this.smartTableSelectedAnalysisParameter.measurement.trim().length > 0
        ) {
          alert(
            'Der Analyseparameter kann nicht gelöscht werden, da bereits ein Messresultat vorhanden ist.'
          );
          return;
        }

        if (confirm('Wollen Sie den Analyseparameter wirklich löschen?') === false) {
          return;
        }

        if (this.smartTableSelectedAnalysisParameter.discriminator === 'AnalysisParameter') {
          await this.deleteAnalysisParameters([this.smartTableSelectedAnalysisParameter.id]);
        } else {
          await this.deleteSampleLayoutElements([this.smartTableSelectedAnalysisParameter.id]);
        }

        this.smartTableSelectedAnalysisParameter = undefined;
        this.$emit('updated-parameters');
      }
    },
    removeAllAnalysisParameterTableItems: async function () {
      if (
        this.windowedSmartTableData.some(
          (ap) =>
            ap.measurement !== null &&
            ap.measurement !== undefined &&
            ap.measurement.trim().length > 0
        )
      ) {
        alert(
          'Die Analyseparameter können nicht gelöscht werden, da mindestens ein Messresultat vorhanden ist.'
        );
        return;
      }

      if (confirm('Wollen Sie die gefilterten Analyseparameter wirklich löschen?') === false) {
        return;
      }

      await this.deleteAnalysisParameters(
        this.windowedSmartTableData
          .filter((p) => p.discriminator === 'AnalysisParameter')
          .map((p) => p.id)
      );
      await this.deleteSampleLayoutElements(
        this.windowedSmartTableData
          .filter((p) => p.discriminator === 'LayoutElement')
          .map((p) => p.id)
      );

      this.smartTableSelectedAnalysisParameter = undefined;
      this.$emit('updated-parameters');
    },
    createAnalysisParameters: async function (parameters: Array<IAnalysisParameterIdentifier>) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisParameters = await this.$labordatApi
          .post(`${this.basePath}/analysisparameters/fromgroup`, parameters)
          .then((response) => response.data as IUpdatedAnalysisParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    deleteAnalysisParameters: async function (parameters: Array<number>) {
      if (!parameters || parameters.length < 1) {
        return;
      }

      try {
        this.analysisParametersRd = UpdatableRemoteData.updating(
          this.analysisParametersRd.getData()
        );
        await this.$labordatApi
          .delete(`${this.basePath}/analysisparameters`, { data: parameters })
          .then((response) => response.data);
        await this.loadAnalysisParameters();
        await this.loadSampleLayoutElements();
      } catch (error) {
        this.analysisParametersRd = UpdatableRemoteData.updateFailure(
          this.analysisParametersRd.getData(),
          error as Error
        );
      }
    },
    createSampleLayoutElements: async function (layoutElements: Array<ISampleLayoutElement>) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisParameters = await this.$labordatApi
          .post(`${this.basePath}/layoutelements`, layoutElements)
          .then((response) => response.data as IUpdatedAnalysisParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    deleteSampleLayoutElements: async function (elements: Array<number>) {
      if (!elements || elements.length < 1) {
        return;
      }

      try {
        this.sampleLayoutElementsRd = UpdatableRemoteData.updating(
          this.sampleLayoutElementsRd.getData()
        );
        await this.$labordatApi
          .delete(`${this.basePath}/layoutelements`, { data: elements })
          .then((response) => response.data);
        await this.loadAnalysisParameters();
        await this.loadSampleLayoutElements();
      } catch (error) {
        this.sampleLayoutElementsRd = UpdatableRemoteData.updateFailure(
          this.sampleLayoutElementsRd.getData(),
          error as Error
        );
      }
    },
    initializeSmartTable: function () {
      const isAnalysisParameter = (x: IAnalysisParameterTableItem) =>
        x.discriminator === 'AnalysisParameter';
      const isPriceOrSumLayoutElement = (x: IAnalysisParameterTableItem) =>
        x.discriminator === 'LayoutElement' &&
        (x.type === LayoutElementType.Price || x.type === LayoutElementType.Sum);

      this.smartTableConfig = new Config(
        (row: IAnalysisParameterTableItem) => `${row.type}-${row.id}`,
        [
          new Column({
            title: '',
            fieldName: 'type',
            fieldType: 'numeric',
            editable: () => false,
            converter: LayoutElementType.toGerman
          }),
          new Column({
            title: 'Wert',
            fieldName: 'value',
            fieldType: 'string',
            editable: (x: IAnalysisParameterTableItem) =>
              x.discriminator === 'LayoutElement' && x.type !== LayoutElementType.PageBreak
          }),
          new Column({
            title: 'Code',
            fieldName: 'analysisParameterDefinitionCode',
            fieldType: 'string'
          }),
          new Column({
            title: 'Bezeichnung',
            fieldName: this.getLanguageColumnName(),
            fieldType: 'string',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Kommentar',
            fieldName: 'comment',
            fieldType: 'string',
            editable: (x) => isAnalysisParameter(x) || isPriceOrSumLayoutElement(x)
          }),
          new Column({
            title: 'Messresultat',
            fieldName: 'measurement',
            fieldType: 'string',
            editable: (x) => isAnalysisParameter(x) && x.resultTypeCode !== 'ber',
            converter: (cellValue, row) => {
              let realCellValue = cellValue;
              if (
                realCellValue !== undefined &&
                realCellValue !== null &&
                realCellValue !== '' &&
                !isNaN(+realCellValue)
              ) {
                if (row.precision !== 9) {
                  realCellValue = (+realCellValue).toFixed(row.precision);
                }
              }
              return this.withSeparators(realCellValue);
            },
            colStyling: 'text-right',
            cellStyling: (row: IAnalysisParameterTableItem) => ({
              valid: row.validation === ValidationStatusType.Valid,
              invalid: row.validation === ValidationStatusType.Invalid,
              noValue: row.validation === ValidationStatusType.NoValue,
              noGuidanceValue: row.validation === ValidationStatusType.NoGuidanceValue,
              notValidatable: row.validation === ValidationStatusType.NotValidatable,
              erroneous:
                row.validation === ValidationStatusType.Erroneous ||
                row.validation === ValidationStatusType.Unvalidated
            })
          }),
          new Column({
            title: 'Dimension',
            fieldName: 'dimensionCode',
            fieldType: 'dropdown',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Rapportpos.',
            fieldName: 'rapportPosition',
            fieldType: 'numeric',
            editable: () => true,
            validator: hasValue
          }),
          new Column({
            title: 'Berech.pos.',
            fieldName: 'calculationOrder',
            fieldType: 'numeric',
            editable: isAnalysisParameter,
            validator: hasValue
          }),
          new Column({
            title: 'Tarif',
            fieldName: 'priceInChf',
            fieldType: 'numeric',
            editable: isAnalysisParameter,
            validator: hasValue,
            colStyling: 'text-right'
          }),
          new Column({
            title: 'Tarif Null',
            fieldName: 'zeroPrice',
            fieldType: 'boolean',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Abteilung',
            fieldName: 'departmentCode',
            fieldType: 'dropdown',
            editable: (x) => isAnalysisParameter(x) || isPriceOrSumLayoutElement(x),
            validator: hasValue
          }),
          new Column({
            title: 'Drucken',
            fieldName: 'showInPrint',
            fieldType: 'boolean',
            editable: isAnalysisParameter
          }),
          new Column({ title: 'Resultattyp', fieldName: 'resultTypeCode', fieldType: 'dropdown' }),
          new Column({
            title: 'Präzision',
            fieldName: 'precision',
            fieldType: 'numeric',
            editable: isAnalysisParameter,
            validator: hasValue
          }),
          new Column({
            title: 'Interne Methode',
            fieldName: 'internalMethod',
            fieldType: 'string',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Analyseverfahren',
            fieldName: 'analysisProcedureCode',
            fieldType: 'dropdown',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Norm',
            fieldName: 'norm',
            fieldType: 'string',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Richtwert',
            fieldName: 'guidanceValue',
            fieldType: 'string',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Standardwert Min.',
            fieldName: 'standardValueMin',
            fieldType: 'numeric',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Standardwert Max.',
            fieldName: 'standardValueMax',
            fieldType: 'numeric',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Formel',
            fieldName: 'formula',
            fieldType: 'string',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Unterbeauftragslabor',
            fieldName: 'crmSubContractorId',
            fieldType: 'numeric',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Art der Messunsicherheit',
            fieldName: 'uncertaintyOfMeasurementType',
            fieldType: 'dropdown',
            editable: isAnalysisParameter,
            dropdownOptions: [
              { id: UncertaintyOfMeasurement.Percentage, title: 'Prozentual' },
              { id: UncertaintyOfMeasurement.Absolute, title: 'Absolut' }
            ]
          }),
          new Column({
            title: 'Messunsicherheit',
            fieldName: 'uncertaintyOfMeasurement',
            fieldType: 'numeric',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Nachweisgrenze',
            fieldName: 'limitOfDetection',
            fieldType: 'string',
            editable: isAnalysisParameter,
            validator: this.isNumeric
          }),
          new Column({
            title: 'Bestimmungsgrenze',
            fieldName: 'limitOfDetermination',
            fieldType: 'string',
            editable: isAnalysisParameter,
            validator: this.isNumeric
          }),
          new Column({
            title: 'Akkreditiert',
            fieldName: 'accredited',
            fieldType: 'boolean',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'GMP',
            fieldName: 'goodManufacturingPractice',
            fieldType: 'boolean',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Bewertung',
            fieldName: 'validation',
            fieldType: 'dropdown',
            editable: () => false,
            dropdownOptions: [
              { id: ValidationStatusType.Unvalidated, title: 'Nicht validiert' },
              { id: ValidationStatusType.Valid, title: 'Gültig' },
              { id: ValidationStatusType.Invalid, title: 'Ungültig' },
              { id: ValidationStatusType.NotValidatable, title: 'Nicht bewertbar' },
              { id: ValidationStatusType.NoValue, title: 'Kein Resultat' },
              { id: ValidationStatusType.Erroneous, title: 'Fehleingabe' },
              { id: ValidationStatusType.NoGuidanceValue, title: 'Kein Richtwert' }
            ]
          }),
          new Column({
            title: 'Bewertung manuell',
            fieldName: 'validationOverride',
            fieldType: 'dropdown',
            editable: isAnalysisParameter,
            dropdownOptions: [
              { id: ValidationStatusOverrideType.Automatic, title: 'Automatisch' },
              { id: ValidationStatusOverrideType.Valid, title: 'Gültig' },
              { id: ValidationStatusOverrideType.Invalid, title: 'Ungültig' },
              { id: ValidationStatusOverrideType.NotValidatable, title: 'Nicht bewertbar' }
            ]
          })
        ],
        undefined,
        undefined,
        (row) => ({
          'bg-layout-element':
            (row as IAnalysisParameterTableItem).type !== LayoutElementType.Parameter
        })
      );
    },
    smartTableUpdatedAnalysisParameter: async function (tableItem: IAnalysisParameterTableItem) {
      if (tableItem.discriminator === 'AnalysisParameter') {
        await this.updateAnalysisParameter(tableItem);
      } else {
        await this.updateSampleLayoutElement(tableItem);
      }
    },
    updateAnalysisParameter: async function (analysisParameter: IAnalysisParameter) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisParameters = await this.$labordatApi
          .put(`${this.basePath}/analysisparameters/${analysisParameter.id}`, analysisParameter)
          .then((response) => response.data as IUpdatedAnalysisParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    updateSampleLayoutElement: async function (sampleLayoutElement: ISampleLayoutElement) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisParameters = await this.$labordatApi
          .put(`${this.basePath}/layoutelements/${sampleLayoutElement.id}`, sampleLayoutElement)
          .then((response) => response.data as IUpdatedAnalysisParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    loadAnalysisParameters: async function () {
      this.analysisParametersRd = await this.$labordatApi.getUrd(
        `${this.basePath}/analysisparameters`
      );
    },
    loadSampleLayoutElements: async function () {
      this.sampleLayoutElementsRd = await this.$labordatApi.getUrd(
        `${this.basePath}/layoutelements`
      );
    },
    loadAnalysisGroupParameters: async function () {
      this.analysisGroupParametersRd = await this.$labordatApi.getPagedRd(
        '/api/analysisgroupparameter',
        {
          params: {
            equals: `AnalysisGroupCode=${this.selectedAnalysisGroup}`
          }
        }
      );
    },
    loadAnalysisGroupLayoutElements: async function () {
      this.analysisGroupLayoutElementsRd = await this.$labordatApi.getPagedRd(
        '/api/analysisgrouplayoutelement',
        {
          params: {
            equals: `AnalysisGroupCode=${this.selectedAnalysisGroup}`
          }
        }
      );
    },
    loadAnalysisGroups: async function () {
      this.analysisGroupsRd = await this.$labordatApi.getPagedRd('/api/analysisgroup', {
        params: {
          equals: `DepartmentCode=${this.department}`
        }
      });
    },
    loadResultTypes: async function () {
      this.resultTypesRd = await this.$labordatApi.getPagedRd('/api/resulttype');
    },
    loadDimensions: async function () {
      this.dimensionsRd = await this.$labordatApi.getPagedRd('/api/dimension');
    },
    loadAnalysisProcedures: async function () {
      this.analysisProceduresRd = await this.$labordatApi.getPagedRd('/api/analysisprocedure');
    },
    updateColumnDropdownOptions(
      fieldName: string,
      dropdownOptions: { id: string | number | null; title: string }[]
    ) {
      if (this.smartTableConfig && this.smartTableConfig.columns) {
        const str: string | number | null = null;
        dropdownOptions.splice(0, 0, { id: str, title: '' });
        for (const col of this.smartTableConfig.columns) {
          if (col.fieldName === fieldName) {
            col.dropdownOptions = dropdownOptions;
            break;
          }
        }
      }
    },
    updateResultTypeColumnDropdownOption: function () {
      if (this.resultTypesRd.hasData()) {
        this.updateColumnDropdownOptions(
          'resultTypeCode',
          this.resultTypesRd.getData().map((r) => ({ id: r.code, title: r.name }))
        );
      }
    },
    updateDimensionColumnDropdownOptions: function () {
      if (this.dimensionsRd.hasData()) {
        this.updateColumnDropdownOptions(
          'dimensionCode',
          this.dimensionsRd.getData().map((d) => ({
            id: d.code,
            title: d[this.getLanguageColumnName()]
          }))
        );
      }
    },
    updateDepartmentColumnDropdownOptions: function () {
      if (this.dimensionsRd.hasData()) {
        this.updateColumnDropdownOptions(
          'departmentCode',
          this.$departments.map((d) => ({ id: d.code, title: d.code }))
        );
      }
    },
    updateAnalysisProcedureColumnDropdownOptions: function () {
      if (this.analysisProceduresRd.hasData()) {
        this.updateColumnDropdownOptions(
          'analysisProcedureCode',
          this.analysisProceduresRd
            .getData()
            .map((d) => ({ id: d.code, title: d[this.getLanguageColumnName()] }))
        );
      }
    },
    getLanguageColumnName: function () {
      switch (this.language) {
        case Language.DE:
          return 'nameDe';
        case Language.EN:
          return 'nameEn';
        case Language.FR:
          return 'nameFr';
        case Language.IT:
          return 'nameIt';
        default:
          throw new Error('Language not supported');
      }
    },
    startUpdateParameters: function () {
      this.sampleLayoutElementsRd = UpdatableRemoteData.updating(
        this.sampleLayoutElementsRd.getData()
      );
      this.analysisParametersRd = UpdatableRemoteData.updating(this.analysisParametersRd.getData());
    },
    endUpdateParameters: function (updated: IUpdatedAnalysisParameters) {
      const analysisParameters =
        updated.analysisParameters !== undefined
          ? this.analysisParametersRd
              .getData()
              .filter((x) => !updated.analysisParameters.find((y) => x.id === y.id))
              .concat(updated.analysisParameters)
          : this.analysisParametersRd.getData();

      const sampleLayoutElements =
        updated.layoutElements !== undefined
          ? this.sampleLayoutElementsRd
              .getData()
              .filter((x) => !updated.layoutElements.find((y) => x.id === y.id))
              .concat(updated.layoutElements)
          : this.sampleLayoutElementsRd.getData();

      this.analysisParametersRd = UpdatableRemoteData.success(analysisParameters);
      this.sampleLayoutElementsRd = UpdatableRemoteData.success(sampleLayoutElements);

      this.$emit('updated-parameters');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catchUpdateParameters: function (error: any) {
      this.analysisParametersRd = UpdatableRemoteData.updateFailure(
        this.analysisParametersRd.getData(),
        error
      );
      this.sampleLayoutElementsRd = UpdatableRemoteData.updateFailure(
        this.sampleLayoutElementsRd.getData(),
        error
      );
    },
    handleNavigationTopOfList: function (event: Event, showFastEntry: boolean) {
      if (this.currentTopIndex >= this.pageSize) {
        this.currentTopIndex -= this.pageSize;
      } else {
        this.$emit('navigation-top-of-list', { event, showFastEntry });
      }
    },
    handleNavigationBottomOfList: function (event: Event, showFastEntry: boolean) {
      if (this.currentTopIndex < this.smartTableData.length - this.pageSize) {
        this.currentTopIndex += this.pageSize;
      } else {
        this.$emit('navigation-bottom-of-list', { event, showFastEntry });
      }
    },
    async reloadAnalysisParameters() {
      this.modalOpen = false;
      await this.loadAnalysisParameters();
    },
    typeaheadSerializer: function (analysisGroup: IAnalysisGroup) {
      return analysisGroup.code + (analysisGroup.name ? ' (' + analysisGroup.name + ')' : '');
    },
    onHitAnalysisGroup: async function (analysisGroup: IAnalysisGroup) {
      this.cancelTypeaheadSearch();
      this.selectedAnalysisGroup = analysisGroup.code;
    },
    cancelTypeaheadSearch: function () {
      _.debounce(
        function () {
          // @ts-ignore
          this.updateAnalysisGroupTypeahead.cancel();
        }.bind(this),
        100
      )();
    },
    mapIAnalysisParameterIdentifier: function (
      parameter: IAnalysisGroupParameter
    ): IAnalysisParameterIdentifier {
      return {
        parameterId: parameter.id,
        analysisGroupCode: parameter.analysisGroupCode,
        analysisParameterDefinitionCode: parameter.analysisParameterDefinitionCode
      } as IAnalysisParameterIdentifier;
    },
    mapISampleLayoutElement: function (
      layoutElement: IAnalysisGroupLayoutElement
    ): ISampleLayoutElement {
      return {
        type: layoutElement.type,
        sampleId: this.sampleId,
        rapportPosition: layoutElement.rapportPosition,
        value: layoutElement.value,
        comment: layoutElement.comment,
        departmentCode: layoutElement.departmentCode
      } as ISampleLayoutElement;
    }
  }
});
