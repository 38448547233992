


































































































































































import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import OrderMixin from '@/mixins/OrderMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import SigneesMixin from '@/mixins/SigneesMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import UserMixin from '@/mixins/UserMixin';
import FormatMixin from '@/mixins/FormatMixin';
import { RemoteData } from 'rey-frontend-fp';
import IInvoicingTemplate from '@/models/IInvoicingTemplate';
import IGenerateInvoicingReport from '@/models/IGenerateInvoicingReport';
import OrderType from '@/models/OrderType';
import { SmartTableTable, State, Sorting, Config, Column } from 'rey-vue-smarttable';
import InvoiceSummary from '@/models/InvoiceSummary';
import IInvoiceSummary from '@/models/IInvoiceSummary';
import IInvoiceSummaryDepartment from '@/models/IInvoiceSummaryDepartment';
import SmartTableConfigManagementMixin from '@/mixins/SmartTableConfigManagementMixin';

export default mixins(
  SharedDataMixin,
  OrderMixin,
  DateTimeMixin,
  SigneesMixin,
  FileDownloadMixin,
  UserMixin,
  FormatMixin,
  SmartTableConfigManagementMixin
).extend({
  components: { SmartTableTable },
  data: function () {
    return {
      tableId: 'InvoicingSummary',
      invoicingTemplatesRd: RemoteData.notAsked<Array<IInvoicingTemplate>, Error>(),
      invoicingTemplateId: 0,
      invoicingResponsible: 0,
      documentGenerationState: 'None' as
        | 'None'
        | 'Generating'
        | 'Success'
        | 'SuccessPreview'
        | 'Error',
      hasApiError: false,
      allReportsGenerated: false,
      updateDebounced: _.debounce(
        function () {
          // @ts-ignore
          this.updateComment();
        }.bind(this),
        1000
      ),
      smartTableConfig: undefined as Config | undefined,
      smartTableState: new State([new Sorting('sortOrder')]),
      invoicingSummary: [] as InvoiceSummary[]
    };
  },
  created: async function () {
    this.initializeSmartTable();
    const signeesPromises = this.loadSignees();
    await this.loadInvoicingTemplates();
    this.setInitialTemplate();
    await signeesPromises;
    this.setInitialSignee();
  },
  mounted: async function () {
    this.allReportsGenerated = await this.$labordatApi
      .post(`/api/orders/${this.orderRd.getData().id}/reports/areallreportsgenerated`)
      .then((response) => response.data as boolean);
    this.invoicingSummary = await this.$labordatApi
      .get(`/api/orders/${this.orderRd.getData().id}/invoicing/summary`)
      .then(
        (response) =>
          response.data.map((s: IInvoiceSummary) =>
            InvoiceSummary.FromInterface(s)
          ) as InvoiceSummary[]
      );

    this.loadSmartTableConfig(this.tableId).then((config) => {
      if (config) {
        // @ts-ignore
        this.$refs.invoicingSmartTable.setLayout(config);
      }
    });
  },
  computed: {
    canGenerateInvoicingReport: function (): boolean {
      return (
        this.invoicingTemplateId > 0 &&
        !!this.invoicingResponsible &&
        !this.orderIsLocked &&
        !this.reportIsLocked &&
        (this.allReportsGenerated === true ||
          this.orderRd.map((order) => order.orderType === OrderType.NoSample).withDefault(false))
      );
    },
    canGenerateInvoicingReportPreview: function (): boolean {
      return (
        this.invoicingTemplateId > 0 &&
        !!this.invoicingResponsible &&
        (this.allReportsGenerated === true ||
          this.orderRd.map((order) => order.orderType === OrderType.NoSample).withDefault(false))
      );
    }
  },
  methods: {
    initializeSmartTable: function () {
      this.smartTableConfig = new Config(
        (row: InvoiceSummary) => `${row.sortOrder}`,
        [
          new Column({
            title: 'Titel',
            fieldName: 'caption',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Beschreibung',
            fieldName: 'description',
            fieldType: 'string',
            editable: () => false
          })
        ]
      );

      for (const department of this.$departments.filter((d) => d.showInMenu)) {
        this.smartTableConfig.columns?.push(
          new Column({
            title: department.code,
            fieldName: `Department${department.code}`,
            fieldType: 'numeric',
            editable: () => false,
            converter: (cellValue, row) => {
              const summary = row.getDepartment[department.code] as IInvoiceSummaryDepartment;
              return this.withSeparators(
                this.formatWithPrecision(
                  summary && summary.amount != 0 ? '' + summary.amount : '',
                  2
                )
              );
            },
            colStyling: 'text-right'
          })
        );
      }
    },
    async updateSmartTableConfig() {
      this.saveSmartTableConfigDebounced(
        this.tableId,
        // @ts-ignore
        this.$refs.invoicingSmartTable.getLayout()
      );
    },
    generateInvoiceReport: async function () {
      try {
        this.documentGenerationState = 'Generating';
        await this.$labordatApi
          .post(`/api/orders/${this.orderRd.getData().id}/invoicing`, {
            sampleIds: [0],
            templateId: this.invoicingTemplateId,
            responsible: this.invoicingResponsible,
            comment: this.orderRd.getData().invoiceComment
          } as IGenerateInvoicingReport)
          .then(async (response) => {
            this.documentGenerationState = response.status === 200 ? 'Success' : 'Error';
            await this.loadOrder(this.orderRd.getData().id || '');
            if (this.$refs.invoiceComment) {
              const textArea = this.$refs.invoiceComment as HTMLTextAreaElement;
              textArea.disabled = true;
            }
          });
      } catch (error) {
        this.documentGenerationState = 'Error';
      }
    },
    previewInvoiceReport: async function () {
      try {
        this.documentGenerationState = 'Generating';
        await this.$labordatApi
          .post(
            `/api/orders/${this.orderRd.getData().id}/invoicing/preview`,
            {
              sampleIds: [0],
              templateId: this.invoicingTemplateId,
              responsible: this.invoicingResponsible,
              comment: this.orderRd.getData().invoiceComment
            } as IGenerateInvoicingReport,
            { responseType: 'arraybuffer', timeout: 3600000 }
          )
          .then((response) => {
            if (response != null) {
              this.openFile(response, 'Vorschau.pdf');
              this.documentGenerationState = 'SuccessPreview';
            } else {
              throw new Error('No document generated.');
            }
          });
      } catch (error) {
        this.documentGenerationState = 'Error';
      }
    },
    resetInvoice: async function () {
      if (confirm('Wollen Sie die Verrechnung wirklich zurücksetzen?') === false) {
        return;
      }

      this.documentGenerationState = 'None';
      try {
        await this.$labordatApi.post(`/api/orders/${this.orderRd.getData().id}/invoicing/reset`);
        await this.loadOrder(this.orderRd.getData().id || '');
      } catch (error) {
        this.hasApiError = true;
      }

      if (this.$refs.invoiceComment) {
        const textArea = this.$refs.invoiceComment as HTMLTextAreaElement;
        if (textArea.value.indexOf('Auftrag wurde bereits einmal verrechnet.') < 0) {
          textArea.value +=
            (textArea.value && textArea.value.length > 0 ? '\n' : '') +
            'Auftrag wurde bereits einmal verrechnet.';
        }

        textArea.disabled = false;
      }
    },
    setInitialSignee: function () {
      var firstSigneeFoundForCurrentlyLoggedInUser = this.signeesRd
        .getData()
        .find((s) => s.email === this.userEmail);

      if (firstSigneeFoundForCurrentlyLoggedInUser !== undefined) {
        this.invoicingResponsible = firstSigneeFoundForCurrentlyLoggedInUser.id;
      } else {
        this.invoicingResponsible = 0;
      }
    },
    setInitialTemplate: function () {
      this.invoicingTemplateId = this.invoicingTemplatesRd
        .map((r) => (r[0] || { id: 0 }).id)
        .withDefault(0);
    },
    loadInvoicingTemplates: async function () {
      try {
        this.invoicingTemplatesRd = await this.$labordatApi.getPagedRd(`/api/invoicingtemplate`);
      } catch (error) {
        this.invoicingTemplatesRd = RemoteData.failure(error as Error);
      }
    },
    updateComment: async function () {
      try {
        await this.$labordatApi.post(`/api/orders/${this.orderRd.getData().id}/invoicing/comment`, {
          sampleIds: [0],
          templateId: this.invoicingTemplateId,
          responsible: this.invoicingResponsible,
          comment: this.orderRd.getData().invoiceComment
        } as IGenerateInvoicingReport);
      } catch (error) {
        this.hasApiError = true;
      }
    }
  }
});
