









import mixins from 'vue-typed-mixins';
import VModelMixin from '@/mixins/VModelMixin';

export default mixins(VModelMixin).extend({
  name: 'toggle',
  props: { value: Boolean, title: String }
});
