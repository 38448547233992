





































































































import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import OrderMixin from '@/mixins/OrderMixin';
import OrderViewHelperMixin from '@/mixins/OrderViewHelperMixin';
import SettingsMixin from '@/mixins/SettingsMixin';
import SampleRangeMixin from '@/mixins/SampleRangeMixin';
import ReportGenerationMixin from '@/mixins/ReportGenerationMixin';
import StatusHubMixin from '@/mixins/StatusHubMixin';
import {
  SmartTableTable,
  State,
  Sorting,
  Config,
  Column,
  toDateTimeString
} from 'rey-vue-smarttable';
import { RemoteData } from 'rey-frontend-fp';
import IReport from '@/models/IReport';
import Report from '@/models/Report';
import ReportStatus from '@/models/ReportStatus';
import IReportStatusChange from '@/models/IReportStatusChange';
import SampleSelection from '@/services/SampleSelection';
// @ts-ignore
import { Loading } from 'rey-vue-common';

export default mixins(
  OrderMixin,
  OrderViewHelperMixin,
  SettingsMixin,
  SampleRangeMixin,
  ReportGenerationMixin,
  StatusHubMixin
).extend({
  components: { Loading, SmartTableTable },
  data() {
    return {
      reportsRd: RemoteData.notAsked<Array<IReport>, Error>(),
      smartTableConfig: undefined as Config | undefined,
      smartTableState: new State([new Sorting('')]),
      selectedReport: undefined as Report | undefined,
      reportDownloadingState: 'None' as 'None' | 'Generating' | 'Success' | 'Error',
      downloadState: 'None' as 'None' | 'Success' | 'Error'
    };
  },
  async created() {
    this.initializeSmartTable();

    await this.loadOrderReports();
  },
  computed: {
    smartTableData: function (): Array<Report> {
      const data = this.reportsRd
        .withDefault([])
        .map((r) => Report.FromInterface(r, this.orderRd.getData().id || ''));
      const groups = _.groupBy(data, (s) => s.variant);

      let rows = new Array<Report>();
      for (const key of Object.keys(groups)) {
        const row = _.maxBy(groups[key], 'version');
        if (row) {
          rows.push(row);
        }
      }

      // Sort by variant
      if (rows.length > 0) {
        rows = this.sortByVariant(rows);

        // move global report to the top
        const globalReport = new Array<Report>();
        let row = rows.pop();
        if (row) {
          globalReport.push(row);
          rows = globalReport.concat(rows);
        }

        // move irrelevant reports to the bottom
        const irrelevantReports = new Array<Report>();
        for (let i = 0; i < rows.length; i++) {
          row = rows[i];
          if (row.status === ReportStatus.Irrelevant) {
            irrelevantReports.push(row);
            rows.splice(i, 1);
            i--; //NOSONAR
          }
        }
        rows = rows.concat(irrelevantReports);
      }

      if (this.smartTableConfig) {
        rows = this.smartTableState.sortAndFilterLocal(rows, this.smartTableConfig);
      }
      return rows;
    },
    isReportGenerated: function (): boolean {
      return (
        this.selectedReport !== undefined && this.selectedReport.status === ReportStatus.Generated
      );
    },
    isReportPreviewPossible: function (): boolean {
      return (
        this.selectedReport !== undefined &&
        this.selectedReport.status !== ReportStatus.Generated &&
        this.selectedReport.status !== ReportStatus.Invalid
      );
    },
    hasReportPerSample: function (): boolean {
      return (
        this.reportsRd
          .withDefault([])
          .filter((r) => r.variant !== null && (r.variant || '').indexOf('-') > 0).length > 0
      );
    },
    canMarkAsIrrelevant: function (): boolean {
      return this.selectedReport !== undefined && this.selectedReport.status === ReportStatus.New;
    },
    canUnmarkAsIrrelevant: function (): boolean {
      return (
        this.selectedReport !== undefined && this.selectedReport.status === ReportStatus.Irrelevant
      );
    }
  },
  methods: {
    sortByVariant: function (rows: Report[]) {
      return rows.sort((one, two) => {
        if (one.variant && two.variant) {
          if ((one.variant || '').length < (two.variant || '').length) {
            return -1;
          } else if ((one.variant || '').length > (two.variant || '').length) {
            return 1;
          } else {
            return one.variant < two.variant ? -1 : 1;
          }
        } else if (one.variant) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    initializeSmartTable: function () {
      this.smartTableConfig = new Config(
        (row: Report) => `${row.id}`,
        [
          new Column({
            title: 'Status',
            fieldName: 'status',
            fieldType: 'dropdown',
            editable: () => false,
            dropdownOptions: [
              { id: ReportStatus.New, title: 'Erfasst' },
              { id: ReportStatus.FirstSignature, title: 'Erstunterschrift' },
              { id: ReportStatus.Signed, title: 'Freigegeben' },
              { id: ReportStatus.Generated, title: 'Generiert' },
              { id: ReportStatus.Outdated, title: 'Veraltet' },
              { id: ReportStatus.Invalid, title: 'Ungültig' },
              { id: ReportStatus.Irrelevant, title: 'Nicht relevant' }
            ],
            cellStyling: (row: Report) => ({
              newReport: row.status === ReportStatus.New,
              signedReport:
                row.status === ReportStatus.FirstSignature || row.status === ReportStatus.Signed,
              generatedReport: row.status === ReportStatus.Generated,
              outdatedReport: row.status === ReportStatus.Outdated,
              invalidReport: row.status === ReportStatus.Invalid,
              irrelevantReport: row.status === ReportStatus.Irrelevant
            })
          }),
          new Column({
            title: 'Bericht',
            fieldName: 'reportId',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Variante',
            fieldName: 'variant',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Version',
            fieldName: 'version',
            fieldType: 'numeric',
            editable: () => false,
            converter: (v: number) => {
              return v > 0 ? v.toString() : '';
            },
            colStyling: 'text-right'
          }),
          new Column({
            title: 'Probenauswahl',
            fieldName: 'sampleSelector',
            fieldType: 'numeric',
            editable: () => false,
            converter: (ss: string) => {
              return ss !== null ? ss : 'Alle';
            }
          }),
          new Column({
            title: 'Rapportdatum',
            fieldName: 'reportDate',
            fieldType: 'string',
            editable: () => false,
            converter: toDateTimeString
          }),
          new Column({
            title: 'Berichtskommentar',
            fieldName: 'comment',
            fieldType: 'string',
            editable: () => false,
            converter: (c: string) => {
              return c !== null
                ? c
                    .replace(/<\/(?:p|li)+>/g, ' ')
                    .replace(/<br\s*\/*>/g, ' ')
                    .replace(/<[^>]+>/g, '')
                    .replace(/&nbsp;/g, ' ')
                    .replace(/[^\S\r\n]{2,}/g, ' ')
                : '';
            }
          })
        ]
      );
    },
    addReport: async function () {
      const report = await this.$labordatApi
        .post(`/api/orders/${this.orderRd.getData().id}/reports/create`)
        .then((response) => response.data as IReport);

      this.$router.push(`/orders/${this.orderRd.getData().id}/report/${report.id}`);
    },
    addReportPerSample: async function () {
      const report = await this.$labordatApi
        .post(`/api/orders/${this.orderRd.getData().id}/reports/createpersample`)
        .then((response) => response.data as IReport);

      this.$router.push(`/orders/${this.orderRd.getData().id}/report/${report.id}`);
    },
    editReport: function () {
      if (this.selectedReport) {
        this.$router.push(`/orders/${this.orderRd.getData().id}/report/${this.selectedReport.id}`);
      }
    },
    showReport: async function () {
      if (this.selectedReport && this.selectedReport.id) {
        this.downloadState = 'None';
        this.downloadState = await this.getGeneratedDocument(
          this.orderRd.getData().id || '',
          this.selectedReport.id
        );
      }
    },
    previewReport: async function () {
      if (this.selectedReport !== undefined) {
        this.reportDownloadingState = 'Generating';

        await this.initializeSampleRange(this.orderRd.getData().id || '');
        const items = this.getSampleIndices();
        const selection = new SampleSelection(
          this.selectedReport.sampleSelector
            ? this.selectedReport.sampleSelector
            : `${Math.min.apply(null, items)}-${Math.max.apply(null, items)}`,
          this.getSampleIndices()
        );

        this.reportDownloadingState = await this.generateDocument(
          selection,
          this.orderRd.getData().id || '',
          this.selectedReport.id || 0,
          'preview'
        );
      }
    },
    loadOrderReports: async function () {
      this.reportsRd = await this.$labordatApi.getRd(
        `/api/orders/${this.orderRd.getData().id}/reports`
      );
    },
    onReportStatusChanged: function (reportStatus: IReportStatusChange) {
      if (this.orderRd.getData().id === reportStatus.orderId) {
        const reports = this.reportsRd.withDefault([]);
        reports.forEach((report) => {
          if (reportStatus.id === report.id) {
            report.status = reportStatus.status;
          }
        });

        const foundReport = reports.find(
          (r) => this.selectedReport && r.id === this.selectedReport.id
        );
        if (this.selectedReport && foundReport) {
          this.selectedReport.status = foundReport.status;
        }
      }
    },
    markAsIrrelevant: async function () {
      if (this.selectedReport && this.selectedReport.status === ReportStatus.New) {
        await this.$labordatApi.post(
          `/api/orders/${this.orderRd.getData().id || ''}/reports/${
            this.selectedReport.id || 0
          }/markasirrelevant`
        );
      }
    },
    unmarkAsIrrelevant: async function () {
      if (this.selectedReport && this.selectedReport.status === ReportStatus.Irrelevant) {
        await this.$labordatApi.post(
          `/api/orders/${this.orderRd.getData().id || ''}/reports/${
            this.selectedReport.id || 0
          }/unmarkasirrelevant`
        );
      }
    }
  }
});
