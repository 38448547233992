import Vue from 'vue';
import Vuex, { Module } from 'vuex';
import IDepartment from '@/models/IDepartment';

const SETTINGS_STORE = 'labordat-settings';

interface IRootState {
  version: string;
  settings: ISettings;
  sharedData: ISharedData;
  tempData: ITempData;
}

interface ISettings {
  department: string;
  analysisParameterEditMode: boolean;
  isWideLayout: boolean;
}

interface ISharedData {
  departments: IDepartment[];
}

interface ITempData {
  parameterEditColumn: number | undefined;
}

const settingsModule: Module<ISettings, IRootState> = {
  namespaced: true,
  state: {
    department: 'E',
    analysisParameterEditMode: true,
    isWideLayout: false
  },
  mutations: {
    setDepartment: function (state, department) {
      state.department = department;
    },
    setAnalysisParameterEditMode: function (state, analysisParameterEditMode) {
      state.analysisParameterEditMode = analysisParameterEditMode;
    },
    setIsWideLayout: function (state, isWideLayout) {
      state.isWideLayout = isWideLayout;
    },
    init: function (state) {
      const settings = localStorage.getItem(SETTINGS_STORE);
      if (settings) {
        Object.assign(state, JSON.parse(settings));
      }
    }
  }
};

const sharedDataModule: Module<ISharedData, IRootState> = {
  namespaced: true,
  state: {
    departments: []
  },
  mutations: {
    setDepartments: function (state, departments) {
      state.departments = departments;
    }
  }
};

const tempDataModule: Module<ITempData, IRootState> = {
  namespaced: true,
  state: {
    parameterEditColumn: undefined
  },
  mutations: {
    setParameterEditColumn: function (state, parameterEditColumn) {
      state.parameterEditColumn = parameterEditColumn;
    }
  }
};

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    settings: settingsModule,
    sharedData: sharedDataModule,
    tempData: tempDataModule
  }
});

store.subscribe((mutation, state) => {
  localStorage.setItem(SETTINGS_STORE, JSON.stringify(state.settings));
});

export default store;
