













































import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import AnalysisGroupCategoryTypeahead from '@/components/AnalysisGroupCategoryTypeahead.vue';

export default mixins(CreateAndUpdateForm, SharedDataMixin).extend({
  components: { AnalysisGroupCategoryTypeahead },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.analysisGroupCategoryForm);
  }
});
