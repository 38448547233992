import IContact from './IContact';

export default class CrmContact implements IContact {
  public static FromInterface(order: IContact): CrmContact {
    return Object.assign(new CrmContact(), order);
  }

  public id: string | undefined;
  public kundennr: number | undefined;
  public anrede: string | undefined;
  public vorname: string | undefined;
  public nachname: string | undefined;
  public function: string | undefined;
  public telefon: string | undefined;
  public handy: string | undefined;
  public fax: string | undefined;
  public email: string | undefined;
  public inactive: number | undefined;

  public get fullName(): string {
    const nameParts = [];

    if (this.vorname) {
      nameParts.push(this.vorname.trim());
    }
    if (this.nachname) {
      nameParts.push(this.nachname.trim());
    }

    return nameParts.join(' ');
  }
}
