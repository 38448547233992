import SampleSelection from '@/services/SampleSelection';
import { RemoteData } from 'rey-frontend-fp';
import ISampleIndex from '@/models/ISampleIndex';
import Vue from 'vue';

export default Vue.extend({
  data: function () {
    return {
      sampleIndexRd: RemoteData.notAsked<Array<ISampleIndex>, Error>(),
      sampleSelection: new SampleSelection('', [])
    };
  },
  methods: {
    initializeSampleRange: async function (
      orderId: string,
      sampleSelector: string | undefined = undefined
    ) {
      await this.loadSampleIndex(orderId);

      const items = this.sampleIndexRd.withDefault([]).map((s: ISampleIndex) => s.itemNumber);

      if (sampleSelector === undefined || sampleSelector === null) {
        sampleSelector =
          items.length > 0 ? `${Math.min.apply(null, items)}-${Math.max.apply(null, items)}` : '';
      }

      this.sampleSelection = new SampleSelection(sampleSelector, items);
    },
    loadSampleIndex: async function (orderId: string) {
      this.sampleIndexRd = await this.$labordatApi.getRd(`/api/orders/${orderId}/samples/index`);
    },
    getSampleIndices: function () {
      return this.sampleIndexRd.withDefault([]).map((s: ISampleIndex) => s.itemNumber);
    }
  }
});
