























import _ from 'lodash';
import $ from 'jquery';
import mixins from 'vue-typed-mixins';
import TypeaheadMixin from '@/mixins/TypeaheadMixin';
import { RemoteData, identity } from 'rey-frontend-fp';
import IAnalysisParameterDefinition from '@/models/IAnalysisParameterDefinition'; //NOSONAR

export default mixins(TypeaheadMixin).extend({
  props: {
    value: String,
    title: String,
    transformator: Function,
    disabled: Boolean
  },
  data: function () {
    return {
      analysisParameterDefinitionsRd: RemoteData.notAsked<
        Array<IAnalysisParameterDefinition>,
        Error
      >(),
      analysisParameterDefinitionRd: RemoteData.notAsked<IAnalysisParameterDefinition, Error>(),
      // data allows method properties to be accessed
      updateTypeAhead: _.debounce(
        function (searchString: string) {
          // @ts-ignore
          this.loadAnalysisParameterDefinitions(searchString);
        }.bind(this),
        500
      )
    };
  },
  mounted: async function () {
    this.updateDisabled();
    await this.refreshValue();
  },
  computed: {
    dataTransformator: function () {
      return this.transformator || identity;
    }
  },
  watch: {
    value: async function () {
      await this.refreshValue();
    },
    disabled: function () {
      this.updateDisabled();
    }
  },
  methods: {
    refreshValue: async function () {
      this.analysisParameterDefinitionRd = this.value
        ? await this.loadAnalysisParameterDefinition(this.value)
        : RemoteData.notAsked();

      // Sometimes the typeahead is not mounted yet.
      if (this.$refs.typeahead) {
        const value = this.analysisParameterDefinitionRd
          .map(this.typeaheadSerializer)
          .withDefault('');
        // @ts-ignore
        this.$refs.typeahead.inputValue = value;
      }
    },
    updateDisabled: function () {
      $('#aptypeahead input[type=search]').prop('disabled', this.disabled);
    },
    onHit: async function (analysisParameter: IAnalysisParameterDefinition) {
      this.cancelTypeAheadSearch();
      this.analysisParameterDefinitionRd = await this.loadAnalysisParameterDefinition(
        analysisParameter.code
      );

      if (this.analysisParameterDefinitionRd.hasData()) {
        this.$emit('input', +this.analysisParameterDefinitionRd.getData().code);
        this.$emit('hit', this.analysisParameterDefinitionRd.getData());
      }

      // @ts-ignore
      this.$refs.typeahead.inputValue = '';
    },
    cancelTypeAheadSearch: function () {
      _.debounce(
        function () {
          // @ts-ignore
          this.updateTypeAhead.cancel();
        }.bind(this),
        100
      )();
    },
    typeaheadSerializer: function (analysisParameter: IAnalysisParameterDefinition) {
      return (
        analysisParameter.code +
        (analysisParameter.nameDe ? ' (' + analysisParameter.nameDe + ')' : '')
      );
    },
    loadAnalysisParameterDefinitions: async function (searchString: string) {
      if (searchString && searchString.length > 0) {
        this.analysisParameterDefinitionsRd = await this.$labordatApi.getRd(
          `/api/analysisparameterdefinition/search?searchString=${searchString}`
        );
      }
    },
    loadAnalysisParameterDefinition: function (
      code: string
    ): Promise<RemoteData<IAnalysisParameterDefinition, Error>> {
      return this.$labordatApi.getRd(`/api/analysisparameterdefinition/${code}`);
    },
    getAnalysisParameters: function (): IAnalysisParameterDefinition[] {
      return _.sortBy(
        this.analysisParameterDefinitionsRd.withDefault([]),
        (apd) => apd.rapportPosition
      );
    }
  }
});
