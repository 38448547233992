/* eslint-disable max-classes-per-file */

type WorksheetTemplateState = 'new' | 'existing' | 'none';

export interface IWorksheetTemplateState {
  kind: WorksheetTemplateState;
  id: number | undefined;
}

export class NewWorksheetTemplate implements IWorksheetTemplateState {
  public kind: WorksheetTemplateState = 'new';
  public id = 0;
}

export class ExistingWorksheetTemplate implements IWorksheetTemplateState {
  public kind: WorksheetTemplateState = 'existing';
  public id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class NoWorksheetTemplate implements IWorksheetTemplateState {
  public kind: WorksheetTemplateState = 'none';
  public id: number | undefined = undefined;
}
