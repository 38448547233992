

























import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import TypeaheadMixin from '@/mixins/TypeaheadMixin';
import ReportPictureCategoryMixin from '@/mixins/ReportPictureCategoryMixin';
import { RemoteData, identity } from 'rey-frontend-fp';
import IReportPictureCategory from '@/models/IReportPictureCategory'; //NOSONAR

export default mixins(TypeaheadMixin, ReportPictureCategoryMixin).extend({
  props: {
    value: Number,
    title: String,
    required: Boolean,
    transformator: Function
  },
  data: function () {
    return {
      reportPictureCategoriesRd: RemoteData.notAsked<Array<IReportPictureCategory>, Error>(),
      reportPictureCategoryRd: RemoteData.notAsked<IReportPictureCategory, Error>(),
      // data allows method properties to be accessed
      updateTypeAhead: _.debounce(
        function (searchString: string) {
          // @ts-ignore
          this.loadReportPictureCategories(searchString);
        }.bind(this),
        500
      )
    };
  },
  mounted: async function () {
    await this.initCategoryTree();
    await this.refreshValue();
  },
  computed: {
    dataTransformator: function () {
      return this.transformator || identity;
    }
  },
  watch: {
    value: async function () {
      await this.refreshValue();
    }
  },
  methods: {
    refreshValue: async function () {
      this.reportPictureCategoryRd = this.value
        ? await this.loadReportPictureCategory(this.value)
        : RemoteData.notAsked();

      // Sometimes the typeahead is not mounted yet.
      if (this.$refs.typeahead) {
        const value = this.reportPictureCategoryRd
          .map(this.buildSingleCategoryTree)
          .map(this.typeaheadSerializer)
          .withDefault('');
        // @ts-ignore
        this.$refs.typeahead.inputValue = value;
      }
    },
    onHit: async function (category: IReportPictureCategory) {
      this.cancelTypeAheadSearch();
      this.reportPictureCategoryRd = await this.loadReportPictureCategory(category.id);

      if (this.reportPictureCategoryRd.hasData()) {
        this.$emit('input', +this.reportPictureCategoryRd.getData().id);
        this.$emit('hit', this.reportPictureCategoryRd.getData());
      }
    },
    cancelTypeAheadSearch: function () {
      _.debounce(
        function () {
          // @ts-ignore
          this.updateTypeAhead.cancel();
        }.bind(this),
        100
      )();
    },
    typeaheadSerializer: function (category: IReportPictureCategory) {
      return category.name;
    },
    loadReportPictureCategories: async function (searchString: string) {
      this.reportPictureCategoriesRd = await this.$labordatApi.getPagedRd(
        `/api/reportpicturecategory?search=${searchString}`
      );
    },
    loadReportPictureCategory: function (
      id: number
    ): Promise<RemoteData<IReportPictureCategory, Error>> {
      return this.$labordatApi.getRd(`/api/reportpicturecategory/${id}`);
    }
  }
});
