






























































































import IAutomaticReportSendingPreviewDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IAutomaticReportSendingPreviewDto';
import MailEditor, { FormData, MailSelectionItem } from './MailEditor.vue';
import mixins from 'vue-typed-mixins';
import IEmployeeFunction from '@/models/IEmployeeFunction';
import SettingsMixin from '@/mixins/SettingsMixin';
import UserMixin from '@/mixins/UserMixin';
import StickyActionBar from '@/components/StickyActionBar.vue';
import IFinalizeAutomaticReportSendingReportWorkflowRequestDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IFinalizeAutomaticReportSendingReportWorkflowRequestDto';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import Language from '@/models/Language';
import { Guid } from 'guid-typescript';

type MailMasterDataDto = {
  preferredLanguage: number;
  toReceivers: string[];
  ccReceivers: string[];
  proposedMailSubject: string;
  proposedMailBody: string;
};
type MailSignatureDto = {
  compiledSignature: string;
};
type ReportCompilationPreview = {
  id: number;
  sampleId: null | number;
  displayName: string;
  fileName: string;
};
export default mixins(SettingsMixin, UserMixin, FileDownloadMixin).extend({
  props: {
    reportCompilationSessionId: {
      type: Number,
      required: true
    },
    orderId: {
      type: String,
      required: true
    }
  },
  components: { MailEditor, StickyActionBar },
  data() {
    return {
      language: Language.DE,
      intendedToReceiverMailAddresses: [] as MailSelectionItem[],
      intendedCcReceiverMailAddresses: [] as MailSelectionItem[],
      proposedMailText: '',
      proposedMailSubject: '',
      mailSignature: '',
      availableEmployeeFunctions: [] as IEmployeeFunction[],
      validationErrors: [] as Array<{ tempId: Guid; message: string }>,
      mailFormData: {
        toReceiverMailAddresses: [] as MailSelectionItem[],
        ccReceiverMailAddresses: [] as MailSelectionItem[],
        senderMailAddress: null as null | string,
        selectedEmployeeFunction: null,
        mailSubject: '',
        mailContent: '',
        isGeneratingMailContent: true
      } as FormData,
      isGeneratingReportPreview: false,
      reportCompilationPreviews: [] as ReportCompilationPreview[],
      mailAttachmentPreviews: [] as { id: number; fileName: string }[]
    };
  },
  computed: {
    canSubmitDialog(): boolean {
      return this.mailFormData.isGeneratingMailContent !== true;
    },
    canPreviewBeGenerated(): boolean {
      return this.isGeneratingReportPreview !== true;
    }
  },
  async mounted() {
    this.mailFormData.isGeneratingMailContent = true;
    this.mailFormData.senderMailAddress = this.userEmail;
    await this.loadReportCompilationSessionData();
    await this.loadEmployeeFunctions();
    await this.loadMailMasterData();
    await this.reloadMailSignature();
    this.mailFormData.mailSubject = this.proposedMailSubject;
    this.mailFormData.mailContent = this.proposedMailText + this.mailSignature;
    this.resetMailReceivers();
    this.mailFormData.isGeneratingMailContent = false;
  },
  methods: {
    async loadReportCompilationSessionData() {
      await this.$labordatApi
        .get<IAutomaticReportSendingPreviewDto>(
          `/api/AutomaticReportSending/send/preview?reportCompilationSessionToGetPreviewFor=${this.reportCompilationSessionId}`
        )
        .then((response) => {
          this.reportCompilationPreviews = response.data.compilationPreviews.map((x) => {
            let displayName = x.sampleId ? `Probe ${x.sampleId}` : 'Prüfbericht';
            return {
              id: x.reportCompilationId,
              sampleId: x.sampleId,
              displayName: displayName,
              fileName: x.effectiveReportName
            };
          });
          this.mailAttachmentPreviews = response.data.mailAttachmentPreviews.map((x) => {
            return {
              id: x.id,
              fileName: x.name
            };
          });
        });
    },
    async loadEmployeeFunctions() {
      await this.$labordatApi
        .get(`/api/employeefunction?equals=userEmail=${this.userEmail}&orderBy=sequenceNumber`)
        .then((response) => response.data.entities as IEmployeeFunction[])
        .then((data) => {
          this.availableEmployeeFunctions = data.filter(
            (ef) => ef.departmentCode === this.$department
          );
          if (this.availableEmployeeFunctions.length === 0) {
            this.availableEmployeeFunctions = data.filter((ef) => ef.departmentCode === null);
          }
          this.mailFormData.selectedEmployeeFunction =
            this.availableEmployeeFunctions.length > 0 ? this.availableEmployeeFunctions[0] : null;
        });
    },
    async loadMailMasterData() {
      let response = await this.$labordatApi.get<MailMasterDataDto>(
        `/api/AutomaticReportSending/mail/masterData?orderId=${this.orderId}`
      );
      if (response.status == 200) {
        this.language = response.data.preferredLanguage;
        this.proposedMailSubject = response.data.proposedMailSubject;
        this.proposedMailText = response.data.proposedMailBody;
        let tempId = 1;
        response.data.toReceivers.forEach((toReceiver) => {
          this.intendedToReceiverMailAddresses.push({
            id: tempId,
            name: toReceiver,
            slug: toReceiver
          });
          tempId++;
        });
        response.data.ccReceivers.forEach((ccReceiver) => {
          this.intendedCcReceiverMailAddresses.push({
            id: tempId,
            name: ccReceiver,
            slug: ccReceiver
          });
          tempId++;
        });
      }
    },
    async handleSelectedJobTitleChanged() {
      this.mailFormData.isGeneratingMailContent = true;
      await this.reloadMailSignature();
      this.mailFormData.mailContent = this.proposedMailText + this.mailSignature;
      this.mailFormData.isGeneratingMailContent = false;
    },
    navigateToCompilingReportsDialog() {
      this.$emit('navigate-to-compiling-reports-dialog');
    },
    navigateToFinalizingWorkflowDialog() {
      this.validationErrors = [];
      if (!this.validateForm()) {
        return;
      }

      let data: IFinalizeAutomaticReportSendingReportWorkflowRequestDto = {
        ReportCompilationSessionToFinalize: this.reportCompilationSessionId,
        ReplyToMailAddress: this.mailFormData.senderMailAddress,
        MailSubject: this.mailFormData.mailSubject,
        MailBody: this.mailFormData.mailContent,
        ToMailAddresses: this.mailFormData.toReceiverMailAddresses.map((x) => x.name),
        CcMailAddresses: this.mailFormData.ccReceiverMailAddresses.map((x) => x.name)
      };

      this.$emit('navigate-to-finalizing-workflow-dialog', data);
    },
    async downloadMailAttachment(customMailAttachmentIdToDownload: number) {
      await this.$labordatApi
        .get(
          `/api/AutomaticReportSendingCustomMailAttachments/${customMailAttachmentIdToDownload}`,
          {
            responseType: 'arraybuffer'
          }
        )
        .then((response) => {
          this.downloadFile(response, 'Zusätzlicher_Mailanhang');
        });
    },
    async downloadReportPreview() {
      this.isGeneratingReportPreview = true;
      await this.$labordatApi
        .get(
          `/api/AutomaticReportSending/reportCompilation/preview?reportCompilationSessionToGetPreviewFor=${this.reportCompilationSessionId}`,
          {
            responseType: 'arraybuffer'
          }
        )
        .then((response) => {
          this.downloadFile(response, 'Prüfberichtsvorschau.pdf');
        });
      this.isGeneratingReportPreview = false;
    },
    triggerWorkflowCancelledEvent() {
      this.$emit('workflow-cancelled');
    },
    async reloadMailSignature() {
      if (!this.mailFormData.selectedEmployeeFunction) {
        return;
      }
      let response = await this.$labordatApi.get<MailSignatureDto>(
        `/api/AutomaticReportSending/mail/signature?language=${this.language}&employeeFunctionId=${this.mailFormData.selectedEmployeeFunction.id}`
      );
      if (response.status == 200) {
        this.mailSignature = response.data.compiledSignature;
      }
    },
    resetMailReceivers() {
      this.mailFormData.toReceiverMailAddresses = [...this.intendedToReceiverMailAddresses];
      this.mailFormData.ccReceiverMailAddresses = [...this.intendedCcReceiverMailAddresses];
    },
    validateForm(): boolean {
      const mailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z0-9]+$/;

      if (!mailRegex.test(this.mailFormData.senderMailAddress)) {
        this.validationErrors.push({
          tempId: Guid.create(),
          message: 'Die Sender-Mailadresse ist keine gültige E-Mailadresse'
        });
      }

      if (this.mailFormData.toReceiverMailAddresses.length < 1) {
        this.validationErrors.push({
          tempId: Guid.create(),
          message: `Es muss mindestens eine An-Mailadresse angegeben werden`
        });
      } else {
        this.mailFormData.toReceiverMailAddresses
          .map((x) => x.name)
          .forEach((mailAddress) => {
            if (!mailRegex.test(mailAddress)) {
              this.validationErrors.push({
                tempId: Guid.create(),
                message: `Die An-Mailadresse "${mailAddress}" ist keine gültige E-Mailadresse`
              });
            }
          });
      }

      this.mailFormData.ccReceiverMailAddresses
        .map((x) => x.name)
        .forEach((mailAddress) => {
          if (!mailRegex.test(mailAddress)) {
            this.validationErrors.push({
              tempId: Guid.create(),
              message: `Die Cc-Mailadresse "${mailAddress}" ist keine gültige E-Mailadresse`
            });
          }
        });

      return this.validationErrors.length === 0;
    }
  }
});
