











































































































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import _ from 'lodash';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import WorksheetTemplateDetailsForm from '@/views/Templates/WorksheetTemplate/WorksheetTemplateDetailsForm.vue';
import {
  IWorksheetTemplateState,
  NoWorksheetTemplate,
  ExistingWorksheetTemplate,
  NewWorksheetTemplate
} from '@/models/IWorksheetTemplateState';
import IWorksheetTemplate from '@/models/IWorksheetTemplate';
import ReportType from '@/models/ReportType';
import IDepartment from '@/models/IDepartment';

export default mixins(SettingsMixin, SharedDataMixin, FileDownloadMixin).extend({
  components: { WorksheetTemplateDetailsForm },
  name: 'worksheet-template',
  props: {
    itemId: String
  },
  data: function () {
    return {
      worksheetTemplatesRd: RemoteData.notAsked<Array<IWorksheetTemplate>, Error>(),
      worksheetTemplateRd: UpdatableRemoteData.notAsked<IWorksheetTemplate, Error>(),
      worksheetTemplateState: new NoWorksheetTemplate()
    };
  },
  created: async function () {
    await this.loadWorksheetTemplates();
    if (this.id) {
      this.loadWorksheetTemplate(this.id);
      this.worksheetTemplateState = new ExistingWorksheetTemplate(this.id);
    }
  },
  computed: {
    id: function (): number {
      return +this.itemId;
    }
  },
  watch: {
    worksheetTemplateState: async function (value: IWorksheetTemplateState) {
      switch (value.kind) {
        case 'none':
          this.worksheetTemplateRd = UpdatableRemoteData.notAsked();
          this.$router.push(`/templates/worksheettemplate`);
          break;
        case 'new':
          this.worksheetTemplateRd = UpdatableRemoteData.notCreated({
            departmentCode: this.$department,
            reportType: ReportType.Standard
          } as IWorksheetTemplate);
          this.$router.push(`/templates/worksheettemplate`);
          break;
        case 'existing':
          if (value.id) {
            await this.loadWorksheetTemplate(value.id);
            this.$router.push(`/templates/worksheettemplate/${value.id}`);
          }
          break;
      }
    }
  },
  methods: {
    worksheetTemplateDepartments: function (): IDepartment[] {
      return this.$departments
        .filter(
          (d) =>
            this.worksheetTemplatesRd
              .withDefault([])
              .map((wt) => wt.departmentCode)
              .indexOf(d.code) >= 0
        )
        .sort();
    },
    worksheetTemplatesByDepartment: function (department: string) {
      return _.sortBy(
        this.worksheetTemplatesRd.getData().filter((wt) => wt.departmentCode === department),
        (wt) => wt.name
      );
    },
    loadWorksheetTemplates: async function () {
      try {
        this.worksheetTemplatesRd = await this.$labordatApi.getPagedRd(`/api/worksheettemplate`);
      } catch (error) {
        this.worksheetTemplatesRd = RemoteData.failure(error as Error);
      }
    },
    loadWorksheetTemplate: async function (id: number) {
      try {
        this.worksheetTemplateRd = UpdatableRemoteData.loading();
        this.worksheetTemplateRd = await this.$labordatApi.getUrd(`/api/worksheettemplate/${id}`);
      } catch (error) {
        this.worksheetTemplateRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    selectWorksheetTemplate: function (event: Event) {
      const itemId = +(event.target as HTMLInputElement).value;
      if (itemId) {
        this.worksheetTemplateState = new ExistingWorksheetTemplate(itemId);
      }
    },
    newWorksheetTemplate: function () {
      this.worksheetTemplateState = new NewWorksheetTemplate();
    },
    deleteWorksheetTemplate: async function () {
      if (this.worksheetTemplateState.kind !== 'existing' || !this.worksheetTemplateRd.hasData()) {
        return;
      }

      if (confirm('Wollen Sie die Arbeitsblattsvorlage wirklich löschen?') === false) {
        return;
      }

      try {
        this.worksheetTemplateRd = UpdatableRemoteData.updating(this.worksheetTemplateRd.getData());
        const worksheetTemplate = this.worksheetTemplateRd.getData();

        await this.$labordatApi.delete(
          `/api/file/delete?templateType=Worksheet&templateId=${worksheetTemplate.id}`
        );
        await this.$labordatApi.delete(
          `/api/worksheettemplate/${this.worksheetTemplateRd.getData().id}`,
          {
            data: this.worksheetTemplateRd.getData()
          }
        );

        this.worksheetTemplateState = new NoWorksheetTemplate();
        await this.loadWorksheetTemplates();
      } catch (error) {
        this.worksheetTemplateRd = UpdatableRemoteData.updateFailure(
          this.worksheetTemplateRd.getData(),
          error as Error
        );
      }
    },
    resetWorksheetTemplate: function () {
      this.worksheetTemplateState = new NoWorksheetTemplate();
    },
    createWorksheetTemplate: async function () {
      const worksheetTemplate = this.worksheetTemplateRd.getData();
      this.worksheetTemplateRd = UpdatableRemoteData.updating(worksheetTemplate);
      try {
        const result: IWorksheetTemplate = await this.$labordatApi
          .post(`/api/worksheettemplate`, worksheetTemplate)
          .then((response) => response.data as IWorksheetTemplate);

        this.worksheetTemplateState = new ExistingWorksheetTemplate(result.id);
      } catch (error) {
        this.worksheetTemplateRd = UpdatableRemoteData.updateFailure<IWorksheetTemplate, Error>(
          worksheetTemplate,
          error as Error
        );
      }
      await this.loadWorksheetTemplates();
    },
    updateWorksheetTemplate: async function () {
      const worksheetTemplate = this.worksheetTemplateRd.getData();
      this.worksheetTemplateRd = UpdatableRemoteData.updating(worksheetTemplate);
      this.worksheetTemplateRd = await this.$labordatApi
        .put(`/api/worksheettemplate/${worksheetTemplate.id}`, worksheetTemplate)
        .then((response) => response.data as IWorksheetTemplate)
        .then((data) => {
          return UpdatableRemoteData.success<IWorksheetTemplate, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IWorksheetTemplate, Error>(
            worksheetTemplate,
            error as Error
          )
        );
      await this.loadWorksheetTemplates();
    },
    upload: async function (id: string, file: File) {
      const worksheetTemplate = this.worksheetTemplateRd.getData();
      this.worksheetTemplateRd = UpdatableRemoteData.updating(worksheetTemplate);
      try {
        const formData = new FormData();
        formData.append('templateId', worksheetTemplate.id.toString());
        formData.append('templateType', 'Worksheet');
        formData.append('file', file);
        this.worksheetTemplateRd = await this.$labordatApi
          .post(`/api/file/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => response.data as IWorksheetTemplate)
          .then((data) => {
            return UpdatableRemoteData.success<IWorksheetTemplate, Error>(data);
          });
      } catch (error) {
        this.worksheetTemplateRd = UpdatableRemoteData.updateFailure<IWorksheetTemplate, Error>(
          worksheetTemplate,
          error as Error
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    download: async function (id: string) {
      const worksheetTemplate = this.worksheetTemplateRd.getData();
      try {
        await this.$labordatApi
          .get(`/api/file/download?templateType=Worksheet&templateId=${worksheetTemplate.id}`, {
            responseType: 'arraybuffer'
          })
          .then((response) => {
            this.downloadFile(response, 'Arbeitsblattvorlage.docx');
          });
      } catch (error) {
        this.worksheetTemplateRd = UpdatableRemoteData.updateFailure<IWorksheetTemplate, Error>(
          worksheetTemplate,
          error as Error
        );
      }
    }
  }
});
