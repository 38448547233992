











































































































































import $ from 'jquery';
import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import { RemoteData } from 'rey-frontend-fp';
import FileUpAndDownload from '@/components/FileUpAndDownload.vue';
import ReportPictureCategoryTypeahead from '@/components/ReportPictureCategoryTypeahead.vue';
// @ts-ignore
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
// @ts-ignore
import { Compact } from 'vue-color';
import IReportPictureTag from '@/models/IReportPictureTag';

export default mixins(CreateAndUpdateForm, DateTimeMixin, SharedDataMixin).extend({
  components: { FileUpAndDownload, VueCropper, ReportPictureCategoryTypeahead, Compact },
  name: 'report-picture-details-form',
  data() {
    return {
      reportPictureTagsRd: RemoteData.notAsked<Array<IReportPictureTag>, Error>(),
      hasApiError: false,
      cropBoxBig: String,
      cropBoxSmall: String,
      cropBoxChangedDebounced: _.debounce(
        function () {
          // @ts-ignore
          this.$emit('crop-box-changed', this.cropBoxBig, this.cropBoxSmall);
        }.bind(this),
        500
      ),
      activeTags: [] as { id: number; name: string; slug: string }[],
      allTags: [] as { id: number; name: string; slug: string }[],
      newTags: [] as { id: number; name: string; slug: string }[]
    };
  },
  watch: {
    model: function () {
      this.loadReportPictureTags();
    }
  },
  mounted: async function () {
    await this.loadReportPictureTags();
    // @ts-ignore
    this.setFormRef(this.$refs.reportPictureForm);
    (this.$refs.name as HTMLInputElement).focus();
  },
  methods: {
    uploadFile: async function (id: string, file: File) {
      this.$emit('file-uploaded', file);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadFile: async function (id: string) {
      this.$emit('file-downloaded');
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadCropBoxData: async function (e: Event) {
      if (this.$refs.cropperBig) {
        // @ts-ignore
        this.$refs.cropperBig.setData(JSON.parse(this.model.cropBoxBig));
      }
      if (this.$refs.cropperSmall) {
        // @ts-ignore
        this.$refs.cropperSmall.setData(JSON.parse(this.model.cropBoxSmall));
      }
    },
    cropChanged: async function (e: Event) {
      if (this.$refs.cropperBig && this.$refs.cropperSmall) {
        // @ts-ignore
        const big = this.$refs.cropperBig.getData();
        // @ts-ignore
        const small = this.$refs.cropperSmall.getData();

        if (e.target) {
          this.calculateCrop(e, big, small);
        }

        if (
          JSON.stringify(big) !== JSON.stringify(new Object()) &&
          JSON.stringify(small) !== JSON.stringify(new Object())
        ) {
          this.cropBoxBig = big;
          this.cropBoxSmall = small;
          this.cropBoxChangedDebounced();
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    calculateCrop(e: Event, big: any, small: any) {
      if (
        // @ts-ignore
        $(e.target).closest('div').attr('id') === 'cropperBig' &&
        JSON.stringify(big) !== JSON.stringify(new Object())
      ) {
        if (small.width !== big.width) {
          small.height = (small.height / small.width) * big.width;
          small.width = big.width;
          small.x = big.x;
          // @ts-ignore
          this.$refs.cropperSmall.setData(small);
        }
      } else if (
        // @ts-ignore
        $(e.target).closest('div').attr('id') === 'cropperSmall' &&
        JSON.stringify(small) !== JSON.stringify(new Object())
      ) {
        if (big.width !== small.width) {
          big.height = (big.height / big.width) * small.width;
          big.width = small.width;
          big.x = small.x;
          // @ts-ignore
          this.$refs.cropperBig.setData(big);
        }
      }
    },
    commentColorChanged: async function () {
      this.$emit('color-changed', this.model.commentColor.hex);
    },
    loadReportPictureTags: async function () {
      this.reportPictureTagsRd = await this.$labordatApi.getPagedRd(`/api/reportpicturetag`);
      this.getAllTags();
      this.getActiveTags();
    },
    getActiveTags: function () {
      const tags = (this.model.tags || '').split(' ');
      this.activeTags = this.allTags.filter((t) => tags.indexOf(t.name) >= 0);
    },
    getAllTags: function () {
      let tags = this.reportPictureTagsRd.withDefault([]).map(function (r) {
        return {
          id: r.id,
          name: r.name,
          slug: r.name
        };
      });

      for (const newTag of this.newTags) {
        if (tags.every((t) => t.name !== newTag.name)) {
          newTag.id = -1;
          tags.push(newTag);
        }
      }

      this.allTags = tags;
    },
    tagCreatedOrAdded: async function (tag: { id: number; name: string; slug: string }) {
      const tags = (this.model.tags || '').trim().split(' ');
      if (tags.indexOf(tag.name) < 0) {
        tags.push(tag.name);
        this.model.tags = tags.join(' ');
        this.updateDebounced();
        if (this.allTags.every((t) => t.name !== tag.name)) {
          this.newTags.push(tag);
        }
        await this.loadReportPictureTags();
      }
      // @ts-ignore
      this.$refs.reportPictureTags.$refs.hiddenInput.focus();
    },
    tagRemoved: async function (tag: { id: number; name: string; slug: string }) {
      let tags: string[] = (this.model.tags || '').trim().split(' ');
      if (tags.indexOf(tag.name) >= 0) {
        tags = tags.filter((t) => t !== tag.name);
        this.model.tags = tags.join(' ');
        this.updateDebounced();
        this.newTags = this.newTags.filter((t) => t.name !== tag.name);
        await this.loadReportPictureTags();
      }
    },
    clearNewTags() {
      this.newTags = [];
    }
  }
});
