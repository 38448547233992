
































































































































import $ from 'jquery';
import mixins from 'vue-typed-mixins';
import VModelMixin from '@/mixins/VModelMixin';
import SettingsMixin from '@/mixins/SettingsMixin';
import UserMixin from '@/mixins/UserMixin';
import OrderConfirmationMixin from '@/mixins/OrderConfirmationMixin';
// @ts-ignore
import { VueEditor } from 'vue2-editor';
import QuillOptions from '@/models/QuillOptions';
import IMail from '@/models/IMail';
import IEmployeeFunction from '@/models/IEmployeeFunction';
import Language from '@/models/Language';

export default mixins(VModelMixin, SettingsMixin, UserMixin, OrderConfirmationMixin).extend({
  components: {
    VueEditor
  },
  props: {
    orderId: String,
    language: Number,
    capturedReceiverMailAddressesOfCustomer: Array,
    proposedReceiverMailAddresses: Array,
    initialSenderMailAddress: String,
    initialSubject: String,
    initialText: String,
    isVisible: Boolean
  },
  data() {
    return {
      id: 'orderConfirmationMailDialog',
      quillOptions: QuillOptions.config,
      inputs: {
        senderMailAddress: this.initialSenderMailAddress,
        receiverMailAddresses: this.proposedReceiverMailAddresses as {
          id: number;
          name: string;
          slug: string;
        }[],
        senderEmployeeFunction: undefined as IEmployeeFunction | undefined,
        subject: this.initialSubject,
        content: this.initialText
      },
      areReceiverMailAddressesValid: true,
      isSenderMailAddressValid: true,
      isSubjectValid: true,
      isSendingOrderConfirmationMail: false,
      somethingWentWrongWhileSendingMail: false,
      availableEmployeeFunctions: [] as IEmployeeFunction[],
      isGeneratingOrderConfirmationMailText: false
    };
  },
  watch: {
    isVisible: function (newValue) {
      if (newValue === true) {
        this.loadEmployeeFunctions();
        this.fillInInitialFormValues();
        // @ts-ignore
        $('#' + this.id).modal('show');
      } else if (newValue === false) {
        // @ts-ignore
        $('#' + this.id).modal('hide');
        this.resetForm();
      }
    }
  },
  mounted() {
    $('#' + this.id).on('hidden.bs.modal', () => this.onCancel());
  },
  methods: {
    onCancel: function () {
      this.$emit('cancelDialog');
    },
    onMailSent: function () {
      this.$emit('mailSent');
    },
    onSend: function () {
      const mailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z0-9]+$/;
      this.isSubjectValid = !!this.inputs.subject;
      this.isSenderMailAddressValid = mailRegex.test(this.inputs.senderMailAddress);
      this.areReceiverMailAddressesValid =
        this.inputs.receiverMailAddresses.length > 0 &&
        !this.inputs.receiverMailAddresses.some((entry) => !mailRegex.test(entry.name));
      if (
        !this.isSubjectValid ||
        !this.isSenderMailAddressValid ||
        !this.areReceiverMailAddressesValid
      ) {
        return;
      }

      this.isSendingOrderConfirmationMail = true;
      this.$labordatApi
        .post(`/api/orders/${this.orderId}/confirmation`, {
          senderMailAddress: this.inputs.senderMailAddress,
          receiverMailAddresses: this.inputs.receiverMailAddresses.map((entry) => entry.name),
          subject: this.inputs.subject,
          content: this.inputs.content
        } as IMail)
        .then((response) => {
          this.isSendingOrderConfirmationMail = false;
          if (response && response.status == 200) {
            this.somethingWentWrongWhileSendingMail = false;
            this.onMailSent();
          } else {
            this.somethingWentWrongWhileSendingMail = true;
          }
        });
    },
    resetForm() {
      this.inputs.senderEmployeeFunction = undefined;
      this.inputs.receiverMailAddresses = [];
      this.inputs.subject = '';
      this.inputs.content = '';
      this.isSubjectValid = true;
      this.isSenderMailAddressValid = true;
      this.areReceiverMailAddressesValid = true;
      this.isSendingOrderConfirmationMail = false;
      this.somethingWentWrongWhileSendingMail = false;
    },
    fillInInitialFormValues() {
      this.inputs.senderEmployeeFunction =
        this.availableEmployeeFunctions.length > 0 ? this.availableEmployeeFunctions[0] : undefined;
      this.inputs.subject = this.initialSubject;
      this.inputs.senderMailAddress = this.initialSenderMailAddress;
      this.inputs.receiverMailAddresses = this.proposedReceiverMailAddresses as {
        id: number;
        name: string;
        slug: string;
      }[];
      this.inputs.content = this.initialText;
    },
    loadEmployeeFunctions() {
      this.$labordatApi
        .get(`/api/employeefunction?equals=userEmail=${this.userEmail}&orderBy=sequenceNumber`)
        .then((response) => response.data.entities as IEmployeeFunction[])
        .then((data) => {
          this.availableEmployeeFunctions = data.filter(
            (ef) => ef.departmentCode === this.$department
          );
          if (this.availableEmployeeFunctions.length === 0) {
            this.availableEmployeeFunctions = data.filter((ef) => ef.departmentCode === null);
          }
          this.inputs.senderEmployeeFunction =
            this.availableEmployeeFunctions.length > 0
              ? this.availableEmployeeFunctions[0]
              : undefined;
        });
    },
    getJobTitle(employeeFunction: IEmployeeFunction) {
      switch (this.language) {
        case Language.EN:
          return employeeFunction.nameEn;
        case Language.FR:
          return employeeFunction.nameFr;
        case Language.IT:
          return employeeFunction.nameIt;
        case Language.DE:
        default:
          return employeeFunction.nameDe;
      }
    },
    jobTitleChanged() {
      this.isGeneratingOrderConfirmationMailText = true;
      this.generateOrderConfirmationMailText(
        this.orderId,
        this.inputs.senderEmployeeFunction ? this.inputs.senderEmployeeFunction.id : 0
      )
        .then((generatedOrderConfirmationMailText) => {
          this.inputs.content = generatedOrderConfirmationMailText;
        })
        .finally(() => {
          this.isGeneratingOrderConfirmationMailText = false;
        });
    },
    receiverMailAddressAdded: async function (receiverToAdd: {
      id: number;
      name: string;
      slug: string;
    }) {
      const alreadyInList = this.inputs.receiverMailAddresses.some(
        (receiver) => receiver.name.toLowerCase() == receiverToAdd.name.toLowerCase()
      );
      if (!alreadyInList) {
        this.inputs.receiverMailAddresses.push(receiverToAdd);
      }
    },
    receiverMailAddressRemoved: async function (receiverToRemove: {
      id: number;
      name: string;
      slug: string;
    }) {
      this.inputs.receiverMailAddresses = this.inputs.receiverMailAddresses.filter(
        (receiver) => receiver.name.toLowerCase() != receiverToRemove.name.toLowerCase()
      );
    }
  }
});
