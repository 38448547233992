/* eslint-disable max-classes-per-file */

type PredefinedReportAttachmentState = 'new' | 'existing' | 'none';

export interface IPredefinedReportAttachmentState {
  kind: PredefinedReportAttachmentState;
  id: number | undefined;
}

export class NewPredefinedReportAttachment implements IPredefinedReportAttachmentState {
  public kind: PredefinedReportAttachmentState = 'new';
  public id = 0;
}

export class ExistingPredefinedReportAttachment implements IPredefinedReportAttachmentState {
  public kind: PredefinedReportAttachmentState = 'existing';
  public id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class NoPredefinedReportAttachment implements IPredefinedReportAttachmentState {
  public kind: PredefinedReportAttachmentState = 'none';
  public id: number | undefined = undefined;
}
