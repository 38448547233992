







































































import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import FileUpAndDownload from '@/components/FileUpAndDownload.vue';
import ReportType from '@/models/ReportType';

export default mixins(CreateAndUpdateForm, SharedDataMixin).extend({
  components: { FileUpAndDownload },
  name: 'worksheet-template-details-form',
  data() {
    return {
      hasApiError: false
    };
  },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.worksheetTemplateForm);
    (this.$refs.templatename as HTMLInputElement).focus();
  },
  computed: {
    reportTypes: function (): { id: number; name: string }[] {
      return this.enumToObjectArray(ReportType).map((rt) => ({
        id: rt.id,
        name: rt.name === 'GoodManufacturingPractice' ? 'GMP' : rt.name
      }));
    }
  },
  methods: {
    uploadFile: async function (id: string, file: File) {
      this.$emit('file-uploaded', '', file);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadFile: async function (id: string) {
      this.$emit('file-downloaded', '');
    }
  }
});
