






































































































































































































import _ from 'lodash';
import axios from 'axios';
import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import SummaryDataMixin from '@/mixins/SummaryDataMixin';
import SummaryDefinitionEntityMixin from '@/mixins/SummaryDefinitionEntityMixin';
import SmartTableConfigManagementMixin from '@/mixins/SmartTableConfigManagementMixin';
import FormatMixin from '@/mixins/FormatMixin';
import { SmartTable, Filter, Sorting } from 'rey-vue-smarttable';
import ISummaryDefinition, { SummaryDefinitionEntityType } from '@/models/ISummaryDefinition';
import ISummaryDefinitionOptGroupRepresentation from '@/models/ISummaryDefinitionOptGroupRepresentation';

export default mixins(
  SettingsMixin,
  SharedDataMixin,
  SummaryDataMixin,
  SummaryDefinitionEntityMixin,
  SmartTableConfigManagementMixin,
  FormatMixin
).extend({
  components: {
    SmartTable
  },
  data: function () {
    return {
      selectedSummaryDefinitionId: undefined as number | undefined,
      currentSummaryDefinition: undefined as ISummaryDefinition | undefined,
      selected: undefined as { orderId: string; o_Id: string; s_ItemNumber: number } | undefined,
      tableIdPrefix: 'SummaryLayout',
      isOrderDelaySummaryDisplayed: false,
      orderDelaySummaryInterceptor: undefined as number | undefined,
      percentageOfOrdersDelayedPaginated: 0 as number,
      percentageOfOrdersDelayedFiltered: 0 as number,
      percentageOfOrdersDelayed: 0 as number,
      isBudgetSummaryDisplayed: false,
      budgetSummaryTotalInterceptor: undefined as number | undefined,
      budgetSummaryTotal: 0,
      budgetSummaryParameters: {
        $date: this.toIntlDateString(new Date()),
        $department: ''
      } as { $date: string; $department: string }
    };
  },
  computed: {
    summaryDefinitionGroups: function () {
      const groupedUnordered = _.groupBy(
        this.summaryDefinitions,
        this.getCategory
      ) as unknown as ISummaryDefinitionOptGroupRepresentation;
      return this.sortSummaryDefinitionByCategory(groupedUnordered);
    },
    entityName: function (): string {
      return (
        'Gehe zu ' +
        (this.currentSummaryDefinition
          ? this.getButtonNameSuffixForEntity(this.currentSummaryDefinition.entity)
          : '')
      );
    },
    displayEntitySelection: function (): boolean {
      if (!this.selectedSummaryDefinitionId) {
        return false;
      }
      return (
        this.selectedSummaryDefinitionId != this.summaryDefinitionIdOfBudget &&
        this.selectedSummaryDefinitionId != this.summaryDefinitionIdOfOrderDelay
      );
    }
  },
  created() {
    this.selectedSummaryDefinitionId = 1;
    this.budgetSummaryParameters.$department = this.$department;
  },
  async mounted() {
    this.budgetSummaryTotalInterceptor = axios.interceptors.response.use(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        if (
          response?.config?.url
            ?.toLowerCase()
            .endsWith(this.getApiUrlFromEntity('Budget').toLowerCase()) &&
          response?.config?.method?.toLowerCase() == 'get'
        ) {
          this.budgetSummaryTotal = response.data.budgetTotal;
          response.data = response.data.pagedResult;
        }
        return response;
      },
      function (error) {
        return error;
      }
    );
    this.orderDelaySummaryInterceptor = axios.interceptors.response.use(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        if (
          response?.config?.url
            ?.toLowerCase()
            .endsWith(this.getApiUrlFromEntity('OrderDelay').toLowerCase()) &&
          response?.config?.method?.toLowerCase() == 'get'
        ) {
          this.percentageOfOrdersDelayedPaginated =
            response.data.percentageOfOrdersDelayedPaginated;
          this.percentageOfOrdersDelayedFiltered = response.data.percentageOfOrdersDelayedFiltered;
          this.percentageOfOrdersDelayed = response.data.percentageOfOrdersDelayed;
          response.data = response.data.pagedResult;
        }
        return response;
      },
      function (error) {
        return error;
      }
    );
    await this.refreshSmartTableConfig();
  },
  beforeDestroy() {
    if (this.budgetSummaryTotalInterceptor) {
      axios.interceptors.response.eject(this.budgetSummaryTotalInterceptor);
    }
    if (this.orderDelaySummaryInterceptor) {
      axios.interceptors.response.eject(this.orderDelaySummaryInterceptor);
    }
  },
  watch: {
    selectedSummaryDefinitionId: function () {
      this.currentSummaryDefinition = this.summaryDefinitions.find(
        (x) => x.id === (this.selectedSummaryDefinitionId || 0)
      );

      if (this.currentSummaryDefinition?.entity == 'Budget') {
        this.isBudgetSummaryDisplayed = true;
        this.budgetSummaryTotal = 0;
        this.budgetSummaryParameters.$date = this.toIntlDateString(new Date());
        this.budgetSummaryParameters.$department = this.$department;
      } else {
        this.isBudgetSummaryDisplayed = false;
      }

      if (this.currentSummaryDefinition?.entity == 'OrderDelay') {
        this.isOrderDelaySummaryDisplayed = true;
        this.percentageOfOrdersDelayedPaginated = 0;
        this.percentageOfOrdersDelayedFiltered = 0;
        this.percentageOfOrdersDelayed = 0;
      } else {
        this.isOrderDelaySummaryDisplayed = false;
      }

      this.$nextTick(async () => {
        await this.refreshSmartTableConfig();
      });
    },
    $department: async function () {
      const current = this.selectedSummaryDefinitionId;
      this.selectedSummaryDefinitionId = undefined;
      this.$nextTick(() => (this.selectedSummaryDefinitionId = current));
    },
    budgetSummaryParameters: {
      deep: true,
      handler() {
        this.refreshSmartTableConfig();
      }
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getCategory(x: any) {
      return x.category;
    },
    async refreshSmartTableConfig() {
      if (!this.currentSummaryDefinition) {
        return;
      }

      const loadedConfig = await this.loadSmartTableConfig(
        this.tableIdPrefix +
          this.currentSummaryDefinition.id +
          this.currentSummaryDefinition.entity.toString()
      );

      if (this.currentSummaryDefinition.id === this.summaryDefinitionIdOfMyDepartmentOrders) {
        const departmentFilter = this.currentSummaryDefinition.config.permanentFilters.find(
          (x: Filter) => x.fieldName == 'o_DepartmentCode'
        );
        if (departmentFilter) {
          departmentFilter.filterQuery = [this.$department];
        } else {
          this.currentSummaryDefinition.config.permanentFilters.push({
            fieldName: 'o_DepartmentCode',
            fieldType: 'string',
            filterQuery: [this.$department],
            filterType: 'equals'
          });
        }
      } else if (this.currentSummaryDefinition.id === this.summaryDefinitionIdOfBudget) {
        const dateParameter = this.currentSummaryDefinition.config.permanentFilters.find(
          (x: Filter) => x.fieldName == 'param_date'
        );
        if (dateParameter) {
          dateParameter.filterQuery = [this.budgetSummaryParameters.$date];
        } else {
          this.currentSummaryDefinition.config.permanentFilters.push(
            new Filter('param_date', 'string', 'equals', [this.budgetSummaryParameters.$date])
          );
        }

        const departmentParameter = this.currentSummaryDefinition.config.permanentFilters.find(
          (x: Filter) => x.fieldName == 'param_department'
        );
        if (departmentParameter) {
          departmentParameter.filterQuery = [this.budgetSummaryParameters.$department];
        } else {
          this.currentSummaryDefinition.config.permanentFilters.push(
            new Filter('param_department', 'string', 'equals', [
              this.budgetSummaryParameters.$department
            ])
          );
        }
      }

      // This is crap as the internal smartTableTable is not yet there. So loop until it is...
      const interval = setInterval(() => {
        // @ts-ignore
        if (this.$refs.smartTable && this.$refs.smartTable.$refs.smartTableTable) {
          // @ts-ignore
          this.$refs.smartTable.resetLayout();
          if (loadedConfig) {
            // @ts-ignore
            this.$refs.smartTable.setLayout(loadedConfig);
          }

          clearInterval(interval);
        }
      }, 50);
    },
    async resetCurrentlySelectedSmartTableSettings() {
      if (!this.$refs.smartTable) {
        return;
      }

      // @ts-ignore
      this.$refs.smartTable.state.filters.length = 0;
      // @ts-ignore
      this.$refs.smartTable.state.sorting = [new Sorting('o_Id')];

      await this.saveSmartTableConfig(
        // @ts-ignore
        this.tableIdPrefix +
          // @ts-ignore
          this.currentSummaryDefinition.id +
          // @ts-ignore
          this.currentSummaryDefinition.entity.toString(),
        // @ts-ignore
        this.$refs.smartTable.getLayout()
      );
      await this.refreshSmartTableConfig();
    },
    async updateSmartTableConfig(tableId: string) {
      // @ts-ignore
      this.saveSmartTableConfigDebounced(tableId, this.$refs.smartTable.getLayout());
    },
    async currentSummaryDefinitionEntityChanged(entityType: SummaryDefinitionEntityType) {
      if (!this.currentSummaryDefinition) {
        return;
      }

      this.currentSummaryDefinition.entity = entityType;
      this.currentSummaryDefinition.config.withEfApiActions(
        // @ts-ignore
        this.$labordatApi,
        this.getApiUrlFromEntity(entityType)
      );

      this.currentSummaryDefinition.config.columns = this.getColumnSetFor(entityType);
      this.currentSummaryDefinition.config.key = this.getKeyFromEntity(entityType);

      await this.refreshSmartTableConfig();
    },
    goToEntity: function () {
      if (!this.selected || !this.currentSummaryDefinition) {
        return;
      }

      var entityLink = undefined;
      switch (this.currentSummaryDefinition.entity) {
        case 'Order':
          entityLink = `/orders/${this.selected.o_Id}`;
          break;

        case 'Sample':
        case 'AnalysisParameter':
          entityLink = `/orders/${this.selected.o_Id}/samples/${this.selected.s_ItemNumber}`;
          break;

        case 'OrderDelay':
        case 'Budget':
          entityLink = `/orders/${this.selected.orderId}`;
          break;
      }

      if (entityLink) {
        this.$router.push(entityLink);
      }
    },
    newOrder: function () {
      this.$router.push('/orders');
    },
    getButtonNameSuffixForEntity: function (entityType: SummaryDefinitionEntityType): string {
      switch (entityType) {
        case 'Order':
        case 'OrderDelay':
        case 'Budget':
          return 'Auftrag';

        case 'Sample':
        case 'AnalysisParameter':
          return 'Probe';
      }
    },
    exportCsv: function () {
      if (this.$refs.smartTable) {
        if (this.currentSummaryDefinition?.entity == 'Budget') {
          // @ts-ignore
          this.$refs.smartTable.exportCsv(
            (exportData: string) =>
              exportData +
              `\nTotal CHF: ${this.withSeparators(this.financial(this.budgetSummaryTotal))}`
          );
        } else if (this.currentSummaryDefinition?.entity == 'OrderDelay') {
          // @ts-ignore
          this.$refs.smartTable.exportCsv(
            (exportData: string) =>
              exportData +
              `\nAnteil der verspäteten Aufträge` +
              `\nAktuelle Ansicht;${this.percentageOfOrdersDelayedPaginated}%` +
              `\nGefiltert;${this.percentageOfOrdersDelayedFiltered}%`
          );
        } else {
          // @ts-ignore
          this.$refs.smartTable.exportCsv();
        }
      }
    },
    printView: function () {
      // @ts-ignore
      const isChrome = window.chrome != null && window.navigator.vendor === 'Google Inc.';

      if (
        isChrome ||
        (!isChrome &&
          confirm('Drucken funktioniert am besten im Chrome. Wollen Sie trotzdem drucken?'))
      ) {
        window.print();
      }
    },
    updateFilter: function (choice: string) {
      this.budgetSummaryParameters.$department = choice;
    }
  }
});
