/* eslint-disable max-classes-per-file */

type SamplePictureState = 'new' | 'existing' | 'none';

export interface ISamplePictureState {
  kind: SamplePictureState;
  id: number | undefined;
}

export class ExistingSamplePicture implements ISamplePictureState {
  public kind: SamplePictureState = 'existing';
  public id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class NoSamplePicture implements ISamplePictureState {
  public kind: SamplePictureState = 'none';
  public id: number | undefined = undefined;
}
