
















































import mixins from 'vue-typed-mixins';
import { PropType } from 'vue';
import draggable from 'vuedraggable';
import { Guid } from 'guid-typescript';
import IAddableReportCompilationItem from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/IAddableReportCompilationItem';
import IReportCompilationItem from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/IReportCompilationItem';
import IReportCompilation from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/IReportCompilation';

export default mixins().extend({
  props: {
    compilation: Object as PropType<IReportCompilation>
  },
  components: {
    draggable
  },
  data() {
    return {};
  },
  computed: {
    arrangedCompilationItems(): IReportCompilationItem[] {
      return this.compilation.compilationItems.sort((a, b) => a.sortOrder - b.sortOrder);
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkMove(event: any) {
      const isReorderingInCompilation = event.from == event.to;
      return isReorderingInCompilation;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleDraggableAddEvent(event: any) {
      const selectedAttachment = event.item['_underlying_vm_'] as IAddableReportCompilationItem;
      const positionToInsertInto = event.newIndex;
      this.addCompilationItem(selectedAttachment, positionToInsertInto);
    },
    addCompilationItem(
      selectedAttachment: IAddableReportCompilationItem,
      positionToInsertInto: number
    ) {
      this.$emit(
        'compilation-item-added',
        this.compilation.tempId,
        selectedAttachment,
        positionToInsertInto
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleDraggableReorderEvent(event: any) {
      const movedCompilationItem = event.item['_underlying_vm_'] as IReportCompilationItem;
      const positionToMoveTo = event.newIndex;
      this.reorderCompilationItem(movedCompilationItem.tempId, positionToMoveTo);
    },
    reorderCompilationItem(movedCompilationItem: Guid, positionToMoveTo: number) {
      this.$emit(
        'compilation-item-reordered',
        this.compilation.tempId,
        movedCompilationItem,
        positionToMoveTo
      );
    },
    removeCompilationItem(removedCompilationItem: Guid) {
      this.$emit('compilation-item-removed', this.compilation.tempId, removedCompilationItem);
    }
  }
});
