import mixins from 'vue-typed-mixins';
import FileDownloadMixin from './FileDownloadMixin';
import IGenerateReport from '@/models/IGenerateReport';
import SampleSelection from '@/services/SampleSelection';

export default mixins(FileDownloadMixin).extend({
  methods: {
    async generateDocument(
      sampleSelection: SampleSelection,
      orderId: string,
      reportId: number,
      documentPath: string
    ): Promise<'None' | 'Generating' | 'Success' | 'Error'> {
      let result: 'None' | 'Generating' | 'Success' | 'Error' = 'None';
      try {
        const sampleIds: number[] =
          sampleSelection.result.isOk() === true ? sampleSelection.result.getData() : [0];
        await this.$labordatApi
          .post(
            `/api/orders/${orderId}/reports/${documentPath}`,
            <IGenerateReport>{
              sampleIds: sampleIds,
              reportId: reportId
            },
            { responseType: 'arraybuffer', timeout: 3600000 }
          )
          .then((response) => {
            if (response != null) {
              this.openFile(response, 'Vorschau.pdf');
              result = 'Success';
            } else {
              throw new Error('No document generated.');
            }
          });
      } catch (error) {
        result = 'Error';
      }

      return result;
    },
    async getGeneratedDocument(orderId: string, reportId: number) {
      let result: 'None' | 'Success' | 'Error' = 'None';
      try {
        await this.$labordatApi
          .get(`/api/file/downloadreport?orderId=${orderId}&reportId=${reportId}`, {
            responseType: 'arraybuffer'
          })
          .then((response) => {
            if (response != null) {
              this.openFile(response, 'Prüfbericht.pdf');
              result = 'Success';
            } else {
              throw new Error('No document found.');
            }
          });
      } catch (error) {
        result = 'Error';
      }

      return result;
    }
  }
});
