











































































































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import _ from 'lodash';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import ReportTemplateDetailsForm from '@/views/Templates/ReportTemplate/ReportTemplateDetailsForm.vue';
import {
  NoReportTemplate,
  ExistingReportTemplate,
  NewReportTemplate
} from '@/models/IReportTemplateState';
import IReportTemplate from '@/models/IReportTemplate';
import ReportTemplate from '@/models/ReportTemplate';
import ReportType from '@/models/ReportType';
import IDepartment from '@/models/IDepartment';

export default mixins(SettingsMixin, SharedDataMixin, FileDownloadMixin).extend({
  components: { ReportTemplateDetailsForm },
  name: 'report-template',
  props: {
    itemId: String
  },
  data: function () {
    return {
      reportTemplatesRd: RemoteData.notAsked<Array<IReportTemplate>, Error>(),
      reportTemplateRd: UpdatableRemoteData.notAsked<IReportTemplate, Error>(),
      reportTemplateFilesRd: RemoteData.notAsked<Array<ReportTemplate>, Error>(),
      reportTemplateState: new NoReportTemplate()
    };
  },
  created: async function () {
    await this.loadReportTemplates();
    if (this.id) {
      this.loadReportTemplate(this.id);
      this.reportTemplateState = new ExistingReportTemplate(this.id);
    }
  },
  computed: {
    id: function (): number {
      return +this.itemId;
    },
    fileDe: function (): ReportTemplate {
      return this.reportTemplateFilesRd.map((f) => f[0]).withDefault(new ReportTemplate(0));
    },
    fileEn: function (): ReportTemplate {
      return this.reportTemplateFilesRd.map((f) => f[1]).withDefault(new ReportTemplate(0));
    },
    fileFr: function (): ReportTemplate {
      return this.reportTemplateFilesRd.map((f) => f[2]).withDefault(new ReportTemplate(0));
    },
    fileIt: function (): ReportTemplate {
      return this.reportTemplateFilesRd.map((f) => f[3]).withDefault(new ReportTemplate(0));
    }
  },
  watch: {
    reportTemplateState: async function (value) {
      switch (value.kind) {
        case 'none':
          this.reportTemplateRd = UpdatableRemoteData.notAsked();
          this.$router.push(`/templates/reporttemplate`);
          break;
        case 'new':
          this.reportTemplateRd = UpdatableRemoteData.notCreated({
            departmentCode: this.$department,
            reportType: ReportType.Standard
          } as IReportTemplate);
          this.reportTemplateFilesRd = this.reportTemplateFilesRd = await this.$labordatApi.getRd(
            `/api/file/getreporttemplatefiles?templateId=0`
          );
          this.$router.push(`/templates/reporttemplate`);
          break;
        case 'existing':
          if (value.id) {
            await this.loadReportTemplate(value.id);
            this.$router.push(`/templates/reporttemplate/${value.id}`);
          }
          break;
      }
    }
  },
  methods: {
    reportTemplateDepartments: function (): IDepartment[] {
      return this.$departments
        .filter(
          (d) =>
            this.reportTemplatesRd
              .withDefault([])
              .map((rt) => rt.departmentCode)
              .indexOf(d.code) >= 0
        )
        .sort();
    },
    reportTemplatesByDepartment: function (department: string) {
      return _.sortBy(
        this.reportTemplatesRd.getData().filter((rt) => rt.departmentCode === department),
        (rt) => rt.name
      );
    },
    loadReportTemplates: async function () {
      try {
        this.reportTemplatesRd = await this.$labordatApi.getPagedRd(`/api/reporttemplate`);
      } catch (error) {
        this.reportTemplatesRd = RemoteData.failure(error as Error);
      }
    },
    loadReportTemplate: async function (id: number) {
      try {
        this.reportTemplateRd = UpdatableRemoteData.loading();
        this.reportTemplateRd = await this.$labordatApi.getUrd(`/api/reporttemplate/${id}`);
        this.reportTemplateFilesRd = await this.$labordatApi.getRd(
          `/api/file/getreporttemplatefiles?templateId=${id}`
        );
      } catch (error) {
        this.reportTemplateRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    selectReportTemplate: function (event: Event) {
      const itemId = +(event.target as HTMLInputElement).value;
      if (itemId) {
        this.reportTemplateState = new ExistingReportTemplate(itemId);
      }
    },
    newReportTemplate: async function () {
      this.reportTemplateState = new NewReportTemplate();
    },
    deleteReportTemplate: async function () {
      if (this.reportTemplateState.kind !== 'existing') {
        return;
      }

      if (confirm('Wollen Sie die Berichtsvorlage wirklich löschen?') === false) {
        return;
      }

      try {
        this.reportTemplateRd = UpdatableRemoteData.updating(this.reportTemplateRd.getData());
        const reportTemplate = this.reportTemplateRd.getData();

        await this.$labordatApi.delete(
          `/api/file/delete?templateType=Report&templateId=${reportTemplate.id}`
        );
        this.reportTemplateRd = await this.$labordatApi.getUrd(
          `/api/reporttemplate/${reportTemplate.id}`
        );
        await this.$labordatApi.delete(
          `/api/reporttemplate/${this.reportTemplateRd.getData().id}`,
          {
            data: this.reportTemplateRd.getData()
          }
        );

        this.reportTemplateState = new NoReportTemplate();
        await this.loadReportTemplates();
      } catch (error) {
        this.reportTemplateRd = UpdatableRemoteData.updateFailure(
          this.reportTemplateRd.getData(),
          error as Error
        );
      }
    },
    resetReportTemplate: function () {
      this.reportTemplateState = new NoReportTemplate();
    },
    createReportTemplate: async function () {
      const reportTemplate = this.reportTemplateRd.getData();
      this.reportTemplateRd = UpdatableRemoteData.updating(reportTemplate);
      try {
        const result: IReportTemplate = await this.$labordatApi
          .post(`/api/reporttemplate`, reportTemplate)
          .then((response) => response.data as IReportTemplate);

        this.reportTemplateState = new ExistingReportTemplate(result.id);
      } catch (error) {
        this.reportTemplateRd = UpdatableRemoteData.updateFailure<IReportTemplate, Error>(
          reportTemplate,
          error as Error
        );
      }
      await this.loadReportTemplates();
    },
    updateReportTemplate: async function () {
      const reportTemplate = this.reportTemplateRd.getData();
      this.reportTemplateRd = UpdatableRemoteData.updating(reportTemplate);
      this.reportTemplateRd = await this.$labordatApi
        .put(`/api/reporttemplate/${reportTemplate.id}`, reportTemplate)
        .then((response) => response.data as IReportTemplate)
        .then((data) => {
          return UpdatableRemoteData.success<IReportTemplate, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IReportTemplate, Error>(reportTemplate, error as Error)
        );
      await this.loadReportTemplates();
    },
    upload: async function (id: string, file: File) {
      const reportTemplate = this.reportTemplateRd.getData();
      this.reportTemplateRd = UpdatableRemoteData.updating(reportTemplate);
      try {
        const formData = new FormData();
        formData.append('templateType', 'Report');
        formData.append('templateId', reportTemplate.id.toString());
        formData.append('language', id);
        formData.append('file', file);
        this.reportTemplateRd = await this.$labordatApi
          .post(`/api/file/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => response.data as IReportTemplate)
          .then((data) => {
            return UpdatableRemoteData.success<IReportTemplate, Error>(data);
          });
      } catch (error) {
        this.reportTemplateRd = UpdatableRemoteData.updateFailure<IReportTemplate, Error>(
          reportTemplate,
          error as Error
        );
      }

      this.reportTemplateFilesRd = await this.$labordatApi.getRd(
        `/api/file/getreporttemplatefiles?templateId=${reportTemplate.id}`
      );
    },
    download: async function (id: string) {
      const reportTemplate = this.reportTemplateRd.getData();
      try {
        await this.$labordatApi
          .get(
            `/api/file/download?templateType=Report&templateId=${reportTemplate.id}&language=${id}`,
            {
              responseType: 'arraybuffer'
            }
          )
          .then((response) => {
            this.downloadFile(response, 'Berichtsvorlage.docx');
          });
      } catch (error) {
        this.reportTemplateRd = UpdatableRemoteData.updateFailure<IReportTemplate, Error>(
          reportTemplate,
          error as Error
        );
      }
    }
  }
});
