import { Guid } from 'guid-typescript';
import IAddableCustomReportAttachment from './IAddableCustomReportAttachment';
import IAddablePredefinedReportAttachment from './IAddablePredefinedReportAttachment';
import IReportCompilationItem from './IReportCompilationItem';
import IActualReport from './ReportItemTypes/IActualReport';

export default class ReportCompilationItemFactory {
  public static createPredefinedReportAttachmentReportCompilationItem(
    definition: IAddablePredefinedReportAttachment,
    sortOrder = 0
  ): IReportCompilationItem {
    return {
      tempId: Guid.create(),
      type: 'PredefinedAttachment',
      data: {
        id: definition.data.id
      },
      description: definition.title,
      sortOrder: sortOrder
    };
  }

  public static createCustomReportAttachmentReportCompilationItem(
    definition: IAddableCustomReportAttachment,
    sortOrder = 0
  ): IReportCompilationItem {
    return {
      tempId: Guid.create(),
      type: 'CustomAttachment',
      data: definition.data,
      description: definition.title,
      sortOrder: sortOrder
    };
  }

  public static createActualReportReportCompilationItem(
    data: IActualReport,
    sortOrder = 0
  ): IReportCompilationItem {
    return {
      tempId: Guid.create(),
      type: 'ActualReport',
      data: data,
      description: 'Prüfbericht',
      sortOrder: sortOrder
    };
  }
}
