import { render, staticRenderFns } from "./OrderReportDetails.vue?vue&type=template&id=3ca3b738&scoped=true&"
import script from "./OrderReportDetails.vue?vue&type=script&lang=ts&"
export * from "./OrderReportDetails.vue?vue&type=script&lang=ts&"
import style0 from "./OrderReportDetails.vue?vue&type=style&index=0&id=3ca3b738&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_jmni46ydenuhv6sytlbyqtthlq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ca3b738",
  null
  
)

export default component.exports