/* eslint-disable max-classes-per-file */

type AnalysisGroupState = 'new' | 'existing' | 'none';

export interface IAnalysisGroupState {
  kind: AnalysisGroupState;
  code: string | undefined;
}

export class NewAnalysisGroup implements IAnalysisGroupState {
  public kind: AnalysisGroupState = 'new';
  public code = '';
}

export class ExistingAnalysisGroup implements IAnalysisGroupState {
  public kind: AnalysisGroupState = 'existing';
  public code: string;

  constructor(code: string) {
    this.code = code;
  }
}

export class NoAnalysisGroup implements IAnalysisGroupState {
  public kind: AnalysisGroupState = 'none';
  public code: string | undefined = undefined;
}
