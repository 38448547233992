import Vue from 'vue';
import { AxiosResponse } from 'axios';

export default Vue.extend({
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    downloadFile(response: AxiosResponse<any>, defaultFilename: string) {
      if (response.data.byteLength > 0) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const regex = /filename\*.+'(.+)/g;
        const match = regex.exec(decodeURI(response.headers['content-disposition']));
        let filename = defaultFilename;
        if (match && match.length > 1) {
          filename = match[1];
        }

        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // @ts-ignore
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
          }, 100);
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openFile(response: AxiosResponse<any>, defaultFilename: string) {
      if (response.data.byteLength > 0) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const regex = /filename\*.+'(.+)/g;
        const match = regex.exec(decodeURI(response.headers['content-disposition']));
        let filename = defaultFilename;
        if (match && match.length > 1) {
          filename = match[1];
        }

        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // @ts-ignore
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const url = window.location.origin + '/api/downloadfile/' + filename;
          window.open(url);
        }
      }
    }
  }
});
