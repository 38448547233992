import ISample from './ISample';
import SampleStatus from './SampleStatus';

export default class Sample implements ISample {
  public static FromInterface(sample: ISample): Sample {
    return Object.assign(new Sample(sample.orderId), sample);
  }

  public id: number | undefined;
  public itemNumber: number | undefined;
  public orderId: string;
  public receiptDate: Date;
  public analysisDate: Date;
  public retentionDate: Date;
  public materialCode: string | undefined;
  public samplingPointNumber: number | undefined;
  public name: string | undefined;
  public comment: string | undefined;
  public commentDelta: string | undefined;
  public information: string | undefined;
  public informationDelta: string | undefined;
  public basisOfValidation: string | undefined;
  public basisOfValidationDelta: string | undefined;
  public isValidated: boolean | undefined;
  public status: SampleStatus;
  public pageBreakBefore: boolean;
  public priceInChf: number | undefined;

  constructor(orderId: string) {
    this.orderId = orderId;
    this.receiptDate = new Date();

    const analysisDate = new Date();
    analysisDate.setDate(analysisDate.getDate());
    this.analysisDate = analysisDate;

    const retentionDate = new Date();
    retentionDate.setMonth(retentionDate.getMonth() + 3);
    this.retentionDate = retentionDate;

    this.isValidated = true;
    this.status = SampleStatus.Unlocked;
    this.pageBreakBefore = false;
  }
}
