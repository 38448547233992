import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
  data: function () {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      original: undefined as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dirtyObject: undefined as any,
      updateDebouncedUpdateFunction: undefined as (() => void) | undefined,
      updateDebounced: _.debounce(
        function () {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const vm = this;
          if (vm.isDirty() === true) {
            vm.updateDebouncedUpdateFunction();
          }
        }.bind(this),
        1000
      )
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateDebouncedAttachObject: function (objectRef: any) {
      this.dirtyObject = objectRef;
      this.original = _.cloneDeep(objectRef);
    },
    isDirty: function () {
      return !_.isEqual(this.dirtyObject, this.original);
    }
  }
});
