






















import { Guid } from 'guid-typescript';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  name: 'file-drop-zone',
  props: {
    allowedFileEndings: {
      type: Array as PropType<Array<string>>,
      default() {
        return ['*'];
      },
      validator(allowedFileEndings) {
        return allowedFileEndings.every((x) => x.startsWith('.') || x === '*');
      }
    },
    disabled: Boolean
  },
  data() {
    return {
      componentId: 'file-drop-zone-' + Guid.create().toString(),
      isDragging: false,
      files: []
    };
  },
  methods: {
    isFileEndingValid(file: File) {
      if (this.allowedFileEndings.find((allowedFileEnding) => allowedFileEnding === '*')) {
        return true;
      }
      return this.allowedFileEndings.some((allowedFileEnding) =>
        file.name.toLocaleLowerCase().endsWith(allowedFileEnding.toLocaleLowerCase())
      );
    },
    handleFileUpload() {
      const fileInputElement = this.$refs[this.componentId] as HTMLInputElement;
      if (!fileInputElement.files) {
        return;
      }
      const validFilesToUpload = Array.from(fileInputElement.files).filter((x) =>
        this.isFileEndingValid(x)
      );
      this.$emit('files-dropped', validFilesToUpload);
    },
    dragover(e: DragEvent) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e: DragEvent) {
      e.preventDefault();
      this.isDragging = false;
      if (!e.dataTransfer) {
        return;
      }
      const validFilesToUpload = Array.from(e.dataTransfer.files).filter((x) =>
        this.isFileEndingValid(x)
      );
      this.$emit('files-dropped', validFilesToUpload);
    }
  }
});
