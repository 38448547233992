import { render, staticRenderFns } from "./MasterDataGeneric.vue?vue&type=template&id=3d657fa6&scoped=true&"
import script from "./MasterDataGeneric.vue?vue&type=script&lang=ts&"
export * from "./MasterDataGeneric.vue?vue&type=script&lang=ts&"
import style0 from "./MasterDataGeneric.vue?vue&type=style&index=0&id=3d657fa6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_jmni46ydenuhv6sytlbyqtthlq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d657fa6",
  null
  
)

export default component.exports