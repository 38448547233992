import OrderStatus from '@/models/OrderStatus';
import OrderLockStatus from '@/models/OrderLockStatus';
import ReportStatus from '@/models/ReportStatus';
import Vue from 'vue';
import ITranslatable from '@/models/ITranslateable';
import Language from '@/models/Language';
import ValidationStatusType from '@/models/ValidationStatusType';
import ValidationStatusOverrideType from '@/models/ValidationStatusOverrideType';
import ITranslatableDelta from '@/models/ITranslateableDelta';
import { FilterExpressionType } from 'rey-vue-smarttable';

export default Vue.extend({
  methods: {
    orderStatusToViewString: (orderStatus: OrderStatus): string => {
      switch (orderStatus) {
        case OrderStatus.New:
          return 'Erfasst';
        case OrderStatus.AnalysisStarted:
          return 'Analyse gestartet';
        case OrderStatus.AnalysisCompleted:
          return 'Analyse abgeschlossen';
        case OrderStatus.Reported:
          return 'Rapportiert';
        case OrderStatus.Invoiced:
          return 'Verrechnet';
        case OrderStatus.Completed:
          return 'Abgeschlossen';
        default:
          return 'Unbekannt';
      }
    },
    orderStatusViewStringToOrderStatus: (
      orderStatus: FilterExpressionType
    ): FilterExpressionType[] => {
      const isEqualOrContains = (reference: string, searchValue: string): boolean =>
        reference.toUpperCase().includes(searchValue.toUpperCase());

      const orderStatusString = orderStatus as string;
      const status: OrderStatus[] = [];
      if (isEqualOrContains('Erfasst', orderStatusString)) {
        status.push(OrderStatus.New);
      }
      if (isEqualOrContains('Analyse gestartet', orderStatusString)) {
        status.push(OrderStatus.AnalysisStarted);
      }
      if (isEqualOrContains('Analyse abgeschlossen', orderStatusString)) {
        status.push(OrderStatus.AnalysisCompleted);
      }
      if (isEqualOrContains('Rapportiert', orderStatusString)) {
        status.push(OrderStatus.Reported);
      }
      if (isEqualOrContains('Verrechnet', orderStatusString)) {
        status.push(OrderStatus.Invoiced);
      }
      if (isEqualOrContains('Abgeschlossen', orderStatusString)) {
        status.push(OrderStatus.Completed);
      }
      return status;
    },
    orderLockStatusToViewString: (lockStatus: OrderLockStatus): string => {
      switch (lockStatus) {
        case OrderLockStatus.Unlocked:
          return 'Offen';
        case OrderLockStatus.Locked:
          return 'Gesperrt';
        case OrderLockStatus.LockedByExternalApp:
          return 'Gesperrt in externer Applikation';
        default:
          return 'Unbekannt';
      }
    },
    orderLockStatusViewStringToOrderLockStatus: (
      lockStatus: FilterExpressionType
    ): FilterExpressionType[] => {
      const isEqualOrContains = (reference: string, searchValue: string): boolean =>
        reference.toUpperCase().includes(searchValue.toUpperCase());

      const lockStatusString = lockStatus as string;
      const status: OrderLockStatus[] = [];
      if (isEqualOrContains('Offen', lockStatusString)) {
        status.push(OrderLockStatus.Unlocked);
      }
      if (isEqualOrContains('Gesperrt', lockStatusString)) {
        status.push(OrderLockStatus.Locked);
      }
      if (isEqualOrContains('Gesperrt in externer Applikation', lockStatusString)) {
        status.push(OrderLockStatus.LockedByExternalApp);
      }
      return status;
    },
    reportStatusToViewString: (reportStatus: ReportStatus): string => {
      switch (reportStatus) {
        case ReportStatus.New:
          return 'Erfasst';
        case ReportStatus.FirstSignature:
          return 'Erstunterschrift';
        case ReportStatus.Signed:
          return 'Freigegeben';
        case ReportStatus.Generated:
          return 'Generiert';
        case ReportStatus.Outdated:
          return 'Veraltet';
        case ReportStatus.Invalid:
          return 'Ungültig';
        case ReportStatus.Irrelevant:
          return 'Nicht relevant';
        default:
          return 'Unbekannt';
      }
    },
    reportStatusViewStringToReportStatus: (
      reportStatus: FilterExpressionType
    ): FilterExpressionType[] => {
      const isEqualOrContains = (reference: string, searchValue: string): boolean =>
        reference.toUpperCase().includes(searchValue.toUpperCase());

      const reportStatusString = reportStatus as string;
      const status: ReportStatus[] = [];
      if (isEqualOrContains('Erfasst', reportStatusString)) {
        status.push(ReportStatus.New);
      }
      if (isEqualOrContains('Erstunterschrift', reportStatusString)) {
        status.push(ReportStatus.FirstSignature);
      }
      if (isEqualOrContains('Freigegeben', reportStatusString)) {
        status.push(ReportStatus.Signed);
      }
      if (isEqualOrContains('Generiert', reportStatusString)) {
        status.push(ReportStatus.Generated);
      }
      if (isEqualOrContains('Veraltet', reportStatusString)) {
        status.push(ReportStatus.Outdated);
      }
      if (isEqualOrContains('Ungültig', reportStatusString)) {
        status.push(ReportStatus.Invalid);
      }
      if (isEqualOrContains('Nicht relevant', reportStatusString)) {
        status.push(ReportStatus.Irrelevant);
      }
      return status;
    },
    validationStatusToViewString: (validationStatus: ValidationStatusType): string => {
      switch (validationStatus) {
        case ValidationStatusType.Unvalidated:
          return 'Nicht validiert';
        case ValidationStatusType.Valid:
          return 'Gültig';
        case ValidationStatusType.Invalid:
          return 'Ungültig';
        case ValidationStatusType.NotValidatable:
          return 'Nicht bewertbar';
        case ValidationStatusType.NoValue:
          return 'Kein Resultat';
        case ValidationStatusType.Erroneous:
          return 'Fehleingabe';
        case ValidationStatusType.NoGuidanceValue:
          return 'Kein Richtwert';
        default:
          return 'Unbekannt';
      }
    },
    validationStatusOverrideToViewString: (
      validationStatusOverride: ValidationStatusOverrideType
    ): string => {
      switch (validationStatusOverride) {
        case ValidationStatusOverrideType.Automatic:
          return 'Automatisch';
        case ValidationStatusOverrideType.Valid:
          return 'Gültig';
        case ValidationStatusOverrideType.Invalid:
          return 'Ungültig';
        case ValidationStatusOverrideType.NotValidatable:
          return 'Nicht bewertbar';
        default:
          return 'Unbekannt';
      }
    },
    getTranslatedValue(translatable: ITranslatable, language: Language) {
      switch (language) {
        case Language.DE:
          return translatable.nameDe;
        case Language.EN:
          return translatable.nameEn;
        case Language.FR:
          return translatable.nameFr;
        case Language.IT:
          return translatable.nameIt;
        default:
          return translatable.nameDe;
      }
    },
    getTranslatedDeltaValue(translatableDelta: ITranslatableDelta, language: Language) {
      switch (language) {
        case Language.DE:
          return translatableDelta.nameDeltaDe;
        case Language.EN:
          return translatableDelta.nameDeltaEn;
        case Language.FR:
          return translatableDelta.nameDeltaFr;
        case Language.IT:
          return translatableDelta.nameDeltaIt;
        default:
          return translatableDelta.nameDeltaDe;
      }
    }
  }
});
