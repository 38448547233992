export class MsalConfig {
  public authority: string;
  public clientId: string;
  public redirectUri?: string;
  public requireAuthOnInitialize: boolean;

  constructor(authority: string, clientId: string, redirectUri?: string) {
    this.authority = authority;
    this.clientId = clientId;
    this.redirectUri = redirectUri;
    this.requireAuthOnInitialize = true;
  }
}
