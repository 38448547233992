














































import $ from 'jquery';
import axios from 'axios';
import mixins from 'vue-typed-mixins';
import VModelMixin from '@/mixins/VModelMixin';
import IAnalysisParameterUsages from '@/models/IAnalysisParameterUsages';
import IAnalysisParameterUsageByAnalysisGroup from '@/models/IAnalysisParameterUsageByAnalysisGroup';

export default mixins(VModelMixin).extend({
  data() {
    return {
      id: 'analysisParameterUsageDialog',
      isSearchingForData: false,
      hasError: false,
      usages: [] as IAnalysisParameterUsageByAnalysisGroup[],
      cancelToken: axios.CancelToken.source()
    };
  },
  props: {
    isVisible: Boolean,
    analysisParameterCode: String
  },
  watch: {
    isVisible: function (newValue) {
      this.resetDialog();
      if (newValue === true) {
        this.isSearchingForData = true;
        this.cancelToken = axios.CancelToken.source();
        this.$labordatApi
          .get<IAnalysisParameterUsages>(
            `/api/analysisparameterdefinition/${this.analysisParameterCode}/usage`,
            {
              cancelToken: this.cancelToken.token
            }
          )
          .then((response) => {
            this.isSearchingForData = false;
            if (!!response && response.status == 200) {
              this.usages = response.data.analysisGroupsAnalysisParameterIsUsedBy;
              this.hasError = false;
            } else {
              this.usages = [];
              this.hasError = true;
            }
          });
        // @ts-ignore
        $('#' + this.id).modal('show');
      } else if (newValue === false) {
        // @ts-ignore
        $('#' + this.id).modal('hide');
      }
    }
  },
  mounted() {
    $('#' + this.id).on('hidden.bs.modal', () => this.onClose());
  },
  methods: {
    resetDialog: function () {
      this.usages = [];
      this.hasError = false;
      this.isSearchingForData = false;
    },
    onClose: function () {
      this.cancelToken.cancel();
      this.$emit('closeDialog');
    }
  }
});
