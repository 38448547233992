






























import MailEditor, { FormData as MailFormData } from './MailEditor.vue';
import IAutomaticReportSendingResendPreviewDto from '@/models/AutomaticReportSending/AutomaticReportSendingProtocol/Api/IAutomaticReportSendingResendPreviewDto';
import IAutomaticReportSendingResendRequestDto from '@/models/AutomaticReportSending/AutomaticReportSendingProtocol/Api/IAutomaticReportSendingResendRequestDto';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import mixins from 'vue-typed-mixins';
import { Guid } from 'guid-typescript';
import { PropType } from 'vue';

export default mixins(FileDownloadMixin).extend({
  components: { MailEditor },
  props: {
    selectedProtocolItem: {
      type: Object as PropType<{
        id: number;
        preparedMail: { replyToMail: string };
      }>
    },
    isComponentReady: Boolean,
    isResendRunning: Boolean
  },
  created() {
    this.$emit('update:isComponentReady', false);
  },
  async mounted() {
    let result = await this.$labordatApi.get<IAutomaticReportSendingResendPreviewDto>(
      `api/AutomaticReportSending/resend/preview?protocolItemToBaseResendOn=${this.selectedProtocolItem.id}`
    );
    if (result?.status == 200) {
      this.formData.mailSubject = result.data.mailSubject;
      this.formData.mailContent = result.data.mailBody;
      this.previewableAttachments = result.data.attachments;
    }
    this.$emit('update:isComponentReady', true);
  },
  data: function () {
    return {
      validationErrors: [] as Array<{ tempId: Guid; message: string }>,
      formData: {
        senderMailAddress: this.selectedProtocolItem.preparedMail.replyToMail,
        toReceiverMailAddresses: [],
        ccReceiverMailAddresses: [],
        mailSubject: '',
        mailContent: ''
      } as MailFormData,
      previewableAttachments: [] as Array<{
        id: number;
        fileName: string;
      }>
    };
  },
  methods: {
    async downloadMailAttachment(id: number) {
      await this.$labordatApi
        .get(`/api/AutomaticReportSendingPreparedMailAttachments/${id}`, {
          responseType: 'arraybuffer'
        })
        .then((response) => {
          this.downloadFile(response, 'Mailanhang');
        });
    },
    async triggerResend() {
      this.validationErrors = [];
      if (!this.validateForm()) {
        return;
      }

      this.$emit('update:isResendRunning', true);
      let requestDto: IAutomaticReportSendingResendRequestDto = {
        protocolItemToResend: this.selectedProtocolItem.id,
        mailSubject: this.formData.mailSubject,
        mailBody: this.formData.mailContent,
        toMailAddresses: this.formData.toReceiverMailAddresses.map((x) => x.name),
        ccMailAddresses: this.formData.ccReceiverMailAddresses.map((x) => x.name)
      };
      let result = await this.$labordatApi.post(`api/AutomaticReportSending/resend`, requestDto);
      if (result?.status == 200) {
        this.$emit('resend-was-successful');
      } else {
        this.$emit('resend-was-erroneous');
      }
      this.$emit('update:isResendRunning', false);
    },
    validateForm(): boolean {
      const mailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z0-9]+$/;

      if (!mailRegex.test(this.formData.senderMailAddress)) {
        this.validationErrors.push({
          tempId: Guid.create(),
          message: 'Die Sender-Mailadresse ist keine gültige E-Mailadresse'
        });
      }

      if (this.formData.toReceiverMailAddresses.length < 1) {
        this.validationErrors.push({
          tempId: Guid.create(),
          message: `Es muss mindestens eine An-Mailadresse angegeben werden`
        });
      } else {
        this.formData.toReceiverMailAddresses
          .map((x) => x.name)
          .forEach((mailAddress) => {
            if (!mailRegex.test(mailAddress)) {
              this.validationErrors.push({
                tempId: Guid.create(),
                message: `Die An-Mailadresse "${mailAddress}" ist keine gültige E-Mailadresse`
              });
            }
          });
      }

      this.formData.ccReceiverMailAddresses
        .map((x) => x.name)
        .forEach((mailAddress) => {
          if (!mailRegex.test(mailAddress)) {
            this.validationErrors.push({
              tempId: Guid.create(),
              message: `Die Cc-Mailadresse "${mailAddress}" ist keine gültige E-Mailadresse`
            });
          }
        });

      return this.validationErrors.length === 0;
    }
  }
});
