



































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import { UpdatableRemoteData } from 'rey-frontend-fp';
import ILabelTemplate from '@/models/ILabelTemplate'; //NOSONAR
import LabelTemplateDetailsForm from '@/views/Templates/LabelTemplate/LabelTemplateDetailsForm.vue';

export default mixins(SettingsMixin, FileDownloadMixin).extend({
  components: { LabelTemplateDetailsForm },
  name: 'label-template',
  data: function () {
    return {
      labelTemplateRd: UpdatableRemoteData.notAsked<ILabelTemplate, Error>(),
      standardLabelTemplateRd: UpdatableRemoteData.notAsked<ILabelTemplate, Error>(),
      anonymizedLabelTemplateRd: UpdatableRemoteData.notAsked<ILabelTemplate, Error>()
    };
  },
  created: async function () {
    await this.loadLabelTemplates();
  },
  methods: {
    loadLabelTemplates: async function () {
      try {
        this.labelTemplateRd = UpdatableRemoteData.loading();
        this.standardLabelTemplateRd = await this.$labordatApi.getUrd(`/api/labeltemplate/1`);
        this.anonymizedLabelTemplateRd = await this.$labordatApi.getUrd(`/api/labeltemplate/2`);
      } catch (error) {
        this.labelTemplateRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    upload: async function (id: string, file: File) {
      let templateRd: UpdatableRemoteData<ILabelTemplate, Error> | null;
      if (id === 'standard') {
        templateRd = this.standardLabelTemplateRd;
      } else {
        templateRd = this.anonymizedLabelTemplateRd;
      }

      const template = templateRd.getData();
      this.labelTemplateRd = UpdatableRemoteData.updating(template);

      try {
        const formData = new FormData();
        formData.append('templateId', template.id.toString());
        formData.append('templateType', 'Label');
        formData.append('file', file);
        this.labelTemplateRd = await this.$labordatApi
          .post(`/api/file/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => response.data as ILabelTemplate)
          .then((data) => {
            return UpdatableRemoteData.success<ILabelTemplate, Error>(data);
          });
      } catch (error) {
        this.labelTemplateRd = UpdatableRemoteData.updateFailure<ILabelTemplate, Error>(
          template,
          error as Error
        );
      }

      if (id === 'standard') {
        this.standardLabelTemplateRd = this.labelTemplateRd;
      } else {
        this.anonymizedLabelTemplateRd = this.labelTemplateRd;
      }
    },
    download: async function (id: string) {
      let templateRd: UpdatableRemoteData<ILabelTemplate, Error> | null;
      if (id === 'standard') {
        templateRd = this.standardLabelTemplateRd;
      } else {
        templateRd = this.anonymizedLabelTemplateRd;
      }

      const template = templateRd.getData();

      try {
        await this.$labordatApi
          .get(`/api/file/download?templateType=Label&templateId=${template.id}`, {
            responseType: 'arraybuffer'
          })
          .then((response) => {
            this.downloadFile(response, 'Etikettenvorlage.docx');
          });
      } catch (error) {
        this.labelTemplateRd = UpdatableRemoteData.updateFailure<ILabelTemplate, Error>(
          template,
          error as Error
        );
      }
    }
  }
});
