enum LabelType {
  Standard = 0,
  Anonymized = 1
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace LabelType {
  export function parse(type: string): LabelType {
    // @ts-ignore
    return LabelType[type];
  }

  export function toString(type: LabelType): string {
    return LabelType[type];
  }
}

export default LabelType;
