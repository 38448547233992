/* eslint-disable max-classes-per-file */

type SampleBasisOfValidationState = 'new' | 'existing' | 'none';

export interface ISampleBasisOfValidationState {
  kind: SampleBasisOfValidationState;
  id: number | undefined;
}

export class NewSampleBasisOfValidation implements ISampleBasisOfValidationState {
  public kind: SampleBasisOfValidationState = 'new';
  public id = 0;
}

export class ExistingSampleBasisOfValidation implements ISampleBasisOfValidationState {
  public kind: SampleBasisOfValidationState = 'existing';
  public id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class NoSampleBasisOfValidation implements ISampleBasisOfValidationState {
  public kind: SampleBasisOfValidationState = 'none';
  public id: number | undefined = undefined;
}
