









































































































































































































































import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import OrderMixin from '@/mixins/OrderMixin';
import SampleRangeMixin from '@/mixins/SampleRangeMixin';
import SettingsMixin from '@/mixins/SettingsMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import UserMixin from '@/mixins/UserMixin';
import { RemoteData } from 'rey-frontend-fp';
import IWorksheetTemplate from '@/models/IWorksheetTemplate';
import ReportType from '@/models/ReportType';
import LabelType from '@/models/LabelType';
import SampleRange from '@/components/SampleRange.vue';
import IGenerateLabels from '@/models/IGenerateLabels';
import IPrintLabels from '@/models/IPrintLabels';
import IGenerateWorksheets from '@/models/IGenerateWorksheets';
import IPrintWorksheets from '@/models/IPrintWorksheets';
import IUserDefaultTemplate from '@/models/IUserDefaultTemplate';
import IPrinter from '@/models/IPrinter';
import IUserDefaultPrinter from '@/models/IUserDefaultPrinter';
import DocumentType from '@/models/DocumentType';

const AllItemsValue = 'Alle';

export default mixins(
  OrderMixin,
  SampleRangeMixin,
  SettingsMixin,
  FileDownloadMixin,
  SharedDataMixin,
  UserMixin
).extend({
  components: { SampleRange },
  data: function () {
    return {
      worksheetTemplatesRd: RemoteData.notAsked<Array<IWorksheetTemplate>, Error>(),
      orderDepartmentsRd: RemoteData.notAsked<Array<string>, Error>(),
      printersRd: RemoteData.notAsked<Array<IPrinter>, Error>(),
      userDefaultTemplates: [] as Array<IUserDefaultTemplate>,
      userDefaultPrinters: [] as Array<IUserDefaultPrinter>,
      worksheetTemplateSelectionInput: {} as { [key: string]: number },
      analysisParameterFilterSelection: AllItemsValue,
      hasApiError: false,
      documentGenerationState: 'None' as 'None' | 'Generating' | 'Success' | 'Error',
      selectedWorksheetPrinter: undefined as number | undefined,
      selectedLabelPrinter: undefined as number | undefined
    };
  },
  computed: {
    canGenerateLabel: function (): boolean {
      return this.sampleSelection.result.isOk() === true;
    },
    canPrintLabel: function (): boolean {
      return this.canGenerateLabel === true && this.selectedLabelPrinter != undefined;
    },
    canGenerateWorksheet: function (): boolean {
      const departments = this.analysisParameterFilterSelection
        .split(',')
        .filter((apfs) => apfs !== '');
      if (departments.length === 1 && departments[0] === AllItemsValue) {
        return (
          this.sampleSelection.result.isOk() === true &&
          this.analysisParameterFilterOptions
            .filter((apfs) => apfs !== AllItemsValue)
            .every((apfo) => this.worksheetTemplateSelectionInput[apfo] > 0)
        );
      } else {
        return (
          this.sampleSelection.result.isOk() === true &&
          departments.every((apfs) => {
            return this.worksheetTemplateSelectionInput[apfs] > 0;
          })
        );
      }
    },
    canPrintWorksheet: function (): boolean {
      return this.canGenerateWorksheet === true && this.selectedWorksheetPrinter != undefined;
    },
    isGmpOrder: function (): boolean {
      return this.orderRd.getData().reportType === ReportType.GoodManufacturingPractice;
    },
    analysisParameterFilterOptions: function (): Array<string> {
      const orderDepartments = _.sortedUniq(this.orderDepartmentsRd.withDefault([]).sort());
      return [AllItemsValue].concat(orderDepartments);
    },
    worksheetPrinters: function (): IPrinter[] {
      const printers = this.printersRd
        .getData()
        .filter((p) => p.documentType === DocumentType.Worksheets);
      return _.sortBy(printers, 'name');
    },
    labelPrinters: function (): IPrinter[] {
      const printers = this.printersRd
        .getData()
        .filter((p) => p.documentType === DocumentType.Labels);
      return _.sortBy(printers, 'name');
    }
  },
  created: async function () {
    await this.loadWorksheets();
    await this.loadOrderDepartments();
    await this.loadUserDefaultTemplates();
    await this.loadPrinters();
    await this.loadUserDefaultPrinters();
    await this.initializeSampleRange(this.orderRd.getData().id || '');

    if (this.analysisParameterFilterOptions.indexOf(this.$department) < 0) {
      this.analysisParameterFilterSelection = AllItemsValue;
    } else {
      this.analysisParameterFilterSelection = this.$department;
    }
  },
  methods: {
    updateFilter: function (choice: string) {
      this.analysisParameterFilterSelection = choice;
    },
    generateLabel: async function (mode: 'Standard' | 'Anonymized') {
      if (this.sampleSelection.result.isErr() === true) {
        return;
      }

      try {
        this.documentGenerationState = 'Generating';
        const sampleSelection: number[] = this.sampleSelection.result.getData();
        const labelType = LabelType.parse(mode);

        await this.$labordatApi
          .post(
            `/api/orders/${this.orderRd.getData().id}/preparations/labels`,
            { sampleIds: sampleSelection, labelType: labelType } as IGenerateLabels,
            { responseType: 'arraybuffer' }
          )
          .then((response) => {
            if (response != null) {
              this.openFile(response, 'Probenbeschriftung.pdf');
              this.documentGenerationState = 'Success';
            } else {
              throw new Error('No document generated.');
            }
          });
      } catch (error) {
        this.documentGenerationState = 'Error';
      }
    },
    generateWorksheet: async function () {
      if (this.sampleSelection.result.isErr() === true) {
        return;
      }

      try {
        this.documentGenerationState = 'Generating';
        const sampleSelection: number[] = this.sampleSelection.result.getData();
        const departmentCodes: string[] =
          this.analysisParameterFilterSelection === AllItemsValue
            ? this.orderDepartmentsRd.withDefault([])
            : this.analysisParameterFilterSelection.split(',').filter((s) => s !== '');
        const worksheetIds: number[] = [];
        departmentCodes.forEach((department) => {
          worksheetIds.push(this.worksheetTemplateSelectionInput[department]);
        });

        await this.$labordatApi
          .post(
            `/api/orders/${this.orderRd.getData().id}/preparations/worksheets`,
            {
              sampleIds: sampleSelection,
              worksheetIds: worksheetIds,
              departmentCodes: departmentCodes
            } as IGenerateWorksheets,
            { responseType: 'arraybuffer' }
          )
          .then((response) => {
            if (response != null) {
              this.openFile(response, 'Arbeitsblatt.pdf');
              this.documentGenerationState = 'Success';
            } else {
              throw new Error('No document generated.');
            }
          });
      } catch (error) {
        this.documentGenerationState = 'Error';
      }
    },
    printLabel: async function (mode: 'Standard' | 'Anonymized') {
      if (this.sampleSelection.result.isErr() === true) {
        return;
      }

      try {
        this.documentGenerationState = 'Generating';
        const sampleSelection: number[] = this.sampleSelection.result.getData();
        const labelType = LabelType.parse(mode);

        await this.$labordatApi
          .post(`/api/orders/${this.orderRd.getData().id}/preparations/printlabels`, {
            sampleIds: sampleSelection,
            labelType: labelType,
            printerId: this.selectedLabelPrinter
          } as IPrintLabels)
          .then((response) => {
            if (response != null) {
              this.documentGenerationState = 'Success';
            } else {
              throw new Error('No document generated.');
            }
          });
      } catch (error) {
        this.documentGenerationState = 'Error';
      }
    },
    printWorksheet: async function () {
      if (this.sampleSelection.result.isErr() === true) {
        return;
      }

      try {
        this.documentGenerationState = 'Generating';
        const sampleSelection: number[] = this.sampleSelection.result.getData();
        const departmentCodes: string[] =
          this.analysisParameterFilterSelection === AllItemsValue
            ? this.orderDepartmentsRd.withDefault([])
            : this.analysisParameterFilterSelection.split(',').filter((s) => s !== '');
        const worksheetIds: number[] = [];
        departmentCodes.forEach((department) => {
          worksheetIds.push(this.worksheetTemplateSelectionInput[department]);
        });

        await this.$labordatApi
          .post(`/api/orders/${this.orderRd.getData().id}/preparations/printworksheets`, {
            sampleIds: sampleSelection,
            worksheetIds: worksheetIds,
            departmentCodes: departmentCodes,
            printerId: this.selectedWorksheetPrinter
          } as IPrintWorksheets)
          .then((response) => {
            if (response != null) {
              this.documentGenerationState = 'Success';
            } else {
              throw new Error('No document generated.');
            }
          });
      } catch (error) {
        this.documentGenerationState = 'Error';
      }
    },
    loadOrderDepartments: async function () {
      this.orderDepartmentsRd = await this.$labordatApi.getRd(
        `/api/orders/${this.orderRd.getData().id}/departments`
      );
    },
    loadWorksheets: async function () {
      this.worksheetTemplatesRd = await this.$labordatApi.getPagedRd('/api/worksheettemplate');
    },
    loadUserDefaultTemplates: async function () {
      this.userDefaultTemplates = await this.$labordatApi
        .get<Array<IUserDefaultTemplate>>(
          '/api/defaulttemplate?user=' + this.userEmail + '&documentId=worksheettemplate'
        )
        .then((response) => response.data);

      this.userDefaultTemplates.forEach((template) => {
        const parts = template.documentId.split('-');
        if (parts.length === 2) {
          this.worksheetTemplateSelectionInput[parts[1]] = template.templateId;
        }
      });
    },
    getWorksheetTemplates: function (department: string): Array<IWorksheetTemplate> {
      return this.worksheetTemplatesRd
        .map((array) =>
          array.filter(
            (w) =>
              w.departmentCode === department &&
              (this.isGmpOrder === true || w.reportType === ReportType.Standard)
          )
        )
        .withDefault([]);
    },
    loadPrinters: async function () {
      this.printersRd = await this.$labordatApi.getPagedRd('/api/printer');
    },
    loadUserDefaultPrinters: async function () {
      this.userDefaultPrinters = await this.$labordatApi
        .get<Array<IUserDefaultPrinter>>('/api/defaultprinter?user=' + this.userEmail)
        .then((response) => response.data);

      this.userDefaultPrinters.forEach((printer) => {
        switch (printer.documentType) {
          case DocumentType.Worksheets:
            {
              const printers = this.worksheetPrinters.filter((p) => p.id === printer.printerId);
              this.selectedWorksheetPrinter =
                printers && printers.length > 0 ? printers[0].id : undefined;
            }
            break;
          case DocumentType.Labels:
            {
              const printers = this.labelPrinters.filter((p) => p.id === printer.printerId);
              this.selectedLabelPrinter =
                printers && printers.length > 0 ? printers[0].id : undefined;
            }
            break;
          default:
            throw Error('Unknown printer type');
        }
      });
    }
  }
});
