











































































































































































































































































































































































































































































import _ from 'lodash';
import $ from 'jquery';
import axios, { AxiosPromise } from 'axios';
import mixins from 'vue-typed-mixins';
import OrderMixin from '@/mixins/OrderMixin';
import OrderViewHelperMixin from '@/mixins/OrderViewHelperMixin';
import CreateAndUpdateFormMixin from '@/mixins/CreateAndUpdateFormMixin';
import SampleRangeMixin from '@/mixins/SampleRangeMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import SigneesMixin from '@/mixins/SigneesMixin';
import SettingsMixin from '@/mixins/SettingsMixin';
import ReportGenerationMixin from '@/mixins/ReportGenerationMixin';
import StatusHubMixin from '@/mixins/StatusHubMixin';
import UserMixin from '@/mixins/UserMixin';
import Signature from '@/components/Signature.vue';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import IReportTemplate from '@/models/IReportTemplate';
import { IOption } from '@/components/AddingSelect.vue';
import IReportComment from '@/models/IReportComment';
import SampleRange from '@/components/SampleRange.vue';
import IReport from '@/models/IReport';
import Report from '@/models/Report';
import IReportPicture from '@/models/IReportPicture';
import ReportPictureCategoryTypeahead from '@/components/ReportPictureCategoryTypeahead.vue';
import ReportType from '@/models/ReportType';
import ReportStatus from '@/models/ReportStatus';
import IReportStatusChange from '@/models/IReportStatusChange';
import ValidationStatusType from '@/models/ValidationStatusType';
import ISignee from '@/models/ISignee';
// @ts-ignore
import { Loading, Pagination } from 'rey-vue-common';
// @ts-ignore
import { quillEditor } from 'rey-vue-quill-editor';
import QuillOptions from '@/models/QuillOptions';
import ReportSorting from '@/models/ReportSorting';
import { IPagedResult } from 'rey-vue-smarttable';
import { WorkflowSteps } from './AutomaticReportSendingWorkflow/AutomaticReportSendingWorkflow.vue';

export default mixins(
  OrderMixin,
  OrderViewHelperMixin,
  CreateAndUpdateFormMixin,
  SampleRangeMixin,
  DateTimeMixin,
  SigneesMixin,
  SettingsMixin,
  ReportGenerationMixin,
  StatusHubMixin,
  UserMixin
).extend({
  components: {
    Loading,
    SampleRange,
    Signature,
    quillEditor,
    ReportPictureCategoryTypeahead,
    Pagination
  },
  props: ['reportId'],
  data() {
    return {
      reportRd: UpdatableRemoteData.notAsked<IReport, Error>(),
      reportCommentsRd: RemoteData.notAsked<Array<IReportComment>, Error>(),
      reportTemplatesRd: RemoteData.notAsked<Array<IReportTemplate>, Error>(),
      reportTemplateSelect: undefined,
      reportPicturesRd: RemoteData.notAsked<Array<IReportPicture>, Error>(),
      reportPicturesModalRd: RemoteData.notAsked<IPagedResult<IReportPicture>, Error>(),
      reportPicturePagination: {
        take: 15,
        initialTopIndex: 15,
        currentTopIndex: 15
      },
      hasApiError: false,
      isLoading: false,
      tempSelectedId: undefined as number | undefined,
      documentGenerationState: 'None' as 'None' | 'Generating' | 'Success' | 'Error',
      draftFolderLink: '',
      finalFolderLink: '',
      treeIdPrefix: 'PictureCategoryReport',
      reportCommentFilterSelection: 'Alle',
      hasErroneousParameter: false as boolean,
      quillOptions: QuillOptions.config,
      firstSignee: undefined as ISignee | undefined,
      secondSignee: undefined as ISignee | undefined,
      reportCommentRecreationCounter: 0,
      reportRevisionCommentRecreationCounter: 0,
      updateCommentDebounced: _.debounce(
        function () {
          // @ts-ignore
          this.updateReport();
        }.bind(this),
        1000
      ),
      pictureSearchDebounced: _.debounce(
        function () {
          // @ts-ignore
          this.reportPicturePagination.currentTopIndex =
            // @ts-ignore
            this.reportPicturePagination.initialTopIndex;
          // @ts-ignore
          this.search();
        }.bind(this),
        1000
      ),
      cancelTokenSource: axios.CancelToken.source()
    };
  },
  async created() {
    await this.loadSignees();
    await this.loadReport();
    await this.setFirstAndSecondSignee();
    await this.loadReportComments();
    await this.loadReportTemplates();
    await this.loadReportPictures();
    await this.initializeSampleRange(
      this.orderRd.getData().id || '',
      this.reportRd.getData().sampleSelector
    );

    await this.$labordatApi.get(`/api/file/getreportfolder?folderType=draft`).then((response) => {
      this.draftFolderLink = 'ch.laborveritas.crm.clipboard.app:' + response.data;
    });
    await this.$labordatApi.get(`/api/file/getreportfolder?folderType=final`).then((response) => {
      this.finalFolderLink = 'ch.laborveritas.crm.clipboard.app:' + response.data;
    });
    this.tempSelectedId = this.reportRd.getData().reportPictureId;

    if (this.reportCommentFilterOptions.indexOf(this.$department) >= 0) {
      this.reportCommentFilterSelection = this.$department;
    }
  },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.reportDetailsForm);
  },
  computed: {
    signingAllowed: function (): boolean {
      return (
        this.reportRd.hasData() &&
        (this.reportRd.getData().id || 0) > 0 &&
        this.reportRd.getData().realFirstSignee !== this.userEmail &&
        this.reportRd.getData().realSecondSignee !== this.userEmail &&
        this.signeesRd.map((s) => s.filter((u) => u.email === this.userEmail)).withDefault([])
          .length > 0
      );
    },
    reportIsSigned: function (): boolean {
      return this.reportRd.hasData() &&
        (this.reportRd.getData().id || 0) > 0 &&
        this.orderRd.getData().reportType === ReportType.GoodManufacturingPractice
        ? this.reportRd.getData().realFirstSignee !== null &&
            this.reportRd.getData().realSecondSignee !== null
        : this.reportRd.getData().realFirstSignee !== null ||
            this.reportRd.getData().realSecondSignee !== null;
    },
    reportIsLocked: function (): boolean {
      return (
        this.reportRd.hasData() &&
        (this.reportRd.getData().status || ReportStatus.New) >= ReportStatus.Generated
      );
    },
    reportTemplates: function (): IReportTemplate[] {
      return this.reportTemplatesRd
        .map((array) => array.filter((w) => w.departmentCode === this.$department))
        .withDefault([]);
    },
    reportPictures: function (): IReportPicture[] {
      if (this.reportPicturesRd.hasData()) {
        // @ts-ignore
        return _.sortBy(this.reportPicturesRd.withDefault([]).entities, (rp) => rp.name);
      }
      return [];
    },
    sortedReportPictures: function (): IReportPicture[] {
      return _.sortBy(this.reportPictures, (r) => r.name);
    },
    reportPicturesModal(): IReportPicture[] {
      if (this.reportPicturesModalRd.hasData()) {
        return this.reportPicturesModalRd.map((x) => x.entities).withDefault([]);
      }
      return [];
    },
    documentGenerationEnabled: function (): boolean {
      const report = this.reportRd.getData();
      return !!(report.reportTemplateId && report.reportPictureId);
    },
    firstSigneeDisplayName: function (): string {
      return (
        this.reportRd.map((r) => r.realFirstSigneeName || '').withDefault('') +
        this.reportRd
          .map((r) => (r.realFirstSigneeJobTitle != null ? ', ' + r.realFirstSigneeJobTitle : ''))
          .withDefault('')
      );
    },
    secondSigneeDisplayName: function (): string {
      return (
        this.reportRd.map((r) => r.realSecondSigneeName || '').withDefault('') +
        this.reportRd
          .map((r) => (r.realSecondSigneeJobTitle != null ? ', ' + r.realSecondSigneeJobTitle : ''))
          .withDefault('')
      );
    },
    reportCommentSelectorOptions: function (): IOption[] {
      return this.reportCommentsRd
        .map((array) =>
          array.filter(
            (w) =>
              this.reportCommentFilterSelection === 'Alle' ||
              w.departmentCode === this.reportCommentFilterSelection
          )
        )
        .withDefault([]);
    },
    reportCommentFilterOptions: function (): Array<string> {
      const commentDepartments = _.sortedUniq(
        this.reportCommentsRd
          .withDefault([])
          .map((rc) => rc.departmentCode)
          .sort()
      );
      return ['Alle'].concat(commentDepartments);
    },
    reportSortingOptions: function (): Array<{ id: number; name: string }> {
      return this.enumToObjectArray(ReportSorting).map((rs: { id: number; name: string }) => ({
        id: rs.id,
        name: rs.name === 'Default' ? 'Standard' : 'Nach Messstelle und Material'
      }));
    },
    isReportPerSample: function (): boolean {
      return this.reportRd.hasData() && (this.reportRd.getData().variant || '').indexOf('-') >= 0;
    },
    canCreateNewVersion: function (): boolean {
      return (
        this.reportRd.hasData() &&
        (this.reportRd.getData().status === ReportStatus.Generated ||
          this.reportRd.getData().status === ReportStatus.Outdated)
      );
    },
    isCompleteReport: function (): boolean {
      return this.reportRd.hasData() && this.reportRd.getData().variant === null;
    },
    getReportId: function (): string {
      const orderId = this.orderRd.getData().id;
      return orderId ? Report.FromInterface(this.reportRd.getData(), orderId).reportId : '';
    }
  },
  methods: {
    loadReportComments: async function () {
      this.reportCommentsRd = await this.$labordatApi.getPagedRd('/api/reportcomment');
    },
    addReportComment: async function (selection: IReportComment | undefined) {
      if (!selection) {
        return;
      }

      if (!this.reportRd.getData().comment) {
        // initialize string if undefined, null or empty
        this.reportRd.getData().comment = '';
      } else {
        // add line break
        this.reportRd.getData().comment += '\n';
      }
      this.reportRd.getData().comment += this.getTranslatedValue(
        selection,
        this.orderRd.getData().language
      );

      let commentDelta = JSON.parse('[]');
      if (!this.reportRd.getData().commentDelta) {
        // initialize string if undefined, null or empty
        commentDelta = '{"ops":[{"insert":"\n"}]}';
      } else {
        // add line break
        commentDelta = JSON.parse(this.reportRd.getData().commentDelta || '');
        commentDelta.ops.push({ insert: '\n' });
      }

      if (commentDelta.ops) {
        commentDelta.ops = commentDelta.ops.concat(
          JSON.parse(this.getTranslatedDeltaValue(selection, this.orderRd.getData().language)).ops
        );
      } else {
        commentDelta = JSON.parse(
          this.getTranslatedDeltaValue(selection, this.orderRd.getData().language)
        );
      }

      this.reportRd.getData().commentDelta = JSON.stringify(commentDelta);
      this.reportCommentRecreationCounter++;

      await this.updateReport();

      $('#reportComment .ql-editor').html(this.reportRd.getData().comment || '');
    },
    async search() {
      await this.loadReportPicturesBySearchString(
        (this.$refs.pictureSearch as HTMLInputElement).value
      );
    },
    async reportPicturePaginationChanged(newTopIndex: number) {
      this.reportPicturePagination.currentTopIndex = newTopIndex;
      await this.search();
    },
    async startAutomaticReportSendingDialogFromStart() {
      let startingAt: WorkflowSteps = 'CompilingReports';
      this.$router.push({
        name: 'AutomaticReportSendingWorkflow',
        params: {
          reportId: this.reportId,
          startingAtStep: startingAt
        }
      });
    },
    async startAutomaticReportSendingDialogSkippingCompilationStep() {
      let startingAt: WorkflowSteps = 'CompilingMail';
      this.$router.push({
        name: 'AutomaticReportSendingWorkflow',
        params: {
          reportId: this.reportId,
          startingAtStep: startingAt
        }
      });
    },
    async generateDraft() {
      this.documentGenerationState = 'Generating';
      this.documentGenerationState = await this.generateDocument(
        this.sampleSelection,
        this.orderRd.getData().id || '',
        this.reportRd.getData().id || 0,
        'draft'
      );
      await this.loadReport();
    },
    async generatePreview() {
      this.documentGenerationState = 'Generating';
      this.documentGenerationState = await this.generateDocument(
        this.sampleSelection,
        this.orderRd.getData().id || '',
        this.reportRd.getData().id || 0,
        'preview'
      );
    },
    async updateReport() {
      if (
        this.reportRd.hasData() === false ||
        this.reportRd.getData().reportPictureId === undefined ||
        this.reportRd.getData().reportTemplateId === undefined
      ) {
        return;
      }

      this.tempSelectedId = this.reportRd.getData().reportPictureId;
      this.reportRd = await this.postUrd<IReport, Error>(
        this.reportRd,
        this.$labordatApi.post(
          `/api/orders/${this.orderRd.getData().id || 0}/reports`,
          this.reportRd.getData()
        )
      );
    },
    async unSign(signeeNumber: number): Promise<void> {
      this.reportRd = UpdatableRemoteData.updating(this.reportRd.getData());
      this.reportRd = await this.postUrd(
        this.reportRd,
        this.$labordatApi.post(
          `/api/orders/${this.orderRd.getData().id}/reports/${
            this.reportRd.getData().id || 0
          }/unsign/${signeeNumber}`
        )
      );
      this.reportRd = UpdatableRemoteData.success(this.reportRd.getData());
      this.$router.go(0);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async sign(signee: any, signeeNumber: number) {
      this.reportRd = await this.postUrd(
        this.reportRd,
        this.$labordatApi.post(
          `/api/orders/${this.orderRd.getData().id}/reports/${
            this.reportRd.getData().id || 0
          }/sign/${signeeNumber}`,
          signee
        )
      );
      this.reportRd = UpdatableRemoteData.success(this.reportRd.getData());
      this.$router.go(0);
    },
    async notifySignee(receipient: string, signeeNumber: number) {
      try {
        await this.$labordatApi
          .post(
            `/api/orders/${this.orderRd.getData().id}/reports/${
              this.reportRd.getData().id || 0
            }/notifysignee`,
            {
              recipient: receipient,
              signeeNumber: signeeNumber
            }
          )
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .then((response) => alert('E-Mail wurde versandt'));
      } catch (error) {
        this.hasApiError = true;
      }
    },
    intendedSigneeChanged(selectedSignee: ISignee, signeeNumber: number) {
      if (signeeNumber === 1) {
        this.reportRd.getData().intendedFirstSignee = selectedSignee.email;
      } else if (signeeNumber === 2) {
        this.reportRd.getData().intendedSecondSignee = selectedSignee.email;
      }
    },
    async postUrd<T, E>(remoteData: UpdatableRemoteData<T, E>, httpRequest: AxiosPromise<T>) {
      UpdatableRemoteData.updating<T, E>(remoteData.getData());
      return httpRequest
        .then((response) => response.data)
        .then((model: T) => UpdatableRemoteData.success<T, E>(model))
        .catch((error: E) => UpdatableRemoteData.updateFailure<T, E>(remoteData.getData(), error));
    },
    async loadReport() {
      this.reportRd = await this.$labordatApi.getUrd<IReport, Error>(
        `/api/report/${this.reportId}`
      );
    },
    async setFirstAndSecondSignee() {
      if (this.reportRd.hasData() && this.signeesRd.hasData()) {
        const reportData = this.reportRd.getData();

        var preselectedFirstSignee = this.userEmail;
        if (reportData.intendedFirstSignee) {
          preselectedFirstSignee = reportData.intendedFirstSignee;
        }

        this.firstSignee = this.signeesRd
          .getData()
          .filter(
            (s) =>
              s.departmentCode === this.orderRd.getData().departmentCode &&
              s.email === preselectedFirstSignee
          )
          .sort((s1, s2) => s1.sequenceNumber - s2.sequenceNumber)
          .at(0);

        if (!this.firstSignee) {
          this.firstSignee = this.signeesRd
            .getData()
            .filter((s) => !s.departmentCode && s.email === preselectedFirstSignee)
            .sort((s1, s2) => s1.sequenceNumber - s2.sequenceNumber)
            .at(0);
        }

        if (reportData.intendedSecondSignee) {
          this.secondSignee = this.signeesRd
            .getData()
            .find((s) => s.email === reportData.intendedSecondSignee);
        }
      }
    },
    async loadReportTemplates() {
      this.reportTemplatesRd = await this.$labordatApi.getPagedRd('/api/reporttemplate');
    },
    async loadReportPictures() {
      this.reportPicturesRd = await this.$labordatApi.getRd('/api/reportpicture');
    },
    async loadReportPicturesBySearchString(searchString: string) {
      this.isLoading = true;
      this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
      this.reportPicturesModalRd = await this.$labordatApi.getRd('/api/reportpicture/cropped', {
        params: {
          take: this.reportPicturePagination.take,
          skip: this.reportPicturePagination.currentTopIndex - this.reportPicturePagination.take,
          searchString: searchString
        }
      });
      this.isLoading = false;
    },
    clickFolderLink(folderType: string) {
      const textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '1px';
      textArea.style.height = '1px';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = folderType === 'draft' ? this.draftFolderLink : this.finalFolderLink;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    },
    async selectReportPictureFromModal() {
      if (this.tempSelectedId !== undefined) {
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = axios.CancelToken.source();
        this.reportRd.getData().reportPictureId = this.tempSelectedId;
        await this.updateReport();
      }
      // @ts-ignore
      $('#reportPictureModal').modal('hide');
    },
    async showModal() {
      await this.loadReportPicturesBySearchString('');
    },
    onHidden() {
      if (this.$refs.pictureSearch) {
        // @ts-ignore
        this.$refs.pictureSearch.value = '';
      }
    },
    sampleSelectionChanged: async function () {
      await this.updateVariant();

      if (this.sampleSelection.result.isErr() === true) {
        return;
      }

      await this.updateSampleSelector();
      await this.checkErroneousParameter();
    },
    updateVariant: async function () {
      const report = this.reportRd.getData();
      if (
        report.variant != null &&
        report.variant.indexOf('-') > 0 &&
        report.status === ReportStatus.New
      ) {
        await this.$labordatApi.put(
          `/api/orders/${this.orderRd.getData().id}/reports/${report.id}/updatevariant`,
          this.sampleSelection.result.getData()
        );
        await this.loadReport();
      }
    },
    updateSampleSelector: async function () {
      if (this.sampleSelection.result.getData().length === this.getSampleIndices().length) {
        this.reportRd.getData().sampleSelector = undefined;
      } else {
        this.reportRd.getData().sampleSelector = this.sampleSelection.input;
      }

      this.reportRd = await this.postUrd<IReport, Error>(
        this.reportRd,
        this.$labordatApi.post(
          `/api/orders/${this.orderRd.getData().id}/reports`,
          this.reportRd.getData()
        )
      );
    },
    checkErroneousParameter: async function () {
      const validationStatus: ValidationStatusType = await this.$labordatApi
        .post(
          `/api/orders/${this.orderRd.getData().id}/reports/checkreportvalidationstatus`,
          this.sampleSelection.result.getData()
        )
        .then((response) => response.data as ValidationStatusType);
      this.hasErroneousParameter = validationStatus === ValidationStatusType.Erroneous;
    },
    goToOverview: function () {
      this.$router.push(`/orders/${this.orderRd.getData().id}/report`);
    },
    createReportVersion: async function () {
      await this.$labordatApi
        .post(
          `/api/orders/${this.orderRd.getData().id}/reports/${
            this.reportRd.getData().id || 0
          }/createversion`
        )
        .then((response) => response.data as IReport)
        .then((data) => {
          this.$router.push(`/orders/${this.orderRd.getData().id}/report/${data.id}`);
        });
    },
    deleteReport: async function () {
      await this.$labordatApi.delete(
        `/api/orders/${this.orderRd.getData().id}/reports/${this.reportRd.getData().id || 0}`
      );
      this.goToOverview();
    },
    splitReport: async function () {
      await this.$labordatApi.post(
        `/api/orders/${this.orderRd.getData().id}/reports/${this.reportRd.getData().id || 0}/split`
      );
      this.goToOverview();
    },
    onReportStatusChanged: function (reportStatus: IReportStatusChange) {
      if (this.orderRd.getData().id === reportStatus.orderId) {
        if (reportStatus.id === this.reportRd.getData().id) {
          this.reportRd.getData().status = reportStatus.status;
        }
      }
    },
    async getReportPicture(reportPictureId: number) {
      await this.$labordatApi
        .get(`/api/reportpicture/${reportPictureId}/cropped`, {
          cancelToken: this.cancelTokenSource.token
        })
        .then((response) => response.data as IReportPicture)
        .then((data) => {
          if (data && data.pictureBig) {
            $('#reportPicture-' + reportPictureId).attr('src', data.pictureBig);
          }
        });
    }
  }
});
