























import Vue from 'vue';
export default Vue.extend({
  name: 'radio-select',
  props: {
    options: Array,
    value: [String, Number, Boolean, Object],
    toId: Function,
    toValue: Function,
    toTitle: Function,
    disabled: Boolean
  },
  data: function () {
    return {
      // @ts-ignore
      radioChoice: this.value
    };
  },
  watch: {
    value: function (): void {
      this.radioChoice = this.value;
    },
    radioChoice: function (value: string): void {
      // @ts-ignore
      this.$emit('input', value);
    }
  },
  methods: {
    onClick: function (value: string) {
      this.$emit('onClick', value);
    }
  }
});
