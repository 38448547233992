

















































































































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import _ from 'lodash';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import SampleBasisOfValidationDetailsForm from '@/views/MasterData/SampleBasisOfValidation/SampleBasisOfValidationDetailsForm.vue';
import {
  ISampleBasisOfValidationState,
  NoSampleBasisOfValidation,
  ExistingSampleBasisOfValidation,
  NewSampleBasisOfValidation
} from '@/models/ISampleBasisOfValidationState';
import ISampleBasisOfValidation from '@/models/ISampleBasisOfValidation';
import IDepartment from '@/models/IDepartment';

export default mixins(SettingsMixin, SharedDataMixin).extend({
  components: { SampleBasisOfValidationDetailsForm },
  name: 'sample-basis-of-validation',
  props: {
    itemId: String
  },
  data: function () {
    return {
      sampleBasisOfValidationsRd: RemoteData.notAsked<Array<ISampleBasisOfValidation>, Error>(),
      sampleBasisOfValidationRd: UpdatableRemoteData.notAsked<ISampleBasisOfValidation, Error>(),
      sampleBasisOfValidationState: new NoSampleBasisOfValidation()
    };
  },
  created: async function () {
    await this.loadSampleBasisOfValidations();
    if (this.id) {
      this.loadSampleBasisOfValidation(this.id);
      this.sampleBasisOfValidationState = new ExistingSampleBasisOfValidation(this.id);
    }
  },
  computed: {
    id: function (): number {
      return +this.itemId;
    }
  },
  watch: {
    sampleBasisOfValidationState: async function (value: ISampleBasisOfValidationState) {
      switch (value.kind) {
        case 'none':
          this.sampleBasisOfValidationRd = UpdatableRemoteData.notAsked();
          this.$router.push(`/masterdata/samplebasisofvalidation`);
          break;
        case 'new':
          this.sampleBasisOfValidationRd = UpdatableRemoteData.notCreated({
            departmentCode: this.$department,
            nameDe: '',
            nameFr: '',
            nameIt: '',
            nameEn: ''
          } as ISampleBasisOfValidation);
          this.$router.push(`/masterdata/samplebasisofvalidation`);
          break;
        case 'existing':
          if (value.id) {
            await this.loadSampleBasisOfValidation(value.id);
            this.$router.push(`/masterdata/samplebasisofvalidation/${value.id}`);
          }
          break;
      }
    }
  },
  methods: {
    sampleBasisOfValidationDepartments: function (): IDepartment[] {
      return this.$departments
        .filter(
          (d) =>
            this.sampleBasisOfValidationsRd
              .withDefault([])
              .map((rc) => rc.departmentCode)
              .indexOf(d.code) >= 0
        )
        .sort();
    },
    sampleBasisOfValidationsByDepartment: function (department: string) {
      return _.sortBy(
        this.sampleBasisOfValidationsRd.getData().filter((rc) => rc.departmentCode === department),
        (rc) => rc.name
      );
    },
    loadSampleBasisOfValidations: async function () {
      try {
        this.sampleBasisOfValidationsRd = await this.$labordatApi.getPagedRd(
          `/api/samplebasisofvalidation`
        );
      } catch (error) {
        this.sampleBasisOfValidationsRd = RemoteData.failure(error as Error);
      }
    },
    loadSampleBasisOfValidation: async function (id: number) {
      try {
        this.sampleBasisOfValidationRd = UpdatableRemoteData.loading();
        this.sampleBasisOfValidationRd = await this.$labordatApi.getUrd(
          `/api/samplebasisofvalidation/${id}`
        );
      } catch (error) {
        this.sampleBasisOfValidationRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    selectSampleBasisOfValidation: function (event: Event) {
      const itemId = +(event.target as HTMLInputElement).value;
      if (itemId) {
        this.sampleBasisOfValidationState = new ExistingSampleBasisOfValidation(itemId);
      }
    },
    newSampleBasisOfValidation: function () {
      this.sampleBasisOfValidationState = new NewSampleBasisOfValidation();
    },
    deleteSampleBasisOfValidation: async function () {
      if (
        this.sampleBasisOfValidationState.kind !== 'existing' ||
        !this.sampleBasisOfValidationRd.hasData()
      ) {
        return;
      }

      if (confirm('Wollen Sie die Grundlage für Bewertung wirklich löschen?') === false) {
        return;
      }

      try {
        this.sampleBasisOfValidationRd = UpdatableRemoteData.updating(
          this.sampleBasisOfValidationRd.getData()
        );

        const sampleBasisOfValidation = this.sampleBasisOfValidationRd.getData();
        await this.$labordatApi.delete(
          `/api/samplebasisofvalidation/${sampleBasisOfValidation.id}`,
          {
            data: sampleBasisOfValidation
          }
        );

        this.sampleBasisOfValidationState = new NoSampleBasisOfValidation();
        await this.loadSampleBasisOfValidations();
      } catch (error) {
        this.sampleBasisOfValidationRd = UpdatableRemoteData.updateFailure(
          this.sampleBasisOfValidationRd.getData(),
          error as Error
        );
      }
    },
    resetSampleBasisOfValidation: function () {
      this.sampleBasisOfValidationState = new NoSampleBasisOfValidation();
    },
    createSampleBasisOfValidation: async function () {
      const sampleBasisOfValidation = this.sampleBasisOfValidationRd.getData();
      this.sampleBasisOfValidationRd = UpdatableRemoteData.updating(sampleBasisOfValidation);
      try {
        const result: ISampleBasisOfValidation = await this.$labordatApi
          .post(`/api/samplebasisofvalidation`, sampleBasisOfValidation)
          .then((response) => response.data as ISampleBasisOfValidation);

        this.sampleBasisOfValidationState = new ExistingSampleBasisOfValidation(result.id);
      } catch (error) {
        this.sampleBasisOfValidationRd = UpdatableRemoteData.updateFailure<
          ISampleBasisOfValidation,
          Error
        >(sampleBasisOfValidation, error as Error);
      }
      await this.loadSampleBasisOfValidations();
    },
    updateSampleBasisOfValidation: async function () {
      const sampleBasisOfValidation = this.sampleBasisOfValidationRd.getData();
      this.sampleBasisOfValidationRd = UpdatableRemoteData.updating(sampleBasisOfValidation);
      this.sampleBasisOfValidationRd = await this.$labordatApi
        .put(`/api/samplebasisofvalidation/${sampleBasisOfValidation.id}`, sampleBasisOfValidation)
        .then((response) => response.data as ISampleBasisOfValidation)
        .then((data) => {
          return UpdatableRemoteData.success<ISampleBasisOfValidation, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<ISampleBasisOfValidation, Error>(
            sampleBasisOfValidation,
            error
          )
        );
      await this.loadSampleBasisOfValidations();
    }
  }
});
