import 'bootstrap';
import 'json.date-extensions';
import Vue from 'vue';
import App from './App.vue';
import msal from 'vue-msal';
import router from './router';
import store from '@/services/Store';
import { AuthenticatedAxios } from './plugins/AuthenticatedAxios';
import { SignalRHub, IHubOptions } from './plugins/SignalRHub';
// @ts-ignore
import { Alert, Loading } from 'rey-vue-common';
import { MsalConfig } from './services/MsalConfig';
import Logger from '@/services/Logger';
import SelectInput from '@/components/SelectInput.vue';
import AddingSelect from '@/components/AddingSelect.vue';
import RadioSelect from '@/components/RadioSelect.vue';
import RadioMultiSelect from '@/components/RadioMultiSelect.vue';
import StickyActionBar from '@/components/StickyActionBar.vue';
import Toggle from '@/components/Toggle.vue';
import HtmlViewer from '@/components/HtmlViewer.vue';
import { VueBootstrapTypeahead } from 'rey-vue-bootstrap-typeahead';
// @ts-ignore
import { VueTags } from 'rey-vue-tags-component';

// @ts-ignore
JSON.useDateParser();

Vue.config.productionTip = false;

Vue.component('typeahead', VueBootstrapTypeahead);
Vue.component('vue-tags', VueTags);
Vue.component('select-input', SelectInput);
Vue.component('radio-select', RadioSelect);
Vue.component('radio-multi-select', RadioMultiSelect);
Vue.component('adding-select', AddingSelect);
Vue.component('alert', Alert);
Vue.component('loading', Loading);
Vue.component('sticky-actionbar', StickyActionBar);
Vue.component('toggle', Toggle);
Vue.component('html-viewer', HtmlViewer);

const msalConfig = new MsalConfig(
  process.env.VUE_APP_AAD_AUTHORITY || '',
  process.env.VUE_APP_AAD_CLIENTID || '',
  window.location.origin
);

Vue.use(msal, {
  auth: msalConfig,
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  router: router,
  graph: {
    callAfterInit: true,
    endpoints: {
      groups: '/me/memberOf'
    }
  }
});

Vue.use(AuthenticatedAxios, {
  router: router,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorHandler: (error: any) => Logger.error(error)
});

Vue.use(SignalRHub, <IHubOptions>{
  name: 'statusHub',
  url: '/hubs/status',
  events: [
    'OnOrderStatusChanged',
    'OnOrderLockStatusChanged',
    'OnReportStatusChanged',
    'OnSampleStatusChanged'
  ],
  accessTokenFactory: async () => {
    await Vue.prototype.$msal.acquireToken();
    return Vue.prototype.$msal.data.idToken;
  }
});

new Vue({
  router,
  store,
  beforeCreate: async function () {
    this.$store.commit('settings/init');
    document.cookie = '';
    await Vue.prototype.$msal.signIn();
  },
  created: function () {
    this.$statusHub.start();
  },
  render: (h) => h(App)
}).$mount('#app');
