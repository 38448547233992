


































































































import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import FileUpAndDownload from '@/components/FileUpAndDownload.vue';

export default mixins(CreateAndUpdateForm, SharedDataMixin).extend({
  components: { FileUpAndDownload },
  name: 'predefined-report-attachment-details-form',
  props: {
    isInUpdateMode: Boolean,
    fileDe: Object,
    fileEn: Object,
    fileFr: Object,
    fileIt: Object
  },
  data() {
    return {
      hasApiError: false
    };
  },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.predefinedReportAttachmentDetailsForm);
    (this.$refs.predefinedreportattachmentname as HTMLInputElement).focus();
  },
  methods: {
    uploadFile: async function (id: string, file: File) {
      const language = id.substring(id.length - 2);
      this.$emit('file-uploaded', language, file);
    },
    downloadFile: async function (id: string) {
      const language = id.substring(id.length - 2);
      this.$emit('file-downloaded', language);
    }
  }
});
