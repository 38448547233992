import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  methods: {
    toDateTimeString: function (date: string | Date) {
      return date ? moment(date).format('DD.MM.YYYY - HH:mm:ss') : date;
    },
    toDateString: function (date: string | Date) {
      return date ? moment(date).format('DD.MM.YYYY') : date;
    },
    toIntlDateString: function (date: string | Date) {
      return date ? moment(date).format('YYYY-MM-DD') : date;
    }
  }
});
