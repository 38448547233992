import Vue from 'vue';

export default class AuthenticationStateService {
  public static userIsAuthenticated(): boolean {
    return Vue.prototype.$msal.data.isAuthenticated;
  }

  public static userEmail(): string {
    return Vue.prototype.$msal.data.isAuthenticated && Vue.prototype.$msal.data.user
      ? Vue.prototype.$msal.data.user?.userName || 'anonymous'
      : 'anonymous';
  }

  public static userName(): string {
    return Vue.prototype.$msal.data.isAuthenticated && Vue.prototype.$msal.data.user
      ? Vue.prototype.$msal.data.user?.name || 'anonymous'
      : 'anonymous';
  }

  public static authenticatedUserHasAdminPermissions(
    userGroups: Array<{ id: string }> | undefined
  ): boolean {
    if (!Vue.prototype.$msal.data.isAuthenticated) {
      return false;
    }

    const labordatAdminGroupId = process.env.VUE_APP_AAD_LABORDAT_ADMINISTRATOR_GROUP;
    if (!labordatAdminGroupId) {
      return true;
    }

    if (!Array.isArray(userGroups)) {
      return false;
    }

    return !!userGroups.find((x) => x.id === labordatAdminGroupId);
  }
}
