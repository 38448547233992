




























































































































































































































import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import OrderMixin from '@/mixins/OrderMixin';
import TempDataMixin from '@/mixins/TempDataMixin';
import OrderViewHelperMixin from '@/mixins/OrderViewHelperMixin';
import StatusHubMixin from '@/mixins/StatusHubMixin';
import FormatMixin from '@/mixins/FormatMixin';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import Sample from '@/models/Sample';
import SampleDetailsForm from '@/views/Order/Sample/SampleDetailsForm.vue';
import SampleAnalysisParameters from '@/views/Order/Sample/SampleAnalysisParameters.vue';
import SamplesNav from '@/views/Order/Sample/SamplesNav.vue';
import ISamplingPoint from '@/models/ISamplingPoint';
import IMaterial from '@/models/IMaterial';
import { NoSample, ExistingSample, NewSample } from '@/models/ISampleState';
import ISample from '@/models/ISample';
import IAnalysisParameter from '@/models/IAnalysisParameter';
import ISampleStatusChange from '@/models/ISampleStatusChange';
import SampleStatus from '@/models/SampleStatus';
// @ts-ignore
import StickyEvents from 'sticky-events';

export default mixins(
  OrderMixin,
  OrderViewHelperMixin,
  TempDataMixin,
  StatusHubMixin,
  FormatMixin
).extend({
  components: { SampleDetailsForm, SampleAnalysisParameters, SamplesNav },
  props: ['itemNumber', 'fast'],
  data: function () {
    return {
      samplesRd: UpdatableRemoteData.notAsked<Array<ISample>, Error>(),
      samplingPointsRd: RemoteData.notAsked<Array<ISamplingPoint>, Error>(),
      materialsRd: RemoteData.notAsked<Array<IMaterial>, Error>(),
      currentSample: undefined as Sample | undefined,
      sampleState: new NoSample(),
      analysisParametersMetaRemoteStatus: 'ok' as 'failure' | 'updating' | 'ok',
      stickyEvents: undefined as StickyEvents | undefined,
      isSticky: false as boolean,
      updateCounter: 0 as number,
      analysisParametersCounter: 0 as number
    };
  },
  watch: {
    sampleState: function (value) {
      const orderId = this.orderRd.getData().id || '';
      const sample = this.samplesRd.getData().find((s) => s.id === value.sampleId);
      switch (value.kind) {
        case 'none':
          this.currentSample = undefined;
          this.$router.push(`/orders/${orderId}/samples`);
          break;
        case 'new':
          this.currentSample = new Sample(orderId);
          this.$router.push(`/orders/${orderId}/samples/0`);
          break;
        case 'existing':
          if (sample) {
            this.currentSample = Sample.FromInterface(sample);
            if (
              this.$router.currentRoute.fullPath !==
              `/orders/${orderId}/samples/${this.currentSample.itemNumber}${
                (this.sampleState as ExistingSample).showFastEntry ? '/fast' : ''
              }`
            ) {
              this.$router.push(
                `/orders/${orderId}/samples/${this.currentSample.itemNumber}${
                  (this.sampleState as ExistingSample).showFastEntry ? '/fast' : ''
                }`
              );
            }
          }
          break;
      }
    }
  },
  created: async function () {
    await this.loadSamples(this.orderRd.getData().id || '');
    await this.loadSamplingPoints();
    await this.loadMaterials();

    if (this.itemNumber) {
      const sample = this.samplesRd.getData().find((s) => s.itemNumber === +this.itemNumber);
      if (sample && sample.id) {
        this.sampleState = new ExistingSample(sample.id, this.fast === 'fast');
      } else {
        this.sampleState = new NewSample();
      }
    }
  },
  mounted: function () {
    this.stickyEvents = new StickyEvents();
    const { stickyElements } = this.stickyEvents;
    stickyElements.forEach((sticky: HTMLElement) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sticky.addEventListener(StickyEvents.STUCK, (event) => {
        this.isSticky = true;
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sticky.addEventListener(StickyEvents.UNSTUCK, (event) => {
        this.isSticky = false;
      });
    });
  },
  computed: {
    samples: function (): Array<ISample> {
      return _.sortBy(this.samplesRd.withDefault([]), 'itemNumber');
    },
    totalPriceInChf: function (): string {
      let sum = 0;
      this.samples.forEach((sample) => {
        sum += sample.priceInChf || 0;
      });
      return this.withSeparators(this.financial(sum));
    },
    sampleIsLocked: function (): boolean {
      return this.currentSample !== undefined && this.currentSample.status === SampleStatus.Locked;
    }
  },
  methods: {
    selectSample: function (event: Event) {
      const sampleId = +(event.target as HTMLInputElement).value;
      this.sampleState = new ExistingSample(sampleId, false);
    },
    loadSamples: async function (orderId: string) {
      try {
        const samples: ISample[] = await this.$labordatApi
          .get(`/api/orders/${orderId}/samples`)
          .then((response) => response.data as ISample[]);

        samples.sort((a, b) => (a.itemNumber || 0) - (b.itemNumber || 0));
        this.samplesRd = UpdatableRemoteData.success(samples);
      } catch (error) {
        this.samplesRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    newSample: async function () {
      await this.checkIfCompleteReportIsGenerated();

      this.sampleState = new NewSample();
    },
    copySample: async function () {
      if (this.sampleState.kind !== 'existing') {
        return;
      }

      await this.checkIfCompleteReportIsGenerated();

      try {
        if (this.currentSample) {
          this.samplesRd = UpdatableRemoteData.updating(this.samplesRd.getData());
          const result: ISample = await this.$labordatApi
            .post(`/api/orders/${this.orderRd.getData().id}/samples/${this.currentSample.id}`)
            .then((response) => response.data as ISample);

          await this.loadSamples(this.orderRd.getData().id || '');
          if (result && result.id) {
            this.sampleState = new ExistingSample(result.id, false);
          }
          this.onUpdatedParameters();
        }
      } catch (error) {
        this.samplesRd = UpdatableRemoteData.updateFailure(
          this.samplesRd.getData(),
          error as Error
        );
      }
    },
    validateSample: async function () {
      if (this.sampleState.kind !== 'existing') {
        return;
      }

      await this.checkIfCompleteReportIsGenerated();

      try {
        if (this.currentSample) {
          const id = this.currentSample.id || 0;
          this.samplesRd = UpdatableRemoteData.updating(this.samplesRd.getData());
          await this.$labordatApi.post(
            `/api/orders/${this.orderRd.getData().id}/samples/revalidate`
          );

          await this.loadSamples(this.orderRd.getData().id || '');
          this.sampleState = new NoSample();
          this.$nextTick(() => (this.sampleState = new ExistingSample(id, false)));

          this.onUpdatedParameters();
        }
      } catch (error) {
        this.samplesRd = UpdatableRemoteData.updateFailure(
          this.samplesRd.getData(),
          error as Error
        );
      }
    },
    checkIfCompleteReportIsGenerated: async function () {
      const isGenerated = await this.$labordatApi
        .post(`/api/orders/${this.orderRd.getData().id}/reports/iscompletereportgenerated`)
        .then((response) => {
          return response.data;
        });
      if (
        isGenerated === true &&
        confirm(
          'Möchten Sie wirklich eine neue Probe anlegen? Dies hat Auswirkungen auf den Gesamtbericht. Dieser wird somit auf "Veraltet" gesetzt.'
        ) === false
      ) {
        return;
      }
    },
    deleteSample: async function () {
      if (this.sampleState.kind !== 'existing') {
        return;
      }

      // Check if any analysisparameter has a measurement
      if (this.currentSample !== undefined) {
        try {
          const hasResults = await this.$labordatApi
            .get(
              `/api/orders/${this.orderRd.getData().id}/samples/${
                this.currentSample.id
              }/analysisparameters`
            )
            .then((response) => response.data as IAnalysisParameter[])
            .then((data) => {
              return data.some((ap: IAnalysisParameter) => {
                if (ap.measurement === null || ap.measurement === undefined) {
                  return false;
                }
                return ap.measurement.trim().length > 0;
              });
            });
          if (hasResults) {
            alert(
              'Die Analyseparameter können nicht gelöscht werden, da mindestens ein Messresultat vorhanden ist.'
            );
            return;
          }
        } catch (error) {
          this.samplesRd = UpdatableRemoteData.updateFailure(
            this.samplesRd.getData(),
            error as Error
          );
          return;
        }
      }

      if (confirm('Wollen Sie die Probe wirklich löschen?') === false) {
        return;
      }

      try {
        if (this.currentSample) {
          this.samplesRd = UpdatableRemoteData.updating(this.samplesRd.getData());
          await this.$labordatApi
            .delete(`/api/orders/${this.orderRd.getData().id}/samples/${this.currentSample.id}`, {
              data: this.currentSample
            })
            .then((response) => response.data);

          await this.loadSamples(this.orderRd.getData().id || '');

          this.sampleState = new NoSample();
          this.onUpdatedParameters();
        }
      } catch (error) {
        this.samplesRd = UpdatableRemoteData.updateFailure(
          this.samplesRd.getData(),
          error as Error
        );
      }
    },
    resetSample: function () {
      this.sampleState = new NoSample();
    },
    createSample: async function (sample: Sample) {
      try {
        this.samplesRd = UpdatableRemoteData.updating(this.samplesRd.getData());
        const updatedSample: Sample = await this.$labordatApi
          .post(`/api/orders/${this.orderRd.getData().id}/samples`, sample)
          .then((response) => response.data as ISample)
          .then((data) => Sample.FromInterface(data));

        const updatedSamples = this.samplesRd
          .getData()
          .filter((x) => x.id !== updatedSample.id)
          .concat([updatedSample])
          .sort((a, b) => (a.itemNumber || 0) - (b.itemNumber || 0));

        this.samplesRd = UpdatableRemoteData.success(updatedSamples);
        if (updatedSample && updatedSample.id) {
          this.sampleState = new ExistingSample(updatedSample.id, false);
        }
        this.onUpdatedParameters();
      } catch (error) {
        this.samplesRd = UpdatableRemoteData.updateFailure(
          this.samplesRd.getData(),
          error as Error
        );
      }
    },
    updateSample: async function (sample: Sample) {
      try {
        this.samplesRd = UpdatableRemoteData.updating(this.samplesRd.getData());
        const updatedSample: Sample = await this.$labordatApi
          .put(`/api/orders/${this.orderRd.getData().id}/samples/${sample.id}`, sample)
          .then((response) => response.data as ISample)
          .then((data) => Sample.FromInterface(data));

        const updatedSamples = this.samplesRd
          .getData()
          .filter((x) => x.id !== updatedSample.id)
          .concat([updatedSample])
          .sort((a, b) => (a.itemNumber || 0) - (b.itemNumber || 0));

        if (updatedSample && updatedSample.id) {
          this.sampleState = new ExistingSample(updatedSample.id, false);
        }
        this.samplesRd = UpdatableRemoteData.success(updatedSamples);
      } catch (error) {
        this.samplesRd = UpdatableRemoteData.updateFailure(
          this.samplesRd.getData(),
          error as Error
        );
      }
    },
    unlockSample: async function () {
      if (this.currentSample !== undefined) {
        if (
          confirm(
            'Möchten Sie wirklich die Probe entsperren? Dadurch werden alle Berichte, welche diese Probe beinhalten auf "Neu" oder "Veraltet" zurückgesetzt.'
          ) === false
        ) {
          return;
        }

        this.currentSample.status = SampleStatus.Unlocked;
        await this.updateSample(this.currentSample);
        this.updateCounter++;
      }
    },
    loadSamplingPoints: async function () {
      this.samplingPointsRd = await this.$labordatApi.getPagedRd(`/api/samplingpoint`);
    },
    loadMaterials: async function () {
      this.materialsRd = await this.$labordatApi.getPagedRd(`/api/material`);
    },
    importResults: async function () {
      const fileInput = this.$refs.file as HTMLInputElement;
      if (
        fileInput !== null &&
        fileInput.files !== null &&
        fileInput.accept.indexOf(fileInput.files[0].type) !== -1
      ) {
        this.samplesRd = UpdatableRemoteData.updating(this.samplesRd.getData());
        try {
          const formData = new FormData();
          formData.append('orderId', this.orderRd.getData().id || '');
          if (this.currentSample && this.currentSample.id) {
            formData.append('sampleId', `${this.currentSample.id}`);
          }
          formData.append('file', fileInput.files[0]);
          this.samplesRd = await this.$labordatApi
            .post(`/api/file/importresults`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => response.data as string)
            .then((data) => {
              if (data) {
                this.sampleState = new NoSample();
                this.$nextTick(() => (this.sampleState = new ExistingSample(data, false)));
              }
              return UpdatableRemoteData.success(this.samplesRd.getData());
            });
        } catch (error) {
          this.samplesRd = UpdatableRemoteData.updateFailure(
            this.samplesRd.getData(),
            error as Error
          );
        }
        fileInput.value = '';
      }
    },
    onUpdatedParameters: function () {
      const orderId = this.orderRd.getData().id || '';
      this.loadOrder(orderId);
      this.loadSamples(orderId);
    },
    moveToPreviousSample: function (data: { event: number; showFastEntry: boolean }) {
      const column: number = data.event;
      const showFastEntry: boolean = data.showFastEntry;
      const newNumber = Math.max.apply(
        Math,
        this.samplesRd
          .getData()
          .filter(
            (s) =>
              (s.itemNumber || 0) < (this.currentSample ? this.currentSample.itemNumber || 0 : 0)
          )
          .map((s) => s.itemNumber || 0)
      );
      if (newNumber !== -Infinity) {
        const sample = this.samplesRd.getData().find((s) => s.itemNumber === newNumber);
        if (sample && sample.id) {
          this.$parameterEditColumn = column;
          this.sampleState = new ExistingSample(sample.id, showFastEntry);
        }
      } else {
        this.analysisParametersCounter++;
      }
    },
    moveToNextSample: function (data: { event: number; showFastEntry: boolean }) {
      const column: number = data.event;
      const showFastEntry: boolean = data.showFastEntry;
      const newNumber = Math.min.apply(
        Math,
        this.samplesRd
          .getData()
          .filter(
            (s) =>
              (s.itemNumber || 0) > (this.currentSample ? this.currentSample.itemNumber || 0 : 0)
          )
          .map((s) => s.itemNumber || 0)
      );
      if (newNumber !== Infinity) {
        const sample = this.samplesRd.getData().find((s) => s.itemNumber === newNumber);
        if (sample && sample.id) {
          this.$parameterEditColumn = column;
          this.sampleState = new ExistingSample(sample.id, showFastEntry);
        }
      } else {
        this.analysisParametersCounter++;
      }
    },
    onSampleStatusChanged: function (sampleStatus: ISampleStatusChange) {
      const samples = this.samplesRd
        .withDefault([])
        .filter((s) => s.orderId === sampleStatus.orderId);
      samples.forEach((sample) => {
        if (sampleStatus.itemNumbers.filter((s) => s === sample.itemNumber).length > 0) {
          sample.status = sampleStatus.status;
        }
      });

      if (
        this.currentSample &&
        this.currentSample.orderId === sampleStatus.orderId &&
        sampleStatus.itemNumbers.filter(
          (s) => s === (this.currentSample ? this.currentSample.itemNumber : 0)
        ).length > 0
      ) {
        this.currentSample.status = sampleStatus.status;
      }
    }
  }
});
