enum ReportStatus {
  New = 0,
  FirstSignature = 1,
  Signed = 2,
  Generated = 3,
  Outdated = 4,
  Invalid = 5,
  Irrelevant = 6
}

export default ReportStatus;
