




































import mixins from 'vue-typed-mixins';
import VModelMixin from '@/mixins/VModelMixin';
import ICustomer from '@/models/ICustomer';
import CrmCustomerTypeahead from '@/components/CrmCustomerTypeahead.vue';
import CrmContact from '@/models/CrmContact';
import { AxiosResponse } from 'axios';

export default mixins(VModelMixin).extend({
  name: 'crm-customer-viewer',
  components: { CrmCustomerTypeahead },
  data: function () {
    return {
      selectedCustomer: undefined as ICustomer | undefined,
      selectedCustomerContact: undefined as CrmContact | undefined,
      refreshingCrmCustomer: false
    };
  },
  mounted: async function () {
    await this.refreshCrmCustomerContacts();
  },
  watch: {
    selectedCustomer: function (customer: ICustomer) {
      this.model.setCustomerDetails(customer);

      const contact = new CrmContact();
      if (customer.anrede) {
        contact.anrede = customer.anrede;
      }
      if (customer.vorname) {
        contact.vorname = customer.vorname;
      }
      if (customer.nachname) {
        contact.nachname = customer.nachname;
      }

      this.model.setCustomerContact(contact);
      this.$emit('selected', this.model);
    },
    selectedCustomerContact: function (contact: CrmContact) {
      this.model.setCustomerContact(contact);
      this.$emit('selected', this.model);
    }
  },
  computed: {
    customerContacts: function (): CrmContact[] {
      return this.selectedCustomer && this.selectedCustomer.ansprechpartner
        ? this.selectedCustomer.ansprechpartner
            .map((c) => CrmContact.FromInterface(c))
            .filter((c) => !!c.fullName)
        : [];
    }
  },
  methods: {
    refreshCrmCustomerContacts: async function () {
      this.refreshingCrmCustomer = true;
      try {
        if (this.model.customerNumber) {
          this.selectedCustomer = await this.loadCrmCustomer(this.model.customerNumber);
          this.selectedCustomerContact = this.customerContacts.filter(
            (c) =>
              c.vorname === this.model.customerContactFirstName &&
              c.nachname === this.model.customerContactLastName
          )[0];
        }
      } finally {
        this.refreshingCrmCustomer = false;
      }
    },
    onHitCustomer: async function (crmCustomer: ICustomer) {
      if (crmCustomer) {
        this.model.setInvoiceAddress(crmCustomer);
      }
      this.selectedCustomer = crmCustomer;
    },
    onChangeCustomerContact: async function () {
      this.$emit('input', this.model);
    },
    loadCrmCustomer: function (customerNumber: number) {
      return this.$labordatApi
        .get(`/api/crm/customers/${customerNumber}`)
        .then((response: AxiosResponse) => response.data);
    }
  }
});
