







































































































import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import OrderMixin from '@/mixins/OrderMixin';
import OrderViewHelperMixin from '@/mixins/OrderViewHelperMixin';
import CommonMixin from '@/mixins/CommonMixin';
import SvgCircle from '@/components/SvgCircle.vue';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import Order from '@/models/Order';
import SettingsMixin from '@/mixins/SettingsMixin';
import StatusHubMixin from '@/mixins/StatusHubMixin';
import IOrderStatusChange from '@/models/IOrderStatusChange'; //NOSONAR
import OrderStatus from '@/models/OrderStatus';
import IOrderLockStatusChange from '@/models/IOrderLockStatusChange'; //NOSONAR
import IReportStatusChange from '@/models/IReportStatusChange'; //NOSONAR
import ReportStatus from '@/models/ReportStatus';
import IReport from '@/models/IReport'; //NOSONAR
import Report from '@/models/Report';

export default mixins(
  OrderMixin,
  CommonMixin,
  OrderViewHelperMixin,
  SettingsMixin,
  StatusHubMixin
).extend({
  name: 'orderlayout',
  props: ['orderId'],
  components: { SvgCircle },
  data: function () {
    return {
      orderRd: UpdatableRemoteData.notAsked<Order, Error>(),
      orderReportsRd: RemoteData.notAsked<Array<IReport>, Error>()
    };
  },
  computed: {
    orderStatusCount: function (): number {
      return Object.keys(OrderStatus).length / 2;
    },
    isInUpdateMode: function (): boolean {
      return this.orderId ? true : false;
    },
    orderReportStatus: function (): ReportStatus {
      let status: ReportStatus = +ReportStatus.New;
      if (this.orderReportsRd.hasData()) {
        const data = this.orderReportsRd
          .withDefault([])
          .map((r) => Report.FromInterface(r, this.orderId));
        const groups = _.groupBy(data, (s) => s.variant);

        const rows: Report[] = [];
        for (const key of Object.keys(groups)) {
          const row = _.maxBy(groups[key], 'version');
          if (row && row.status !== ReportStatus.Irrelevant) {
            rows.push(row);
          }
        }

        const reports = _.sortedUniq(rows.map((r) => r.status).sort());
        if (reports.indexOf(ReportStatus.Outdated) >= 0) {
          status = ReportStatus.Outdated;
        } else if (
          reports.indexOf(ReportStatus.New) >= 0 ||
          reports.indexOf(ReportStatus.FirstSignature) >= 0 ||
          reports.indexOf(ReportStatus.Signed) >= 0
        ) {
          status = ReportStatus.New;
        } else if (
          reports.indexOf(ReportStatus.New) < 0 &&
          reports.indexOf(ReportStatus.FirstSignature) < 0 &&
          reports.indexOf(ReportStatus.Signed) < 0 &&
          reports.indexOf(ReportStatus.Outdated) < 0
        ) {
          status = ReportStatus.Generated;
        }
      }
      return status;
    }
  },
  created: async function () {
    await this.initialize(this.orderId);
  },
  methods: {
    initialize: async function (orderId: string | undefined) {
      if (orderId) {
        await this.loadOrder(orderId);
        await this.loadOrderReports();
      } else {
        this.orderRd = UpdatableRemoteData.notCreated(new Order(this.$department));
      }
    },
    loadOrderReports: async function () {
      this.orderReportsRd = await this.$labordatApi.getPagedRd('/api/report', {
        params: {
          equals: `OrderId=${this.orderRd.getData().id}`
        }
      });
    },
    onOrderStatusChanged: function (orderStatus: IOrderStatusChange) {
      if (this.orderId === orderStatus.id) {
        this.orderRd.map((x) => (x.status = orderStatus.status));
      }
    },
    onOrderLockStatusChanged: function (lockStatus: IOrderLockStatusChange) {
      if (this.orderId === lockStatus.id) {
        this.orderRd.map((x) => (x.lockStatus = lockStatus.lockStatus));
      }
    },
    onReportStatusChanged: function (reportStatus: IReportStatusChange) {
      if (this.orderRd.getData().id === reportStatus.orderId) {
        const reports = this.orderReportsRd.withDefault([]);
        reports.forEach((report) => {
          if (reportStatus.id === report.id) {
            report.status = reportStatus.status;
          }
        });
      }
    },
    getColorForCircles(index: number) {
      const status: OrderStatus = this.orderRd.getData().status;
      if (index > status) {
        return 'grey';
      } else if (
        status < OrderStatus.Invoiced &&
        this.orderRd.getData().deliveryDate < new Date()
      ) {
        return 'red';
      } else {
        return 'green';
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTitleForCircles(index: number) {
      const status: OrderStatus = this.orderRd.getData().status;
      return status < OrderStatus.Invoiced && this.orderRd.getData().deliveryDate < new Date()
        ? 'Liefertermin überschritten'
        : this.orderStatusToViewString(status);
    },
    getColorForReportCircle(reportStatus: ReportStatus) {
      let color = 'grey';
      switch (reportStatus) {
        case ReportStatus.New:
        case ReportStatus.FirstSignature:
        case ReportStatus.Signed:
          color = 'yellow';
          break;
        case ReportStatus.Outdated:
          color = 'red';
          break;
        case ReportStatus.Generated:
        case ReportStatus.Invalid:
          color = 'green';
          break;
      }

      return color;
    },
    getTitleForReportCircle(reportStatus: ReportStatus) {
      const text = this.reportStatusToViewString(reportStatus);
      return text === 'Erfasst' ? 'In Arbeit' : text;
    }
  },
  watch: {
    orderId: async function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      await this.initialize(newValue);
    }
  }
});
