














































import Vue from 'vue';
import $ from 'jquery';
import { SmartTableTable, State, Sorting, Config, Column } from 'rey-vue-smarttable';
// @ts-ignore
import { Pagination } from 'rey-vue-common';
import IAnalysisParameterTableItem from '@/models/IAnalysisParameterTableItem';
import Language from '@/models/Language';
import LayoutElementType from '@/models/LayoutElementType';
import IAnalysisParameter from '@/models/IAnalysisParameter';
import { UpdatableRemoteData } from 'rey-frontend-fp';

export default Vue.extend({
  name: 'fast-resultentry',
  components: { SmartTableTable, Pagination },
  props: {
    language: Number,
    sampleId: Number,
    orderId: String,
    dimensions: Array,
    basePath: String
  },
  data() {
    return {
      pageSize: 100,
      smartTableConfigFastResultEntry: undefined as Config | undefined,
      smartTableStateFastResultEntry: new State([new Sorting('rapportPosition', 'ascending')]),
      currentFastResultEntryTopIndex: 0 as number,
      fastResultEntriesRd: UpdatableRemoteData.notAsked<Array<IAnalysisParameter>, Error>(),
      selectedFastResultEntry: undefined as IAnalysisParameterTableItem | undefined
    };
  },
  async created() {
    this.initializeSmartTable();
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    $('#fastResultEntryModal').on('show.bs.modal', async function () {
      vm.updateDimensionColumnDropdownOptionsFastResultEntry();
      await vm.loadFastResultEntries();
    });
    $('#fastResultEntryModal').on('hide.bs.modal', function () {
      vm.$emit('closed');
    });
    // @ts-ignore
    const config = this.$refs.smartTableFastResultEntry.getLayout();
    config.columns[0].size = 400;
    config.columns[1].size = 300;
    config.columns[2].size = 150;
    config.columns[3].hidden = true;
    this.$nextTick(() => {
      // @ts-ignore
      this.$refs.smartTableFastResultEntry.setLayout(config);
    });
  },
  computed: {
    fastResultEntryData(): IAnalysisParameterTableItem[] {
      const analysisParameters: IAnalysisParameterTableItem[] = this.fastResultEntriesRd
        .map((array) =>
          array.map((a: IAnalysisParameter) => {
            (a as IAnalysisParameterTableItem).discriminator = 'AnalysisParameter';
            (a as IAnalysisParameterTableItem).type = LayoutElementType.Parameter;
            return a as IAnalysisParameterTableItem;
          })
        )
        .withDefault([]);

      let rows = analysisParameters;
      if (this.smartTableConfigFastResultEntry) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        rows = this.smartTableStateFastResultEntry.sortAndFilterLocal(
          rows,
          this.smartTableConfigFastResultEntry
        );
      }
      return rows;
    },
    windowedFastResultEntryData(): IAnalysisParameterTableItem[] {
      return this.fastResultEntryData.slice(
        this.currentFastResultEntryTopIndex,
        this.currentFastResultEntryTopIndex + this.pageSize
      );
    }
  },
  methods: {
    initializeSmartTable() {
      this.smartTableConfigFastResultEntry = new Config(
        (row: IAnalysisParameterTableItem) => `${row.type}-${row.id}`,
        [
          new Column({
            title: 'Bezeichnung',
            fieldName: this.getLanguageColumnName(),
            fieldType: 'string'
          }),
          new Column({
            title: 'Messresultat',
            fieldName: 'measurement',
            fieldType: 'string',
            editable: (x) => x.resultTypeCode !== 'ber',
            colStyling: 'text-right'
          }),
          new Column({ title: 'Dimension', fieldName: 'dimensionCode', fieldType: 'dropdown' }),
          new Column({
            title: '',
            fieldName: 'rapportPosition',
            fieldType: 'numeric',
            editable: () => false
          })
        ]
      );
    },
    async fastResultEntryUpdated(tableItem: IAnalysisParameterTableItem) {
      await this.updateFastResultEntry(tableItem);
    },
    async updateFastResultEntry(analysisParameter: IAnalysisParameter) {
      try {
        this.fastResultEntriesRd = UpdatableRemoteData.updating(this.fastResultEntriesRd.getData());

        await this.$labordatApi
          .put(`${this.basePath}/analysisparameters/${analysisParameter.id}`, analysisParameter)
          .then((response) => response.data);
      } catch (error) {
        this.fastResultEntriesRd = UpdatableRemoteData.updateFailure(
          this.fastResultEntriesRd.getData(),
          error as Error
        );
      }
    },
    getLanguageColumnName() {
      switch (this.language) {
        case Language.DE:
          return 'nameDe';
        case Language.EN:
          return 'nameEn';
        case Language.FR:
          return 'nameFr';
        case Language.IT:
          return 'nameIt';
        default:
          throw new Error('Language not supported');
      }
    },
    updateColumnDropdownOptionsFastResultEntry(
      fieldName: string,
      dropdownOptions: { id: string | number | null; title: string }[]
    ) {
      if (this.smartTableConfigFastResultEntry && this.smartTableConfigFastResultEntry.columns) {
        const str: string | number | null = null;
        dropdownOptions.splice(0, 0, { id: str, title: '' });
        for (const col of this.smartTableConfigFastResultEntry.columns) {
          if (col.fieldName === fieldName) {
            col.dropdownOptions = dropdownOptions;
            break;
          }
        }
      }
    },
    updateDimensionColumnDropdownOptionsFastResultEntry() {
      if (this.dimensions.length > 0) {
        this.updateColumnDropdownOptionsFastResultEntry(
          'dimensionCode',
          this.dimensions.map((d) => ({
            // @ts-ignore
            id: d.code,
            // @ts-ignore
            title: d[this.getLanguageColumnName()]
          }))
        );
      }
    },
    async loadFastResultEntries() {
      this.fastResultEntriesRd = await this.$labordatApi.getUrd(
        `${this.basePath}/analysisparameters`
      );
    },
    handleNavigationTopOfListFastResultEntry(event: Event) {
      if (this.currentFastResultEntryTopIndex >= this.pageSize) {
        this.currentFastResultEntryTopIndex -= this.pageSize;
      } else {
        // @ts-ignore
        $('#fastResultEntryModal').modal('hide');
        this.$emit('navigation-top-of-list', event);
      }
    },
    handleNavigationBottomOfListFastResultEntry(event: Event) {
      if (this.currentFastResultEntryTopIndex < this.fastResultEntryData.length - this.pageSize) {
        this.currentFastResultEntryTopIndex += this.pageSize;
      } else {
        // @ts-ignore
        $('#fastResultEntryModal').modal('hide');
        this.$emit('navigation-bottom-of-list', event);
      }
    }
  }
});
