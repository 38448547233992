import { Guid } from 'guid-typescript';
import IReportCompilation from './IReportCompilation';

export default class ReportCompilationItemFactory {
  public static initializeSampleSpecificCompilation(
    sampleId: number,
    fileNameTemplate: string
  ): IReportCompilation {
    return {
      tempId: Guid.create(),
      sampleReference: '' + sampleId,
      title: `Prüfbericht für Probe ${sampleId}`,
      compilationItems: [],
      fileNameTemplate: fileNameTemplate
    };
  }

  public static initializeCompilationForSampleRange(fileNameTemplate: string): IReportCompilation {
    return {
      tempId: Guid.create(),
      sampleReference: 'SampleRange',
      title: `Prüfbericht`,
      compilationItems: [],
      fileNameTemplate: fileNameTemplate
    };
  }
}
