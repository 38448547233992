



























































































































































































































































































































































































import _ from 'lodash';
import $ from 'jquery';
import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import OrderViewHelperMixin from '@/mixins/OrderViewHelperMixin';
import SettingsMixin from '@/mixins/SettingsMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import IReportComment from '@/models/IReportComment';
import { ISamplePicture } from '@/models/ISamplePicture';
import {
  NoSamplePicture,
  ExistingSamplePicture,
  ISamplePictureState
} from '@/models/ISamplePictureState';
import IMaterial from '@/models/IMaterial';
import ISampleBasisOfValidation from '@/models/ISampleBasisOfValidation';
import ISample from '@/models/ISample';
import { IOption } from '@/components/AddingSelect.vue';
import SamplePictureDetailsForm from './SamplePictureDetailsForm.vue';
// @ts-ignore
import { quillEditor } from 'rey-vue-quill-editor';
import QuillOptions from '@/models/QuillOptions';

export default mixins(
  CreateAndUpdateForm,
  OrderViewHelperMixin,
  SettingsMixin,
  FileDownloadMixin,
  DateTimeMixin
).extend({
  components: { quillEditor, SamplePictureDetailsForm },
  name: 'sample-details-form',
  props: {
    samplingPoints: Array,
    materials: Array,
    language: Number
  },
  data() {
    return {
      samplePicturesRd: RemoteData.notAsked<Array<ISamplePicture>, Error>(),
      sampleCommentsRd: RemoteData.notAsked<Array<IReportComment>, Error>(),
      samplePictureRd: UpdatableRemoteData.notAsked<ISamplePicture, Error>(),
      samplePictureState: new NoSamplePicture(),
      sampleBasisOfValidationsRd: RemoteData.notAsked<Array<IReportComment>, Error>(),
      sampleCommentFilterSelection: 'Alle',
      sampleBasisOfValidationFilterSelection: 'Alle',
      sampleCommentRecreationCounter: 0,
      basisOfValidationRecreationCounter: 0,
      quillOptions: QuillOptions.config
    };
  },
  computed: {
    sampleTitle(): string {
      return this.isInUpdateMode === true
        ? `Probe: ${(this.model as ISample).orderId}/${(this.model as ISample).itemNumber}`
        : 'Neue Probe';
    },
    restSampleCommentSelectorOptions(): IOption[] {
      return this.sampleCommentsRd
        .map((array) =>
          array.filter(
            (w) =>
              this.sampleCommentFilterSelection === 'Alle' ||
              w.departmentCode === this.sampleCommentFilterSelection
          )
        )
        .withDefault([]);
    },
    sampleCommentFilterOptions(): Array<string> {
      const commentDepartments = _.sortedUniq(
        this.sampleCommentsRd
          .withDefault([])
          .map((sc) => sc.departmentCode)
          .sort()
      );
      return ['Alle'].concat(commentDepartments);
    },
    restSampleBasisOfValidationSelectorOptions: function (): IOption[] {
      return this.sampleBasisOfValidationsRd
        .map((array) =>
          array.filter(
            (w) =>
              this.sampleBasisOfValidationFilterSelection === 'Alle' ||
              w.departmentCode === this.sampleBasisOfValidationFilterSelection
          )
        )
        .withDefault([]);
    },
    sampleBasisOfValidationFilterOptions(): Array<string> {
      const basisOfValidationDepartments = _.sortedUniq(
        this.sampleBasisOfValidationsRd
          .withDefault([])
          .map((sbov) => sbov.departmentCode)
          .sort()
      );
      return ['Alle'].concat(basisOfValidationDepartments);
    },
    sortedMaterials: function (): IMaterial[] {
      return _.sortBy(this.materials as IMaterial[], (m) => m.name);
    }
  },
  async created() {
    await this.loadSampleComments();
    await this.loadSampleImages();
    await this.loadSampleBasisOfValidations();

    if (this.sampleCommentFilterOptions.indexOf(this.$department) >= 0) {
      this.sampleCommentFilterSelection = this.$department;
    }

    if (this.sampleBasisOfValidationFilterOptions.indexOf(this.$department) >= 0) {
      this.sampleBasisOfValidationFilterSelection = this.$department;
    }
  },
  mounted() {
    // @ts-ignore
    this.setFormRef(this.$refs.sampleForm);
  },
  watch: {
    async samplePictureState(value: ISamplePictureState) {
      switch (value.kind) {
        case 'none':
          this.samplePictureRd = UpdatableRemoteData.notAsked();
          break;
        case 'existing':
          if (value.id) {
            await this.loadSampleImage(value.id);
          }
          break;
      }
    }
  },
  methods: {
    async loadSampleImages() {
      try {
        this.samplePicturesRd = await this.$labordatApi.getRd(`/api/samplepicture/filtered`, {
          params: { sampleId: this.model.id }
        });
      } catch (error) {
        this.samplePicturesRd = RemoteData.failure(error as Error);
      }
    },
    async loadSampleImage(id: number) {
      try {
        this.samplePictureRd = UpdatableRemoteData.loading();
        this.samplePictureRd = await this.$labordatApi.getUrd(`/api/samplepicture/${id}/picture`);
      } catch (error) {
        this.samplePictureRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    async deleteSamplePicture() {
      if (this.samplePictureState.kind !== 'existing') {
        return;
      }

      if (confirm('Wollen Sie das Probebild wirklich löschen?') === false) {
        return;
      }

      try {
        this.samplePictureRd = UpdatableRemoteData.updating(this.samplePictureRd.getData());
        await this.$labordatApi.delete(
          `/api/file/deletepicture?pictureType=Sample&pictureId=${
            this.samplePictureRd.getData().id
          }`
        );
        await this.$labordatApi.delete(`/api/samplepicture/${this.samplePictureRd.getData().id}`, {
          data: this.samplePictureRd.getData()
        });

        this.samplePictureState = new NoSamplePicture();
        await this.loadSampleImages();
      } catch (error) {
        this.samplePictureRd = UpdatableRemoteData.updateFailure(
          this.samplePictureRd.getData(),
          error as Error
        );
      }
    },
    async addSampleComment(selection: IReportComment | undefined) {
      if (!selection) {
        return;
      }

      if (!this.model.comment) {
        // initialize string if undefined, null or empty
        this.model.comment = '';
      } else {
        // add line break
        this.model.comment += '\n';
      }

      this.model.comment += this.getTranslatedValue(selection, this.language);

      let commentDelta = JSON.parse('[]');
      if (!this.model.commentDelta) {
        // initialize string if undefined, null or empty
        commentDelta = '{"ops":[{"insert":"\n"}]}';
      } else {
        // add line break
        commentDelta = JSON.parse(this.model.commentDelta);
        commentDelta.ops.push({ insert: '\n' });
      }

      if (commentDelta.ops) {
        commentDelta.ops = commentDelta.ops.concat(
          JSON.parse(this.getTranslatedDeltaValue(selection, this.language)).ops
        );
      } else {
        commentDelta = JSON.parse(this.getTranslatedDeltaValue(selection, this.language));
      }

      this.model.commentDelta = JSON.stringify(commentDelta);
      this.sampleCommentRecreationCounter++;

      await this.updateDebounced();

      $('#sampleComment .ql-editor').html(this.model.comment);
    },
    addSampleBasisOfValidation: async function (selection: ISampleBasisOfValidation | undefined) {
      if (!selection) {
        return;
      }

      if (!this.model.basisOfValidation) {
        // initialize string if undefined, null or empty
        this.model.basisOfValidation = '';
      } else {
        // add line break
        this.model.basisOfValidation += '\n';
      }
      this.model.basisOfValidation += this.getTranslatedValue(selection, this.language);

      let basisOfValidationDelta = JSON.parse('[]');
      if (!this.model.basisOfValidationDelta) {
        // initialize string if undefined, null or empty
        basisOfValidationDelta = '{"ops":[{"insert":"\n"}]}';
      } else {
        // add line break
        basisOfValidationDelta = JSON.parse(this.model.basisOfValidationDelta);
        basisOfValidationDelta.ops.push({ insert: '\n' });
      }

      if (basisOfValidationDelta.ops) {
        basisOfValidationDelta.ops = basisOfValidationDelta.ops.concat(
          JSON.parse(this.getTranslatedDeltaValue(selection, this.language)).ops
        );
      } else {
        basisOfValidationDelta = JSON.parse(this.getTranslatedDeltaValue(selection, this.language));
      }
      this.model.basisOfValidationDelta = JSON.stringify(basisOfValidationDelta);
      this.basisOfValidationRecreationCounter++;

      await this.updateDebounced();

      $('#sampleBasisOfValidation .ql-editor').html(this.model.basisOfValidation);
    },
    async loadSampleComments() {
      this.sampleCommentsRd = await this.$labordatApi.getPagedRd('/api/reportcomment');
    },
    async newSamplePicture() {
      let maxReferenceNumber = 0;
      if (this.samplePicturesRd.getData().length > 0) {
        maxReferenceNumber = this.samplePicturesRd
          .getData()
          .map((sp) => sp.referenceNumber)
          .sort((sp1, sp2) => sp2 - sp1)[0];
      }

      this.samplePictureRd = UpdatableRemoteData.notCreated({
        name: 'Abb. ' + (maxReferenceNumber + 1),
        referenceNumber: maxReferenceNumber + 1
      } as ISamplePicture);
      await this.createSamplePicture();
    },
    async createSamplePicture() {
      const samplePicture = this.samplePictureRd.getData();
      this.samplePictureRd = UpdatableRemoteData.updating(samplePicture);
      try {
        samplePicture.sampleId = this.model.id;
        const result: ISamplePicture = await this.$labordatApi
          .post(`/api/samplepicture`, samplePicture)
          .then((response) => response.data as ISamplePicture);
        this.samplePictureState = new ExistingSamplePicture(result.id);
      } catch (error) {
        this.samplePictureRd = UpdatableRemoteData.updateFailure<ISamplePicture, Error>(
          samplePicture,
          error as Error
        );
      }
      await this.loadSampleImages();
    },
    pictureSelectionChanged(event: Event) {
      const itemId = Number((event.target as HTMLInputElement).value);
      if (itemId) {
        this.samplePictureState = new ExistingSamplePicture(itemId);
      }
    },
    async upload(file: File) {
      const samplePicture = this.samplePictureRd.getData();
      this.samplePictureRd = UpdatableRemoteData.updating(samplePicture);
      try {
        const formData = new FormData();
        formData.append('pictureType', 'Sample');
        formData.append('pictureId', samplePicture.id.toString());
        formData.append('file', file);
        this.samplePictureRd = await this.$labordatApi
          .post(`/api/file/uploadpicture`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => response.data as ISamplePicture)
          .then((data) => {
            return UpdatableRemoteData.success<ISamplePicture, Error>(data);
          });
      } catch (error) {
        this.samplePictureRd = UpdatableRemoteData.updateFailure<ISamplePicture, Error>(
          samplePicture,
          error as Error
        );
      }
    },
    async download() {
      const samplePicture = this.samplePictureRd.getData();
      try {
        await this.$labordatApi
          .get(`/api/file/downloadpicture?pictureType=Sample&pictureId=${samplePicture.id}`, {
            responseType: 'arraybuffer'
          })
          .then((response) => {
            this.downloadFile(response, 'Probebild.png');
          });
      } catch (error) {
        this.samplePictureRd = UpdatableRemoteData.updateFailure<ISamplePicture, Error>(
          samplePicture,
          error as Error
        );
      }
    },
    loadSampleBasisOfValidations: async function () {
      this.sampleBasisOfValidationsRd = await this.$labordatApi.getPagedRd(
        '/api/samplebasisofvalidation'
      );
    }
  }
});
