















import Vue from 'vue';

export interface IOption {
  id: number;
  name: string;
}

export default Vue.extend({
  name: 'adding-select',
  props: {
    id: String,
    options: Array
  },
  data: function () {
    return {
      selection: 0
    };
  },
  methods: {
    onAdd: function () {
      // @ts-ignore
      const selection = (this.options as IOption[]).find(
        // @ts-ignore
        (o) => o.id === this.selection
      );
      this.$emit('add', selection);
    }
  }
});
