import IReportTemplate from './IReportTemplate';
import ReportType from './ReportType';

export default class ReportTemplate implements IReportTemplate {
  public static FromInterface(reportTemplate: IReportTemplate): ReportTemplate {
    return Object.assign(new ReportTemplate(reportTemplate.id), reportTemplate);
  }

  public id: number;
  public name: string | undefined;
  public reportType: ReportType | undefined;
  public departmentCode: string | undefined;

  constructor(id: number) {
    this.id = id;
  }
}
