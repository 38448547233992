var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('h3',[_vm._v("Berichtsbilderkategorien")]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"reportPictureCategoryTypeahead"}},[_vm._v("Kategoriefilter")]),_c('report-picture-category-typeahead',{staticClass:"mb-3",attrs:{"title":"Kategoriefilter"},on:{"hit":_vm.onHitReportPictureCategory}}),(_vm.reportPictureCategoriesRd.isLoading())?_c('loading'):(_vm.reportPictureCategoriesRd.hasData())?_c('tree-view',{ref:"categoryTreeView",attrs:{"idPrefix":_vm.treeIdPrefix},on:{"selection-changed":_vm.categoryChanged},model:{value:(_vm.reportPictureCategoriesRd.getData()),callback:function ($$v) {_vm.$set(_vm.reportPictureCategoriesRd, "getData()", $$v)},expression:"reportPictureCategoriesRd.getData()"}}):(_vm.reportPictureCategoriesRd.hasError())?_c('alert',{attrs:{"mode":"danger"}},[_vm._v("Ein Fehler ist aufgetreten. Bitte laden Sie die Seite neu.")]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-8"},[_c('h3',[_vm._v("Details")]),(_vm.reportPictureCategoryRd.isNotAsked())?_c('div',[_c('span',[_vm._v("Keine Kategorie ausgewählt.")])]):_vm._e(),(_vm.reportPictureCategoryRd.isLoading())?_c('div',[_c('loading')],1):_vm._e(),(_vm.reportPictureCategoryRd.hasError())?_c('div',{staticClass:"my-3"},[_c('alert',{attrs:{"mode":"danger"}},[_vm._v("Ein Fehler ist aufgetreten. Bitte laden Sie die Seite neu.")])],1):_vm._e(),(_vm.reportPictureCategoryRd.hasData())?_c('div',[(
            _vm.reportPictureCategoryState.kind === 'existing' ||
            _vm.reportPictureCategoryState.kind === 'new'
          )?_c('report-picture-category-details-form',{ref:"reportPictureDetailsForm",attrs:{"is-in-update-mode":_vm.reportPictureCategoryState.kind === 'existing'},on:{"update":_vm.updateReportPictureCategory,"create":_vm.createReportPictureCategory},model:{value:(_vm.reportPictureCategoryRd.getData()),callback:function ($$v) {_vm.$set(_vm.reportPictureCategoryRd, "getData()", $$v)},expression:"reportPictureCategoryRd.getData()"}}):_vm._e()],1):_vm._e()])]),_c('sticky-actionbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":_vm.newReportPictureCategory}},[_c('em',{staticClass:"fas fa-plus"}),_vm._v(" Neue Kategorie ")])]),_c('div',[_c('button',{staticClass:"btn btn-danger mx-1",attrs:{"disabled":_vm.reportPictureCategoryState.kind !== 'existing' || _vm.reportPictureCategoryRd.isUpdating()},on:{"click":_vm.deleteReportPicture}},[_c('em',{staticClass:"fas fa-trash"}),_vm._v(" Kategorie löschen ")])])]},proxy:true},{key:"right",fn:function(){return [(_vm.reportPictureCategoryState.kind === 'new')?_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":function($event){return _vm.$refs.reportPictureDetailsForm.validateAndCreate()}}},[_vm._v(" Erstellen ")])]):_vm._e(),(_vm.reportPictureCategoryState.kind === 'new')?_c('div',[_c('button',{staticClass:"btn btn-secondary mx-1",on:{"click":_vm.resetReportPictureCategory}},[_vm._v(" Abbrechen ")])]):_vm._e(),(_vm.reportPictureCategoryRd.isUpdateFailure())?_c('div',{staticClass:"text-danger"},[_vm._v(" Es ist ein Fehler aufgetreten. Laden Sie die Seite neu. "),_c('em',{staticClass:"fas fa-exclamation-triangle mx-3"})]):_vm._e(),(!_vm.allowedToSave)?_c('div',{staticClass:"text-danger"},[_vm._v(" Diese Kategorie kann nicht gespeichert werden. Sie ihr eigene Oberkategorie. "),_c('em',{staticClass:"fas fa-exclamation-triangle mx-3"})]):(
          _vm.reportPictureCategoryRd.isUpdating() && _vm.reportPictureCategoryState.kind === 'existing'
        )?_c('div',[_vm._v(" Änderungen werden gespeichert "),_c('em',{staticClass:"fas fa-sync-alt fa-spin mx-3"})]):(
          _vm.reportPictureCategoryRd.hasData() && _vm.reportPictureCategoryState.kind === 'existing'
        )?_c('div',[_vm._v(" Alle Daten gespeichert "),_c('em',{staticClass:"fas fa-check mx-3"})]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }