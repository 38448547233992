import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import IFetchOrderConfirmationReceiversResponse from '@/models/IFetchOrderConfirmationReceiversResponse';
import mixins from 'vue-typed-mixins';

export default mixins(FileDownloadMixin).extend({
  methods: {
    async fetchReceivers(orderId: string): Promise<IFetchOrderConfirmationReceiversResponse> {
      const result = await this.$labordatApi.get<IFetchOrderConfirmationReceiversResponse>(
        `/api/orders/${orderId}/confirmation/receivers`
      );

      if (result.status == 200) {
        return Promise.resolve(result.data);
      }
      return Promise.reject();
    },
    generateOrderConfirmationMailText: async function (
      orderId: string,
      employeeFunctionId: number
    ): Promise<string> {
      const result = await this.$labordatApi.get<string>(
        `/api/orders/${orderId}/confirmation?employeeFunctionId=${employeeFunctionId}`
      );

      if (result.status == 200) {
        return Promise.resolve(result.data);
      }
      return Promise.reject();
    }
  }
});
