import Vue from 'vue';
import IDepartment from '@/models/IDepartment';

export default Vue.extend({
  computed: {
    $departments: {
      get: function (): IDepartment[] {
        return this.$store.state.sharedData.departments;
      },
      set: function (value: IDepartment[]) {
        this.$store.commit('sharedData/setDepartments', value);
      }
    },
    $buildTimestamp: {
      get: function (): string {
        return document.documentElement.dataset.buildTimestamp || '';
      }
    }
  }
});
