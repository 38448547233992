import Vue from 'vue';

export default Vue.extend({
  computed: {
    $department: {
      get: function (): string {
        return this.$store.state.settings.department;
      },
      set: function (value: string) {
        this.$store.commit('settings/setDepartment', value);
      }
    },
    $analysisParameterEditMode: {
      get: function (): boolean {
        return this.$store.state.settings.analysisParameterEditMode;
      },
      set: function (value: boolean) {
        this.$store.commit('settings/setAnalysisParameterEditMode', value);
      }
    },
    $isWideLayout: {
      get: function (): string {
        return this.$store.state.settings.isWideLayout;
      },
      set: function (value: string) {
        this.$store.commit('settings/setIsWideLayout', value);
      }
    }
  }
});
