


























































































































import ReportCompilationEditor from './ReportCompilationEditor.vue';
import draggable from 'vuedraggable';
import { Loading } from 'rey-vue-common';
import mixins from 'vue-typed-mixins';
import { Guid } from 'guid-typescript';
import IPredefinedReportAttachment from '@/models/AutomaticReportSending/MasterData/IPredefinedReportAttachment';
import FileDropZone from '@/components/FileDropZone.vue';
import IReportCompilationSessionDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IReportCompilationSessionDto';
import IReportCompilationItemDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IReportCompilationItemDto';
import ICommitReportCompilationSessionDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/ICommitReportCompilationSessionDto';
import ICommitReportCompilationSessionReportCompilationDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/ICommitReportCompilationSessionReportCompilationDto';
import IAddableCustomReportAttachment from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/IAddableCustomReportAttachment';
import IAddablePredefinedReportAttachment from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/IAddablePredefinedReportAttachment';
import IAddableReportCompilationItem from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/IAddableReportCompilationItem';
import IReportCompilationItem from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/IReportCompilationItem';
import ReportCompilation from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/IReportCompilation';
import CustomAttachment from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/ReportItemTypes/ICustomAttachment';
import PredefinedAttachment from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/ReportItemTypes/IPredefinedAttachment';
import ReportCompilationItemFactory from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/ReportCompilationItemFactory';
import ReportCompilationFactory from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/ReportCompilationFactory';
import IUploadCustomReportAttachmentResultDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IUploadCustomAttachmentResultDto';
import ICustomMailAttachment from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/ComponentModel/ICustomMailAttachment';
import IReportCompilationDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IReportCompilationDto';
import { PropType } from 'vue';
import StickyActionBar from '@/components/StickyActionBar.vue';
import _ from 'lodash';

export default mixins().extend({
  props: {
    reportCompilationSession: Object as PropType<IReportCompilationSessionDto>
  },
  components: {
    Loading,
    ReportCompilationEditor,
    draggable,
    FileDropZone,
    StickyActionBar
  },
  data() {
    return {
      sessionId: null as null | number,
      selectedDepartmentForPredefinedAttachments: 'all-departments',
      allPredefinedAttachments: [] as IAddablePredefinedReportAttachment[],
      customAttachments: [] as IAddableCustomReportAttachment[],
      customMailAttachments: [] as ICustomMailAttachment[],
      compilations: [] as ReportCompilation[]
    };
  },
  async mounted() {
    this.allPredefinedAttachments = await this.$labordatApi
      .get<IPredefinedReportAttachment[]>(`/api/AutomaticReportSendingPredefinedReportAttachments`)
      .then((response) => {
        return response.data.map<IAddablePredefinedReportAttachment>((x) => {
          return {
            tempId: Guid.create(),
            type: 'PredefinedAttachment',
            title: x.name,
            data: x
          } as IAddablePredefinedReportAttachment;
        });
      });

    await this.setupComponentModel(this.reportCompilationSession);
  },
  computed: {
    hasUploadedCustomReportAttachments(): boolean {
      return !!this.customAttachments && this.customAttachments.length > 0;
    },
    departmentsToFilterPredefinedAttachmentsBy(): string[] {
      return _.uniq(
        ['all-departments'].concat(
          this.allPredefinedAttachments.map((x) => x.data.departmentCode ?? '')
        )
      );
    },
    filteredPredefinedAttachments(): IAddablePredefinedReportAttachment[] {
      if (this.selectedDepartmentForPredefinedAttachments == 'all-departments') {
        return this.allPredefinedAttachments;
      }
      return this.allPredefinedAttachments.filter(
        (x) => x.data.departmentCode == this.selectedDepartmentForPredefinedAttachments
      );
    }
  },
  methods: {
    async setupComponentModel(reportCompilationSession: IReportCompilationSessionDto) {
      this.sessionId = reportCompilationSession.id;
      this.customAttachments = reportCompilationSession.uploadedCustomReportAttachments.map((x) => {
        return {
          tempId: Guid.create(),
          type: 'CustomAttachment',
          data: { id: x.id, name: x.name } as CustomAttachment,
          title: x.name
        } as IAddableCustomReportAttachment;
      });

      this.customMailAttachments = reportCompilationSession.uploadedCustomMailAttachments.map(
        (x) => {
          return {
            id: x.id,
            name: x.name
          };
        }
      );

      this.compilations = reportCompilationSession.committedCompilations.map((x) =>
        this.mapReportCompilation(x)
      );
    },
    mapReportCompilation(reportCompilation: IReportCompilationDto) {
      let mappedReportCompilation =
        reportCompilation.compilationReferenceId == 'SampleRange'
          ? ReportCompilationFactory.initializeCompilationForSampleRange(
              reportCompilation.fileNameTemplate
            )
          : ReportCompilationFactory.initializeSampleSpecificCompilation(
              +reportCompilation.compilationReferenceId,
              reportCompilation.fileNameTemplate
            );

      mappedReportCompilation.compilationItems = reportCompilation.compilationItems
        .map((compilationItem) => {
          return this.mapReportCompilationItem(compilationItem);
        })
        .filter((x) => x != null)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((x) => x!);

      return mappedReportCompilation;
    },
    mapReportCompilationItem(data: IReportCompilationItemDto): IReportCompilationItem | null {
      if (data.referencedAttachmentType === 0) {
        return ReportCompilationItemFactory.createActualReportReportCompilationItem(
          {},
          data.position
        );
      }

      if (data.referencedAttachmentType === 1) {
        let predefinedReportAttachment = this.allPredefinedAttachments.find(
          (x) => x.data.id == data.referencedAttachmentId
        );

        return predefinedReportAttachment
          ? ReportCompilationItemFactory.createPredefinedReportAttachmentReportCompilationItem(
              predefinedReportAttachment,
              data.position
            )
          : null;
      }

      if (data.referencedAttachmentType === 2) {
        let customReportAttachment = this.customAttachments.find(
          (x) => x.data.id == data.referencedAttachmentId
        );

        return customReportAttachment
          ? ReportCompilationItemFactory.createCustomReportAttachmentReportCompilationItem(
              customReportAttachment,
              data.position
            )
          : null;
      }

      return null;
    },

    async addCompilationItem(
      compilationTempId: Guid,
      attachmentToAdd: IAddableReportCompilationItem,
      positionToInsertInto?: number
    ) {
      const compilationToModify = this.compilations.find((x) => x.tempId == compilationTempId);
      if (!compilationToModify) {
        return;
      }

      let addedReportCompilationItem = null as IReportCompilationItem | null;

      if (attachmentToAdd.type == 'PredefinedAttachment') {
        let addedPredefinedAttachment = this.allPredefinedAttachments.find(
          (x) => x.tempId == attachmentToAdd.tempId
        );

        if (addedPredefinedAttachment) {
          addedReportCompilationItem =
            ReportCompilationItemFactory.createPredefinedReportAttachmentReportCompilationItem(
              addedPredefinedAttachment
            );
        }
      }

      if (attachmentToAdd.type == 'CustomAttachment') {
        let addedCustomAttachment = this.customAttachments.find(
          (x) => x.tempId == attachmentToAdd.tempId
        );

        if (addedCustomAttachment) {
          addedReportCompilationItem =
            ReportCompilationItemFactory.createCustomReportAttachmentReportCompilationItem(
              addedCustomAttachment
            );
        }
      }

      if (addedReportCompilationItem) {
        this.insertCompilationItemAtPosition(
          compilationToModify,
          addedReportCompilationItem,
          positionToInsertInto
        );
      }
    },
    async removeCompilationItem(compilationTempId: Guid, removedCompilationItemTempId: Guid) {
      const compilationToModify = this.compilations.find((x) => x.tempId == compilationTempId);
      if (!compilationToModify) {
        return;
      }

      const indexOfItemToRemove = compilationToModify.compilationItems.findIndex(
        (x) => x.tempId == removedCompilationItemTempId
      );

      if (indexOfItemToRemove == -1) {
        return;
      }

      compilationToModify.compilationItems.splice(indexOfItemToRemove, 1);
      this.makeSortOrdersSubsequent(compilationToModify);
    },
    async reorderCompilationItem(
      compilationTempId: Guid,
      movedCompilationItemTempId: Guid,
      newPosition: number
    ) {
      const compilationToModify = this.compilations.find((x) => x.tempId == compilationTempId);
      if (!compilationToModify) {
        return;
      }

      const originalIndexOfItemToMove = compilationToModify.compilationItems.findIndex(
        (x) => x.tempId == movedCompilationItemTempId
      );

      if (originalIndexOfItemToMove == -1) {
        return;
      }

      const compilationItemToMove = compilationToModify.compilationItems[originalIndexOfItemToMove];
      compilationToModify.compilationItems.splice(originalIndexOfItemToMove, 1);
      this.insertCompilationItemAtPosition(compilationToModify, compilationItemToMove, newPosition);
    },
    insertCompilationItemAtPosition(
      compilationToModify: ReportCompilation,
      compilationItemToAdd: IReportCompilationItem,
      positionToInsertInto?: number
    ) {
      this.makeSortOrdersSubsequent(compilationToModify);

      let actualPositionToInsertTo =
        positionToInsertInto !== undefined
          ? positionToInsertInto
          : Math.max(...compilationToModify.compilationItems.map((x) => x.sortOrder)) + 1;

      compilationToModify.compilationItems
        .filter((compilationItem) => compilationItem.sortOrder >= actualPositionToInsertTo)
        .forEach((compilationItem) => {
          compilationItem.sortOrder++;
        });

      compilationItemToAdd.sortOrder = actualPositionToInsertTo;
      compilationToModify.compilationItems.push(compilationItemToAdd);
    },
    makeSortOrdersSubsequent(compilationToModify: ReportCompilation) {
      let index = 0;
      compilationToModify.compilationItems.sort((a, b) => a.sortOrder - b.sortOrder);
      compilationToModify.compilationItems.forEach((x) => {
        x.sortOrder = index;
        index++;
      });
    },

    checkMove(event: { from: HTMLElement; to: HTMLElement }) {
      const isAddingToCompilation = event.from != event.to;
      return isAddingToCompilation;
    },

    uploadCustomReportAttachment(files: FileList) {
      Array.from(files).forEach((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('reportCompilationSessionId', '' + this.sessionId);
        this.$labordatApi
          .post<IUploadCustomReportAttachmentResultDto>(
            `/api/AutomaticReportSendingCustomReportAttachments`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          )
          .then((data) => {
            this.customAttachments.push({
              tempId: Guid.create(),
              type: 'CustomAttachment',
              title: data.data.name,
              data: {
                id: data.data.id
              }
            });
          });
      });
    },
    uploadCustomMailAttachment(files: File[]) {
      files.forEach((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('reportCompilationSessionId', '' + this.sessionId);
        this.$labordatApi
          .post<IUploadCustomReportAttachmentResultDto>(
            `/api/AutomaticReportSendingCustomMailAttachments`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          )
          .then((data) => {
            this.customMailAttachments.push({
              id: data.data.id,
              name: data.data.name
            });
          });
      });
    },
    deleteCustomMailAttachment(idOfCustomMailAttachmentToDelete: number) {
      this.$labordatApi
        .delete<IUploadCustomReportAttachmentResultDto>(
          `/api/AutomaticReportSendingCustomMailAttachments/${idOfCustomMailAttachmentToDelete}`
        )
        .then(() => {
          this.customMailAttachments = this.customMailAttachments.filter(
            (x) => x.id != idOfCustomMailAttachmentToDelete
          );
        });
    },
    async commitSessionState() {
      let collectedCompilations = [] as ICommitReportCompilationSessionReportCompilationDto[];
      this.compilations.forEach((x) => {
        let compilationItems = x.compilationItems.map((x) => {
          let type = 'ActualReport';
          let referencedAttachmentId = 0;

          if (x.type == 'CustomAttachment') {
            type = 'CustomReportAttachment';
            referencedAttachmentId = (x.data as CustomAttachment).id;
          }

          if (x.type == 'PredefinedAttachment') {
            type = 'PredefinedReportAttachment';
            referencedAttachmentId = (x.data as PredefinedAttachment).id;
          }

          return {
            referencedAttachmentId: referencedAttachmentId,
            referencedAttachmentType: type,
            position: x.sortOrder
          };
        });
        collectedCompilations.push({
          compilationReferenceId: x.sampleReference,
          fileNameTemplate: x.fileNameTemplate,
          compilationItems: compilationItems
        });
      });

      let dto = {
        compilations: collectedCompilations
      } as ICommitReportCompilationSessionDto;

      await this.$labordatApi.put(
        `/api/AutomaticReportSendingReportCompilationSessions/${this.sessionId}`,
        dto
      );
    },
    navigateToSendingReportDialog() {
      this.commitSessionState();
      this.$emit('navigate-to-compiling-mail-dialog');
    },
    triggerWorkflowCancelledEvent() {
      this.$emit('workflow-cancelled');
    }
  }
});
