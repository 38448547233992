














































































































import Vue from 'vue';
import { SmartTableTable, SmartTableAddDialog, SmartTableForm, State } from 'rey-vue-smarttable';
// @ts-ignore
import { Pagination } from 'rey-vue-common';
import { UpdatableRemoteData } from 'rey-frontend-fp';
import IPagedResult from '@/models/IPagedResult';
import Qs from 'qs';
import AnalysisParameterUsageDialog from './AnalysisParameterUsageDialog.vue';

export default Vue.extend({
  name: 'master-data-generic',
  components: {
    Pagination,
    SmartTableTable,
    SmartTableAddDialog,
    SmartTableForm,
    AnalysisParameterUsageDialog
  },
  props: {
    apiUrl: String,
    config: Object,
    entityId: String
  },
  data: function () {
    return {
      masterDataRd: UpdatableRemoteData.notAsked<Array<unknown>, Error>(),
      smartTableConfig: this.config.config,
      smartTableState: new State(this.config.sorting),
      addingElement: {},
      selected: undefined as unknown | undefined,
      currentTopIndex: 25,
      totalItems: 0,
      step: 25,
      analysisParameterUsageDialog: {
        isVisible: false,
        analysisParameterCode: undefined
      }
    };
  },
  created: async function () {
    await this.loadMasterData();
  },
  watch: {
    config: async function (value) {
      this.currentTopIndex = this.step;
      this.smartTableConfig = value.config;
      this.smartTableState = new State(value.sorting);
      await this.loadMasterData();
    },
    currentTopIndex: async function () {
      await this.loadMasterData();
    }
  },
  computed: {
    skip: function (): number {
      return Math.max(this.currentTopIndex - this.step, 0);
    }
  },
  methods: {
    stateChanged: async function (state: State) {
      this.smartTableState = state;
      await this.loadMasterData();
    },
    loadMasterData: async function (showLoading = true) {
      if (showLoading) {
        this.masterDataRd = UpdatableRemoteData.loading();
      }
      try {
        const equals = this.smartTableState.filters
          .filter((f) => f.filterType === 'equals')
          .map((filter) => `${filter.fieldName}=${filter.filterQuery.join('||')}`);
        const contains = this.smartTableState.filters
          .filter((f) => f.filterType !== 'equals')
          .map((filter) => `${filter.fieldName}=${filter.filterQuery.join('||')}`);

        const result: IPagedResult<unknown> = await this.$labordatApi
          .get(this.apiUrl, {
            params: {
              skip: this.skip,
              take: this.step,
              orderby: this.smartTableState.sorting.map(
                (s) => `${s.sortBy}=${s.sortMode === 'descending'}`
              ),
              equals: equals,
              contains: contains
            },
            paramsSerializer: function (params) {
              return Qs.stringify(params, { arrayFormat: 'repeat' });
            }
          })
          .then((response) => response.data as IPagedResult<unknown>);

        this.totalItems = result.totalCount;
        this.masterDataRd = UpdatableRemoteData.success(result.entities);
      } catch (error) {
        this.masterDataRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    updateElement: async function (element: unknown) {
      try {
        if (this.entityId === 'dimension' || this.entityId === 'analysisprocedure') {
          await this.$labordatApi
            .put(`${this.apiUrl}masterdata`, element)
            .then((response) => response.data);
        } else {
          await this.$labordatApi
            .put(`${this.apiUrl}/${this.smartTableConfig.key(element)}`, element)
            .then((response) => response.data);
        }

        await this.loadMasterData(false);
      } catch (error) {
        this.masterDataRd = UpdatableRemoteData.updateFailure(
          this.masterDataRd.getData(),
          error as Error
        );
      }
    },
    startAdding: function () {
      this.addingElement = {};
      // @ts-ignore
      this.$refs.smartTableAddDialog.show();
    },
    addElement: async function () {
      // @ts-ignore
      if (this.$refs.smartTableForm.validateAndUpdate()) {
        // @ts-ignore
        this.$refs.smartTableAddDialog.hide();

        this.masterDataRd = UpdatableRemoteData.updating(this.masterDataRd.getData());
        try {
          await this.$labordatApi
            .post(this.apiUrl, this.addingElement)
            .then((response) => response.data);

          if (this.entityId === 'dimension' || this.entityId === 'abbreviation') {
            await this.$labordatApi
              .post(
                `/api/dimensionabbreviation/refresh/${this.entityId}?action=add`,
                this.addingElement
              )
              .then((response) => response.data);
          }

          await this.loadMasterData(false);
        } catch (error) {
          this.masterDataRd = UpdatableRemoteData.updateFailure(
            this.masterDataRd.getData(),
            error as Error
          );
        }
      }
    },
    deleteElement: async function () {
      if (!this.selected) {
        return;
      }
      if (confirm('Wollen Sie dieses Element wirklich löschen?') === false) {
        return;
      }
      this.masterDataRd = UpdatableRemoteData.updating(this.masterDataRd.getData());
      try {
        await this.$labordatApi
          .delete(`${this.apiUrl}/${this.smartTableConfig.key(this.selected)}`, {
            data: this.selected
          })
          .then((response) => response.data);

        if (this.entityId === 'abbreviation' || this.entityId === 'dimension') {
          await this.$labordatApi
            .post(
              `/api/dimensionabbreviation/refresh/${this.entityId}?action=delete`,
              this.selected
            )
            .then((response) => response.data);
        }

        this.selected = undefined;
        await this.loadMasterData(false);
      } catch (error) {
        this.masterDataRd = UpdatableRemoteData.updateFailure(
          this.masterDataRd.getData(),
          error as Error
        );
      }
    },
    deleteUserSmartTableConfig: async function () {
      if (!this.selected) {
        return;
      }
      if (
        confirm('Wollen Sie sämtliche Tabellenlayouts dieses Users wirklich zurücksetzen?') ===
        false
      ) {
        return;
      }
      try {
        // @ts-ignore
        await this.$labordatApi.delete(`/api/smarttableconfig?user=${this.selected.userEmail}`);
      } catch (error) {
        // Nothing to do here
      }
    },
    displayAnalysisParameterUsageDialog: function () {
      if (this.entityId !== 'analysisparameter' || !this.selected) {
        return;
      }
      // @ts-ignore
      this.analysisParameterUsageDialog.analysisParameterCode = this.selected.code;
      this.analysisParameterUsageDialog.isVisible = true;
    },
    hideAnalysisParameterUsageDialog: function () {
      this.analysisParameterUsageDialog.analysisParameterCode = undefined;
      this.analysisParameterUsageDialog.isVisible = false;
    }
  }
});
