






















































































































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import _ from 'lodash';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import IAnalysisGroup from '@/models/IAnalysisGroup';
import {
  IAnalysisGroupState,
  NewAnalysisGroup,
  ExistingAnalysisGroup,
  NoAnalysisGroup
} from '@/models/IAnalysisGroupState';
import AnalysisGroupDetailsForm from '@/views/MasterData/AnalysisGroup/AnalysisGroupDetailsForm.vue';
import AnalysisGroupParameters from '@/views/MasterData/AnalysisGroup/AnalysisGroupParameters.vue';

export default mixins(SettingsMixin).extend({
  components: { AnalysisGroupDetailsForm, AnalysisGroupParameters },
  name: 'analysis-group',
  props: {
    itemId: String
  },
  data: function () {
    return {
      allAnalysisGroupsRd: RemoteData.notAsked<Array<IAnalysisGroup>, Error>(),
      analysisGroupsRd: RemoteData.notAsked<Array<IAnalysisGroup>, Error>(),
      analysisGroupRd: UpdatableRemoteData.notAsked<IAnalysisGroup, Error>(),
      analysisGroupState: new NoAnalysisGroup(),
      analysisGroupTypeahead: '',
      updateAnalysisGroupTypeahead: _.debounce(
        async function (value: string) {
          // @ts-ignore
          await this.loadAnalysisGroups(value);
        }.bind(this),
        500
      )
    };
  },
  created: function () {
    this.selectAnalysisGroup(this.itemId);
  },
  mounted: async function () {
    await this.loadAllAnalysisGroups();
  },
  watch: {
    analysisGroupState: async function (value: IAnalysisGroupState) {
      switch (value.kind) {
        case 'none':
          this.analysisGroupRd = UpdatableRemoteData.notAsked();
          this.$router.push(`/masterdata/analysisgroup`);
          break;
        case 'new':
          this.analysisGroupRd = UpdatableRemoteData.notCreated({
            departmentCode: this.$department
          } as IAnalysisGroup);
          this.$router.push(`/masterdata/analysisgroup`);
          break;
        case 'existing':
          if (value.code) {
            await this.loadAnalysisGroup(value.code);
            this.$router.push(`/masterdata/analysisgroup/${value.code}`);
          }
          break;
      }
    },
    analysisGroupTypeahead: function (value: string) {
      if (value) {
        this.updateAnalysisGroupTypeahead(value);
      }
    }
  },
  methods: {
    selectAnalysisGroup: function (value: string | undefined) {
      if (value) {
        this.analysisGroupState = new ExistingAnalysisGroup(value);
      }
    },
    resetAnalysisGroup: function () {
      this.analysisGroupState = new NoAnalysisGroup();
    },
    newAnalysisGroup: function () {
      this.analysisGroupState = new NewAnalysisGroup();
    },
    onHitAnalysisGroup: async function (analysisGroup: IAnalysisGroup) {
      this.cancelTypeaheadSearch();
      this.analysisGroupState = new ExistingAnalysisGroup(analysisGroup.code);
    },
    typeaheadSerializer: function (analysisGroup: IAnalysisGroup) {
      return analysisGroup.code + (analysisGroup.name ? ' (' + analysisGroup.name + ')' : '');
    },
    cancelTypeaheadSearch: function () {
      _.debounce(
        function () {
          // @ts-ignore
          this.updateAnalysisGroupTypeahead.cancel();
        }.bind(this),
        100
      )();
    },
    deleteAnalysisGroup: async function () {
      if (this.analysisGroupState.kind !== 'existing' || !this.analysisGroupRd.hasData()) {
        return;
      }

      if (confirm('Wollen Sie diese Analysegruppe wirklich löschen?') === false) {
        return;
      }

      this.analysisGroupRd = UpdatableRemoteData.updating(this.analysisGroupRd.getData());
      try {
        await this.$labordatApi.delete(
          `/api/analysisgroupmasterdata/${this.analysisGroupRd.getData().code}`
        );

        this.analysisGroupState = new NoAnalysisGroup();
        this.loadAllAnalysisGroups();
      } catch (error) {
        this.analysisGroupRd = UpdatableRemoteData.updateFailure(
          this.analysisGroupRd.getData(),
          error as Error
        );
      }
    },
    createAnalysisGroup: async function () {
      const analysisGroup = this.analysisGroupRd.getData();
      this.analysisGroupRd = UpdatableRemoteData.updating(analysisGroup);
      try {
        const result: IAnalysisGroup = await this.$labordatApi
          .post(`/api/analysisgroup`, analysisGroup)
          .then((response) => response.data as IAnalysisGroup);

        this.analysisGroupState = new ExistingAnalysisGroup(result.code);
        this.loadAllAnalysisGroups();
      } catch (error) {
        this.analysisGroupRd = UpdatableRemoteData.updateFailure<IAnalysisGroup, Error>(
          analysisGroup,
          error as Error
        );
      }
    },
    updateAnalysisGroup: async function () {
      const analysisGroup = this.analysisGroupRd.getData();
      this.analysisGroupRd = UpdatableRemoteData.updating(analysisGroup);
      this.analysisGroupRd = await this.$labordatApi
        .put(`/api/analysisgroup/${analysisGroup.code}`, analysisGroup)
        .then((response) => response.data as IAnalysisGroup)
        .then((data) => {
          return UpdatableRemoteData.success<IAnalysisGroup, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IAnalysisGroup, Error>(analysisGroup, error)
        );
      this.loadAllAnalysisGroups();
    },
    loadAllAnalysisGroups: async function () {
      this.allAnalysisGroupsRd = await this.$labordatApi.getPagedRd('/api/analysisgroup');
    },
    loadAnalysisGroups: async function (searchString: string) {
      this.analysisGroupsRd = await this.$labordatApi.getPagedRd('/api/analysisgroup', {
        params: {
          contains: `code=${searchString}`
        }
      });
    },
    loadAnalysisGroup: async function (code: string) {
      this.analysisGroupRd = UpdatableRemoteData.loading();
      this.analysisGroupRd = await this.$labordatApi.getUrd(`/api/analysisgroup/${code}`);
    }
  }
});
