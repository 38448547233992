














































































































import Vue from 'vue';
import { UpdatableRemoteData, RemoteData } from 'rey-frontend-fp';
import IAnalysisGroupCategory from '@/models/IAnalysisGroupCategory';
import IAnalysisGroupCategoryTree from '@/models/IAnalysisGroupCategoryTree';
import {
  IAnalysisGroupCategoryState,
  NewAnalysisGroupCategory,
  ExistingAnalysisGroupCategory,
  NoAnalysisGroupCategory
} from '@/models/IAnalysisGroupCategoryState';
import AnalysisGroupCategoryTypeahead from '@/components/AnalysisGroupCategoryTypeahead.vue';
import TreeView from '@/components/TreeView.vue';
import AnalysisGroupCategoryDetailsForm from '@/views/MasterData/AnalysisGroupCategory/AnalysisGroupCategoryDetailsForm.vue';

export default Vue.extend({
  components: {
    AnalysisGroupCategoryTypeahead,
    AnalysisGroupCategoryDetailsForm,
    TreeView
  },
  name: 'analysis-group-category',
  props: {
    itemId: [Number, String]
  },
  data() {
    return {
      allowedToSave: true,
      analysisGroupCategoryRd: UpdatableRemoteData.notAsked<IAnalysisGroupCategory, Error>(),
      analysisGroupCategoryState: new NoAnalysisGroupCategory(),
      selectedCategoryId: undefined as number | undefined,
      analysisGroupCategoriesRd: RemoteData.notAsked<Array<IAnalysisGroupCategoryTree>, Error>(),
      treeIdPrefix: 'AnalysisGroupCategoryManagement'
    };
  },
  async created() {
    await this.loadAnalysisGroupCategories();
    this.treeDataLoaded();
  },
  watch: {
    async analysisGroupCategoryState(value: IAnalysisGroupCategoryState) {
      switch (value.kind) {
        case 'none':
          this.analysisGroupCategoryRd = UpdatableRemoteData.notAsked();
          if (this.itemId !== value.id) {
            this.$router.push(`/masterdata/analysisgroupcategory`);
          }
          break;
        case 'new':
          this.analysisGroupCategoryRd = UpdatableRemoteData.notCreated({
            parentId: this.analysisGroupCategoryState.parentId
          } as IAnalysisGroupCategory);
          if (this.itemId !== value.id) {
            this.$router.push(`/masterdata/analysisgroupcategory`);
          }
          break;
        case 'existing':
          if (value.id) {
            await this.loadAnalysisGroupCategory(value.id);
            if (+this.itemId !== value.id) {
              this.$router.push(`/masterdata/analysisgroupcategory/${value.id}`);
            }
          }
          break;
      }
    }
  },
  methods: {
    treeDataLoaded() {
      if (+this.itemId) {
        this.analysisGroupCategoryState = new ExistingAnalysisGroupCategory(+this.itemId);
        this.categoryChanged(+this.itemId);
      }
    },
    resetAnalysisGroupCategory() {
      this.analysisGroupCategoryState = new NoAnalysisGroupCategory();
      // @ts-ignore
      this.$refs.categoryTreeView.setSelected(undefined);
    },
    newAnalysisGroupCategory() {
      this.analysisGroupCategoryState = new NewAnalysisGroupCategory(this.selectedCategoryId);
    },
    onHitAnalysisGroupCategory(category: IAnalysisGroupCategory) {
      this.categoryChanged(category.id);
    },
    async deleteAnalysisGroupCategory() {
      if (
        this.analysisGroupCategoryState.kind !== 'existing' ||
        !this.analysisGroupCategoryRd.hasData()
      ) {
        return;
      }
      if (confirm('Wollen Sie diese Analysegruppekategorie wirklich löschen?') === false) {
        return;
      }

      this.analysisGroupCategoryRd = UpdatableRemoteData.updating(
        this.analysisGroupCategoryRd.getData()
      );
      try {
        await this.$labordatApi
          .delete(`/api/analysisgroupcategory/${this.analysisGroupCategoryRd.getData().id}`, {
            data: this.analysisGroupCategoryRd.getData()
          })
          .then((response) => response.data);

        await this.loadAnalysisGroupCategories();
        this.treeDataLoaded();
        this.resetAnalysisGroupCategory();
      } catch (error) {
        this.analysisGroupCategoryRd = UpdatableRemoteData.updateFailure(
          this.analysisGroupCategoryRd.getData(),
          error as Error
        );
      }
    },
    async createAnalysisGroupCategory() {
      const analysisGroupCategory = this.analysisGroupCategoryRd.getData();
      this.allowedToSave = true;
      this.analysisGroupCategoryRd = UpdatableRemoteData.updating(analysisGroupCategory);
      try {
        const result: IAnalysisGroupCategory = await this.$labordatApi
          .post(`/api/analysisgroupcategory`, analysisGroupCategory)
          .then((response) => response.data as IAnalysisGroupCategory);
        this.analysisGroupCategoryState = new ExistingAnalysisGroupCategory(result.id);
        await this.loadAnalysisGroupCategories();
        this.treeDataLoaded();
        // @ts-ignore
        this.$refs.categoryTreeView.setSelected(this.treeIdPrefix + result.id);
      } catch (error) {
        this.analysisGroupCategoryRd = UpdatableRemoteData.updateFailure<
          IAnalysisGroupCategory,
          Error
        >(analysisGroupCategory, error as Error);
      }
    },
    async updateAnalysisGroupCategory() {
      const analysisGroupCategory = this.analysisGroupCategoryRd.getData();
      if (analysisGroupCategory.id === analysisGroupCategory.parentId) {
        this.allowedToSave = false;
        return;
      }
      this.allowedToSave = true;
      this.analysisGroupCategoryRd = UpdatableRemoteData.updating(analysisGroupCategory);
      this.analysisGroupCategoryRd = await this.$labordatApi
        .put(`/api/analysisgroupcategory/${analysisGroupCategory.id}`, analysisGroupCategory)
        .then((response) => response.data as IAnalysisGroupCategory)
        .then(async (data) => {
          await this.loadAnalysisGroupCategories();
          this.treeDataLoaded();
          return UpdatableRemoteData.success<IAnalysisGroupCategory, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IAnalysisGroupCategory, Error>(
            analysisGroupCategory,
            error
          )
        );
    },
    loadAnalysisGroupCategory: async function (id: number) {
      this.analysisGroupCategoryRd = UpdatableRemoteData.loading();
      this.analysisGroupCategoryRd = await this.$labordatApi.getUrd(
        `/api/analysisgroupcategory/${id}`
      );
    },
    categoryChanged(categoryId: number | undefined) {
      if (this.selectedCategoryId !== categoryId) {
        this.selectedCategoryId = categoryId;
        if (this.selectedCategoryId) {
          this.analysisGroupCategoryState = new ExistingAnalysisGroupCategory(
            this.selectedCategoryId
          );
          if (
            // @ts-ignore
            this.$refs.categoryTreeView.selectedNodeId !==
            this.treeIdPrefix + this.selectedCategoryId
          ) {
            // @ts-ignore
            this.$refs.categoryTreeView.setSelected(this.treeIdPrefix + this.selectedCategoryId);
          }
        } else {
          this.analysisGroupCategoryState = new NoAnalysisGroupCategory();
          // @ts-ignore
          this.$refs.categoryTreeView.setSelected(undefined);
        }
      }
    },
    async loadAnalysisGroupCategories() {
      this.analysisGroupCategoriesRd = await this.$labordatApi.getRd(
        `/api/analysisgroupcategory/tree`
      );
    }
  }
});
