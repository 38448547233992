var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('h3',[_vm._v("Prüfberichtsanhänge")]),(_vm.predefinedReportAttachmentsRd.hasData())?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.predefinedReportAttachmentState.id),expression:"predefinedReportAttachmentState.id"}],staticClass:"custom-select",attrs:{"size":"16"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.predefinedReportAttachmentState, "id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.selectPredefinedReportAttachment]}},_vm._l((_vm.predefinedReportAttachmentDepartments()),function(department){return _c('optgroup',{key:department.code,attrs:{"label":department.code + ': ' + department.name}},_vm._l((_vm.predefinedReportAttachmentsByDepartment(department.code)),function(reportTemplate){return _c('option',{key:reportTemplate.name,domProps:{"value":reportTemplate.id}},[_vm._v(" "+_vm._s(("" + (reportTemplate.name)))+" ")])}),0)}),0):_vm._e()]),_c('div',{staticClass:"col-sm-8"},[_c('h3',[_vm._v("Details")]),(_vm.predefinedReportAttachmentRd.isNotAsked())?_c('div',[_c('span',[_vm._v("Kein Berichtsanhang ausgewählt.")])]):_vm._e(),(_vm.predefinedReportAttachmentRd.isLoading())?_c('div',[_c('loading')],1):_vm._e(),(_vm.predefinedReportAttachmentRd.hasError())?_c('div',{staticClass:"my-3"},[_c('alert',{attrs:{"mode":"danger"}},[_vm._v("Ein Fehler ist aufgetreten. Bitte laden Sie die Seite neu.")])],1):_vm._e(),(_vm.predefinedReportAttachmentRd.hasData())?_c('div',[(
            _vm.predefinedReportAttachmentState.kind === 'existing' ||
            _vm.predefinedReportAttachmentState.kind === 'new'
          )?_c('predefined-report-attachment-details-form',{ref:"predefinedReportAttachmentDetailsForm",attrs:{"value":_vm.predefinedReportAttachmentRd.getData(),"fileDe":_vm.fileDe,"fileEn":_vm.fileEn,"fileFr":_vm.fileFr,"fileIt":_vm.fileIt,"is-in-update-mode":_vm.predefinedReportAttachmentState.kind === 'existing'},on:{"update":_vm.updatePredefinedReportAttachment,"create":_vm.createPredefinedReportAttachment,"file-uploaded":_vm.upload,"file-downloaded":_vm.download}}):_vm._e()],1):_vm._e()])]),_c('sticky-actionbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":_vm.newPredefinedReportAttachment}},[_c('em',{staticClass:"fas fa-plus"}),_vm._v(" Neuer Berichtsanhang ")])]),(_vm.predefinedReportAttachmentState.kind === 'existing')?_c('div',[_c('button',{staticClass:"btn btn-danger mx-1",attrs:{"disabled":_vm.predefinedReportAttachmentRd.isUpdating() === true},on:{"click":_vm.deletePredefinedReportAttachment}},[_c('em',{staticClass:"fas fa-trash"}),_vm._v(" Berichtsanhang löschen ")])]):_vm._e()]},proxy:true},{key:"right",fn:function(){return [(_vm.predefinedReportAttachmentState.kind === 'new')?_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":function($event){return _vm.$refs.predefinedReportAttachmentDetailsForm.validateAndCreate()}}},[_vm._v(" Erstellen ")])]):_vm._e(),(_vm.predefinedReportAttachmentState.kind === 'new')?_c('div',[_c('button',{staticClass:"btn btn-secondary mx-1",on:{"click":_vm.resetPredefinedReportAttachment}},[_vm._v(" Abbrechen ")])]):_vm._e(),(_vm.predefinedReportAttachmentRd.isUpdateFailure())?_c('div',{staticClass:"text-danger"},[_vm._v(" Es ist ein Fehler aufgetreten. Laden Sie die Seite neu. "),_c('em',{staticClass:"fas fa-exclamation-triangle mx-3"})]):(
          _vm.predefinedReportAttachmentState.kind === 'existing' &&
          _vm.predefinedReportAttachmentRd.isUpdating() === true
        )?_c('div',[_vm._v(" Änderungen werden gespeichert "),_c('em',{staticClass:"fas fa-sync-alt fa-spin mx-3"})]):(
          _vm.predefinedReportAttachmentState.kind === 'existing' &&
          _vm.predefinedReportAttachmentRd.hasData()
        )?_c('div',[_vm._v(" Alle Daten gespeichert "),_c('em',{staticClass:"fas fa-check mx-3"})]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }