export default class QuillOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static get config(): any {
    return {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ script: 'sub' }, { script: 'super' }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean']
        ]
      },
      placeholder: ''
    };
  }
}
