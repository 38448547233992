





































































































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import InvoicingTemplateDetailsForm from '@/views/Templates/InvoicingTemplate/InvoicingTemplateDetailsForm.vue';
import {
  IInvoicingTemplateState,
  NoInvoicingTemplate,
  ExistingInvoicingTemplate,
  NewInvoicingTemplate
} from '@/models/IInvoicingTemplateState';
import IInvoicingTemplate from '@/models/IInvoicingTemplate';

export default mixins(SettingsMixin, FileDownloadMixin).extend({
  components: { InvoicingTemplateDetailsForm },
  name: 'invoicing-template',
  props: {
    itemId: String
  },
  data: function () {
    return {
      invoicingTemplatesRd: RemoteData.notAsked<Array<IInvoicingTemplate>, Error>(),
      invoicingTemplateRd: UpdatableRemoteData.notAsked<IInvoicingTemplate, Error>(),
      invoicingTemplateState: new NoInvoicingTemplate()
    };
  },
  created: async function () {
    await this.loadInvoicingTemplates();
    if (this.id) {
      this.loadInvoicingTemplate(this.id);
      this.invoicingTemplateState = new ExistingInvoicingTemplate(this.id);
    }
  },
  computed: {
    id: function (): number {
      return +this.itemId;
    }
  },
  watch: {
    invoicingTemplateState: async function (value: IInvoicingTemplateState) {
      switch (value.kind) {
        case 'none':
          this.invoicingTemplateRd = UpdatableRemoteData.notAsked();
          this.$router.push(`/templates/invoicingtemplate`);
          break;
        case 'new':
          this.invoicingTemplateRd = UpdatableRemoteData.notCreated({} as IInvoicingTemplate);
          this.$router.push(`/templates/invoicingtemplate`);
          break;
        case 'existing':
          if (value.id) {
            await this.loadInvoicingTemplate(value.id);
            this.$router.push(`/templates/invoicingtemplate/${value.id}`);
          }
          break;
      }
    }
  },
  methods: {
    loadInvoicingTemplates: async function () {
      try {
        this.invoicingTemplatesRd = await this.$labordatApi.getPagedRd(`/api/invoicingtemplate`);
      } catch (error) {
        this.invoicingTemplatesRd = RemoteData.failure(error as Error);
      }
    },
    loadInvoicingTemplate: async function (id: number) {
      try {
        this.invoicingTemplateRd = UpdatableRemoteData.loading();
        this.invoicingTemplateRd = await this.$labordatApi.getUrd(`/api/invoicingtemplate/${id}`);
      } catch (error) {
        this.invoicingTemplateRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    selectInvoicingTemplate: function (event: Event) {
      const itemId = +(event.target as HTMLInputElement).value;
      if (itemId) {
        this.invoicingTemplateState = new ExistingInvoicingTemplate(itemId);
      }
    },
    newInvoicingTemplate: function () {
      this.invoicingTemplateState = new NewInvoicingTemplate();
    },
    deleteInvoicingTemplate: async function () {
      if (this.invoicingTemplateState.kind !== 'existing' || !this.invoicingTemplateRd.hasData()) {
        return;
      }

      if (confirm('Wollen Sie die Verrechnungsvorlage wirklich löschen?') === false) {
        return;
      }

      try {
        this.invoicingTemplateRd = UpdatableRemoteData.updating(this.invoicingTemplateRd.getData());
        const invoicingTemplate = this.invoicingTemplateRd.getData();

        await this.$labordatApi.delete(
          `/api/file/delete?templateType=Invoicing&templateId=${invoicingTemplate.id}`
        );
        await this.$labordatApi.delete(
          `/api/invoicingtemplate/${this.invoicingTemplateRd.getData().id}`,
          {
            data: this.invoicingTemplateRd.getData()
          }
        );

        this.invoicingTemplateState = new NoInvoicingTemplate();
        await this.loadInvoicingTemplates();
      } catch (error) {
        this.invoicingTemplateRd = UpdatableRemoteData.updateFailure(
          this.invoicingTemplateRd.getData(),
          error as Error
        );
      }
    },
    resetInvoicingTemplate: function () {
      this.invoicingTemplateState = new NoInvoicingTemplate();
    },
    createInvoicingTemplate: async function () {
      const invoicingTemplate = this.invoicingTemplateRd.getData();
      this.invoicingTemplateRd = UpdatableRemoteData.updating(invoicingTemplate);
      try {
        const result: IInvoicingTemplate = await this.$labordatApi
          .post(`/api/invoicingtemplate`, invoicingTemplate)
          .then((response) => response.data as IInvoicingTemplate);

        this.invoicingTemplateState = new ExistingInvoicingTemplate(result.id);
      } catch (error) {
        this.invoicingTemplateRd = UpdatableRemoteData.updateFailure<IInvoicingTemplate, Error>(
          invoicingTemplate,
          error as Error
        );
      }
      await this.loadInvoicingTemplates();
    },
    updateInvoicingTemplate: async function () {
      const invoicingTemplate = this.invoicingTemplateRd.getData();
      this.invoicingTemplateRd = UpdatableRemoteData.updating(invoicingTemplate);
      this.invoicingTemplateRd = await this.$labordatApi
        .put(`/api/invoicingtemplate/${invoicingTemplate.id}`, invoicingTemplate)
        .then((response) => response.data as IInvoicingTemplate)
        .then((data) => {
          return UpdatableRemoteData.success<IInvoicingTemplate, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IInvoicingTemplate, Error>(
            invoicingTemplate,
            error as Error
          )
        );
      await this.loadInvoicingTemplates();
    },
    upload: async function (id: string, file: File) {
      const invoicingTemplate = this.invoicingTemplateRd.getData();
      this.invoicingTemplateRd = UpdatableRemoteData.updating(invoicingTemplate);
      try {
        const formData = new FormData();
        formData.append('templateId', invoicingTemplate.id.toString());
        formData.append('templateType', 'Invoicing');
        formData.append('file', file);
        this.invoicingTemplateRd = await this.$labordatApi
          .post(`/api/file/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => response.data as IInvoicingTemplate)
          .then((data) => {
            return UpdatableRemoteData.success<IInvoicingTemplate, Error>(data);
          });
      } catch (error) {
        this.invoicingTemplateRd = UpdatableRemoteData.updateFailure<IInvoicingTemplate, Error>(
          invoicingTemplate,
          error as Error
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    download: async function (id: string) {
      const invoicingTemplate = this.invoicingTemplateRd.getData();
      try {
        await this.$labordatApi
          .get(`/api/file/download?templateType=Invoicing&templateId=${invoicingTemplate.id}`, {
            responseType: 'arraybuffer'
          })
          .then((response) => {
            this.downloadFile(response, 'Verrechnungsvorlage.docx');
          });
      } catch (error) {
        this.invoicingTemplateRd = UpdatableRemoteData.updateFailure<IInvoicingTemplate, Error>(
          invoicingTemplate,
          error as Error
        );
      }
    }
  }
});
