import IAnalysisParameterDefinition from './IAnalysisParameterDefinition';
import UncertaintyOfMeasurement from './UncertaintyOfMeasurementType';

export default class AnalysisGroupParameter {
  public id: number | undefined;
  public dimensionCode: string;
  public precision: number;
  public rapportPosition: number;
  public calculationOrder: number;
  public showInPrint: boolean;
  public guidanceValue: string | undefined;
  public standardValueMin: number | undefined;
  public standardValueMax: number | undefined;
  public analysisParameterDefinitionCode: string;
  public analysisGroupCode: string;
  public uncertaintyOfMeasurementType: UncertaintyOfMeasurement;
  public uncertaintyOfMeasurement: number | null;
  public limitOfDetection: string;
  public limitOfDetermination: string;
  public accredited: boolean;
  public goodManufacturingPractice: boolean;
  public zeroPrice: boolean;

  constructor(
    analysisParameterDefinition: IAnalysisParameterDefinition,
    analysisGroupCode: string
  ) {
    this.dimensionCode = analysisParameterDefinition.dimensionCode;
    this.precision = analysisParameterDefinition.precision;
    this.rapportPosition = analysisParameterDefinition.rapportPosition;
    this.calculationOrder = analysisParameterDefinition.calculationOrder;
    this.showInPrint = analysisParameterDefinition.showInPrint;
    this.analysisParameterDefinitionCode = analysisParameterDefinition.code;
    this.analysisGroupCode = analysisGroupCode;
    this.uncertaintyOfMeasurementType = analysisParameterDefinition.uncertaintyOfMeasurementType;
    this.uncertaintyOfMeasurement = analysisParameterDefinition.uncertaintyOfMeasurement;
    this.limitOfDetection = analysisParameterDefinition.limitOfDetection;
    this.limitOfDetermination = analysisParameterDefinition.limitOfDetermination;
    this.accredited = analysisParameterDefinition.accredited;
    this.goodManufacturingPractice = analysisParameterDefinition.goodManufacturingPractice;
    this.zeroPrice = analysisParameterDefinition.zeroPrice;
  }
}
