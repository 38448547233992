























import Vue from 'vue';
import $ from 'jquery';

export default Vue.extend({
  name: 'tree-view-node',
  props: { nodes: Array, idPrefix: String },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expandCollapse(event: any) {
      const element = $(event.target);
      element.toggleClass('fa-caret-right fa-caret-down');
      element.closest('li').children('ul').toggleClass('collapsed expanded');
    }
  }
});
