import _Vue from 'vue';
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import Logger from '@/services/Logger';

export function SignalRHub(Vue: typeof _Vue, options: IHubOptions): void {
  const hub = new Vue();

  const connection = new HubConnectionBuilder()
    .withUrl(options.url, { accessTokenFactory: options.accessTokenFactory })
    .configureLogging(LogLevel.Error)
    .build();

  async function start() {
    try {
      await connection.start();
    } catch (err) {
      Logger.error(err as Error);
      setTimeout(() => start(), 5000);
    }
  }

  connection.onclose(async () => {
    await start();
  });

  options.events.forEach((event: string) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    connection.on(event, (payload: any) => {
      hub.$emit(event, payload);
    })
  );

  Vue.prototype['$' + options.name] = hub;
  Vue.prototype['$' + options.name].start = () => connection.start();
}

export interface IHubOptions {
  name: string;
  url: string;
  events: string[];
  retry: number | undefined;
  accessTokenFactory: () => string | Promise<string>;
}

export interface IStatusHub extends _Vue {
  start: () => void;
}
