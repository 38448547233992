


















































import Vue from 'vue';
import ISample from '@/models/ISample';
export default Vue.extend({
  name: 'samples-nav',
  props: {
    samples: { type: Array as () => ISample[], required: true },
    selected: { type: Number, required: false }
  },
  data: function () {
    return {
      sampleNumberInput: undefined as number | undefined
    };
  },
  created: function () {
    this.sampleNumberInput = this.itemNumberOfSelected;
  },
  watch: {
    selected: function () {
      this.sampleNumberInput = this.itemNumberOfSelected;
    }
  },
  computed: {
    itemNumberOfSelected: function (): number | undefined {
      const sample = this.samples.find((s) => s.id === this.selected);
      return sample ? sample.itemNumber : undefined;
    },
    arrayIndexOfSelected: function (): number {
      return this.samples.findIndex((s) => s.id === this.selected);
    },
    isPreviousDisabled: function (): boolean {
      return this.arrayIndexOfSelected === 0 || this.samples.length <= 1;
    },
    isNextDisabled: function (): boolean {
      return this.arrayIndexOfSelected === this.samples.length - 1 || this.samples.length <= 1;
    },
    isNumberInputDisabled: function (): boolean {
      return this.samples.length <= 1;
    },
    first: function (): number | undefined {
      return this.samples[0].id;
    },
    previous: function (): number | undefined {
      if (this.selected) {
        return this.samples[this.arrayIndexOfSelected - 1].id;
      }
      return undefined;
    },
    next: function (): number | undefined {
      if (this.selected) {
        return this.samples[this.arrayIndexOfSelected + 1].id;
      }
      return undefined;
    },
    last: function (): number | undefined {
      return this.samples[this.samples.length - 1].id;
    }
  },
  methods: {
    idForItemNumber: function (sampleItemNumber: number | string) {
      const sample = this.samples.find((s) => s.itemNumber === +sampleItemNumber);
      return sample ? sample.id : undefined;
    },
    goToSample: function (sampleId: number | undefined) {
      if (sampleId) {
        this.$emit('change', { target: { value: sampleId } });
      }
    }
  }
});
