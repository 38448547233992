import mixins from 'vue-typed-mixins';
import CommonMixin from './CommonMixin';
import VModelMixin from './VModelMixin';
import UpdateDebouncedMixin from './UpdateDebouncedMixin';

export default mixins(CommonMixin, VModelMixin, UpdateDebouncedMixin).extend({
  props: {
    isInUpdateMode: Boolean,
    isLocked: Boolean
  },
  data: function () {
    return {
      formRef: undefined as HTMLFormElement | undefined
    };
  },
  created: function () {
    this.updateDebouncedUpdateFunction = () => this.validateAndUpdate();
    this.updateAttachedObject(this.model);
  },
  watch: {
    model: function (value) {
      this.updateAttachedObject(value);
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateAttachedObject: function (value: any) {
      if (!value) {
        return;
      }
      this.updateDebouncedAttachObject(value);
    },
    setFormRef: function (form: HTMLFormElement) {
      this.formRef = form;
    },
    validateAndUpdate: function () {
      if (this.isInUpdateMode === false) {
        return;
      }
      if (!this.formRef) {
        return;
      }

      if (this.validateForm(this.formRef) === false) {
        return;
      }

      this.$emit('update', this.model);
    },
    validateAndCreate: function () {
      if (this.isInUpdateMode === true) {
        return;
      }
      if (!this.formRef) {
        return;
      }

      if (this.validateForm(this.formRef) === false) {
        return;
      }

      this.$emit('create', this.model);
    }
  },
  beforeDestroy: function () {
    if (this.isDirty() === true) {
      this.updateDebounced.flush();
    }
  }
});
