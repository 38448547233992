









import Vue from 'vue';

export default Vue.extend({
  props: {
    rawHtml: String,
    rows: Number
  },
  computed: {
    height: function (): number {
      return this.rows * 30;
    }
  }
});
