













































import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import ReportPictureCategoryTypeahead from '@/components/ReportPictureCategoryTypeahead.vue';

export default mixins(CreateAndUpdateForm, SharedDataMixin).extend({
  components: { ReportPictureCategoryTypeahead },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.reportPictureCategoryForm);
  }
});
