import Vue from 'vue';
import { ValidationResult } from 'rey-vue-smarttable';

export default Vue.extend({
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isNumeric: function (cellValue: any): ValidationResult {
      return {
        isValid:
          cellValue === undefined || cellValue === null || cellValue === '' || !isNaN(+cellValue),
        errorMessages: ['Bitte geben Sie eine Zahl ein.']
      };
    }
  }
});
