/* eslint-disable max-classes-per-file */

type ReportCommentState = 'new' | 'existing' | 'none';

export interface IReportCommentState {
  kind: ReportCommentState;
  id: number | undefined;
}

export class NewReportComment implements IReportCommentState {
  public kind: ReportCommentState = 'new';
  public id = 0;
}

export class ExistingReportComment implements IReportCommentState {
  public kind: ReportCommentState = 'existing';
  public id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class NoReportComment implements IReportCommentState {
  public kind: ReportCommentState = 'none';
  public id: number | undefined = undefined;
}
