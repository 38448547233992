import Vue from 'vue';
import AuthenticationService from '@/services/AuthenticationStateService';

export default Vue.extend({
  computed: {
    userIsAuthenticated: {
      get: function (): boolean {
        return AuthenticationService.userIsAuthenticated();
      }
    },
    userEmail: {
      get: function (): string {
        return AuthenticationService.userEmail();
      }
    },
    userName: {
      get: function (): string {
        return AuthenticationService.userName();
      }
    }
  }
});
