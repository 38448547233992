



































import Vue from 'vue';
export default Vue.extend({
  name: 'file-up-and-download',
  props: {
    id: String,
    value: String,
    accept: String,
    disabled: Boolean
  },
  data() {
    return {
      file: new File([], '')
    };
  },
  methods: {
    handleFileUpload() {
      const fileInput = this.$refs.file as HTMLInputElement;
      const fileValidation = this.$refs.fileValidation as HTMLButtonElement;
      if (fileValidation) {
        fileValidation.setCustomValidity('');
      }

      if (
        fileInput !== null &&
        fileInput.files !== null &&
        fileInput.accept.indexOf(fileInput.files[0].type) !== -1
      ) {
        this.file = fileInput.files[0];
        (this.$refs.filename as HTMLInputElement).value = this.file.name;
        this.$emit('file-uploaded', this.id, this.file);

        fileInput.value = '';
      } else if (fileValidation) {
        fileValidation.setCustomValidity(
          'Der gewählte Dateityp ist ungültig. Bitte wählen Sie eine korrekte Datei.'
        );
        fileValidation.reportValidity();
      }
    },
    handleFileDownload() {
      if ((this.$refs.filename as HTMLInputElement).value) {
        this.$emit('file-downloaded', this.id);
      }
    }
  }
});
