

































import MailEditor, { FormData as MailFormData, MailSelectionItem } from './MailEditor.vue';
import IAutomaticReportSendingProtocolItemEntity from '@/models/AutomaticReportSending/AutomaticReportSendingProtocol/Api/IAutomaticReportSendingProtocolItemEntity';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import mixins from 'vue-typed-mixins';
import { PropType } from 'vue';

export default mixins(FileDownloadMixin).extend({
  components: { MailEditor },
  props: {
    selectedProtocolItem: {
      type: Object as PropType<IAutomaticReportSendingProtocolItemEntity>
    },
    isComponentReady: Boolean
  },
  created() {
    this.$emit('update:isComponentReady', false);
  },
  async mounted() {
    let result = await this.$labordatApi.get<IAutomaticReportSendingProtocolItemEntity>(
      `api/AutomaticReportSendingProtocolItems/${this.selectedProtocolItem.id}`
    );
    if (result?.status == 200) {
      this.canDisplayMailPreview = !!result.data.preparedMail;
      if (this.canDisplayMailPreview) {
        this.formData.mailSubject = result.data.preparedMail?.mailSubject ?? '';
        this.formData.mailContent = result.data.preparedMail?.mailBody ?? '';
        this.formData.toReceiverMailAddresses = this.toArrayOfMailSelectionItem(
          result.data.mailReceivers
        );
        this.formData.ccReceiverMailAddresses = this.toArrayOfMailSelectionItem(
          result.data.ccMailReceivers
        );
        this.previewableAttachments = result.data.preparedMail?.mailAttachments ?? [];
      }
    }
    this.$emit('update:isComponentReady', true);
  },
  data: function () {
    return {
      canDisplayMailPreview: false,
      formData: {
        senderMailAddress: this.selectedProtocolItem.preparedMail?.replyToMail ?? '',
        toReceiverMailAddresses: [],
        ccReceiverMailAddresses: [],
        mailSubject: '',
        mailContent: ''
      } as MailFormData,
      previewableAttachments: [] as Array<{
        id: number;
        fileName: string;
      }>
    };
  },
  methods: {
    async downloadMailAttachment(id: number) {
      await this.$labordatApi
        .get(`/api/AutomaticReportSendingPreparedMailAttachments/${id}`, {
          responseType: 'arraybuffer'
        })
        .then((response) => {
          this.downloadFile(response, 'Mailanhang');
        });
    },
    toArrayOfMailSelectionItem(listSeparatedByComa: string): Array<MailSelectionItem> {
      if (!listSeparatedByComa) {
        return [];
      }

      let result: Array<MailSelectionItem> = [];
      let newId = 1;
      for (const item of listSeparatedByComa.split(',')) {
        result.push({
          id: newId,
          name: item,
          slug: item
        });
        newId++;
      }
      return result;
    }
  }
});
