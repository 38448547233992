import _Vue from 'vue';
import _ from 'lodash';
import Router from 'vue-router';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import IPagedResult from '@/models/IPagedResult';

export function AuthenticatedAxios(
  Vue: typeof _Vue,
  config: {
    router: typeof Router;
    errorHandler: (error: Error) => void;
  }
): void {
  const http: AxiosInstance = axios;

  http.interceptors.response.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: any) => response,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (err: any) => {
      // if we have a 401, delete cookies and try getting a token
      if (_.get(err.response, 'status') === 401 && !err.config.__isRetryRequest) {
        document.cookie = '';
        clearMsalFromStorage(sessionStorage);
        clearMsalFromStorage(localStorage);
        Vue.prototype.$msal.acquireToken();
      } else {
        config.errorHandler(err);
      }
    }
  );

  http.interceptors.request.use(async (request: AxiosRequestConfig) => {
    // Encode all special characters
    request.url = request.url ? encodeURI(request.url) : request.url;

    // Disable caching
    // @ts-ignore
    request.headers['Cache-Control'] = 'no-cache';

    // Get token via vue-msal
    await Vue.prototype.$msal.acquireToken();
    const token = Vue.prototype.$msal.data.idToken;
    if (token) {
      // @ts-ignore
      request.headers.Authorization = `BEARER ${token}`;
    }
    return request;
  });

  http.getRd = function <T, E>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<RemoteData<T, E>> {
    return http
      .get(url, requestConfig)
      .then((response) => response.data as T)
      .then((data: T) => RemoteData.success<T, E>(data))
      .catch((error: E) => RemoteData.failure<T, E>(error));
  };

  http.getPagedRd = function <T, E>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<RemoteData<Array<T>, E>> {
    return http
      .getRd<IPagedResult<T>, E>(url, requestConfig)
      .then((result) => result.map((x) => x.entities));
  };

  http.getUrd = function <T, E>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<UpdatableRemoteData<T, E>> {
    return http
      .get(url, requestConfig)
      .then((response) => response.data as T)
      .then((data: T) => UpdatableRemoteData.success<T, E>(data))
      .catch((error: E) => UpdatableRemoteData.failure<T, E>(error));
  };

  http.getPagedUrd = function <T, E>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<UpdatableRemoteData<Array<T>, E>> {
    return http
      .getUrd<IPagedResult<T>, E>(url, requestConfig)
      .then((result) => result.map((x) => x.entities));
  };

  Vue.prototype.$labordatApi = http;

  function clearMsalFromStorage(storage: Storage) {
    for (let i = storage.length - 1; i >= 0; i--) {
      const key = storage.key(i);
      if (
        key != null &&
        (key.indexOf('msal.acquireTokenUser') === 0 || key.indexOf('msal.token.renew') === 0)
      ) {
        storage.removeItem(key);
      }
    }
  }
}
