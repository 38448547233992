






















import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import TypeaheadMixin from '@/mixins/TypeaheadMixin';
import { RemoteData, identity } from 'rey-frontend-fp';
import ICustomer from '@/models/ICustomer'; //NOSONAR

export default mixins(TypeaheadMixin).extend({
  name: 'crm-customer-typeahead',
  props: {
    value: Number,
    title: String,
    transformator: Function
  },
  data: function () {
    return {
      crmCustomersRd: RemoteData.notAsked<Array<ICustomer>, Error>(),
      crmCustomerRd: RemoteData.notAsked<ICustomer, Error>(),
      // data allows method properties to be accessed
      updateCustomerTypeAhead: _.debounce(
        function (searchString: string) {
          // @ts-ignore
          this.loadCrmCustomers(searchString);
        }.bind(this),
        500
      )
    };
  },
  mounted: async function () {
    await this.refreshValue();
  },
  computed: {
    dataTransformator: function () {
      return this.transformator || identity;
    }
  },
  watch: {
    value: async function () {
      await this.refreshValue();
    }
  },
  methods: {
    refreshValue: async function () {
      this.crmCustomerRd = this.value
        ? await this.loadCrmCustomer(this.value)
        : RemoteData.notAsked();

      // Sometimes the typeahead is not mounted yet.
      if (this.$refs.typeahead) {
        const value = this.crmCustomerRd.map(this.customerTypeAheadSerializer).withDefault('');
        // @ts-ignore
        this.$refs.typeahead.inputValue = value;
      }
    },
    onHitCustomer: async function (crmCustomer: ICustomer) {
      this.cancelCustomerTypeAheadSearch();
      this.crmCustomerRd = await this.loadCrmCustomer(crmCustomer.kundennr);

      if (this.crmCustomerRd.hasData()) {
        this.$emit('input', +this.crmCustomerRd.getData().kundennr);
        this.$emit('hit', this.crmCustomerRd.getData());
      }
    },
    cancelCustomerTypeAheadSearch: function () {
      _.debounce(
        function () {
          // @ts-ignore
          this.updateCustomerTypeAhead.cancel();
        }.bind(this),
        100
      )();
    },
    customerTypeAheadSerializer: function (customer: ICustomer) {
      return [
        customer.kundennr,
        customer.firma,
        customer.zusatz,
        customer.nachname,
        customer.vorname,
        customer.ort
      ]
        .filter((s) => s && s !== 'null')
        .join(' - ');
    },
    loadCrmCustomers: async function (searchString: string) {
      this.crmCustomersRd = await this.$labordatApi.getRd(
        `/api/crm/customers?search=${searchString}`
      );
    },
    loadCrmCustomer: function (customerNumber: number): Promise<RemoteData<ICustomer, Error>> {
      return this.$labordatApi.getRd(`/api/crm/customers/${customerNumber}`);
    }
  }
});
