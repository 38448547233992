










































































import Vue from 'vue';
import $ from 'jquery';
import { RemoteData } from 'rey-frontend-fp';
import IAnalysisParameter from '@/models/IAnalysisParameter';
import IAnion from '@/models/IAnion';
import ICation from '@/models/ICation';

export default Vue.extend({
  name: 'ionic-balance-modal',
  props: {
    basePath: String
  },
  data() {
    return {
      analysisParametersRd: RemoteData.notAsked<Array<IAnalysisParameter>, Error>(),
      anionsRd: RemoteData.notAsked<Array<IAnion>, Error>(),
      cationsRd: RemoteData.notAsked<Array<ICation>, Error>(),
      sumCations: 0 as number,
      sumAnions: 0 as number,
      ionicBalance: 0 as number,
      sumAnalyts: 0 as number
    };
  },
  mounted: async function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    $('#ionicBalanceModal').on('show.bs.modal', async function () {
      await vm.loadAnions();
      await vm.loadCations();
      await vm.loadAnalysisParameters();
      vm.calculateIonicBalance();
    });
    $('#ionicBalanceModal').on('hide.bs.modal', function () {
      vm.$emit('closed');
    });
  },
  methods: {
    loadAnions: async function () {
      this.anionsRd = await this.$labordatApi.getRd(`/api/anion`);
    },
    loadCations: async function () {
      this.cationsRd = await this.$labordatApi.getRd(`/api/cation`);
    },
    loadAnalysisParameters: async function () {
      this.analysisParametersRd = await this.$labordatApi.getRd(
        `${this.basePath}/analysisparameters`
      );
    },
    calculateIonicBalance: function () {
      if (
        this.analysisParametersRd.hasData() &&
        this.anionsRd.hasData() &&
        this.cationsRd.hasData()
      ) {
        const parameters = this.analysisParametersRd.getData().filter(
          (ap) =>
            // @ts-ignore
            this.anionsRd.getData().entities.find((a) => a.name === ap.nameDe) !== undefined ||
            // @ts-ignore
            this.cationsRd.getData().entities.find((c) => c.name === ap.nameDe) !== undefined
        );
        let anions = 0.0;
        let cations = 0.0;
        let analyts = 0.0;
        for (const parameter of parameters) {
          ({ anions, cations, analyts } = this.calculateIonicBalanceValuesForParameter(
            parameter,
            anions,
            cations,
            analyts
          ));
        }

        this.sumAnions = anions;
        this.sumCations = cations;
        this.sumAnalyts = analyts;
        this.ionicBalance = (100.0 * (cations - anions)) / (cations + anions);
        if (isNaN(this.ionicBalance)) {
          this.ionicBalance = 0;
        }
      }
    },
    calculateIonicBalanceValuesForParameter(
      parameter: IAnalysisParameter,
      anions: number,
      cations: number,
      analyts: number
    ) {
      if (!isNaN(+parameter.measurement)) {
        // @ts-ignore
        const anion = this.anionsRd.getData().entities.find((a) => a.name === parameter.nameDe);
        if (anion !== undefined) {
          anions += (+parameter.measurement * 1.0) / anion.factor;
        }

        const cation = this.cationsRd
          .getData()
          // @ts-ignore
          .entities.find((c) => c.name === parameter.nameDe);
        if (cation !== undefined) {
          cations += (+parameter.measurement * 1.0) / cation.factor;
        }

        analyts += +parameter.measurement;
      }
      return { anions, cations, analyts };
    }
  }
});
