/* eslint-disable max-classes-per-file */

type InvoicingTemplateState = 'new' | 'existing' | 'none';

export interface IInvoicingTemplateState {
  kind: InvoicingTemplateState;
  id: number | undefined;
}

export class NewInvoicingTemplate implements IInvoicingTemplateState {
  public kind: InvoicingTemplateState = 'new';
  public id = 0;
}

export class ExistingInvoicingTemplate implements IInvoicingTemplateState {
  public kind: InvoicingTemplateState = 'existing';
  public id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class NoInvoicingTemplate implements IInvoicingTemplateState {
  public kind: InvoicingTemplateState = 'none';
  public id: number | undefined = undefined;
}
