




























































import $ from 'jquery';
import mixins from 'vue-typed-mixins';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import { UpdatableRemoteData } from 'rey-frontend-fp';
// @ts-ignore
import { Loading } from 'rey-vue-common';
import ReportPictureDetailsForm from '@/views/Templates/ReportPicture/ReportPictureDetailsForm.vue';
import IReportPicture from '@/models/IReportPicture'; //NOSONAR

export default mixins(FileDownloadMixin).extend({
  name: 'report-picture-details-modal',
  components: { Loading, ReportPictureDetailsForm },
  props: {
    reportPictureId: Number
  },
  data() {
    return {
      reportPictureRd: UpdatableRemoteData.notAsked<IReportPicture, Error>(),
      isLoading: false
    };
  },
  mounted: async function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    $('#reportPictureDetailsModal').on('show.bs.modal', function () {
      if (vm.reportPictureId === 0) {
        vm.reportPictureRd = UpdatableRemoteData.notCreated({} as IReportPicture);
        vm.reportPictureRd.getData().comment = 'Stimmungsbild von Adobe Stock';
        vm.reportPictureRd.getData().commentColor = '#FFFFFF';
      }
    });
    $('#reportPictureDetailsModal').on('hide.bs.modal', function () {
      // @ts-ignore
      vm.$refs.reportPictureDetailsForm.clearNewTags();
      vm.$emit('closed');
    });
  },
  watch: {
    reportPictureId: async function (value) {
      await this.loadReportPicture(value);
    }
  },
  methods: {
    loadReportPicture: async function (reportPictureId: number) {
      this.isLoading = true;
      if (reportPictureId === 0) {
        this.reportPictureRd = UpdatableRemoteData.notCreated({} as IReportPicture);
        this.reportPictureRd.getData().comment = 'Stimmungsbild von Adobe Stock';
        this.reportPictureRd.getData().commentColor = '#FFFFFF';
      } else {
        this.reportPictureRd = await this.$labordatApi.getUrd(
          `/api/reportpicture/${reportPictureId}/expanded`
        );
      }
      this.isLoading = false;
    },
    deleteReportPicture: async function () {
      if (this.reportPictureId === 0) {
        return;
      }

      // Check if any report is using this picture
      try {
        const hasResults = await this.$labordatApi
          .get(`/api/reportpicture/${this.reportPictureRd.getData().id}/isused`)
          .then((response) => {
            return response.data;
          });
        if (hasResults) {
          alert(
            'Das Berichtsbild kann nicht gelöscht werden, da es in einem Bericht verwendet wird.'
          );
          return;
        }
      } catch (error) {
        this.reportPictureRd = UpdatableRemoteData.updateFailure(
          this.reportPictureRd.getData(),
          error as Error
        );
        return;
      }

      if (confirm('Wollen Sie das Berichtsbild wirklich löschen?') === false) {
        return;
      }

      try {
        const id = this.reportPictureRd.getData().id;
        this.reportPictureRd = UpdatableRemoteData.updating(this.reportPictureRd.getData());
        await this.$labordatApi.delete(
          `/api/file/deletepicture?pictureType=Report&pictureId=${id}`
        );
        await this.$labordatApi.delete(`/api/reportpicture/${id}/cropped`);
        await this.$labordatApi.delete(`/api/reportpicture/${this.reportPictureRd.getData().id}`, {
          data: this.reportPictureRd.getData()
        });
        await this.$labordatApi.get(`/api/reportpicture/${id}/updatetags`);

        // @ts-ignore
        $('#reportPictureDetailsModal').modal('hide');
      } catch (error) {
        this.reportPictureRd = UpdatableRemoteData.updateFailure(
          this.reportPictureRd.getData(),
          error as Error
        );
      }
    },
    createReportPicture: async function () {
      const reportPicture = this.reportPictureRd.getData();
      this.reportPictureRd = UpdatableRemoteData.updating(reportPicture);
      try {
        await this.$labordatApi
          .post(`/api/reportpicture`, reportPicture)
          .then((response) => response.data as IReportPicture)
          .then(async (data) => {
            await this.$labordatApi.get(`/api/reportpicture/${data.id}/reorder`);
            await this.$labordatApi.get(`/api/reportpicture/${data.id}/updatetags`);
            this.$emit('created', data.id);
            return data;
          });
      } catch (error) {
        this.reportPictureRd = UpdatableRemoteData.updateFailure<IReportPicture, Error>(
          reportPicture,
          error as Error
        );
      }
    },
    updateReportPicture: async function () {
      const reportPicture = this.reportPictureRd.getData();
      this.reportPictureRd = UpdatableRemoteData.updating(reportPicture);
      this.reportPictureRd = await this.$labordatApi
        .put(`/api/reportpicture/${reportPicture.id}`, reportPicture)
        .then((response) => response.data as IReportPicture)
        .then(async (data) => {
          await this.$labordatApi.get(`/api/reportpicture/${reportPicture.id}/reorder`);
          await this.$labordatApi.get(`/api/reportpicture/${reportPicture.id}/updatetags`);
          return UpdatableRemoteData.success<IReportPicture, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IReportPicture, Error>(reportPicture, error as Error)
        );
    },
    updateReportPictureCommentColor: async function (color: string) {
      const reportPicture = this.reportPictureRd.getData();
      this.reportPictureRd = UpdatableRemoteData.updating(reportPicture);
      reportPicture.commentColor = color;

      this.reportPictureRd = await this.$labordatApi
        .put(`/api/reportpicture/${reportPicture.id}`, reportPicture)
        .then((response) => response.data as IReportPicture)
        .then((data) => {
          return UpdatableRemoteData.success<IReportPicture, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IReportPicture, Error>(reportPicture, error as Error)
        );
    },
    // eslint-disable-next-line @typescript-eslint/ban-types
    updateReportPictureCropBox: async function (big: object, small: object) {
      const reportPicture = this.reportPictureRd.getData();
      this.reportPictureRd = UpdatableRemoteData.updating(reportPicture);

      reportPicture.cropBoxBig = JSON.stringify(big);
      reportPicture.cropBoxSmall = JSON.stringify(small);

      this.reportPictureRd = await this.$labordatApi
        .put(`/api/reportpicture/${reportPicture.id}`, reportPicture)
        .then((response) => response.data as IReportPicture)
        .then(async (data) => {
          await this.updateCroppedPictures();
          return UpdatableRemoteData.success<IReportPicture, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IReportPicture, Error>(reportPicture, error as Error)
        );
    },
    updateCroppedPictures: async function () {
      await this.$labordatApi.get(`/api/reportpicture/${this.reportPictureRd.getData().id}/recrop`);
    },
    upload: async function (file: File) {
      const reportPicture = this.reportPictureRd.getData();
      this.reportPictureRd = UpdatableRemoteData.updating(reportPicture);
      try {
        const formData = new FormData();
        formData.append('pictureId', reportPicture.id.toString());
        formData.append('file', file);
        this.reportPictureRd = await this.$labordatApi
          .post(`/api/file/uploadpicture`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => response.data as IReportPicture)
          .then(async (data) => {
            await this.updateCroppedPictures();
            return UpdatableRemoteData.success<IReportPicture, Error>(data);
          });
      } catch (error) {
        this.reportPictureRd = UpdatableRemoteData.updateFailure<IReportPicture, Error>(
          reportPicture,
          error as Error
        );
      }
    },
    download: async function () {
      const reportPicture = this.reportPictureRd.getData();
      try {
        await this.$labordatApi
          .get(`/api/file/downloadpicture?pictureId=${reportPicture.id}`, {
            responseType: 'arraybuffer'
          })
          .then((response) => {
            this.downloadFile(response, 'Berichtsbild.png');
          });
      } catch (error) {
        this.reportPictureRd = UpdatableRemoteData.updateFailure<IReportPicture, Error>(
          reportPicture,
          error as Error
        );
      }
    }
  }
});
