import IInvoiceSummary from './IInvoiceSummary';
import IInvoiceSummaryDepartment from './IInvoiceSummaryDepartment';

export default class InvoiceSummary implements IInvoiceSummary {
  public caption = '';
  public description = '';
  public sortOrder = 0;
  public departments: IInvoiceSummaryDepartment[] = [];
  public getDepartment: { [key: string]: IInvoiceSummaryDepartment } = {};

  public static FromInterface(summary: IInvoiceSummary): InvoiceSummary {
    const invoiceSummary = Object.assign(new InvoiceSummary(), summary);
    for (const department of summary.departments) {
      invoiceSummary.getDepartment[department.code] = department;
    }
    return invoiceSummary;
  }
}
