/* eslint-disable max-classes-per-file */

type ReportPictureCategoryState = 'new' | 'existing' | 'none';

export interface IReportPictureCategoryState {
  kind: ReportPictureCategoryState;
  id: number | undefined;
  parentId: number | undefined;
}

export class NewReportPictureCategory implements IReportPictureCategoryState {
  public kind: ReportPictureCategoryState = 'new';
  public id: number | undefined = undefined;
  public parentId: number | undefined = undefined;
  constructor(parentId?: number | undefined) {
    this.parentId = parentId;
  }
}

export class ExistingReportPictureCategory implements IReportPictureCategoryState {
  public kind: ReportPictureCategoryState = 'existing';
  public id: number;
  public parentId: number | undefined;

  constructor(id: number) {
    this.id = id;
  }
}

export class NoReportPictureCategory implements IReportPictureCategoryState {
  public kind: ReportPictureCategoryState = 'none';
  public id: number | undefined = undefined;
  public parentId: number | undefined = undefined;
}
