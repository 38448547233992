import Vue from 'vue';

export default Vue.extend({
  methods: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    enumToObjectArray: (enumType: object): Array<{ id: number; name: string }> =>
      Object.keys(enumType)
        // @ts-ignore
        .filter((key) => typeof enumType[key] === 'number')
        // @ts-ignore
        .map((key) => ({ id: enumType[key] as number, name: key })),
    validateForm: (form: HTMLFormElement | HTMLInputElement): boolean => {
      if (form.checkValidity() === false) {
        form.reportValidity();
        return false;
      }
      return true;
    }
  }
});
