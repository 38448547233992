
















import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
export default mixins(SettingsMixin).extend({});
