








































import mixins from 'vue-typed-mixins';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import { toDateTimeString } from 'rey-vue-smarttable';

export default mixins(SharedDataMixin).extend({
  data: function () {
    return {
      licenses: Array<{ component: string; license: string }>()
    };
  },
  created: async function () {
    this.licenses.push({ component: '@aspnet/signalr', license: 'Apache-2.0' });
    this.licenses.push({
      component: '@fortawesome/fontawesome-free',
      license: 'CC BY 4.0 & SIL OFL 1.1 & MIT'
    });
    this.licenses.push({ component: '@types/adal-angular', license: 'MIT' });
    this.licenses.push({ component: '@types/jest', license: 'MIT' });
    this.licenses.push({ component: '@types/jquery', license: 'MIT' });
    this.licenses.push({ component: '@types/lodash', license: 'MIT' });
    this.licenses.push({ component: '@types/qs', license: 'MIT' });
    this.licenses.push({ component: '@typescript-eslint/eslint-plugin', license: 'MIT' });
    this.licenses.push({ component: '@typescript-eslint/parser', license: 'BSD-2-Clause' });
    this.licenses.push({ component: '@vue/cli-plugin-babel', license: 'MIT' });
    this.licenses.push({ component: '@vue/cli-plugin-eslint', license: 'MIT' });
    this.licenses.push({ component: '@vue/cli-plugin-typescript', license: 'MIT' });
    this.licenses.push({ component: '@vue/cli-plugin-unit-jest', license: 'MIT' });
    this.licenses.push({ component: '@vue/cli-service', license: 'MIT' });
    this.licenses.push({ component: '@vue/eslint-config-prettier', license: 'MIT' });
    this.licenses.push({ component: '@vue/eslint-config-typescript', license: 'MIT' });
    this.licenses.push({ component: '@vue/test-utils', license: 'MIT' });
    this.licenses.push({ component: 'axios', license: 'MIT' });
    this.licenses.push({ component: 'babel-core', license: 'MIT' });
    this.licenses.push({ component: 'bootstrap', license: 'MIT' });
    this.licenses.push({ component: 'core-js', license: 'MIT' });
    this.licenses.push({ component: 'eslint', license: 'MIT' });
    this.licenses.push({ component: 'eslint-config-prettier', license: 'MIT' });
    this.licenses.push({ component: 'eslint-plugin-prettier', license: 'MIT' });
    this.licenses.push({ component: 'eslint-plugin-vue', license: 'MIT' });
    this.licenses.push({ component: 'fibers', license: 'MIT' });
    this.licenses.push({ component: 'jquery', license: 'MIT' });
    this.licenses.push({ component: 'json.date-extensions', license: 'MIT' });
    this.licenses.push({ component: 'lodash', license: 'MIT' });
    this.licenses.push({ component: 'moment', license: 'MIT' });
    this.licenses.push({ component: 'node-sass', license: 'MIT' });
    this.licenses.push({ component: 'popper.js', license: 'MIT' });
    this.licenses.push({ component: 'prettier', license: 'MIT' });
    this.licenses.push({ component: 'resize-observer-polyfill', license: 'MIT' });
    this.licenses.push({ component: 'sass-loader', license: 'MIT' });
    this.licenses.push({ component: 'sticky-events', license: 'MIT' });
    this.licenses.push({ component: 'ts-jest', license: 'MIT' });
    this.licenses.push({ component: 'typescript', license: 'Apache-2.0' });
    this.licenses.push({ component: 'vue', license: 'MIT' });
    this.licenses.push({ component: 'vue-adal', license: 'MIT' });
    this.licenses.push({ component: 'vue-color', license: 'MIT' });
    this.licenses.push({ component: 'vue-cli-plugin-webpack-bundle-analyzer', license: 'MIT' });
    this.licenses.push({ component: 'vue-cropperjs', license: 'MIT' });
    this.licenses.push({ component: 'vue-router', license: 'MIT' });
    this.licenses.push({ component: 'vue-template-compiler', license: 'MIT' });
    this.licenses.push({ component: 'vue-typed-mixins', license: 'MIT' });
    this.licenses.push({ component: 'vuex', license: 'MIT' });
    this.licenses.push({ component: 'webpack', license: 'MIT' });
    this.licenses.push({ component: 'webpack-bundle-analyzer', license: 'MIT' });
    this.licenses.push({ component: 'Audit.EntityFramework.Core', license: 'MIT' });
    this.licenses.push({
      component: 'AutoMapper.Extensions.Microsoft.DependencyInjection',
      license: 'MIT'
    });
    this.licenses.push({ component: 'Clippit', license: 'MIT' });
    this.licenses.push({ component: 'CsvHelper', license: 'MS-PL | Apache-2.0 ' });
    this.licenses.push({ component: 'DocumentFormat.OpenXml', license: 'MIT' });
    this.licenses.push({ component: 'MathParser.org-mXparser', license: 'Simplified BSD' });
    this.licenses.push({ component: 'Microsoft.AspNet.WebApi.Client', license: 'Apache-2.0' });
    this.licenses.push({
      component: 'Microsoft.AspNetCore.Mvc.NewtonsoftJson',
      license: 'Apache-2.0'
    });
    this.licenses.push({ component: 'Microsoft.EntityFrameworkCore', license: 'Apache-2.0' });
    this.licenses.push({
      component: 'Microsoft.EntityFrameworkCore.Design',
      license: 'Apache-2.0'
    });
    this.licenses.push({
      component: 'Microsoft.EntityFrameworkCore.Relational',
      license: 'Apache-2.0'
    });
    this.licenses.push({
      component: 'Microsoft.EntityFrameworkCore.SqlServer',
      license: 'Apache-2.0'
    });
    this.licenses.push({ component: 'Microsoft.EntityFrameworkCore.Tools', license: 'Apache-2.0' });
    this.licenses.push({
      component: 'Microsoft.Extensions.Hosting.Abstractions',
      license: 'Apache-2.0'
    });
    this.licenses.push({ component: 'Microsoft.Extensions.Logging', license: 'Apache-2.0' });
    this.licenses.push({ component: 'Microsoft.Extensions.Options', license: 'Apache-2.0' });
    this.licenses.push({ component: 'Microsoft.Graph', license: 'MIT' });
    this.licenses.push({
      component: 'Microsoft.IdentityModel.Clients.ActiveDirectory',
      license: 'MIT'
    });
    this.licenses.push({ component: 'Newtonsoft.Json', license: 'MIT' });
    this.licenses.push({ component: 'Serilog.AspNetCore', license: 'Apache-2.0' });
    this.licenses.push({ component: 'Serilog.Settings.Configuration', license: 'Apache-2.0' });
    this.licenses.push({ component: 'Serilog.Sinks.Console', license: 'Apache-2.0' });
    this.licenses.push({ component: 'Serilog.Sinks.File', license: 'Apache-2.0' });
    this.licenses.push({ component: 'System.Data.OleDb', license: 'MIT' });
    this.licenses.push({ component: 'System.Drawing.Common', license: 'MIT' });
  },
  computed: {
    applicationVersion: function (): string {
      return process.env.VUE_APP_VERSION + '';
    },
    buildTimestamp: function (): string {
      return toDateTimeString(new Date(this.$buildTimestamp));
    }
  }
});
