enum ValidationStatusType {
  Unvalidated = 0,
  Valid = 1,
  Invalid = 2,
  NotValidatable = 3,
  NoValue = 4,
  Erroneous = 5,
  NoGuidanceValue = 6
}

export default ValidationStatusType;
