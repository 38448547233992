import IReport from './IReport';
import ReportStatus from './ReportStatus';
import ReportSorting from './ReportSorting';

export default class Report implements IReport {
  public static FromInterface(report: IReport, orderId: string): Report {
    return Object.assign(new Report(orderId), report);
  }

  public id: number | undefined;
  public orderId: string;
  public variant: string | undefined;
  public version = 0;
  public reportTemplateId: number | undefined;
  public reportDate: Date | undefined;
  public intendedFirstSignee: string | undefined;
  public realFirstSignee: string | undefined;
  public realFirstSigneeName: string | undefined;
  public realFirstSigneeJobTitle: string | undefined;
  public firstSignatureTimeStamp: Date | undefined;
  public intendedSecondSignee: string | undefined;
  public realSecondSignee: string | undefined;
  public realSecondSigneeName: string | undefined;
  public realSecondSigneeJobTitle: string | undefined;
  public secondSignatureTimeStamp: Date | undefined;
  public reportPictureId: number | undefined;
  public comment: string | undefined;
  public commentDelta: string | undefined;
  public revisionComment: string | undefined;
  public revisionCommentDelta: string | undefined;
  public sampleSelector: string | undefined;
  public changedSamples: string | undefined;
  public status: ReportStatus | undefined;
  public sorting: ReportSorting | undefined;

  public get reportId(): string {
    return (
      this.orderId +
      (this.variant !== null ? '/' + this.variant : '') +
      (this.version > 0 ? '-K' + this.version : '')
    );
  }

  constructor(orderId: string) {
    this.status = ReportStatus.New;
    this.orderId = orderId;
  }
}
