import Language from '../../Language';
import IPredefinedReportAttachmentFile from './IPredefinedReportAttachmentFile';

export default class PredefinedReportAttachmentFile implements IPredefinedReportAttachmentFile {
  public id: number;
  public name: string | undefined;
  public language: Language | undefined;

  public static Empty(): PredefinedReportAttachmentFile {
    return new PredefinedReportAttachmentFile(0);
  }

  constructor(id: number) {
    this.id = id;
  }
}
