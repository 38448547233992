











































































































import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
// @ts-ignore
import { quillEditor } from 'rey-vue-quill-editor';
import QuillOptions from '@/models/QuillOptions';

export default mixins(CreateAndUpdateForm, SharedDataMixin).extend({
  components: { quillEditor },
  name: 'sample-basis-of-validation-details-form',
  data() {
    return {
      hasApiError: false,
      quillOptions: QuillOptions.config
    };
  },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.sampleBasisOfValidationForm);
    (this.$refs.sampleBasisOfValidationName as HTMLInputElement).focus();
  },
  methods: {}
});
