

























import IReportCompilationSessionDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IReportCompilationSessionDto';
import CompilingReportsDialog from './CompilingReportsDialog.vue';
import CompilingMailDialog from './CompilingMailDialog.vue';
import FinalizingWorkflowDialog from './FinalizingWorkflowDialog.vue';
import { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import IFinalizeAutomaticReportSendingReportWorkflowRequestDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IFinalizeAutomaticReportSendingReportWorkflowRequestDto';

export type WorkflowSteps = 'CompilingReports' | 'CompilingMail' | 'FinalizingWorkflow';
export default mixins().extend({
  props: {
    orderId: String,
    reportId: String,
    startingAtStep: {
      type: String as PropType<WorkflowSteps>,
      default: 'CompilingReports' as WorkflowSteps
    }
  },
  components: { CompilingReportsDialog, CompilingMailDialog, FinalizingWorkflowDialog },
  data() {
    return {
      initialReportCompilationSession: null as IReportCompilationSessionDto | null,
      currentlyAtStep: null as null | WorkflowSteps,
      finalizeAutomaticReportSendingReportWorkflowRequestDto:
        null as null | IFinalizeAutomaticReportSendingReportWorkflowRequestDto
    };
  },
  mounted() {
    this.updateStateAndMoveTo(this.startingAtStep);
  },
  computed: {
    reportCompilationSessionId(): number {
      return this.initialReportCompilationSession?.id ?? -1;
    }
  },
  methods: {
    moveOnToCompilingMailDialog() {
      this.updateStateAndMoveTo('CompilingMail');
    },
    goBackToCompilingReportsDialog() {
      this.updateStateAndMoveTo('CompilingReports');
    },
    moveOnToFinalizingWorkflowDialog(
      data: IFinalizeAutomaticReportSendingReportWorkflowRequestDto
    ) {
      this.finalizeAutomaticReportSendingReportWorkflowRequestDto = data;
      this.updateStateAndMoveTo('FinalizingWorkflow');
    },
    cancelWorkflow() {
      if (
        confirm('Wollen Sie den Dialog abbrechen?\nIhr Fortschritt wird dadurch verworfen.') !==
        true
      ) {
        return;
      }

      if (this.reportCompilationSessionId != -1) {
        this.$labordatApi.delete(
          `/api/AutomaticReportSendingReportCompilationSessions/${this.reportCompilationSessionId}`
        );
      }

      this.$router.push({
        name: 'ReportDetails',
        params: {
          reportId: this.reportId
        }
      });
    },
    async updateStateAndMoveTo(moveTo: WorkflowSteps) {
      this.initialReportCompilationSession = await this.$labordatApi
        .post<IReportCompilationSessionDto>(
          `/api/AutomaticReportSendingReportCompilationSessions?reportId=${this.reportId}`
        )
        .then((response) => response.data);
      this.currentlyAtStep = moveTo;
    }
  }
});
