































































































































































import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import { UpdatableRemoteData, RemoteData } from 'rey-frontend-fp';
import IAnalysisGroupParameter from '@/models/IAnalysisGroupParameter';
import IAnalysisGroupLayoutElement from '@/models/IAnalysisGroupLayoutElement';
import IAnalysisGroupParameterTableItem from '@/models/IAnalysisGroupParameterTableItem';
import LayoutElementType from '@/models/LayoutElementType';
import IAnalysisParameterDefinition from '@/models/IAnalysisParameterDefinition';
import AnalysisGroupParameter from '@/models/AnalysisGroupParameter';
import { SmartTableTable, Config, Sorting, State, Column, hasValue } from 'rey-vue-smarttable';
import IDimension from '@/models/IDimension';
import AnalysisParameterDefinitionTypeahead from '@/components/AnalysisParameterDefinitionTypeahead.vue';
import IUpdatedAnalysisGroupParameters from '@/models/IUpdatedAnalysisGroupParameters';
import UncertaintyOfMeasurement from '@/models/UncertaintyOfMeasurementType';

export default mixins(SharedDataMixin, ValidatorMixin).extend({
  components: { AnalysisParameterDefinitionTypeahead, SmartTableTable },
  name: 'analysis-group-parameters',
  props: {
    analysisGroupCode: String
  },
  data: function () {
    return {
      analysisGroupParametersRd: UpdatableRemoteData.notAsked<
        Array<IAnalysisGroupParameter>,
        Error
      >(),
      analysisGroupLayoutElementsRd: UpdatableRemoteData.notAsked<
        Array<IAnalysisGroupLayoutElement>,
        Error
      >(),
      analysisParameterDefinitionsRd: RemoteData.notAsked<
        Array<IAnalysisParameterDefinition>,
        Error
      >(),
      dimensionsRd: RemoteData.notAsked<Array<IDimension>, Error>(),
      smartTableConfig: undefined as Config | undefined,
      smartTableState: new State([new Sorting('rapportPosition', 'ascending')]),
      smartTableSelected: undefined as IAnalysisGroupParameterTableItem | undefined,
      selectedAnalysisElement: undefined as IAnalysisParameterDefinition | undefined,
      analysisElementListFilter: ''
    };
  },
  created: async function () {
    this.initializeSmartTable();
    await this.loadAnalysisGroupParameters();
    await this.loadAnalysisGroupLayoutElements();
    await this.loadDimensions();
    this.updateDimensionColumnDropdownOptions();
    this.updateDepartmentColumnDropdownOptions();
    await this.loadAnalysisParameterDefinitions();
  },
  computed: {
    smartTableData: function () {
      const analysisGroupParameters: IAnalysisGroupParameterTableItem[] =
        this.analysisGroupParametersRd
          .map((array) =>
            array.map((a: IAnalysisGroupParameter) => {
              (a as IAnalysisGroupParameterTableItem).discriminator = 'AnalysisGroupParameter';
              (a as IAnalysisGroupParameterTableItem).type = LayoutElementType.Parameter;
              return a as IAnalysisGroupParameterTableItem;
            })
          )
          .withDefault([]);

      const layoutElements: IAnalysisGroupParameterTableItem[] = this.analysisGroupLayoutElementsRd
        .map((array) =>
          array.map((a: IAnalysisGroupLayoutElement) => {
            (a as IAnalysisGroupParameterTableItem).discriminator = 'AnalysisGroupLayoutElement';
            return a as IAnalysisGroupParameterTableItem;
          })
        )
        .withDefault([]);

      let rows = analysisGroupParameters.concat(layoutElements);
      if (this.smartTableConfig) {
        rows = this.smartTableState.sortAndFilterLocal(rows, this.smartTableConfig);
      }
      return rows;
    },
    analysisElements: function (): IAnalysisParameterDefinition[] {
      const analysisParameters: IAnalysisParameterDefinition[] =
        this.analysisParameterDefinitionsRd.withDefault([]);
      return _.sortBy(analysisParameters, (x) => x.rapportPosition);
    },
    filteredAnalysisElements: function (): IAnalysisParameterDefinition[] {
      return this.analysisElements.filter(
        (ap) => ap.code.toLowerCase().indexOf(this.analysisElementListFilter.toLowerCase()) >= 0
      );
    }
  },
  methods: {
    initializeSmartTable: function () {
      const isAnalysisParameter = (x: IAnalysisGroupParameterTableItem) =>
        x.discriminator === 'AnalysisGroupParameter';
      const isPriceOrSumLayoutElement = (x: IAnalysisGroupParameterTableItem) =>
        x.discriminator === 'AnalysisGroupLayoutElement' &&
        (x.type === LayoutElementType.Price || x.type === LayoutElementType.Sum);

      this.smartTableConfig = new Config(
        (row: IAnalysisGroupParameterTableItem) => `${row.type}-${row.id}`,
        [
          new Column({
            title: '',
            fieldName: 'type',
            fieldType: 'numeric',
            editable: () => false,
            converter: LayoutElementType.toGerman
          }),
          new Column({
            title: 'Wert',
            fieldName: 'value',
            fieldType: 'string',
            editable: (x: IAnalysisGroupParameterTableItem) =>
              x.discriminator === 'AnalysisGroupLayoutElement' &&
              x.type !== LayoutElementType.PageBreak
          }),
          new Column({
            title: 'Code',
            fieldName: 'analysisParameterDefinitionCode',
            fieldType: 'string'
          }),
          new Column({
            title: 'Bezeichnung',
            fieldName: 'nameDe',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Tarif',
            fieldName: 'priceInChf',
            fieldType: 'numeric',
            editable: () => false,
            colStyling: 'text-right'
          }),
          new Column({
            title: 'Dimension',
            fieldName: 'dimensionCode',
            fieldType: 'dropdown',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Berech.pos.',
            fieldName: 'calculationOrder',
            fieldType: 'numeric',
            editable: isAnalysisParameter,
            validator: hasValue
          }),
          new Column({
            title: 'Rapportpos.',
            fieldName: 'rapportPosition',
            fieldType: 'numeric',
            editable: () => true,
            validator: hasValue
          }),
          new Column({
            title: 'Drucken',
            fieldName: 'showInPrint',
            fieldType: 'boolean',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Präzision',
            fieldName: 'precision',
            fieldType: 'numeric',
            editable: isAnalysisParameter,
            validator: hasValue
          }),
          new Column({
            title: 'Richtwert',
            fieldName: 'guidanceValue',
            fieldType: 'string',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Standardwert Min.',
            fieldName: 'standardValueMin',
            fieldType: 'numeric',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Standardwert Max.',
            fieldName: 'standardValueMax',
            fieldType: 'numeric',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Art der Messunsicherheit',
            fieldName: 'uncertaintyOfMeasurementType',
            fieldType: 'dropdown',
            editable: isAnalysisParameter,
            dropdownOptions: [
              { id: UncertaintyOfMeasurement.Percentage, title: 'Prozentual' },
              { id: UncertaintyOfMeasurement.Absolute, title: 'Absolut' }
            ]
          }),
          new Column({
            title: 'Messunsicherheit',
            fieldName: 'uncertaintyOfMeasurement',
            fieldType: 'numeric',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Nachweisgrenze',
            fieldName: 'limitOfDetection',
            fieldType: 'string',
            editable: isAnalysisParameter,
            validator: this.isNumeric
          }),
          new Column({
            title: 'Bestimmungsgrenze',
            fieldName: 'limitOfDetermination',
            fieldType: 'string',
            editable: isAnalysisParameter,
            validator: this.isNumeric
          }),
          new Column({
            title: 'Akkreditiert',
            fieldName: 'accredited',
            fieldType: 'boolean',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'GMP',
            fieldName: 'goodManufacturingPractice',
            fieldType: 'boolean',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Tarif Null',
            fieldName: 'zeroPrice',
            fieldType: 'boolean',
            editable: isAnalysisParameter
          }),
          new Column({
            title: 'Abteilung',
            fieldName: 'departmentCode',
            fieldType: 'dropdown',
            editable: (x) => isPriceOrSumLayoutElement(x),
            validator: hasValue
          }),
          new Column({
            title: 'Kommentar',
            fieldName: 'comment',
            fieldType: 'string',
            editable: (x) => isPriceOrSumLayoutElement(x)
          })
        ],
        undefined,
        undefined,
        (row) => ({
          'bg-layout-element':
            (row as IAnalysisGroupParameterTableItem).type !== LayoutElementType.Parameter
        })
      );
    },
    addLayoutElement: async function (
      layoutElementType: 'PageBreak' | 'Title' | 'Text' | 'Sum' | 'Price'
    ) {
      const type = LayoutElementType.parse(layoutElementType);
      if (type === undefined) {
        return;
      }

      const position =
        this.smartTableSelected !== undefined
          ? (this.smartTableSelected.rapportPosition || 0) + 1
          : (_.max(this.analysisGroupParametersRd.getData().map((ap) => ap.rapportPosition)) || 0) +
            1;

      const layoutElement = {
        type: type,
        analysisGroupCode: this.analysisGroupCode,
        rapportPosition: position
      } as IAnalysisGroupLayoutElement;
      this.setLayoutElementDefaultValue(layoutElement, layoutElementType);

      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisGroupParameters = await this.$labordatApi
          .post('/api/analysisgrouplayoutelementmasterdata', layoutElement)
          .then((response) => response.data as IUpdatedAnalysisGroupParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    addAnalysisParameter: async function (analysisParameter: IAnalysisParameterDefinition) {
      const analysisGroupParameter = new AnalysisGroupParameter(
        analysisParameter,
        this.analysisGroupCode
      );

      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisGroupParameters = await this.$labordatApi
          .post('/api/analysisgroupparametermasterdata', analysisGroupParameter)
          .then((response) => response.data as IUpdatedAnalysisGroupParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    addAnalysisParameterBulk: async function (analysisParameters: IAnalysisParameterDefinition[]) {
      const analysisGroupParameters = analysisParameters.map(
        (analysisParameter) => new AnalysisGroupParameter(analysisParameter, this.analysisGroupCode)
      );

      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisGroupParameters = await this.$labordatApi
          .post('/api/analysisgroupparametermasterdata/bulk', analysisGroupParameters)
          .then((response) => response.data as IUpdatedAnalysisGroupParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    setLayoutElementDefaultValue(
      layoutElement: IAnalysisGroupLayoutElement,
      layoutElementType: 'PageBreak' | 'Title' | 'Text' | 'Sum' | 'Price'
    ) {
      switch (layoutElementType) {
        case 'PageBreak':
          layoutElement.value = '-';
          break;
        case 'Title':
          layoutElement.value = 'Title';
          break;
        case 'Text':
          layoutElement.value = 'Text';
          break;
        case 'Sum':
          layoutElement.value = '0.00';
          break;
        case 'Price':
          layoutElement.value = '0.00';
          break;
      }
    },
    deleteFilteredAnalysisParameterTableItems: async function () {
      if (confirm('Wollen Sie die gefilterten Analyseparameter wirklich löschen?') === false) {
        return;
      }

      await this.deleteAnalysisGroupParameters(
        this.smartTableData
          .filter((p) => p.discriminator === 'AnalysisGroupParameter')
          .map((p) => p.id)
      );
      await this.deleteAnalysisGroupLayoutElements(
        this.smartTableData
          .filter((p) => p.discriminator === 'AnalysisGroupLayoutElement')
          .map((p) => p.id)
      );

      this.smartTableSelected = undefined;
    },
    deleteAnalysisGroupParameters: async function (parameters: Array<number>) {
      if (!parameters || parameters.length < 1) {
        return;
      }

      try {
        this.analysisGroupParametersRd = UpdatableRemoteData.updating(
          this.analysisGroupParametersRd.getData()
        );

        await this.$labordatApi
          .delete(`/api/analysisgroupparametermasterdata`, { data: parameters })
          .then((response) => response.data);

        await this.loadAnalysisGroupParameters();
      } catch (error) {
        this.analysisGroupParametersRd = UpdatableRemoteData.updateFailure(
          this.analysisGroupParametersRd.getData(),
          error as Error
        );
      }
    },
    deleteAnalysisGroupLayoutElements: async function (elements: Array<number>) {
      if (!elements || elements.length < 1) {
        return;
      }

      try {
        this.analysisGroupLayoutElementsRd = UpdatableRemoteData.updating(
          this.analysisGroupLayoutElementsRd.getData()
        );

        await this.$labordatApi
          .delete(`/api/analysisgrouplayoutelementmasterdata`, { data: elements })
          .then((response) => response.data);

        await this.loadAnalysisGroupLayoutElements();
      } catch (error) {
        this.analysisGroupLayoutElementsRd = UpdatableRemoteData.updateFailure(
          this.analysisGroupLayoutElementsRd.getData(),
          error as Error
        );
      }
    },
    deleteAnalysisGroupParameterTableItem: async function () {
      if (this.smartTableSelected !== undefined) {
        if (confirm('Wollen Sie den Analyseparameter wirklich löschen?') === false) {
          return;
        }

        if (this.smartTableSelected.discriminator === 'AnalysisGroupParameter') {
          await this.deleteAnalysisGroupParameter(this.smartTableSelected);
        } else {
          await this.deleteAnalysisGroupLayoutElement(this.smartTableSelected);
        }

        this.smartTableSelected = undefined;
      }
    },
    deleteAnalysisGroupParameter: async function (parameter: IAnalysisGroupParameter) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisGroupParameters = await this.$labordatApi
          .delete(`/api/analysisgroupparametermasterdata/${parameter.id}`, { data: parameter })
          .then((response) => response.data as IUpdatedAnalysisGroupParameters);

        this.endUpdateParameters(updated);

        await this.loadAnalysisGroupParameters();
        await this.loadAnalysisGroupLayoutElements();
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    deleteAnalysisGroupLayoutElement: async function (parameter: IAnalysisGroupLayoutElement) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisGroupParameters = await this.$labordatApi
          .delete(`/api/analysisgrouplayoutelementmasterdata/${parameter.id}`, { data: parameter })
          .then((response) => response.data as IUpdatedAnalysisGroupParameters);

        this.endUpdateParameters(updated);

        await this.loadAnalysisGroupParameters();
        await this.loadAnalysisGroupLayoutElements();
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    smartTableUpdated: async function (tableItem: IAnalysisGroupParameterTableItem) {
      if (tableItem.discriminator === 'AnalysisGroupParameter') {
        await this.updateAnalysisGroupParameter(tableItem);
      } else {
        await this.updateAnalysisGroupLayoutElement(tableItem);
      }
    },
    updateAnalysisGroupParameter: async function (analysisGroupParameter: IAnalysisGroupParameter) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisGroupParameters = await this.$labordatApi
          .put(
            `/api/analysisgroupparametermasterdata/${analysisGroupParameter.id}`,
            analysisGroupParameter
          )
          .then((response) => response.data as IUpdatedAnalysisGroupParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    updateAnalysisGroupLayoutElement: async function (layoutElement: IAnalysisGroupLayoutElement) {
      try {
        this.startUpdateParameters();

        const updated: IUpdatedAnalysisGroupParameters = await this.$labordatApi
          .put(`/api/analysisgrouplayoutelementmasterdata/${layoutElement.id}`, layoutElement)
          .then((response) => response.data as IUpdatedAnalysisGroupParameters);

        this.endUpdateParameters(updated);
      } catch (error) {
        this.catchUpdateParameters(error);
      }
    },
    loadAnalysisGroupParameters: async function () {
      this.analysisGroupParametersRd = UpdatableRemoteData.loading();
      this.analysisGroupParametersRd = await this.$labordatApi.getUrd(
        `/api/analysisgroupparameters/${this.analysisGroupCode}`
      );
    },
    loadAnalysisGroupLayoutElements: async function () {
      this.analysisGroupLayoutElementsRd = await this.$labordatApi.getPagedUrd(
        '/api/analysisgrouplayoutelement',
        {
          params: {
            equals: `AnalysisGroupCode=${this.analysisGroupCode}`
          }
        }
      );
    },
    loadDimensions: async function () {
      this.dimensionsRd = await this.$labordatApi.getPagedRd('/api/dimension');
    },
    loadAnalysisParameterDefinitions: async function () {
      this.analysisParameterDefinitionsRd = await this.$labordatApi.getPagedRd(
        '/api/analysisparameterdefinition'
      );
    },
    updateColumnDropdownOptions(
      fieldName: string,
      dropdownOptions: { id: string | number | null; title: string }[]
    ) {
      if (this.smartTableConfig && this.smartTableConfig.columns) {
        const str: string | number | null = null;
        dropdownOptions.splice(0, 0, { id: str, title: '' });
        for (const col of this.smartTableConfig.columns) {
          if (col.fieldName === fieldName) {
            col.dropdownOptions = dropdownOptions;
            break;
          }
        }
      }
    },
    updateDimensionColumnDropdownOptions: function () {
      if (this.dimensionsRd.hasData()) {
        this.updateColumnDropdownOptions(
          'dimensionCode',
          this.dimensionsRd.getData().map((d) => ({ id: d.code, title: d.code }))
        );
      }
    },
    updateDepartmentColumnDropdownOptions: function () {
      if (this.dimensionsRd.hasData()) {
        this.updateColumnDropdownOptions(
          'departmentCode',
          this.$departments.map((d) => ({ id: d.code, title: d.code }))
        );
      }
    },
    startUpdateParameters: function () {
      this.analysisGroupLayoutElementsRd = UpdatableRemoteData.updating(
        this.analysisGroupLayoutElementsRd.getData()
      );
      this.analysisGroupParametersRd = UpdatableRemoteData.updating(
        this.analysisGroupParametersRd.getData()
      );
    },
    endUpdateParameters: function (updated: IUpdatedAnalysisGroupParameters) {
      const newParameters: IAnalysisGroupParameterTableItem[] =
        this.setDisplayPropertiesBeforeInsertingIntoTable(updated);
      const analysisGroupParameters =
        updated.analysisParameters !== undefined
          ? this.analysisGroupParametersRd
              .getData()
              .filter((x) => !updated.analysisParameters.find((y) => x.id === y.id))
              .concat(newParameters)
          : this.analysisGroupParametersRd.getData();
      this.analysisGroupParametersRd.isUpdating();

      const analysisGroupLayoutElements =
        updated.layoutElements !== undefined
          ? this.analysisGroupLayoutElementsRd
              .getData()
              .filter((x) => !updated.layoutElements.find((y) => x.id === y.id))
              .concat(updated.layoutElements)
          : this.analysisGroupLayoutElementsRd.getData();

      this.analysisGroupLayoutElementsRd = UpdatableRemoteData.success(analysisGroupLayoutElements);
      this.analysisGroupParametersRd = UpdatableRemoteData.success(analysisGroupParameters);
    },
    setDisplayPropertiesBeforeInsertingIntoTable: function (
      updated: IUpdatedAnalysisGroupParameters
    ): IAnalysisGroupParameterTableItem[] {
      if (!updated.analysisParameters) {
        return [];
      }
      const newParameters = updated.analysisParameters.map(
        (ap) => ap as IAnalysisGroupParameterTableItem
      );
      for (const param of newParameters) {
        const code = this.analysisParameterDefinitionsRd
          .getData()
          .find((def) => def.code === param.analysisParameterDefinitionCode);
        if (code) {
          param.nameDe = code.nameDe;
          param.priceInChf = code.priceInChf;
        }
      }
      return newParameters;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catchUpdateParameters: function (error: any) {
      this.analysisGroupLayoutElementsRd = UpdatableRemoteData.updateFailure(
        this.analysisGroupLayoutElementsRd.getData(),
        error
      );
      this.analysisGroupParametersRd = UpdatableRemoteData.updateFailure(
        this.analysisGroupParametersRd.getData(),
        error
      );
    }
  }
});
