
































































import Vue from 'vue';
import $ from 'jquery';
import _ from 'lodash';
import axios from 'axios';
import { RemoteData } from 'rey-frontend-fp';
// @ts-ignore
import { Loading, Pagination } from 'rey-vue-common';
import ReportPictureDetailsModal from '@/views/Templates/ReportPicture/ReportPictureDetailsModal.vue';
import IReportPicture from '@/models/IReportPicture';
import { IPagedResult } from 'rey-vue-smarttable';

export default Vue.extend({
  name: 'report-picture',
  components: { Loading, ReportPictureDetailsModal, Pagination },
  props: {
    id: String,
    value: String,
    accept: String,
    disabled: Boolean
  },
  data() {
    return {
      reportPicturesModalRd: RemoteData.notAsked<IPagedResult<IReportPicture>, Error>(),
      tempSelectedId: 0,
      isLoading: false,
      reportPicturePagination: {
        take: 15,
        initialTopIndex: 15,
        currentTopIndex: 15
      },
      pictureSearchDebounced: _.debounce(
        function () {
          // @ts-ignore
          this.reportPicturePagination.currentTopIndex =
            // @ts-ignore
            this.reportPicturePagination.initialTopIndex;
          // @ts-ignore
          this.search();
        }.bind(this),
        1000
      ),
      cancelTokenSource: axios.CancelToken.source()
    };
  },
  created: async function () {
    this.loadReportPicturesBySearchString('');
  },
  computed: {
    reportPicturesModal(): IReportPicture[] {
      return this.reportPicturesModalRd.map((x) => x.entities).withDefault([]);
    }
  },
  methods: {
    async loadReportPicturesBySearchString(searchString: string) {
      this.isLoading = true;
      this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
      this.reportPicturesModalRd = await this.$labordatApi.getRd('/api/reportpicture/cropped', {
        params: {
          take: this.reportPicturePagination.take,
          skip: this.reportPicturePagination.currentTopIndex - this.reportPicturePagination.take,
          searchString: searchString
        }
      });
      this.isLoading = false;
    },
    async search() {
      await this.loadReportPicturesBySearchString(
        (this.$refs.pictureSearch as HTMLInputElement).value
      );
    },
    async reportPicturePaginationChanged(newTopIndex: number) {
      this.reportPicturePagination.currentTopIndex = newTopIndex;
      await this.search();
    },
    select(id: number) {
      this.tempSelectedId = id;
    },
    newReportPicture() {
      this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
      this.tempSelectedId = 0;
      // @ts-ignore
      $('#reportPictureDetailsModal').modal('show');
    },
    editReportPicture(reportPictureId: number) {
      this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
      this.tempSelectedId = reportPictureId;
      // @ts-ignore
      $('#reportPictureDetailsModal').modal('show');
    },
    async getReportPicture(reportPictureId: number) {
      try {
        await this.$labordatApi
          .get(`/api/reportpicture/${reportPictureId}/cropped`, {
            cancelToken: this.cancelTokenSource.token
          })
          .then((response) => response.data as IReportPicture)
          .then((data) => {
            if (data && data.pictureBig) {
              $('#reportPicture-' + reportPictureId).attr('src', data.pictureBig);
            }
          });
      } catch (error) {
        // report picture loading canceled or possibliy invalid
      }
    }
  }
});
