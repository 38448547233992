


































































































































import mixins from 'vue-typed-mixins';
import UserMixin from '@/mixins/UserMixin';
import { UpdatableRemoteData } from 'rey-frontend-fp';
import OrderMixin from '@/mixins/OrderMixin';
import OrderConfirmationMixin from '@/mixins/OrderConfirmationMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import OrderDetailsForm from '@/views/Order/OrderDetails/OrderDetailsForm.vue';
import OrderConfirmationMailDialog from '@/views/Order/OrderConfirmationMailDialog.vue';
import IOrderExpanded from '@/models/IOrderExpanded';

export default mixins(UserMixin, OrderMixin, OrderConfirmationMixin, FileDownloadMixin).extend({
  components: {
    OrderDetailsForm,
    OrderConfirmationMailDialog
  },
  data: () => {
    return {
      isGeneratingOrderConfirmationMailText: false,
      shouldDisplayOrderConfirmationMailDialog: false,
      orderConfirmationDialogParameters: {
        generatedOrderConfirmationMailText: '',
        capturedReceiverMailAddressesOfCustomer: [] as { id: number; name: string; slug: string }[],
        proposedReceiverMailAddresses: [] as { id: number; name: string; slug: string }[]
      },
      orderConfirmationMailHasBeenSent: false
    };
  },
  props: {
    isInUpdateMode: Boolean
  },
  methods: {
    cancel: async function () {
      // @ts-ignore
      await this.$parent.initialize();
    },
    createOrder: async function () {
      try {
        this.orderRd = UpdatableRemoteData.updating(this.orderRd.getData());
        const order = await this.$labordatApi
          .post('/api/orders', this.orderRd.getData())
          .then((response) => response.data as IOrderExpanded);
        this.$router.push(`/orders/${order.id}`);
      } catch (error) {
        this.orderRd = UpdatableRemoteData.updateFailure(this.orderRd.getData(), error as Error);
      }
    },
    startOrderConfirmationMailDialog: function () {
      this.isGeneratingOrderConfirmationMailText = true;
      this.orderConfirmationMailHasBeenSent = false;

      Promise.all([
        this.fetchReceivers(this.orderRd.getData().id || ''),
        this.generateOrderConfirmationMailText(this.orderRd.getData().id || '', 0)
      ])
        .then((values) => {
          this.orderConfirmationDialogParameters.generatedOrderConfirmationMailText = values[1];

          this.orderConfirmationDialogParameters.capturedReceiverMailAddressesOfCustomer =
            values[0].capturedReceiversOfCustomer.map((mailAddress) => {
              return { id: 0, name: mailAddress, slug: mailAddress };
            });
          this.orderConfirmationDialogParameters.proposedReceiverMailAddresses =
            values[0].proposedReceivers.map((mailAddress) => {
              return { id: 0, name: mailAddress, slug: mailAddress };
            });

          this.shouldDisplayOrderConfirmationMailDialog = true;
        })
        .finally(() => {
          this.isGeneratingOrderConfirmationMailText = false;
        });
    },
    cancelOrderConfirmationMailDialog: function () {
      this.shouldDisplayOrderConfirmationMailDialog = false;
    },
    orderConfirmationMailSent: function () {
      this.shouldDisplayOrderConfirmationMailDialog = false;
      this.orderConfirmationMailHasBeenSent = true;
    },
    copyOrder: async function () {
      try {
        this.orderRd = UpdatableRemoteData.updating(this.orderRd.getData());
        const order = await this.$labordatApi
          .get(`/api/orders/${this.orderRd.getData().id}/copy`)
          .then((response) => response.data as IOrderExpanded);
        if (order) {
          this.$router.push(`/orders/${order.id}`);
        }
      } catch (error) {
        this.orderRd = UpdatableRemoteData.updateFailure(this.orderRd.getData(), error as Error);
      }
    },
    canOrderBeCopied: function () {
      if (!this.orderRd.hasData) {
        return false;
      }
      return this.orderRd.getData().canOrderBeCopied ?? false;
    },
    exportOrder: async function () {
      try {
        this.orderRd = UpdatableRemoteData.updating(this.orderRd.getData());
        await this.$labordatApi
          .get(`/api/orders/${this.orderRd.getData().id}/export`, { responseType: 'arraybuffer' })
          .then((response) => {
            if (response != null) {
              this.downloadFile(response, `Export-${this.orderRd.getData().id}.csv`);
              this.orderRd = UpdatableRemoteData.success(this.orderRd.getData());
            } else {
              throw new Error('No document generated.');
            }
          });
      } catch (error) {
        this.orderRd = UpdatableRemoteData.updateFailure(this.orderRd.getData(), error as Error);
      }
    },
    exportOrderAuditLog: async function () {
      try {
        this.orderRd = UpdatableRemoteData.updating(this.orderRd.getData());
        await this.$labordatApi
          .get(`/api/orders/${this.orderRd.getData().id}/exportauditlog`, {
            responseType: 'arraybuffer'
          })
          .then((response) => {
            if (response != null) {
              this.downloadFile(response, `ExportAuditLog-${this.orderRd.getData().id}.xslx`);
              this.orderRd = UpdatableRemoteData.success(this.orderRd.getData());
            } else {
              throw new Error('No document generated.');
            }
          });
      } catch (error) {
        this.orderRd = UpdatableRemoteData.updateFailure(this.orderRd.getData(), error as Error);
      }
    },
    scrollToTop: function () {
      document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    lockOrderInternal: async function () {
      if (confirm('Möchten Sie die den Auftrag wirklich sperren?') === false) {
        return;
      }

      await this.setlockOrderStatus(1);
    },
    unlockOrderInternal: async function () {
      if (confirm('Möchten Sie die Auftragssperre wirklich entfernen?') === false) {
        return;
      }

      await this.setlockOrderStatus(0);
    },
    unlockOrderExternal: async function () {
      if (
        confirm(
          'Möchten Sie die Auftragssperre wirklich entfernen?\n\nDer Auftrag kann danach nicht mehr synchron mit der externen Applikation sein.'
        ) === false
      ) {
        return;
      }

      await this.setlockOrderStatus(0);
    },
    setlockOrderStatus: async function (lockStatus: number) {
      try {
        this.orderRd = UpdatableRemoteData.updating(this.orderRd.getData());
        await this.$labordatApi
          .put(`/api/orders/${this.orderRd.getData().id}/lockstatus`, `${lockStatus}`, {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          .then(() => {
            this.orderRd = UpdatableRemoteData.success(this.orderRd.getData());
          });
      } catch (error) {
        this.orderRd = UpdatableRemoteData.updateFailure(this.orderRd.getData(), error as Error);
      }
    }
  }
});
