



import Vue from 'vue';
import $ from 'jquery';
import TreeViewNode from './TreeViewNode.vue';

export default Vue.extend({
  components: { TreeViewNode },
  name: 'tree-view',
  props: { value: Array, idPrefix: String },
  data() {
    return {
      selectedNodeId: undefined as string | undefined
    };
  },
  methods: {
    setSelected(categoryId: string | undefined) {
      if (this.selectedNodeId === categoryId) {
        this.selectedNodeId = undefined;
      } else {
        this.selectedNodeId = categoryId;
      }
    },
    expandByGivenCategoryId(categoryId: string) {
      const node = $('#' + categoryId).closest('ul');
      if (node.hasClass('collapsed') === true) {
        node.toggleClass('collapsed expanded');
        const parents = node.parentsUntil('ul.treeView');
        for (const parentElement of parents) {
          const parent = $(parentElement);
          if (parent.hasClass('collapsed') === true) {
            parent.toggleClass('collapsed expanded');
          }
        }
      }
    }
  },
  watch: {
    selectedNodeId(after: string | undefined, before: string | undefined) {
      if (before !== undefined) {
        const currentNode = $('#' + before);
        if (currentNode) {
          currentNode.toggleClass('selected');
        }
      }
      if (after !== undefined) {
        const newNode = $('#' + after);
        if (newNode) {
          newNode.toggleClass('selected');
          this.expandByGivenCategoryId(after);
        }
        this.$emit('selection-changed', +after.substring(this.idPrefix.length));
      } else {
        this.$emit('selection-changed', undefined);
      }
    }
  }
});
