import Vue from 'vue';

export default Vue.extend({
  methods: {
    formatWithPrecision: function (text: string, precision: number) {
      if (text === undefined || text === null || text === '' || isNaN(+text)) {
        return text;
      } else {
        return (+text).toFixed(precision);
      }
    },
    withSeparators: function (cellValue: string) {
      if (cellValue) {
        const parts = cellValue.toString().split('.');
        let part1 = parts[0];
        const part2 = parts.length > 1 ? '.' + parts[1] : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(part1)) {
          part1 = part1.replace(rgx, '$1' + `'` + '$2');
        }
        cellValue = part1 + part2;
      }
      return cellValue;
    },
    removeHtmlTags: function (text: string) {
      return text !== null
        ? text
            .replace(/<\/(?:p|li)+>/g, ' ')
            .replace(/<br\s*\/*>/g, ' ')
            .replace(/<[^>]+>/g, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/&#39;/g, "'")
            .replace(/[^\S\r\n]{2,}/g, ' ')
        : '';
    },
    financial: function (value: number | string | undefined) {
      // @ts-ignore
      return Number.parseFloat(value || 0).toFixed(2);
    }
  }
});
