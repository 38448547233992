






































































































import mixins from 'vue-typed-mixins';
import VModelMixin from '@/mixins/VModelMixin';
import SettingsMixin from '@/mixins/SettingsMixin';
import UserMixin from '@/mixins/UserMixin';
import OrderConfirmationMixin from '@/mixins/OrderConfirmationMixin';
// @ts-ignore
import { VueEditor } from 'vue2-editor';
import { PropType } from 'vue';
import QuillOptions from '@/models/QuillOptions';
import TagSelection from '@/components/TagSelectionComponent/TagSelection.vue';

export type MailSelectionItem = {
  id: number;
  name: string;
  slug: string;
};
export type FormData = {
  toReceiverMailAddresses: Array<MailSelectionItem>;
  ccReceiverMailAddresses: Array<MailSelectionItem>;
  senderMailAddress: string;
  mailSubject: string;
  mailContent: string;
};
export default mixins(VModelMixin, SettingsMixin, UserMixin, OrderConfirmationMixin).extend({
  components: {
    VueEditor,
    TagSelection
  },
  props: {
    availableToReceiverMailAddresses: Array as PropType<Array<MailSelectionItem>>,
    availableCcReceiverMailAddresses: Array as PropType<Array<MailSelectionItem>>,
    formData: Object as PropType<FormData>,
    isReadOnly: Boolean
  },
  data() {
    return {
      quillOptions: QuillOptions.config
    };
  },
  methods: {
    toReceiverMailAddressAdded: async function (receiverToAdd: MailSelectionItem) {
      const alreadyInList = this.formData.toReceiverMailAddresses.some(
        (receiver) => receiver.name.toLowerCase() == receiverToAdd.name.toLowerCase()
      );
      if (!alreadyInList) {
        this.formData.toReceiverMailAddresses.push(receiverToAdd);
      }
    },
    toReceiverMailAddressRemoved: async function (receiverToRemove: MailSelectionItem) {
      this.formData.toReceiverMailAddresses = this.formData.toReceiverMailAddresses.filter(
        (receiver) => receiver.name.toLowerCase() != receiverToRemove.name.toLowerCase()
      );
    },
    ccReceiverMailAddressAdded: async function (receiverToAdd: MailSelectionItem) {
      const alreadyInList = this.formData.ccReceiverMailAddresses.some(
        (receiver) => receiver.name.toLowerCase() == receiverToAdd.name.toLowerCase()
      );
      if (!alreadyInList) {
        this.formData.ccReceiverMailAddresses.push(receiverToAdd);
      }
    },
    ccReceiverMailAddressRemoved: async function (receiverToRemove: MailSelectionItem) {
      this.formData.ccReceiverMailAddresses = this.formData.ccReceiverMailAddresses.filter(
        (receiver) => receiver.name.toLowerCase() != receiverToRemove.name.toLowerCase()
      );
    }
  }
});
