





















































import mixins from 'vue-typed-mixins';
import VModelMixin from '@/mixins/VModelMixin';
import UserMixin from '@/mixins/UserMixin';

export default mixins(VModelMixin, UserMixin).extend({
  name: 'signature',
  props: {
    options: Array,
    realSigneeDisplayText: String,
    id: String,
    signButtonDisabled: Boolean,
    unsignButtonDisabled: Boolean,
    titleEnvelopeButton: String,
    signeeNumber: Number
  },
  computed: {
    loggedInIsSelectedUser: function (): boolean {
      if (!this.userIsAuthenticated || !this.model) {
        return true;
      }
      return this.userEmail !== this.model.email;
    }
  },
  methods: {
    sendMail: function () {
      this.$emit('send-mail', this.model.email, this.signeeNumber);
    },
    sign: function () {
      this.$emit('sign', this.model);
    },
    unSign: function () {
      this.$emit('unsign');
    },
    intendedSigneeChanged: function () {
      this.$emit('intended-signee-changed', this.model);
    }
  }
});
