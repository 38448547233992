




















































































































import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import _ from 'lodash';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import IDepartment from '@/models/IDepartment';
import IPredefinedReportAttachment from '@/models/AutomaticReportSending/MasterData/IPredefinedReportAttachment';
import {
  ExistingPredefinedReportAttachment,
  NewPredefinedReportAttachment,
  NoPredefinedReportAttachment
} from '@/models/AutomaticReportSending/MasterData/PredefinedReportAttachmentState';
import IPredefinedReportAttachmentFile from '@/models/AutomaticReportSending/MasterData/IPredefinedReportAttachmentFile';
import PredefinedReportAttachmentFile from '@/models/AutomaticReportSending/MasterData/PredefinedReportAttachmentFile';
import PredefinedReportAttachmentDetailsForm from '@/views/MasterData/AutomaticReportSending/PredefinedReportAttachmentDetailsForm.vue';
import Language from '@/models/Language';

export default mixins(SettingsMixin, SharedDataMixin, FileDownloadMixin).extend({
  components: { PredefinedReportAttachmentDetailsForm },
  name: 'predefined-report-attachments',
  props: {
    itemId: String
  },
  data: function () {
    return {
      predefinedReportAttachmentsRd: RemoteData.notAsked<
        Array<IPredefinedReportAttachment>,
        Error
      >(),
      predefinedReportAttachmentRd: UpdatableRemoteData.notAsked<
        IPredefinedReportAttachment,
        Error
      >(),
      predefinedReportAttachmentFilesRd: RemoteData.notAsked<
        Array<IPredefinedReportAttachmentFile>,
        Error
      >(),
      predefinedReportAttachmentState: new NoPredefinedReportAttachment()
    };
  },
  created: async function () {
    await this.loadPredefinedReportAttachments();
    if (this.id) {
      this.loadPredefinedReportAttachment(this.id);
      this.predefinedReportAttachmentState = new ExistingPredefinedReportAttachment(this.id);
    }
  },
  computed: {
    id: function (): number {
      return +this.itemId;
    },
    fileDe: function (): IPredefinedReportAttachmentFile {
      if (!this.predefinedReportAttachmentFilesRd.hasData()) {
        return PredefinedReportAttachmentFile.Empty();
      }
      return (
        this.predefinedReportAttachmentFilesRd.getData().find((x) => x.language == Language.DE) ??
        PredefinedReportAttachmentFile.Empty()
      );
    },
    fileEn: function (): IPredefinedReportAttachmentFile {
      if (!this.predefinedReportAttachmentFilesRd.hasData()) {
        return PredefinedReportAttachmentFile.Empty();
      }
      return (
        this.predefinedReportAttachmentFilesRd.getData().find((x) => x.language == Language.EN) ??
        PredefinedReportAttachmentFile.Empty()
      );
    },
    fileFr: function (): IPredefinedReportAttachmentFile {
      if (!this.predefinedReportAttachmentFilesRd.hasData()) {
        return PredefinedReportAttachmentFile.Empty();
      }
      return (
        this.predefinedReportAttachmentFilesRd.getData().find((x) => x.language == Language.FR) ??
        PredefinedReportAttachmentFile.Empty()
      );
    },
    fileIt: function (): IPredefinedReportAttachmentFile {
      if (!this.predefinedReportAttachmentFilesRd.hasData()) {
        return PredefinedReportAttachmentFile.Empty();
      }
      return (
        this.predefinedReportAttachmentFilesRd.getData().find((x) => x.language == Language.IT) ??
        PredefinedReportAttachmentFile.Empty()
      );
    }
  },
  watch: {
    predefinedReportAttachmentState: async function (value) {
      switch (value.kind) {
        case 'none':
          this.predefinedReportAttachmentRd = UpdatableRemoteData.notAsked();
          this.$router.push(`/masterdata/predefinedreportattachment`);
          break;
        case 'new':
          this.predefinedReportAttachmentRd = UpdatableRemoteData.notCreated({
            departmentCode: this.$department
          } as IPredefinedReportAttachment);
          this.predefinedReportAttachmentFilesRd = await this.$labordatApi.getRd(
            `/api/AutomaticReportSendingPredefinedReportAttachments/0/Files`
          );
          this.$router.push(`/masterdata/predefinedreportattachment`);
          break;
        case 'existing':
          if (value.id) {
            await this.loadPredefinedReportAttachment(value.id);
            this.$router.push(`/masterdata/predefinedreportattachment/${value.id}`);
          }
          break;
      }
    }
  },
  methods: {
    predefinedReportAttachmentDepartments: function (): IDepartment[] {
      return this.$departments
        .filter(
          (d) =>
            this.predefinedReportAttachmentsRd
              .withDefault([])
              .map((rt) => rt.departmentCode)
              .indexOf(d.code) >= 0
        )
        .sort();
    },
    predefinedReportAttachmentsByDepartment: function (department: string) {
      return _.sortBy(
        this.predefinedReportAttachmentsRd
          .getData()
          .filter((rt) => rt.departmentCode === department),
        (rt) => rt.name
      );
    },
    loadPredefinedReportAttachments: async function () {
      try {
        this.predefinedReportAttachmentsRd = await this.$labordatApi.getRd(
          `/api/AutomaticReportSendingPredefinedReportAttachments`
        );
      } catch (error) {
        this.predefinedReportAttachmentsRd = RemoteData.failure(error as Error);
      }
    },
    loadPredefinedReportAttachment: async function (id: number) {
      try {
        this.predefinedReportAttachmentRd = UpdatableRemoteData.loading();
        this.predefinedReportAttachmentRd = await this.$labordatApi.getUrd(
          `/api/AutomaticReportSendingPredefinedReportAttachments/${id}`
        );
        this.predefinedReportAttachmentFilesRd = await this.$labordatApi.getRd(
          `/api/AutomaticReportSendingPredefinedReportAttachments/${id}/Files`
        );
      } catch (error) {
        this.predefinedReportAttachmentRd = UpdatableRemoteData.failure(error as Error);
      }
    },
    selectPredefinedReportAttachment: function (event: Event) {
      const itemId = +(event.target as HTMLInputElement).value;
      if (itemId) {
        this.predefinedReportAttachmentState = new ExistingPredefinedReportAttachment(itemId);
      }
    },
    newPredefinedReportAttachment: async function () {
      this.predefinedReportAttachmentState = new NewPredefinedReportAttachment();
    },
    deletePredefinedReportAttachment: async function () {
      if (this.predefinedReportAttachmentState.kind !== 'existing') {
        return;
      }

      if (confirm('Wollen Sie den Berichtsanhang wirklich löschen?') === false) {
        return;
      }

      try {
        this.predefinedReportAttachmentRd = UpdatableRemoteData.updating(
          this.predefinedReportAttachmentRd.getData()
        );
        await this.$labordatApi.delete(
          `/api/AutomaticReportSendingPredefinedReportAttachments/${
            this.predefinedReportAttachmentRd.getData().id
          }`
        );

        this.predefinedReportAttachmentState = new NoPredefinedReportAttachment();
        await this.loadPredefinedReportAttachments();
      } catch (error) {
        this.predefinedReportAttachmentRd = UpdatableRemoteData.updateFailure(
          this.predefinedReportAttachmentRd.getData(),
          error as Error
        );
      }
    },
    resetPredefinedReportAttachment: function () {
      this.predefinedReportAttachmentState = new NoPredefinedReportAttachment();
    },
    createPredefinedReportAttachment: async function () {
      const predefinedReportAttachment = this.predefinedReportAttachmentRd.getData();
      this.predefinedReportAttachmentRd = UpdatableRemoteData.updating(predefinedReportAttachment);
      try {
        const result: IPredefinedReportAttachment = await this.$labordatApi
          .post(
            `/api/AutomaticReportSendingPredefinedReportAttachments`,
            predefinedReportAttachment
          )
          .then((response) => response.data as IPredefinedReportAttachment);

        this.predefinedReportAttachmentState = new ExistingPredefinedReportAttachment(result.id);
      } catch (error) {
        this.predefinedReportAttachmentRd = UpdatableRemoteData.updateFailure<
          IPredefinedReportAttachment,
          Error
        >(predefinedReportAttachment, error as Error);
      }
      await this.loadPredefinedReportAttachments();
    },
    updatePredefinedReportAttachment: async function () {
      const predefinedReportAttachment = this.predefinedReportAttachmentRd.getData();
      this.predefinedReportAttachmentRd = UpdatableRemoteData.updating(predefinedReportAttachment);
      this.predefinedReportAttachmentRd = await this.$labordatApi
        .put(
          `/api/AutomaticReportSendingPredefinedReportAttachments/${predefinedReportAttachment.id}`,
          predefinedReportAttachment
        )
        .then((response) => response.data as IPredefinedReportAttachment)
        .then((data) => {
          return UpdatableRemoteData.success<IPredefinedReportAttachment, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IPredefinedReportAttachment, Error>(
            predefinedReportAttachment,
            error as Error
          )
        );
      await this.loadPredefinedReportAttachments();
    },
    upload: async function (language: string, file: File) {
      const predefinedReportAttachment = this.predefinedReportAttachmentRd.getData();
      this.predefinedReportAttachmentRd = UpdatableRemoteData.updating(predefinedReportAttachment);
      try {
        const formData = new FormData();
        formData.append('language', language);
        formData.append('file', file);
        this.predefinedReportAttachmentRd = await this.$labordatApi
          .post(
            `/api/AutomaticReportSendingPredefinedReportAttachments/${predefinedReportAttachment.id.toString()}/Files/Upload`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }
          )
          .then((response) => response.data as IPredefinedReportAttachment)
          .then((data) => {
            return UpdatableRemoteData.success<IPredefinedReportAttachment, Error>(data);
          });
      } catch (error) {
        this.predefinedReportAttachmentRd = UpdatableRemoteData.updateFailure<
          IPredefinedReportAttachment,
          Error
        >(predefinedReportAttachment, error as Error);
      }

      this.predefinedReportAttachmentFilesRd = await this.$labordatApi.getRd(
        `/api/AutomaticReportSendingPredefinedReportAttachments/${predefinedReportAttachment.id}/Files`
      );
    },
    download: async function (language: string) {
      const predefinedReportAttachment = this.predefinedReportAttachmentRd.getData();
      try {
        await this.$labordatApi
          .get(
            `/api/AutomaticReportSendingPredefinedReportAttachments/${predefinedReportAttachment.id}/Files/Download?language=${language}`,
            {
              responseType: 'arraybuffer'
            }
          )
          .then((response) => {
            this.downloadFile(response, 'Berichtsanhang.pdf');
          });
      } catch (error) {
        this.predefinedReportAttachmentRd = UpdatableRemoteData.updateFailure<
          IPredefinedReportAttachment,
          Error
        >(predefinedReportAttachment, error as Error);
      }
    }
  }
});
