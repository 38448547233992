import Vue from 'vue';
import IOrderStatusChange from '@/models/IOrderStatusChange';
import IOrderLockStatusChange from '@/models/IOrderLockStatusChange';
import IReportStatusChange from '@/models/IReportStatusChange';
import ISampleStatusChange from '@/models/ISampleStatusChange';

export default Vue.extend({
  created: async function () {
    this.$statusHub.$on('OnOrderStatusChanged', this.onOrderStatusChanged);
    this.$statusHub.$on('OnOrderLockStatusChanged', this.onOrderLockStatusChanged);
    this.$statusHub.$on('OnReportStatusChanged', this.onReportStatusChanged);
    this.$statusHub.$on('OnSampleStatusChanged', this.onSampleStatusChanged);
  },
  beforeDestroy() {
    this.$statusHub.$off('OnSampleStatusChanged', this.onSampleStatusChanged);
    this.$statusHub.$off('OnReportStatusChanged', this.onReportStatusChanged);
    this.$statusHub.$off('OnOrderLockStatusChanged', this.onOrderLockStatusChanged);
    this.$statusHub.$off('OnOrderStatusChanged', this.onOrderStatusChanged);
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onOrderStatusChanged: function (orderStatus: IOrderStatusChange) {
      // do nothing, needs to be overwritten
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onOrderLockStatusChanged: function (lockStatus: IOrderLockStatusChange) {
      // do nothing, needs to be overwritten
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onReportStatusChanged: function (reportStatus: IReportStatusChange) {
      // do nothing, needs to be overwritten
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSampleStatusChanged: function (sampleStatus: ISampleStatusChange) {
      // do nothing, needs to be overwritten
    }
  }
});
