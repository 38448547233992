import { render, staticRenderFns } from "./FileDropZone.vue?vue&type=template&id=eb4a5148&scoped=true&"
import script from "./FileDropZone.vue?vue&type=script&lang=ts&"
export * from "./FileDropZone.vue?vue&type=script&lang=ts&"
import style0 from "./FileDropZone.vue?vue&type=style&index=0&id=eb4a5148&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_jmni46ydenuhv6sytlbyqtthlq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb4a5148",
  null
  
)

export default component.exports