
























import mixins from 'vue-typed-mixins';
import TypeaheadMixin from '@/mixins/TypeaheadMixin';
import { RemoteData, identity } from 'rey-frontend-fp';
import { IPagedResult } from 'rey-vue-smarttable';
import IAnalysisGroupCategory from '@/models/IAnalysisGroupCategory';
import _ from 'lodash';

export default mixins(TypeaheadMixin).extend({
  props: {
    value: Number,
    title: String,
    transformator: Function
  },
  data: function () {
    return {
      analysisGroupCategoriesRd: RemoteData.notAsked<Array<IAnalysisGroupCategory>, Error>(),
      analysisGroupCategoryRd: RemoteData.notAsked<IAnalysisGroupCategory, Error>(),
      // data allows method properties to be accessed
      updateTypeAhead: _.debounce(
        function (searchString: string) {
          // @ts-ignore
          this.loadAnalysisGroupCategories(searchString);
        }.bind(this),
        500
      ),
      allCategories: new Array<IAnalysisGroupCategory>()
    };
  },
  mounted: async function () {
    this.allCategories = await this.$labordatApi
      .get('/api/analysisgroupcategory')
      .then((response) => response.data as IPagedResult<IAnalysisGroupCategory>)
      .then((data) => data.entities);

    await this.refreshValue();
  },
  computed: {
    dataTransformator: function () {
      return this.transformator || identity;
    }
  },
  watch: {
    value: async function () {
      await this.refreshValue();
    }
  },
  methods: {
    refreshValue: async function () {
      this.analysisGroupCategoryRd = this.value
        ? await this.loadAnalysisGroupCategory(this.value)
        : RemoteData.notAsked();

      // Sometimes the typeahead is not mounted yet.
      if (this.$refs.typeahead) {
        const value = this.analysisGroupCategoryRd
          .map(this.buildSingleCategoryTree)
          .map(this.typeaheadSerializer)
          .withDefault('');
        // @ts-ignore
        this.$refs.typeahead.inputValue = value;
      }
    },
    onHit: async function (category: IAnalysisGroupCategory) {
      this.cancelTypeAheadSearch();
      this.analysisGroupCategoryRd = await this.loadAnalysisGroupCategory(category.id);

      if (this.analysisGroupCategoryRd.hasData()) {
        this.$emit('input', +this.analysisGroupCategoryRd.getData().id);
        this.$emit('hit', this.analysisGroupCategoryRd.getData());
      }
    },
    cancelTypeAheadSearch: function () {
      _.debounce(
        function () {
          // @ts-ignore
          this.updateTypeAhead.cancel();
        }.bind(this),
        100
      )();
    },
    typeaheadSerializer: function (category: IAnalysisGroupCategory) {
      return category.name;
    },
    loadAnalysisGroupCategories: async function (searchString: string) {
      this.analysisGroupCategoriesRd = await this.$labordatApi.getPagedRd(
        `/api/analysisgroupcategory?search=${searchString}`
      );
    },
    loadAnalysisGroupCategory: function (
      id: number
    ): Promise<RemoteData<IAnalysisGroupCategory, Error>> {
      return this.$labordatApi.getRd(`/api/analysisgroupcategory/${id}`);
    },
    buildCategoryTree: function (categories: Array<IAnalysisGroupCategory>) {
      const data = _.cloneDeep(categories);
      return data.map((a) => this.buildSingleCategoryTree(a));
    },
    buildSingleCategoryTree: function (category: IAnalysisGroupCategory) {
      if (this.allCategories !== null && this.allCategories.length > 0) {
        const data = _.cloneDeep(category);
        const items = new Array<string>();
        let currentId = data.parentId;
        while (currentId !== null) {
          const cat = this.allCategories.find((c) => c.id === currentId);
          if (cat != null) {
            items.splice(0, 0, cat.name);
            currentId = cat.parentId;
          }
        }

        items.push(data.name);
        data.name = items.join(' > ');
        return data;
      } else {
        return category;
      }
    }
  }
});
