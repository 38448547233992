


























import ReportCompilationEditor from './ReportCompilationEditor.vue';
import draggable from 'vuedraggable';
import { Loading } from 'rey-vue-common';
import mixins from 'vue-typed-mixins';
import FileDropZone from '@/components/FileDropZone.vue';
import { PropType } from 'vue';
import IFinalizeAutomaticReportSendingReportWorkflowRequestDto from '@/models/AutomaticReportSending/AutomaticReportSendingWorkflow/Api/IFinalizeAutomaticReportSendingReportWorkflowRequestDto';

export default mixins().extend({
  props: {
    reportCompilationSessionId: Number,
    finalizeRequestBody: Object as PropType<IFinalizeAutomaticReportSendingReportWorkflowRequestDto>
  },
  components: {
    Loading,
    ReportCompilationEditor,
    draggable,
    FileDropZone
  },
  data() {
    return {
      sessionId: null as null | number,
      doneSending: false,
      wasSuccessful: false
    };
  },
  async mounted() {
    let result = await this.$labordatApi.post(
      `/api/AutomaticReportSending/send`,
      this.finalizeRequestBody
    );
    this.doneSending = true;
    this.wasSuccessful = result?.status == 200;
  }
});
