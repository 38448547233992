








































import Vue from 'vue';
export default Vue.extend({
  name: 'select-input',
  props: {
    id: [String, Number],
    value: [String, Number],
    options: Array,
    toId: Function,
    toValue: Function,
    placeholder: String,
    required: Boolean
  }
});
