




















































































































import Vue from 'vue';
import { UpdatableRemoteData, RemoteData } from 'rey-frontend-fp';
import IReportPictureCategory from '@/models/IReportPictureCategory';
import {
  IReportPictureCategoryState,
  NewReportPictureCategory,
  ExistingReportPictureCategory,
  NoReportPictureCategory
} from '@/models/IReportPictureCategoryState';
import ReportPictureCategoryTypeahead from '@/components/ReportPictureCategoryTypeahead.vue';
import ReportPictureCategoryDetailsForm from '@/views/Templates/ReportPictureCategory/ReportPictureCategoryDetailsForm.vue';
import TreeView from '@/components/TreeView.vue';
import IReportPictureCategoryTree from '@/models/IReportPictureCategoryTree';

export default Vue.extend({
  components: {
    ReportPictureCategoryTypeahead,
    ReportPictureCategoryDetailsForm,
    TreeView
  },
  name: 'report-picture-category',
  props: {
    itemId: [Number, String]
  },
  data() {
    return {
      allowedToSave: true,
      selectedCategoryId: undefined as number | undefined,
      reportPictureCategoryRd: UpdatableRemoteData.notAsked<IReportPictureCategory, Error>(),
      reportPictureCategoryState: new NoReportPictureCategory(),
      reportPictureCategoriesRd: RemoteData.notAsked<Array<IReportPictureCategoryTree>, Error>(),
      treeIdPrefix: 'PictureCategoryManagement'
    };
  },
  async created() {
    await this.loadCategoriesTree();
    this.treeDataLoaded();
  },
  watch: {
    async reportPictureCategoryState(value: IReportPictureCategoryState) {
      switch (value.kind) {
        case 'none':
          this.reportPictureCategoryRd = UpdatableRemoteData.notAsked();
          if (this.itemId !== value.id) {
            this.$router.push(`/templates/reportpicturecategory`);
          }
          break;
        case 'new':
          this.reportPictureCategoryRd = UpdatableRemoteData.notCreated({
            parentId: this.reportPictureCategoryState.parentId
          } as IReportPictureCategory);
          if (this.itemId !== value.id) {
            this.$router.push(`/templates/reportpicturecategory`);
          }
          break;
        case 'existing':
          if (value.id) {
            await this.loadReportPictureCategory(value.id);
            if (+this.itemId !== value.id) {
              this.$router.push(`/templates/reportpicturecategory/${value.id}`);
            }
          }
          break;
      }
    }
  },
  methods: {
    treeDataLoaded() {
      if (+this.itemId) {
        this.reportPictureCategoryState = new ExistingReportPictureCategory(+this.itemId);
        this.categoryChanged(+this.itemId);
      }
    },
    resetReportPictureCategory() {
      this.reportPictureCategoryState = new NoReportPictureCategory();
      // @ts-ignore
      this.$refs.categoryTreeView.setSelected(undefined);
    },
    newReportPictureCategory() {
      this.reportPictureCategoryState = new NewReportPictureCategory(this.selectedCategoryId);
    },
    onHitReportPictureCategory(category: IReportPictureCategory) {
      this.categoryChanged(category.id);
    },
    async deleteReportPicture() {
      if (
        this.reportPictureCategoryState.kind !== 'existing' ||
        !this.reportPictureCategoryRd.hasData()
      ) {
        return;
      }
      if (confirm('Wollen Sie diese Bilderkategoriegruppe wirklich löschen?') === false) {
        return;
      }

      this.reportPictureCategoryRd = UpdatableRemoteData.updating(
        this.reportPictureCategoryRd.getData()
      );
      try {
        await this.$labordatApi
          .delete(`/api/reportpicturecategory/${this.reportPictureCategoryRd.getData().id}`, {
            data: this.reportPictureCategoryRd.getData()
          })
          .then((response) => response.data);
        await this.loadCategoriesTree();
        this.treeDataLoaded();
        this.resetReportPictureCategory();
      } catch (error) {
        this.reportPictureCategoryRd = UpdatableRemoteData.updateFailure(
          this.reportPictureCategoryRd.getData(),
          error as Error
        );
      }
    },
    async createReportPictureCategory() {
      const reportPictureCategory = this.reportPictureCategoryRd.getData();
      this.allowedToSave = true;
      this.reportPictureCategoryRd = UpdatableRemoteData.updating(reportPictureCategory);
      try {
        const result: IReportPictureCategory = await this.$labordatApi
          .post(`/api/reportpicturecategory`, reportPictureCategory)
          .then((response) => response.data as IReportPictureCategory);
        this.reportPictureCategoryState = new ExistingReportPictureCategory(result.id);
        await this.loadCategoriesTree();
        this.treeDataLoaded();
        // @ts-ignore
        this.$refs.categoryTreeView.setSelected(this.treeIdPrefix + result.id);
      } catch (error) {
        this.reportPictureCategoryRd = UpdatableRemoteData.updateFailure<
          IReportPictureCategory,
          Error
        >(reportPictureCategory, error as Error);
      }
    },
    async updateReportPictureCategory() {
      const reportPictureCategory = this.reportPictureCategoryRd.getData();
      if (reportPictureCategory.id === reportPictureCategory.parentId) {
        this.allowedToSave = false;
        return;
      }
      this.allowedToSave = true;
      this.reportPictureCategoryRd = UpdatableRemoteData.updating(reportPictureCategory);
      this.reportPictureCategoryRd = await this.$labordatApi
        .put(`/api/reportpicturecategory/${reportPictureCategory.id}`, reportPictureCategory)
        .then((response) => response.data as IReportPictureCategory)
        .then(async (data) => {
          await this.loadCategoriesTree();
          this.treeDataLoaded();
          return UpdatableRemoteData.success<IReportPictureCategory, Error>(data);
        })
        .catch((error) =>
          UpdatableRemoteData.updateFailure<IReportPictureCategory, Error>(
            reportPictureCategory,
            error as Error
          )
        );
    },
    async loadReportPictureCategory(id: number) {
      this.reportPictureCategoryRd = UpdatableRemoteData.loading();
      this.reportPictureCategoryRd = await this.$labordatApi.getUrd(
        `/api/reportpicturecategory/${id}`
      );
    },
    categoryChanged(categoryId: number) {
      if (this.selectedCategoryId !== categoryId) {
        this.selectedCategoryId = categoryId;
        if (this.selectedCategoryId) {
          this.reportPictureCategoryState = new ExistingReportPictureCategory(
            this.selectedCategoryId
          );
          if (
            // @ts-ignore
            this.$refs.categoryTreeView.selectedNodeId !==
            this.treeIdPrefix + this.selectedCategoryId
          ) {
            // @ts-ignore
            this.$refs.categoryTreeView.setSelected(this.treeIdPrefix + this.selectedCategoryId);
          }
        } else {
          this.reportPictureCategoryState = new NoReportPictureCategory();
          // @ts-ignore
          this.$refs.categoryTreeView.setSelected(undefined);
        }
      }
    },
    async loadCategoriesTree() {
      this.reportPictureCategoriesRd = await this.$labordatApi.getRd(
        `/api/reportpicturecategory/tree`
      );
    }
  }
});
