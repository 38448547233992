



























































































































import mixins from 'vue-typed-mixins';
import CreateAndUpdateForm from '@/mixins/CreateAndUpdateFormMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import CrmCustomerTypeahead from '@/components/CrmCustomerTypeahead.vue';
import AnalysisGroupCategoryTypeahead from '@/components/AnalysisGroupCategoryTypeahead.vue';

export default mixins(CreateAndUpdateForm, SharedDataMixin).extend({
  components: {
    CrmCustomerTypeahead,
    AnalysisGroupCategoryTypeahead
  },
  mounted: function () {
    // @ts-ignore
    this.setFormRef(this.$refs.analysisGroupForm);
  }
});
