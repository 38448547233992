







import Vue from 'vue';
export default Vue.extend({
  name: 'svg-circle',
  props: ['color', 'size', 'title'],
  computed: {
    halfSize: function () {
      return (this.size as number) / 2;
    },
    colorClass: function () {
      switch (this.color) {
        case 'red':
          return 'circle-red';
        case 'green':
          return 'circle-green';
        case 'yellow':
          return 'circle-yellow';
        default:
          return 'circle-grey';
      }
    }
  }
});
