

















































































































import Vue from 'vue';
import { SmartTable, Config, Column, Sorting, State, Filter } from 'rey-vue-smarttable';
import moment from 'moment';
import AutomaticReportSendingStatus from '@/models/AutomaticReportSending/AutomaticReportSendingProtocol/Api/AutomaticReportSendingStatus';
import AutomaticReportSendingResendDialog from './AutomaticReportSendingResendDialog.vue';
import AutomaticReportSendingSendDetail from './AutomaticReportSendingSendDetail.vue';
import CrmCustomerTypeahead from '@/components/CrmCustomerTypeahead.vue';
import ICustomer from '@/models/ICustomer';
import IAutomaticReportSendingProtocolItemEntity from '@/models/AutomaticReportSending/AutomaticReportSendingProtocol/Api/IAutomaticReportSendingProtocolItemEntity';

export default Vue.extend({
  components: {
    SmartTable,
    AutomaticReportSendingResendDialog,
    AutomaticReportSendingSendDetail,
    CrmCustomerTypeahead
  },
  data() {
    let dateTimeDisplayText = (value: string | null) => {
      if (!value) {
        return '';
      }
      return moment(value).format('DD.MM.YYYY HH:mm');
    };

    let translateStatus = (value: number): string => {
      if (AutomaticReportSendingStatus.InProgress == value) {
        return 'In Bearbeitung';
      }
      if (AutomaticReportSendingStatus.SuccessfullySent == value) {
        return 'Erfolgreich versendet';
      }
      if (AutomaticReportSendingStatus.Error == value) {
        return 'Fehlerhaft';
      }
      return 'Unbekannter Status';
    };

    let smartTableConfig = new Config(
      (row: { id: number }) => `${row.id}`,
      [
        new Column({
          title: 'Status',
          fieldName: 'status',
          fieldType: 'string',
          editable: () => false,
          converter: (value) => translateStatus(value),
          cellStyling: (row: any) => ({
            'bg-info': AutomaticReportSendingStatus.InProgress === row.status,
            'bg-success': AutomaticReportSendingStatus.SuccessfullySent === row.status,
            'bg-danger': AutomaticReportSendingStatus.Error === row.status,
            'text-white': true
          })
        }),
        new Column({
          title: 'Auftragsnummer',
          fieldName: 'orderNumber',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'Varianten',
          fieldName: 'reportVariantRange',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'Kunde',
          fieldName: 'customerName',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'Gestartet am',
          fieldName: 'startedOn',
          fieldType: 'string',
          editable: () => false,
          converter: (value) => dateTimeDisplayText(value)
        }),
        new Column({
          title: 'Versendet am',
          fieldName: 'sentOn',
          fieldType: 'string',
          editable: () => false,
          converter: (value) => dateTimeDisplayText(value)
        }),
        new Column({
          title: 'Empfänger',
          fieldName: 'mailReceivers',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'War erneuter Versand?',
          fieldName: 'wasResendOperation',
          fieldType: 'boolean',
          editable: () => false
        }),
        new Column({
          title: 'Ist erneuter Versand möglich?',
          fieldName: 'isResendPossible',
          fieldType: 'boolean',
          editable: () => false
        })
      ],
      [new Sorting('startedOn', 'descending')]
    ).withEfApiActions(
      // @ts-ignore
      this.$labordatApi,
      'AutomaticReportSendingProtocolItems'
    );

    return {
      smartTableRef: 'AutomaticReportSendingProtocolSmartTable',
      smartTableConfig: smartTableConfig,
      smartTableState: new State([new Sorting('startedOn', 'descending')]),
      selectedProtocolItem: null as IAutomaticReportSendingProtocolItemEntity | null,
      isResendDialogActive: false,
      isProtocolItemDetailActive: false,
      resendDialog: {
        isComponentReady: false,
        isResendCurrentlyRunning: false
      },
      protocolItemDetail: {
        isComponentReady: false
      },
      selectedProtocolItemForResend: null as IAutomaticReportSendingProtocolItemEntity | null,
      selectedProtocolItemForDetail: null as IAutomaticReportSendingProtocolItemEntity | null,
      resendStatus: null as null | 'success' | 'erroneous'
    };
  },
  computed: {
    isOverviewVisible(): boolean {
      return !this.isResendDialogActive && !this.isProtocolItemDetailActive;
    },
    canStartResendDialogButtonBeDisplayed(): boolean {
      return this.isOverviewVisible && (this.selectedProtocolItem?.isResendPossible ?? false);
    },
    canCloseResendDialogButtonBeDisplayed(): boolean {
      return this.isResendDialogActive;
    },
    canOpenProtocolItemDetailButtonBeDisplayed(): boolean {
      return this.isOverviewVisible && !!this.selectedProtocolItem;
    },
    canCloseProtocolItemDetailButtonBeDisplayed(): boolean {
      return this.isProtocolItemDetailActive;
    },
    canResendButtonBeDisplayed(): boolean {
      return this.isResendDialogActive && this.resendDialog.isComponentReady;
    },
    canResendBeTriggered(): boolean {
      return this.resendDialog.isComponentReady && !this.resendDialog.isResendCurrentlyRunning;
    },
    canDisplayGoToOrderButton(): boolean {
      return this.isOverviewVisible;
    }
  },
  methods: {
    startResendDialog() {
      if (!this.selectedProtocolItem) {
        return;
      }
      this.selectedProtocolItemForResend = this.selectedProtocolItem;
      this.isResendDialogActive = true;
    },
    closeResendDialog(reloadOfTableNeeded: boolean) {
      this.selectedProtocolItemForResend = null;
      this.isResendDialogActive = false;
      if (reloadOfTableNeeded) {
        (this.$refs[this.smartTableRef] as any)?.reload();
      }
    },
    openProtocolItemDetail() {
      if (!this.selectedProtocolItem) {
        return;
      }
      this.selectedProtocolItemForDetail = this.selectedProtocolItem;
      this.isProtocolItemDetailActive = true;
    },
    closeProtocolItemDetail() {
      this.isProtocolItemDetailActive = false;
    },
    handleSuccessfulResend() {
      this.resendStatus = null;
      this.$nextTick(() => (this.resendStatus = 'success'));
      this.closeResendDialog(true);
    },
    handleErroneousResend() {
      this.resendStatus = null;
      this.$nextTick(() => (this.resendStatus = 'erroneous'));
      this.closeResendDialog(true);
    },
    setCustomerFilter(selectedCustomer: ICustomer) {
      let newFilters: Filter[] = [];
      newFilters.push(...this.smartTableState.filters.filter((x) => x.fieldName != 'customerName'));
      newFilters.push(new Filter('customerName', 'string', 'equals', [selectedCustomer.firma]));
      this.smartTableState.filters = newFilters;
    },
    runResend() {
      (this.$refs.automaticReportSendingResendDialog as any).triggerResend();
    },
    goToOrder: function () {
      if (!this.selectedProtocolItem) {
        return;
      }

      this.$router.push(`/orders/${this.selectedProtocolItem.orderNumber}`);
    }
  }
});
