import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import UserMixin from './UserMixin';
import IUserSmartTableConfig from '@/models/IUserSmartTableConfig';

export default mixins(UserMixin).extend({
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async loadSmartTableConfig(tableId: string): Promise<any | undefined> {
      try {
        const result = await this.$labordatApi
          .get('/api/smarttableconfig?user=' + this.userEmail + '&tableId=' + tableId)
          .then((response) => response.data as IUserSmartTableConfig);
        if (result && result.config !== '') {
          return JSON.parse(result.config);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('An error occured during loading config: ', e);
        return undefined;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveSmartTableConfigDebounced: _.debounce(function (tableId: string, config: any) {
      // @ts-ignore
      this.saveSmartTableConfig(tableId, config);
    }, 500),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async saveSmartTableConfig(tableId: string, config: any) {
      try {
        const columnsOrdering = config.columns.map((c: { ordering: number }) => c.ordering);
        const distinctValues = _.uniq(columnsOrdering);
        if (distinctValues.length === config.columns.length) {
          const tableConfigUser: IUserSmartTableConfig = {
            config: JSON.stringify(config),
            user: this.userEmail,
            tableId: tableId
          };
          await this.$labordatApi.post('/api/smarttableconfig', tableConfigUser);
        } else {
          // eslint-disable-next-line no-console
          console.error('Config could not be saved because the config was invalid', config);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('An error occured during saving config: ', e);
      }
    }
  }
});
