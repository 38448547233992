enum LayoutElementType {
  Title = 0,
  Text = 1,
  Sum = 2,
  PageBreak = 3,
  Parameter = 4,
  Price = 5
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace LayoutElementType {
  export function parse(type: string): LayoutElementType {
    // @ts-ignore
    return LayoutElementType[type];
  }

  export function toString(type: LayoutElementType): string {
    return LayoutElementType[type];
  }

  export function toGerman(type: LayoutElementType): string {
    switch (type) {
      case LayoutElementType.PageBreak:
        return 'U';
      case LayoutElementType.Title:
        return 'H';
      case LayoutElementType.Text:
        return 'T';
      case LayoutElementType.Sum:
        return 'S';
      case LayoutElementType.Parameter:
        return 'A';
      case LayoutElementType.Price:
        return 'P';
    }
  }
}
export default LayoutElementType;
