


































import Vue from 'vue';
import FileUpAndDownload from '@/components/FileUpAndDownload.vue';

export default Vue.extend({
  components: { FileUpAndDownload },
  props: { value: Object },
  name: 'sample-picture-details-form',
  methods: {
    uploadFile(id: string, file: File) {
      this.$emit('file-uploaded', file);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadFile(id: string) {
      this.$emit('file-downloaded');
    }
  }
});
