import Vue from 'vue';
import _ from 'lodash';
import { IPagedResult } from 'rey-vue-smarttable';
import IReportPictureCategory from '@/models/IReportPictureCategory';

export default Vue.extend({
  data: function () {
    return {
      allCategories: new Array<IReportPictureCategory>()
    };
  },
  mounted: async function () {
    await this.initCategoryTree();
  },
  methods: {
    initCategoryTree: async function () {
      this.allCategories = await this.$labordatApi
        .get('/api/reportpicturecategory')
        .then((response) => response.data as IPagedResult<IReportPictureCategory>)
        .then((data) => data.entities);
    },
    buildCategoryTree: function (categories: IReportPictureCategory[]) {
      const data = _.cloneDeep(categories);
      return data.map((a) => this.buildSingleCategoryTree(a));
    },
    buildSingleCategoryTree: function (category: IReportPictureCategory) {
      if (this.allCategories !== null && this.allCategories.length > 0) {
        const data = _.cloneDeep(category);
        const items = new Array<string>();
        let currentId = data.parentId;
        while (currentId !== null) {
          const cat = this.allCategories.find((c) => c.id === currentId);
          if (cat != null) {
            items.splice(0, 0, cat.name);
            currentId = cat.parentId;
          }
        }

        items.push(data.name);
        data.name = items.join(' > ');
        return data;
      } else {
        return category;
      }
    }
  }
});
