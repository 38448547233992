

























import Vue from 'vue';
export default Vue.extend({
  name: 'radio-multi-select',
  props: {
    options: Array,
    values: String,
    allItemsValue: String,
    toId: Function,
    toValue: Function,
    toTitle: Function,
    disabled: Boolean
  },
  data: function () {
    return {
      // @ts-ignore
      radioChoice: this.values || this.allItemsValue
    };
  },
  watch: {
    values: function (): void {
      this.radioChoice = this.values;
    }
  },
  methods: {
    selectValue: function (value: string) {
      let choices = this.radioChoice.split(',').filter((c) => c !== '');
      if (choices.indexOf(value) < 0) {
        if (value === this.allItemsValue) {
          this.radioChoice = this.allItemsValue;
        } else {
          this.radioChoice = choices
            .filter((c) => c !== this.allItemsValue && c !== value)
            .concat(value)
            .join(',');
        }
      } else {
        this.radioChoice = choices.filter((c) => c !== value).join(',');
      }

      if (this.radioChoice.length === 0) {
        this.radioChoice = this.allItemsValue;
      }

      // @ts-ignore
      this.$emit('input', this.radioChoice);
    }
  }
});
