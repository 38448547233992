import mixins from 'vue-typed-mixins';
import SettingsMixin from './SettingsMixin';
import DateTimeMixin from './DateTimeMixin';
import FormatMixin from './FormatMixin';
import OrderViewHelperMixin from './OrderViewHelperMixin';
import { Column, Config, Sorting, State, Filter, FilterExpressionType } from 'rey-vue-smarttable';
import ISummaryDefinition, {
  SummaryDefinitionEntityType,
  SummaryDefinitionCategoryType
} from '@/models/ISummaryDefinition';
import ISummaryDefinitionOptGroupRepresentation from '@/models/ISummaryDefinitionOptGroupRepresentation';
import OrderStatus from '@/models/OrderStatus';
import ReportStatus from '@/models/ReportStatus';
import ValidationStatusType from '@/models/ValidationStatusType';
import ValidationStatusOverrideType from '@/models/ValidationStatusOverrideType';
import OrderLockStatus from '@/models/OrderLockStatus';
import UncertaintyOfMeasurementType from '@/models/UncertaintyOfMeasurementType';
import OrderDelayStatus from '@/models/OrderDelayStatus';
import InvoiceMode from '@/models/InvoiceMode';
import ContractType from '@/models/ContractType';

export default mixins(DateTimeMixin, FormatMixin, SettingsMixin, OrderViewHelperMixin).extend({
  data: function () {
    return {
      summaryDefinitionIdOfMyDepartmentOrders: 1,
      summaryDefinitionIdOfOrderOverview: 2,
      summaryDefinitionIdOfCompletedOrders: 3,
      summaryDefinitionIdOfOrdersWithPendingResults: 4,
      summaryDefinitionIdOfOrdersWithPendingBilling: 5,
      summaryDefinitionIdOfSampleOverview: 6,
      summaryDefinitionIdOfAnalysisParameterOverview: 7,
      summaryDefinitionIdOfBudget: 8,
      summaryDefinitionIdOfOrderDelay: 9,
      analysisParameterColumns_: [
        new Column({ title: 'P-Nr.', fieldName: 's_ItemNumber', fieldType: 'numeric' }),
        new Column({
          title: 'Code',
          fieldName: 'a_AnalysisParameterDefinitionCode',
          fieldType: 'string'
        }),
        new Column({ title: 'DE', fieldName: 'a_NameDe', fieldType: 'string' }),
        new Column({ title: 'FR', fieldName: 'a_NameFr', fieldType: 'string' }),
        new Column({ title: 'IT', fieldName: 'a_NameIt', fieldType: 'string' }),
        new Column({ title: 'EN', fieldName: 'a_NameEn', fieldType: 'string' }),
        new Column({
          title: 'Analysegruppencode',
          fieldName: 'a_AnalysisGroupCode',
          fieldType: 'string'
        }),
        new Column({
          title: 'Interne Methode',
          fieldName: 'a_InternalMethod',
          fieldType: 'string'
        }),
        new Column({ title: 'Norm', fieldName: 'a_Norm', fieldType: 'string' }),
        new Column({
          title: 'Analyseverfahren',
          fieldName: 'a_AnalysisProcedureCode',
          fieldType: 'string'
        }),
        new Column({
          title: 'Tarif',
          fieldName: 'a_PriceInChf',
          fieldType: 'numeric',
          colStyling: 'text-right'
        }),
        new Column({ title: 'Tarif Null', fieldName: 'a_ZeroPrice', fieldType: 'boolean' }),
        new Column({ title: 'Präzision', fieldName: 'a_Precision', fieldType: 'numeric' }),
        new Column({ title: 'Rapportpos.', fieldName: 'a_RapportPosition', fieldType: 'numeric' }),
        new Column({ title: 'Berech.pos.', fieldName: 'a_CalculationOrder', fieldType: 'numeric' }),
        new Column({ title: 'Drucken', fieldName: 'a_ShowInPrint', fieldType: 'boolean' }),
        new Column({ title: 'Formel', fieldName: 'a_Formula', fieldType: 'string' }),
        new Column({
          title: 'Unterbeauftragslabor',
          fieldName: 'a_CrmSubContractorId',
          fieldType: 'numeric'
        }),
        new Column({ title: 'Dimension', fieldName: 'a_DimensionCode', fieldType: 'string' }),
        new Column({ title: 'Abteilung', fieldName: 'a_DepartmentCode', fieldType: 'string' }),
        new Column({ title: 'Resultattyp', fieldName: 'a_ResultTypeCode', fieldType: 'string' }),
        new Column({ title: 'Richtwert', fieldName: 'a_GuidanceValue', fieldType: 'string' }),
        new Column({
          title: 'Standardwert Min.',
          fieldName: 'a_StandardValueMin',
          fieldType: 'string'
        }),
        new Column({
          title: 'Standardwert Max.',
          fieldName: 'a_StandardValueMax',
          fieldType: 'string'
        }),
        new Column({
          title: 'Messresultat',
          fieldName: 'a_Measurement',
          fieldType: 'string',
          converter: (cellValue, row) => {
            let realCellValue = cellValue;

            if (
              realCellValue !== undefined &&
              realCellValue !== null &&
              realCellValue !== '' &&
              !isNaN(+realCellValue)
            ) {
              if (row.a_Precision !== 9) {
                realCellValue = (+realCellValue).toFixed(row.a_Precision);
              }

              realCellValue = this.withSeparators(realCellValue);
            }

            return realCellValue;
          },
          colStyling: 'text-right'
        }),
        new Column({
          title: 'Messdatum',
          fieldName: 'a_MeasurementDateTime',
          fieldType: 'date'
        }),
        new Column({ title: 'Prüfer', fieldName: 'a_Examinant', fieldType: 'string' }),
        new Column({
          title: 'Kommentar',
          fieldName: 'a_Comment',
          fieldType: 'string',
          converter: this.removeHtmlTags
        }),
        new Column({
          title: 'Art der Messunsicherheit',
          fieldName: 'a_UncertaintyOfMeasurementType',
          fieldType: 'dropdown',
          dropdownOptions: [
            { id: UncertaintyOfMeasurementType.Percentage, title: 'Prozentual' },
            { id: UncertaintyOfMeasurementType.Absolute, title: 'Absolut' }
          ]
        }),
        new Column({
          title: 'Messunsicherheit',
          fieldName: 'a_UncertaintyOfMeasurement',
          fieldType: 'numeric'
        }),
        new Column({
          title: 'Nachweisgrenze',
          fieldName: 'a_LimitOfDetection',
          fieldType: 'string'
        }),
        new Column({
          title: 'Bestimmungsgrenze',
          fieldName: 'a_LimitOfDetermination',
          fieldType: 'string'
        }),
        new Column({ title: 'Akkreditiert', fieldName: 'a_Accredited', fieldType: 'boolean' }),
        new Column({
          title: 'GMP',
          fieldName: 'a_GoodManufacturingPractice',
          fieldType: 'boolean'
        }),
        new Column({
          title: 'Bewertung',
          fieldName: 'a_Validation',
          fieldType: 'dropdown',
          dropdownOptions: [
            { id: ValidationStatusType.Unvalidated, title: 'Nicht validiert' },
            { id: ValidationStatusType.Valid, title: 'Gültig' },
            { id: ValidationStatusType.Invalid, title: 'Ungültig' },
            { id: ValidationStatusType.NotValidatable, title: 'Nicht bewertbar' },
            { id: ValidationStatusType.NoValue, title: 'Kein Resultat' },
            { id: ValidationStatusType.Erroneous, title: 'Fehleingabe' },
            { id: ValidationStatusType.NoGuidanceValue, title: 'Kein Richtwert' }
          ]
        }),
        new Column({
          title: 'Bewertung manuell',
          fieldName: 'a_ValidationOverride',
          fieldType: 'dropdown',
          dropdownOptions: [
            { id: ValidationStatusOverrideType.Automatic, title: 'Automatisch' },
            { id: ValidationStatusOverrideType.Valid, title: 'Gültig' },
            { id: ValidationStatusOverrideType.Invalid, title: 'Ungültig' },
            { id: ValidationStatusOverrideType.NotValidatable, title: 'Nicht bewertbar' }
          ]
        })
      ],
      sampleColumns_: [
        new Column({ title: 'P-Nr.', fieldName: 's_ItemNumber', fieldType: 'numeric' }),
        new Column({ title: 'Probebez.', fieldName: 's_Name', fieldType: 'string' }),
        new Column({
          title: 'Messstelle',
          fieldName: 'c_SamplingPointReference',
          fieldType: 'string'
        }),
        new Column({ title: 'Material', fieldName: 's_MaterialCode', fieldType: 'string' }),
        new Column({
          title: 'Eingangsdatum',
          fieldName: 's_ReceiptDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Analysedatum',
          fieldName: 's_AnalysisDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Aufbewahrungsdatum',
          fieldName: 's_RetentionDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Kommentar',
          fieldName: 's_Comment',
          fieldType: 'string',
          editable: () => false,
          converter: this.removeHtmlTags
        }),
        new Column({
          title: 'Information',
          fieldName: 's_Information',
          fieldType: 'string',
          editable: () => false,
          converter: this.removeHtmlTags
        }),
        new Column({
          title: 'Bewertungsgrundlagen',
          fieldName: 's_BasisOfValidation',
          fieldType: 'string',
          editable: () => false,
          converter: this.removeHtmlTags
        }),
        new Column({ title: 'Bewerten', fieldName: 's_IsValidated', fieldType: 'boolean' }),
        new Column({
          title: 'Preis',
          fieldName: 'c_PriceInChf',
          fieldType: 'numeric',
          editable: () => false,
          converter: (cellValue) => this.withSeparators(this.formatWithPrecision(cellValue, 2)),
          colStyling: 'text-right'
        })
      ],
      orderColumns_: [
        new Column({
          title: 'Kunde: Firma',
          fieldName: 'o_CustomerCompanyName',
          fieldType: 'string'
        }),
        new Column({ title: 'Auftragsbez.', fieldName: 'o_OrderDescription', fieldType: 'string' }),
        new Column({ title: 'Abteilung', fieldName: 'o_DepartmentCode', fieldType: 'string' }),
        new Column({
          title: 'Status',
          fieldName: 'o_Status',
          fieldType: 'dropdown',
          editable: () => false,
          dropdownOptions: [
            { id: OrderStatus.New, title: 'Erfasst' },
            { id: OrderStatus.AnalysisStarted, title: 'Analyse gestartet' },
            { id: OrderStatus.AnalysisCompleted, title: 'Analyse abgeschlossen' },
            { id: OrderStatus.Reported, title: 'Rapportiert' },
            { id: OrderStatus.Invoiced, title: 'Verrechnet' },
            { id: OrderStatus.Completed, title: 'Abgeschlossen' }
          ]
        }),
        new Column({
          title: 'Sperre',
          fieldName: 'o_LockStatus',
          fieldType: 'dropdown',
          editable: () => false,
          dropdownOptions: [
            { id: OrderLockStatus.Unlocked, title: 'Offen' },
            { id: OrderLockStatus.Locked, title: 'Gesperrt' },
            { id: OrderLockStatus.LockedByExternalApp, title: 'Gesperrt in externer Applikation' }
          ]
        }),
        new Column({
          title: 'Berichtstatus',
          fieldName: 'o_ReportStatus',
          fieldType: 'dropdown',
          editable: () => false,
          dropdownOptions: [
            { id: ReportStatus.New, title: 'Erfasst' },
            { id: ReportStatus.FirstSignature, title: 'Erstunterschrift' },
            { id: ReportStatus.Signed, title: 'Freigegeben' },
            { id: ReportStatus.Generated, title: 'Generiert' },
            { id: ReportStatus.Outdated, title: 'Veraltet' },
            { id: ReportStatus.Invalid, title: 'Ungültig' },
            { id: ReportStatus.Irrelevant, title: 'Nicht relevant' }
          ]
        }),
        new Column({
          title: 'Auftragseingang',
          fieldName: 'o_ReceiptDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Liefertermin',
          fieldName: 'o_DeliveryDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({ title: 'Probenahme', fieldName: 'o_SampleTaker', fieldType: 'string' }),
        new Column({
          title: 'Verrechnungsdatum',
          fieldName: 'o_InvoiceDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Verrechnungskommentar',
          fieldName: 'o_InvoiceComment',
          fieldType: 'string'
        }),
        new Column({
          title: 'Fakturierungsdatum',
          fieldName: 'o_CompletionDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({ title: 'Notizen', fieldName: 'o_InternalNotes', fieldType: 'string' }),
        new Column({
          title: 'Kundenkonditionen',
          fieldName: 'o_CustomerConditions',
          fieldType: 'string',
          editable: () => false,
          converter: this.removeHtmlTags
        }),
        new Column({ title: 'Kundennr.', fieldName: 'o_CustomerNumber', fieldType: 'numeric' }),
        new Column({
          title: 'Kunde: Abteilung',
          fieldName: 'o_CustomerDepartment',
          fieldType: 'string'
        }),
        new Column({
          title: 'Kunde: Anrede',
          fieldName: 'o_CustomerSalutation',
          fieldType: 'string'
        }),
        new Column({
          title: 'Kunde: Kontakt',
          fieldName: 'o_CustomerContact',
          fieldType: 'string'
        }),
        new Column({
          title: 'Kunde: Zusatz',
          fieldName: 'o_CustomerAdditionalInfo',
          fieldType: 'string'
        }),
        new Column({
          title: 'Kunde: Adresse',
          fieldName: 'o_CustomerAddress',
          fieldType: 'string'
        }),
        new Column({
          title: 'Kunde: Adresszeile2',
          fieldName: 'o_CustomerAddressLine2',
          fieldType: 'string'
        }),
        new Column({
          title: 'Kunde: Postfach',
          fieldName: 'o_CustomerPostOfficeBox',
          fieldType: 'string'
        }),
        new Column({ title: 'Kunde: PLZ', fieldName: 'o_CustomerZipCode', fieldType: 'string' }),
        new Column({ title: 'Kunde: Ortschaft', fieldName: 'o_CustomerCity', fieldType: 'string' }),
        new Column({ title: 'Kunde: Land', fieldName: 'o_CustomerCountry', fieldType: 'string' }),
        new Column({
          title: 'Rechnungsadr. Nr.',
          fieldName: 'o_InvoiceAddressNumber',
          fieldType: 'numeric'
        }),
        new Column({
          title: 'Rechnungsadr. Fibu-Nr.',
          fieldName: 'o_InvoiceAddressFibuNumber',
          fieldType: 'numeric'
        }),
        new Column({
          title: 'Rechnungsadr. Firma',
          fieldName: 'o_InvoiceAddressCompanyName',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. Abteilung',
          fieldName: 'o_InvoiceAddressDepartment',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. Anrede',
          fieldName: 'o_InvoiceAddressSalutation',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. Zusatz',
          fieldName: 'o_InvoiceAddressAdditionalInfo',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. Adresse',
          fieldName: 'o_InvoiceAddressAddress',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. Adresszeile2',
          fieldName: 'o_InvoiceAddressAddressLine2',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. Postfach',
          fieldName: 'o_InvoiceAddressPostOfficeBox',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. PLZ',
          fieldName: 'o_InvoiceAddressZipCode',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. Ortschaft',
          fieldName: 'o_InvoiceAddressCity',
          fieldType: 'string'
        }),
        new Column({
          title: 'Rechnungsadr. Land',
          fieldName: 'o_InvoiceAddressCountry',
          fieldType: 'string'
        }),
        new Column({
          title: 'Kosten im Haus',
          fieldName: 'c_SamplesInHouseTotalChf',
          fieldType: 'numeric',
          editable: () => false,
          converter: (cellValue) => this.withSeparators(this.formatWithPrecision(cellValue, 2)),
          colStyling: 'text-right'
        }),
        new Column({
          title: 'Kosten Untervergabe',
          fieldName: 'c_SamplesSubcontractedTotalChf',
          fieldType: 'numeric',
          editable: () => false,
          converter: (cellValue) => this.withSeparators(this.formatWithPrecision(cellValue, 2)),
          colStyling: 'text-right'
        }),
        new Column({
          title: 'Rabatt im Haus',
          fieldName: 'c_SamplesInHouseDiscountChf',
          fieldType: 'numeric',
          editable: () => false,
          converter: (cellValue) => this.withSeparators(this.formatWithPrecision(cellValue, 2)),
          colStyling: 'text-right'
        }),
        new Column({
          title: 'Zuschläge',
          fieldName: 'o_AdditionChf',
          fieldType: 'numeric',
          editable: () => false,
          converter: (cellValue) => this.withSeparators(this.formatWithPrecision(cellValue, 2)),
          colStyling: 'text-right'
        }),
        new Column({
          title: 'Spesen',
          fieldName: 'o_ExpensesChf',
          fieldType: 'numeric',
          editable: () => false,
          converter: (cellValue) => this.withSeparators(this.formatWithPrecision(cellValue, 2)),
          colStyling: 'text-right'
        }),
        new Column({
          title: 'Rechnungsbetrag',
          fieldName: 'c_TotalNetAmount',
          fieldType: 'numeric',
          editable: () => false,
          converter: (cellValue) => this.withSeparators(this.formatWithPrecision(cellValue, 2)),
          colStyling: 'text-right'
        }),
        new Column({
          title: 'Erstellt von',
          fieldName: 'o_CreatedBy',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'Erstellt am',
          fieldName: 'o_CreatedAt',
          fieldType: 'string',
          editable: () => false,
          converter: this.toDateTimeString
        })
      ],
      budgetColumns_: [
        new Column({
          title: 'Autrag',
          fieldName: 'orderId',
          fieldType: 'string',
          editable: () => false,
          cellStyling: (cell) => {
            return {
              isInternalInvoicing: cell.invoiceMode == InvoiceMode.Internal
            };
          }
        }),
        new Column({
          title: 'Kunde: Firma',
          fieldName: 'customer',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'Auftragseingangsdatum',
          fieldName: 'receiptDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Liefertermin',
          fieldName: 'deliveryDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Verrechnungsdatum',
          fieldName: 'invoiceDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Fakturierungsdatum',
          fieldName: 'completionDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Betrag',
          fieldName: 'calculatedBudget',
          fieldType: 'numeric',
          editable: () => false,
          converter: (cellValue) => this.withSeparators(this.formatWithPrecision(cellValue, 2)),
          colStyling: 'text-right'
        }),
        new Column({
          title: 'Servicevertrag',
          fieldName: 'contractType',
          fieldType: 'string',
          editable: () => false,
          converter: (cellValue) => (cellValue === ContractType.Service ? 'Ja' : 'Nein'),
          filterConverter: (cellValue: FilterExpressionType) =>
            cellValue === 'Ja' ? [ContractType.Service] : [ContractType.Standard]
        }),
        new Column({
          title: 'Interne Verrechnung',
          fieldName: 'invoiceMode',
          fieldType: 'string',
          editable: () => false,
          converter: (cellValue) => (cellValue === InvoiceMode.Internal ? 'Ja' : 'Nein'),
          filterConverter: (cellValue: FilterExpressionType) =>
            cellValue === 'Ja' ? [InvoiceMode.Internal] : [InvoiceMode.Standard]
        })
      ],
      orderDelayColumns_: [
        new Column({
          title: 'Auftragsnummer',
          fieldName: 'orderId',
          fieldType: 'string',
          editable: () => false,
          cellStyling: (cell) => {
            return {
              withDelay:
                cell.delayStatus == OrderDelayStatus.UncompletedWithDelay ||
                cell.delayStatus == OrderDelayStatus.CompletedWithDelay,
              withoutDelay: cell.delayStatus == OrderDelayStatus.CompletedWithoutDelay
            };
          }
        }),
        new Column({
          title: 'Auftragsbezeichnung',
          fieldName: 'orderDescription',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'Abteilung',
          fieldName: 'orderDepartment',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'Kundenname',
          fieldName: 'customerCompanyName',
          fieldType: 'string',
          editable: () => false
        }),
        new Column({
          title: 'Auftragseingang',
          fieldName: 'receiptDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Liefertermin (Plan)',
          fieldName: 'plannedDeliveryDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Rapporttermin',
          fieldName: 'reportDate',
          fieldType: 'date',
          editable: () => false
        }),
        new Column({
          title: 'Verzug (Anzahl Tage)',
          fieldName: 'delayInDays',
          fieldType: 'numeric',
          editable: () => false
        }),
        new Column({
          title: 'Status',
          fieldName: 'delayStatus',
          fieldType: 'dropdown',
          editable: () => false,
          dropdownOptions: [
            { id: OrderDelayStatus.UncompletedWithoutDelay, title: 'Offen, ohne Verspätung' },
            { id: OrderDelayStatus.UncompletedWithDelay, title: 'Offen, mit Verspätung' },
            { id: OrderDelayStatus.CompletedWithoutDelay, title: 'Abgeschlossen, ohne Verspätung' },
            { id: OrderDelayStatus.CompletedWithDelay, title: 'Abgeschlossen, mit Verspätung' }
          ],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          csvExportConverter: (cellValue: any) => {
            switch (+cellValue) {
              case OrderDelayStatus.UncompletedWithoutDelay:
                return 'Offen, ohne Verspätung';
              case OrderDelayStatus.UncompletedWithDelay:
                return 'Offen, mit Verspätung';
              case OrderDelayStatus.CompletedWithoutDelay:
                return 'Abgeschlossen, ohne Verspätung';
              case OrderDelayStatus.CompletedWithDelay:
                return 'Abgeschlossen, mit Verspätung';
            }
            return cellValue;
          }
        })
      ],
      orderIdColumn_: [new Column({ title: 'Id', fieldName: 'o_Id', fieldType: 'string' })]
    };
  },
  computed: {
    orderDelayColumnSet: function (): Column[] {
      return this.orderDelayColumns_;
    },
    orderColumnSet: function (): Column[] {
      return this.orderIdColumn_.concat(this.orderColumns_);
    },
    sampleColumnSet: function (): Column[] {
      return this.orderIdColumn_.concat(this.sampleColumns_).concat(this.orderColumns_);
    },
    analysisParameterColumnSet: function (): Column[] {
      return this.orderIdColumn_
        .concat(this.analysisParameterColumns_)
        .concat(this.sampleColumns_.filter((c) => c.fieldName !== 's_ItemNumber'))
        .concat(this.orderColumns_);
    },
    budgetColumnSet: function (): Column[] {
      return this.budgetColumns_;
    },
    summaryDefinitions: function (): Array<ISummaryDefinition> {
      return [
        {
          id: this.summaryDefinitionIdOfMyDepartmentOrders,
          name: 'Aufträge meiner Abteilung',
          entity: 'Order' as SummaryDefinitionEntityType,
          category: 'Order' as SummaryDefinitionCategoryType,
          config: new Config(
            this.getKeyFromEntity('Order'),
            this.getColumnSetFor('Order'),
            [new Sorting('o_Id')],
            undefined,
            undefined,
            undefined,
            [new Filter('o_DepartmentCode', 'string', 'equals', [this.$department])]
          ).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('Order')
          ),
          state: new State([new Sorting('o_Id')])
        },
        {
          id: this.summaryDefinitionIdOfOrderOverview,
          name: 'Auftragsübersicht',
          entity: 'Order' as SummaryDefinitionEntityType,
          category: 'Order' as SummaryDefinitionCategoryType,
          config: new Config(this.getKeyFromEntity('Order'), this.getColumnSetFor('Order'), [
            new Sorting('o_Id')
          ]).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('Order')
          ),
          state: new State([new Sorting('o_Id')])
        },
        {
          id: this.summaryDefinitionIdOfCompletedOrders,
          name: 'Abgeschlossene Aufträge',
          entity: 'Order' as SummaryDefinitionEntityType,
          category: 'Order' as SummaryDefinitionCategoryType,
          config: new Config(
            this.getKeyFromEntity('Order'),
            this.getColumnSetFor('Order'),
            [new Sorting('o_Id')],
            undefined,
            undefined,
            undefined,
            [new Filter('o_Status', 'numeric', 'equals', [OrderStatus.Completed.toString()])]
          ).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('Order')
          ),
          state: new State([new Sorting('o_Id')])
        },
        {
          id: this.summaryDefinitionIdOfOrdersWithPendingResults,
          name: 'Aufträge offene Resultate',
          entity: 'Order' as SummaryDefinitionEntityType,
          category: 'Order' as SummaryDefinitionCategoryType,
          config: new Config(
            this.getKeyFromEntity('Order'),
            this.getColumnSetFor('Order'),
            [new Sorting('o_Id')],
            undefined,
            undefined,
            undefined,
            [new Filter('a_Measurement', 'string', 'equals', [''])]
          ).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('Order')
          ),
          state: new State([new Sorting('o_Id')])
        },
        {
          id: this.summaryDefinitionIdOfOrdersWithPendingBilling,
          name: 'Aufträge offene Verrechnung',
          entity: 'Order' as SummaryDefinitionEntityType,
          category: 'Order' as SummaryDefinitionCategoryType,
          config: new Config(
            this.getKeyFromEntity('Order'),
            this.getColumnSetFor('Order'),
            [new Sorting('o_Id')],
            undefined,
            undefined,
            undefined,
            [new Filter('o_InvoiceDate', 'string', 'equals', [''])]
          ).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('Order')
          ),
          state: new State([new Sorting('o_Id')])
        },
        {
          id: this.summaryDefinitionIdOfSampleOverview,
          name: 'Probenübersicht',
          entity: 'Sample' as SummaryDefinitionEntityType,
          category: 'Sample' as SummaryDefinitionCategoryType,
          config: new Config((x) => x.s_Id, this.getColumnSetFor('Sample'), [
            new Sorting('o_Id')
          ]).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('Sample')
          ),
          state: new State([new Sorting('o_Id')])
        },
        {
          id: this.summaryDefinitionIdOfAnalysisParameterOverview,
          name: 'Analyseparameterübersicht',
          entity: 'AnalysisParameter' as SummaryDefinitionEntityType,
          category: 'AnalysisParameter' as SummaryDefinitionCategoryType,
          config: new Config((x) => x.a_Id, this.getColumnSetFor('AnalysisParameter'), [
            new Sorting('o_Id')
          ]).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('AnalysisParameter')
          ),
          state: new State([new Sorting('o_Id')])
        },
        {
          id: this.summaryDefinitionIdOfBudget,
          name: 'Budgetierungshilfe',
          entity: 'Budget' as SummaryDefinitionEntityType,
          category: 'Budget' as SummaryDefinitionCategoryType,
          config: new Config(
            this.getKeyFromEntity('Budget'),
            this.getColumnSetFor('Budget'),
            [new Sorting('orderId')],
            undefined,
            undefined,
            undefined,
            []
          ).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('Budget')
          ),
          state: new State([new Sorting('orderId')])
        },
        {
          id: this.summaryDefinitionIdOfOrderDelay,
          name: 'Überschreitung von Lieferzeiten',
          entity: 'OrderDelay' as SummaryDefinitionEntityType,
          category: 'Additional' as SummaryDefinitionCategoryType,
          config: new Config(
            this.getKeyFromEntity('OrderDelay'),
            this.getColumnSetFor('OrderDelay'),
            [new Sorting('orderId')]
          ).withEfApiActions(
            // @ts-ignore
            this.$labordatApi,
            this.getApiUrlFromEntity('OrderDelay')
          ),
          state: new State([new Sorting('orderId')])
        }
      ];
    }
  },
  methods: {
    getKeyFromEntity: function (entity: SummaryDefinitionEntityType) {
      switch (entity) {
        case 'Order':
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (x: any) => x.o_Id;
        case 'Sample':
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (x: any) => x.s_Id;
        case 'AnalysisParameter':
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (x: any) => x.a_Id;
        case 'OrderDelay':
        case 'Budget':
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (x: any) => x.orderId;
      }
    },
    getApiUrlFromEntity: function (entity: SummaryDefinitionEntityType) {
      return `summarydata/${entity}`;
    },
    getColumnSetFor: function (entity: SummaryDefinitionEntityType) {
      switch (entity) {
        case 'Order':
          return this.orderColumnSet;
        case 'Sample':
          return this.sampleColumnSet;
        case 'AnalysisParameter':
          return this.analysisParameterColumnSet;
        case 'OrderDelay':
          return this.orderDelayColumnSet;
        case 'Budget':
          return this.budgetColumnSet;
      }
    },
    sortSummaryDefinitionByCategory: function (source: ISummaryDefinitionOptGroupRepresentation) {
      return {
        Order: source.Order,
        Sample: source.Sample,
        AnalysisParameter: source.AnalysisParameter,
        Budget: source.Budget,
        Additional: source.Additional
      };
    }
  }
});
