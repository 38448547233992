/* eslint-disable max-classes-per-file */

type ReportTemplateState = 'new' | 'existing' | 'none';

export interface IReportTemplateState {
  kind: ReportTemplateState;
  id: number | undefined;
}

export class NewReportTemplate implements IReportTemplateState {
  public kind: ReportTemplateState = 'new';
  public id = 0;
}

export class ExistingReportTemplate implements IReportTemplateState {
  public kind: ReportTemplateState = 'existing';
  public id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class NoReportTemplate implements IReportTemplateState {
  public kind: ReportTemplateState = 'none';
  public id: number | undefined = undefined;
}
