/* eslint-disable max-classes-per-file */

type SampleState = 'new' | 'existing' | 'none';

export interface ISampleState {
  kind: SampleState;
  sampleId: number | undefined;
}

export class NewSample implements ISampleState {
  public kind: SampleState = 'new';
  public sampleId = 0;
}

export class ExistingSample implements ISampleState {
  public kind: SampleState = 'existing';
  public sampleId: number;
  public showFastEntry: boolean;

  constructor(sampleId: number | string, showFastEntry: boolean) {
    this.sampleId = +sampleId;
    this.showFastEntry = showFastEntry;
  }
}

export class NoSample implements ISampleState {
  public kind: SampleState = 'none';
  public sampleId: number | undefined = undefined;
}
